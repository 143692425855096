import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IDefaultConfig } from '@libs/shared/interfaces';
import { fixORBNumber } from '@merim/utils';
import { COUNTER_NAME, IOrderCompleteData, MACHINE_ROLE_NAME } from '@shared/models';
import { values } from 'ramda';
import { combineLatest, map, Observable, of, Subject, timeout } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { IBKTechShar3dInfos } from '../interfaces/shar3d-client.interface';
import {
	ConfigurationUrlsType,
	DEVICE,
	RING_APP,
	SHAR3D_CLIENT_ACTION,
	SHAR3D_CLIENT_ACTIONS_URLS,
	SHAR3D_CLIENT_COMMAND,
	SHAR3D_CLIENT_ERROR,
	SHAR3D_CLIENT_PAYLOAD,
	SHAR3D_CLIENT_ROOT,
	SHAR3D_CLIENT_TASK,
	SHAR3D_CLIENT_TIMEOUT,
	URL_TYPE_ENUM,
} from '../models';
import { urlParam } from '../utils';
import { ConfigurationService } from './configuration.service';

const ORB_TIMEOUT = 4 * 1000;

@Injectable({ providedIn: 'root' })
export class Shar3dClientService<T extends IDefaultConfig> {
	private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();
	private url: ConfigurationUrlsType = { deviceUrl: '' };

	constructor(
		private readonly httpClient: HttpClient,
		private readonly configurationService: ConfigurationService<T>,
		private readonly route: ActivatedRoute
	) {
		const configValues$ = this.configurationService.config$;
		const queryParams = this.route.queryParams;

		combineLatest([configValues$, queryParams])
			.pipe(
				takeUntil(this.ngUnsubscribe$),
				filter(([configData, queryParams]) => configData?.['API']?.[DEVICE] || configData?.['API']?.[DEVICE] || queryParams?.device)
			)
			.subscribe(([configData, queryParams]) => {
				this.url = {
					deviceUrl: configData?.['API']?.[DEVICE]?.url || '',
					[URL_TYPE_ENUM.DIRECT_URL]: queryParams?.device || urlParam('device'),
				};
			});
	}

	getDeviceInformation(url = ''): Observable<IBKTechShar3dInfos> {
		const requestUrl = url ? `${url}/` : `${values(this.getUrlPrecedency)?.[0]}/`;
		return this.httpClient
			.get(`${requestUrl}${SHAR3D_CLIENT_ROOT}/${SHAR3D_CLIENT_COMMAND}?${SHAR3D_CLIENT_ACTION}=${SHAR3D_CLIENT_ACTIONS_URLS.TASK_INFOS}`)
			.pipe(map((data) => data as IBKTechShar3dInfos));
	}

	getNextORB(order: IOrderCompleteData, url: string = ''): Observable<number> {
		//  Check for the orb number to have been already assigned
		if (order.orb && order.orb > 0) {
			return of(order.orb);
		}

		// Count is based on Device Role
		let counter = COUNTER_NAME.ORB;
		if (order.machine.role.toUpperCase() === MACHINE_ROLE_NAME.POS) {
			counter = COUNTER_NAME.ORBPOS;
		} else if (order.machine.role.toUpperCase().indexOf(MACHINE_ROLE_NAME.DRIVE) >= 0) {
			counter = COUNTER_NAME.ORBDRIVE;
		}

		const requestUrlBase = url ? `${url}/` : `${this.url.deviceUrl}/`;
		const requestUrl: string = `${requestUrlBase}${SHAR3D_CLIENT_ROOT}/${SHAR3D_CLIENT_COMMAND}?${SHAR3D_CLIENT_ACTION}=${SHAR3D_CLIENT_ACTIONS_URLS.SIMPLE_CALLS}&${SHAR3D_CLIENT_TASK}=${RING_APP}&${SHAR3D_CLIENT_TIMEOUT}=${ORB_TIMEOUT}&${SHAR3D_CLIENT_PAYLOAD}=${counter}&${SHAR3D_CLIENT_ERROR}=ERROR`;

		return this.httpClient.get(requestUrl).pipe(
			timeout(ORB_TIMEOUT),
			catchError((e) => {
				console.error('getNextORB() failed', e);
				return of(NaN);
			}),
			map((item: number) => {
				const orbNumber = fixORBNumber(item);
				if (!orbNumber) {
					return 0;
				}
				return orbNumber;
			})
		);
	}

	get getUrlPrecedency(): Record<string, string> | undefined {
		if (this.url.directUrl) {
			return { [URL_TYPE_ENUM.DIRECT_URL]: this.url.directUrl };
		} else if (this.url.deviceUrl) {
			return { [URL_TYPE_ENUM.DEVICE_URL]: this.url.deviceUrl };
		}

		return undefined;
	}
}
