import { createReducer, on } from '@ngrx/store';
import { compose, lensPath, set } from 'ramda';
import { IDeviceRootState } from '../interfaces';
import { DeviceInformationLoadSuccess } from './actions';

export const initialState: IDeviceRootState = {
	information: null,
	loading: true,
};

export const reducer = createReducer(
	initialState,
	on(DeviceInformationLoadSuccess, (state, action) => {
		return <IDeviceRootState>compose(set(lensPath(['loading']), false), set(lensPath(['information']), action.information))(state);
	})
);
