/**
 * Hard-coded based on data from productFamilies in the BK/BurgerKing_France bigdatas.json (type BKBigDatas)
 */

export enum BK_FR_PRODUCT_FAMILIES {
	/**
	 * hot drinks
	 */
	Boissons_chaudes = '1',
	Burgers = '3',
	/**
	 * Toys
	 */
	Jouet_enfant = '11',
	/**
	 * Breakfast
	 */
	Petit_dejeuner = '12',
	/**
	 * Other sales
	 * I have no idea how it differes from Autre
	 */
	Ventes_annexes = '13',
	/**
	 * Ice creams and Shakes
	 */
	Glaces = '14',
	/**
	 * Other
	 * I have no idea how it differes from Ventes_annexes
	 */
	Autre = '15',
	/**
	 * Frigo.
	 * Drinks from Fridge
	 */
	FridgeDrings = '16',
	FrenchFries = '17',
	SnackSauces = '18',
	/**
	 * Fountain Drinks aka Tap Drinks
	 */
	TapDrinks = '19',
	SaladDressings = '20',
	FriedProducts = '21',
	PastryKitchen = '22',
	/**
	 * Fridge Desserts.
	 * This includes Fruits.
	 */
	FridgeDeserts = '23',
	HotDesserts = '24',
	ColdSalads = '25',
	HotSalads = '26',
}

// ---- BK -----
// PLEASE KEEP HERE FOR REFERENCE AND DEBUGING
// THERE ARE THE PRODUCTFAMILIES COPIED FROM BK BIGDATAS.JSON
// THEY SHOULD BE CONSTANT
/**
 *
"productFamilies": {
    "1": {
      "name": "Boissons chaudes",
      "description": "",
      "parentFamilyId": 0,
      "_picto": 4,
      "id": 1
    },
    "2": {
      "name": "Boissons froides",
      "description": "",
      "parentFamilyId": 0,
      "_picto": 3,
      "id": 2
    },
    "3": {
      "name": "Burgers",
      "description": "",
      "parentFamilyId": 0,
      "_picto": 1,
      "includeInLoadBalancing": true,
      "id": 3
    },
    "4": {
      "name": "Desserts",
      "description": "",
      "parentFamilyId": 0,
      "_picto": 7,
      "id": 4
    },
    "8": {
      "name": "Salades",
      "description": "",
      "parentFamilyId": 0,
      "_picto": 5,
      "id": 8
    },
    "9": {
      "name": "Snacks",
      "description": "",
      "parentFamilyId": 0,
      "_picto": 2,
      "includeInLoadBalancing": true,
      "id": 9
    },
    "10": {
      "name": "Sauces supplémentaires",
      "description": "",
      "parentFamilyId": 0,
      "_picto": 8,
      "id": 10
    },
    "11": {
      "name": "Jouet enfant",
      "description": "Jouet / Cadeau pour enfant",
      "parentFamilyId": 0,
      "_picto": 6,
      "id": 11
    },
    "12": {
      "name": "Petit déjeuner",
      "description": "Tous les produits petit déjeuner accessible que pour les restaurants applicables",
      "parentFamilyId": 0,
      "_picto": 9,
      "id": 12
    },
    "13": {
      "name": "Ventes annexes",
      "description": "Eléments sortant de la carte BK et qui ne sont pas soumis aux royalties BK.",
      "parentFamilyId": 0,
      "_picto": 0,
      "id": 13
    },
    "14": {
      "name": "Glaces",
      "description": "",
      "parentFamilyId": 4,
      "_picto": 7,
      "id": 14
    },
    "15": {
      "name": "Autre",
      "description": "Les éléments inclassables comme la couronne KING OU DOUBLE",
      "parentFamilyId": 0,
      "_picto": 0,
      "id": 15
    }
  },
 */
