import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { LanguageCode, QUERY_STRING } from '../models';
import { TranslateInitializer } from './translate-initializer.service';

const Params = new InjectionToken<LanguageCode>('Available languages');

@Injectable()
export class LanguageService implements OnDestroy {
	readonly currentLanguage$: Observable<LanguageCode>;
	private availableLanguages: LanguageCode[] = [];
	private readonly ngUnsubscribe$: Subject<void> = new Subject<void>();

	constructor(
		private readonly translateService: TranslocoService,
		private readonly translateInitializer: TranslateInitializer,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		@Inject(Params) languages: LanguageCode[]
	) {
		this.availableLanguages = languages;
		this.route.queryParams
			.pipe(
				map((item) => item[QUERY_STRING.PREFERRED_LANGUAGE]),
				filter((item) => !!item),
				takeUntil(this.ngUnsubscribe$)
			)
			.subscribe((item) => {
				if (languages.includes(item) && this.currentLanguage !== item) {
					this.currentLanguage = item;
				}
			});
		this.currentLanguage$ = translateService.langChanges$.pipe(
			map((lang) => {
				return this.stringToLanguageCode(lang);
			})
		);
	}

	get currentLanguage(): LanguageCode {
		return this.stringToLanguageCode(this.translateService.getActiveLang());
	}

	set currentLanguage(language: LanguageCode) {
		this.translateService.setActiveLang(language);
		this.translateInitializer.get();

		if (this.route.snapshot.queryParams[QUERY_STRING.PREFERRED_LANGUAGE]) {
			const queryParams = { [QUERY_STRING.PREFERRED_LANGUAGE]: language };
			this.router.navigate([], {
				relativeTo: this.route,
				queryParams: queryParams,
				queryParamsHandling: 'merge',
			});
		}
	}

	get allAvailableLanguages(): LanguageCode[] {
		return this.availableLanguages;
	}

	set allAvailableLanguages(languages: LanguageCode[]) {
		this.availableLanguages = languages;
		if (!this.availableLanguages.includes(this.translateService.getActiveLang() as LanguageCode) && this.availableLanguages.length > 0) {
			this.currentLanguage = this.availableLanguages?.[0];
		}
	}

	get currentLangCodeShort(): string {
		return this.currentLanguage.split('-')[0];
	}

	private stringToLanguageCode(languageCodeString: string): LanguageCode {
		if (this.isKnownLanguage(languageCodeString)) {
			return languageCodeString;
		} else {
			throw new Error(`Unknown language '${languageCodeString}'`);
		}
	}

	private isKnownLanguage(lang: string): lang is LanguageCode {
		return this.availableLanguages.find((langCode) => langCode === lang) !== undefined;
	}

	ngOnDestroy() {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
