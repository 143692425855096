import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { ANALYZE_HISTORY_FACADE } from '../../facade/analyze-history-facade.injection.token';
import { AnalyzeHistoryServiceProvider } from '../../facade/analyze-history-facade.provider.interface';

@Component({
	selector: 'dk-passed-revenue-tile',
	templateUrl: './passed-revenue-tile.component.html',
	styleUrls: ['./passed-revenue-tile.component.scss'],
})
export class PassedRevenueTileComponent {
	public tooltip = {
		shown: false,
		color: '',
		text: '',
		x: 0,
		y: 0,
	};

	public numberOfColumns = 12;
	public currentHourSeriesColumnOffset = 0;
	public chartPeakValue = 0;

	public viewData$ = combineLatest([
		this._analyzeHistoryFacade.revenueProgress$,
		this._translocoService.selectTranslateObject('revenueTile'),
	]).pipe(
		map(([revenueProgress, i18n]) => {
			if (revenueProgress?.activeHours?.length) {
				this.numberOfColumns = revenueProgress.activeHours.length;
			}

			if (revenueProgress?.currentHourSeries?.length) {
				revenueProgress.currentSeries.forEach((item, index) => {
					if (item.label === revenueProgress.currentHourSeries[0].label) {
						this.currentHourSeriesColumnOffset = index;
					}
				});
			}

			if (revenueProgress?.horizontalSteps?.length && revenueProgress?.horizontalSteps[1]) {
				this.chartPeakValue = revenueProgress.horizontalSteps[1];
			}
			return { revenueProgress, i18n };
		})
	);

	@ViewChild('svgContainer', { read: ElementRef }) svgContainerRef: ElementRef | undefined = undefined;

	constructor(
		private _translocoService: TranslocoService,
		@Inject(ANALYZE_HISTORY_FACADE) private _analyzeHistoryFacade: AnalyzeHistoryServiceProvider
	) {}

	get columnWidth(): number {
		let width = 12;

		if (this.svgContainerRef && this.svgContainerRef.nativeElement) {
			width = this.svgContainerRef.nativeElement.clientWidth / this.numberOfColumns;
		}

		return width;
	}

	get svgHeight(): number {
		let height = 300;

		if (this.svgContainerRef && this.svgContainerRef.nativeElement) {
			height = this.svgContainerRef.nativeElement.clientHeight;
		}

		return height;
	}

	calculateCy(absoluteValue: number): number {
		let height = 12;

		if (this.svgHeight && this.chartPeakValue) {
			const value100Perc = this.chartPeakValue;
			const value1Perc = value100Perc / 100;
			const valuePrec = absoluteValue / value1Perc;

			height = this.svgHeight - (this.svgHeight / 100) * valuePrec;
		}

		return height;
	}

	composePath(series: any): string {
		let path = '';

		if (!series?.length || series.every((x) => +x.value === 0)) {
			return path;
		}

		const columnWidth = this.columnWidth;
		const breakPoints = series.map((item, index) => {
			return `L ${index * columnWidth} ${this.calculateCy(item.value)}`;
		});
		path = `M 0 ${this.svgHeight} ${breakPoints.join(' ')} L ${(breakPoints.length - 1) * columnWidth} ${this.svgHeight}`;

		return path;
	}

	setTootltip(shown: boolean, item: any, color: string, x: number, y: number): void {
		this.tooltip = {
			shown,
			color,
			text: `${item.label}h : ${item.value} EUR`,
			x: x - 60,
			y: y - 30,
		};
	}

	hideTootltip(): void {
		this.tooltip = {
			shown: false,
			color: '',
			text: '',
			x: 0,
			y: 0,
		};
	}
}
