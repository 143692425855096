import { Translation, TranslocoInterceptor } from '@ngneat/transloco';

export class TranslocoCustomInterceptor implements TranslocoInterceptor {
	preSaveTranslation(translation: Translation, lang: string): Translation {
		return translation;
	}

	preSaveTranslationKey(key: string, value: string, lang: string): string {
		return value;
	}
}
