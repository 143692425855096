import { Inject, Injectable, InjectionToken } from '@angular/core';
import type { IShar3dWindow } from '../interfaces';
import { IShar3dWindowModuleConfiguration } from '../interfaces';
import { INACTIVITY_STATE } from '../models';
import { InactivityService } from './inactivity.service';
import { RingConnectorService } from './ring-connector.service';

function getWindow(): any {
	return window;
}

export const SharedWindowToken = new InjectionToken<IShar3dWindow>('Shared window interface');

@Injectable()
export class Shar3dDesktopBaseService {
	private _window: IShar3dWindow;

	constructor(
		private readonly ringConnectorService: RingConnectorService,
		private readonly inactivityService: InactivityService,
		@Inject(SharedWindowToken) configuration?: IShar3dWindowModuleConfiguration
	) {
		// this should be a singleton service in the application from which other services inherit

		if (!this._window) {
			this._window = this.nativeWindow;
			this._isHostShared = this.nativeWindow?.shar3dbridge !== undefined;
			if (this._isHostShared) {
				this._window.onPlayerActivity = () => {
					if (configuration?.onPlayerActivity) {
						configuration.onPlayerActivity();
					}
				};
				this._window.onPlayerInactivity = () => {
					if (configuration?.onPlayerInactivity) {
						configuration.onPlayerInactivity();
					}

					this.inactivityService.setInactivityMode(INACTIVITY_STATE.ACTIVE);
				};

				this._window.onShar3dGlobalVar = () => configuration?.onShar3dGlobalVar;
				this._window?.shar3dbridge?.playerReady();

				this.inactivityService.hostWindow = this._window;
				this.ringConnectorService.hostWindow = this._window;
				this.ringConnectorService.startReadingMessages();
			}
		}
	}

	private _isHostShared: boolean = false;

	get isHostShared(): boolean {
		return this._isHostShared;
	}

	get getInactivityService(): InactivityService {
		return this.inactivityService;
	}

	get getRingConnectorService(): RingConnectorService {
		return this.ringConnectorService;
	}

	get nativeWindow(): IShar3dWindow {
		return getWindow();
	}

	get getThisWIndow(): IShar3dWindow {
		return this._window;
	}
}
