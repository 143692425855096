import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { TranslocoService } from '@ngneat/transloco';
import { DatePeriod, DATE_PERIODS_ORDER } from '@libs/dash/core/entity';

@Component({
	selector: 'app-date-picker',
	templateUrl: './date-picker.component.html',
	styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent {
	@Output() dateSelection = new EventEmitter<{ from: DateTime; to: DateTime }>();
	@Input() initDate: { from: DateTime; to: DateTime } = {
		from: DateTime.now().startOf('week'),
		to: DateTime.now().endOf('week'),
	};

	DatePeriod = DatePeriod;
	datePeriods = DATE_PERIODS_ORDER;
	selectedPeriod: DatePeriod | null = null;

	range = new FormGroup({
		from: new FormControl(this.initDate.from.toJSDate()),
		to: new FormControl(this.initDate.to.toJSDate()),
	});

	constructor(protected translocoService: TranslocoService) {}

	get formattedDateRange(): string {
		return this.getFormattedDateRange(this.range.value.from, this.range.value.to);
	}

	selectPeriod(period: DatePeriod | string): void {
		this.selectedPeriod = period as DatePeriod;
		let from: DateTime, to: DateTime;

		switch (period) {
			case DatePeriod.Today:
				from = to = DateTime.now().startOf('day');
				break;
			case DatePeriod.Week:
				from = DateTime.now().startOf('week');
				to = DateTime.now().endOf('week');
				break;
			case DatePeriod.Month:
				from = DateTime.now().startOf('month');
				to = DateTime.now().endOf('month');
				break;
			case DatePeriod.Year:
				from = DateTime.now().startOf('year');
				to = DateTime.now().endOf('year');
				break;
		}

		this.range.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this.emitDateSelection();
	}

	emitDateSelection(): void {
		const { from, to } = this.range.value;
		if (from && to) {
			this.dateSelection.emit({
				from: DateTime.fromJSDate(from),
				to: DateTime.fromJSDate(to),
			});
		}
	}

	getFormattedDateRange(startDate: Date, endDate: Date): string {
		if (!startDate || !endDate) return this.translocoService.translate('sosKioskTile.datePicker.customRange');

		const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
		const lang = this.translocoService.getActiveLang();
		const start = startDate.toLocaleDateString(lang, options);
		const end = endDate.toLocaleDateString(lang, options);

		return start === end ? start : `${start} - ${end}`;
	}

	openPicker(picker): void {
		picker.open();
	}

	applyCustomDates(): void {
		this.selectedPeriod = null;
		this.emitDateSelection();
	}
}
