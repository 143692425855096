import { createSelector } from '@ngrx/store';
import { IOrderState } from '../interfaces';

export const selectOrderState = (state) => state['order'];

export const getCurrentOrder = createSelector(selectOrderState, (root: IOrderState) => root.data);

export const getCurrentOrderLocation = createSelector(selectOrderState, (root: IOrderState) => root.location);

export const getCurrentOrderDeliveryMode = createSelector(selectOrderState, (root: IOrderState) => root.data.deliveryMode);

export const getCurrentOrderSource = createSelector(selectOrderState, (root: IOrderState) => root.data.source);

export const getCurrentOrderContent = createSelector(selectOrderState, (root: IOrderState) => root.data.orderContent);

export const getOrderDiscounts = createSelector(selectOrderState, (root: IOrderState) => root.data.orderDiscount);

export const isItemInOrder = createSelector(selectOrderState, (root: IOrderState, lineUuid: string) =>
	root.data.orderContent.find((item) => item.lineuuid === lineUuid)
);
export const getCurrentOrderFiscalCorePrices = createSelector(selectOrderState, (root: IOrderState) => root.fiscalCorePrices);

export const isFiscalCorePriceLoading = createSelector(
	selectOrderState,
	(root: IOrderState) => root.fiscalCorePrices.isFiscalCorePriceLoading
);

export const getCurrentOrderPayments = createSelector(selectOrderState, (root: IOrderState) => root.usedPaymentMethods);

export const fidelityRewardsUsed = createSelector(selectOrderState, (root: IOrderState) => root.fidelityRewardsUsed);

export const kioskPaymentEnabled = createSelector(selectOrderState, (root: IOrderState) => root.kioskPaymentEnabled.enabled);
