import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ActionButtons } from '@libs/dash/shared';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ACCOUNT_FACADE } from '../../facade/account-facade.injection.token';
import { AccountServiceProvider } from '../../facade/account-facade.provider.interface';

@Component({
	selector: 'dk-request-password-renewal',
	templateUrl: './request-password-renewal.component.html',
	styleUrls: ['./request-password-renewal.component.scss'],
})
export class RequestPasswordRenewalComponent {
	actionButtons$: Observable<ActionButtons> = this._translocoService.selectTranslateObject('request-password-renewal').pipe(
		map((localisedLoginTexts) => {
			return {
				positive: {
					label: localisedLoginTexts.buttons.login,
					disabled: false,
				},
			};
		})
	);

	requestPasswordRenewalForm = new FormGroup({
		email: new FormControl('', Validators.email),
	});

	localisedTexts$ = this._translocoService.selectTranslateObject('request-password-renewal');

	constructor(
		private _translocoService: TranslocoService,
		@Inject(ACCOUNT_FACADE) private _accountFacade: AccountServiceProvider
	) {}

	promptRequestPasswordRenewal(): void {
		this._accountFacade.promptRequestPasswordRenewal(this.requestPasswordRenewalForm.value.email);
		this.requestPasswordRenewalForm.reset();
	}
}
