import { Component } from '@angular/core';

import { AccountingFacade } from '../../facade/accounting-facade.service';

@Component({
	selector: 'dk-review',
	templateUrl: './review.component.html',
})
export class ReviewComponent {
	localisedTexts$ = this._accountingFacade.i18n$;
	isLoading$ = this._accountingFacade.isLoading$;

	constructor(private readonly _accountingFacade: AccountingFacade) {}
}
