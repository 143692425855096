<ng-container *ngIf="translations$ | async as translation">
	<div class="labels">
		<p>{{ translation.dataTable.byRestaurants }}</p>
		<p #secondLabel class="ignore">{{ translation.dataTable.operationalTimes }}</p>
	</div>
	<table class="dk-table">
		<thead>
			<tr class="h-8">
				<th>{{ translation.dataTable.slots }}</th>
				<th>{{ translation.dataTable.schedule }}</th>
				<th>{{ translation.dataTable.revenue }}</th>
				<th>{{ translation.dataTable.averageTicket }}</th>
				<th>{{ translation.dataTable.orderCount }}</th>
				<th #cell>{{ translation.dataTable.preparation }}</th>
				<th>{{ translation.dataTable.departure }}</th>
				<th class="has-info">
					{{ translation.dataTable.totalService }}
					<button
						mat-icon-button
						[matTooltip]="translation.dataTable.tooltipTotalService"
						[attr.aria-label]="translation.dataTable.tooltipTotalService"
					>
						<mat-icon>info_outline</mat-icon>
					</button>
				</th>
			</tr>
		</thead>
		<tbody>
			@if (data) {
				@for (item of data | keyvalue; track item.key) {
					<tr (click)="toggleRowExpansion(item.key)" class="expandable">
						<td class="text-left">
							<span class="arrow material-icons">
								{{ isRowExpanded(item.key) ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
							</span>
							{{ getRestaurantName(item.key) }}
						</td>
						<td></td>
						<td>{{ item.value.total.total_revenue | currency: 'EUR' : 'symbol' : '1.2-2' }}</td>
						<td>
							{{
								(item.value.total.count > 0 ? item.value.total.total_revenue / item.value.total.count : 0)
									| currency: 'EUR' : 'symbol' : '1.2-2'
							}}
						</td>
						<td>{{ item.value.total.count }}</td>
						<td>{{ item.value.total[TimeStep.PreparationTime] | toMins }}</td>
						<td>{{ item.value.total[TimeStep.ServeTime] | toMins }}</td>
						<td>{{ item.value.total[TimeStep.PreparationTime] + item.value.total[TimeStep.ServeTime] | toMins }}</td>
					</tr>
					@if (isRowExpanded(item.key)) {
						@for (period of getSortedPeriods(item.value.periods); track period.period) {
							<tr [@inOutAnimation]>
								<td>{{ translation.daypartLabels[period.period] }}</td>
								<td>{{ formatTimeRange({ start: period.start, end: period.end }) }}</td>
								<td>{{ period.total_revenue | currency: 'EUR' : 'symbol' : '1.2-2' }}</td>
								<td>{{ period.mean_revenue | currency: 'EUR' : 'symbol' : '1.2-2' }}</td>
								<td>{{ period.count }}</td>
								<td [ngClass]="getClassForValue(period[TimeStep.PreparationTime], ValueType.Preparation)">
									{{ period[TimeStep.PreparationTime] | toMins }}
								</td>
								<td>
									{{ period[TimeStep.ServeTime] | toMins }}
								</td>
								<td>
									{{ period[TimeStep.PreparationTime] + period[TimeStep.ServeTime] | toMins }}
								</td>
							</tr>
						}
					}
				}
			} @else {
				<tr>
					<td colspan="8">{{ translation.dataTable.noDataAvailable }}</td>
				</tr>
			}
		</tbody>
		<tfoot>
			<tr>
				<td colspan="2">{{ translation.dataTable.totalAverage }}</td>
				<td>{{ calculateTotalForField('total_revenue') | currency: 'EUR' : 'symbol' : '1.2-2' }}</td>
				<td>
					{{
						(calculateTotalForField('count') > 0 ? calculateTotalForField('total_revenue') / calculateTotalForField('count') : 0)
							| currency: 'EUR' : 'symbol' : '1.2-2'
					}}
				</td>
				<td>{{ calculateTotalForField('count') }}</td>
				<td>{{ calculateAverageTimeForField(TimeStep.PreparationTime) | toMins }}</td>
				<td>{{ calculateAverageTimeForField(TimeStep.ServeTime) | toMins }}</td>
				<td>{{ calculateAverageTimeForField(TimeStep.PreparationTime) + calculateAverageTimeForField(TimeStep.ServeTime) | toMins }}</td>
			</tr>
		</tfoot>
	</table>
</ng-container>
