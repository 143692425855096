@if (!isNewRoute) {
	<dk-page-container
		[datePicker]="false"
		[editButton]="false"
		[title]="(localisedTexts$ | async)?.title"
		[isLoading]="isLoading$ | async"
	>
		@if (localisedTexts$ | async; as texts) {
			<div class="dk-grid">
				<div class="dk-tile">
					<dk-opening-times [texts]="texts.tiles['opening-hours']" [openingTimes]="openingTimes"
					                  (openingTimesChange)="updateOpeningTimes($event)">
					</dk-opening-times>
				</div>
				<div class="dk-tile">
					<dk-line-tresholds [texts]="texts.tiles['t-lines']" [lineThresholds]="lineThresholds"
					                   (lineTresholdsChange)="updateLineTresholds($event)">
					</dk-line-tresholds>
				</div>
				@if (settingsUpdated) {
					<div class="dk-tile">
						<div class="dk-action-buttons">
							<button mat-stroked-button (click)="discardChanges()">
								{{ texts.buttons.dismiss }}
							</button>
							<div class="dk-spacer"></div>
							<button class="dk-submit-button" mat-raised-button color="primary" (click)="sendUpdatedDataToBackend()">
								{{ texts.buttons.save }}
							</button>
						</div>
					</div>
				}
			</div>
		}
	</dk-page-container>
} @else {
	@if (localisedTexts$ | async; as texts) {
		<div class="dk-grid">
			<div class="dk-tile">
				<dk-opening-times [texts]="texts.tiles['opening-hours']" [openingTimes]="openingTimes"
				                  (openingTimesChange)="updateOpeningTimes($event)">
				</dk-opening-times>
			</div>
			<div class="dk-tile">
				<dk-line-tresholds [texts]="texts.tiles['t-lines']" [lineThresholds]="lineThresholds"
				                   (lineTresholdsChange)="updateLineTresholds($event)">
				</dk-line-tresholds>
			</div>
			@if (settingsUpdated) {
				<div class="dk-tile">
					<div class="dk-action-buttons">
						<button mat-stroked-button (click)="discardChanges()">
							{{ texts.buttons.dismiss }}
						</button>
						<div class="dk-spacer"></div>
						<button class="dk-submit-button" mat-raised-button color="primary" (click)="sendUpdatedDataToBackend()">
							{{ texts.buttons.save }}
						</button>
					</div>
				</div>
			}
		</div>
	}
}