/**
 * Replacement of BKOrderSourceEnum, which only has numeric values, which makes it hard to work with.
 *
 * DISADVANTAGE: HAVE TO TRANSLATED BACK TO THE OLD ENUM WHEN COMMUNICATING WITH OLD ABPROD PARTS OF THE SYSTEM
 */
export enum SalesChannel {
	/**
	 * SHOULD BE NEVER USED. FALLBACK FOR WHEN SERVER AND FRONTEND DATA DOES NOT MATCH
	 */
	UNKNOWN = 'UNKNOWN',

	CASH_MACHINE = 'CASH_MACHINE',
	KIOSK = 'KIOSK',
	DRIVE_WINDOW = 'DRIVE_WINDOW',
	WALK = 'WALK',
	CLICK_AND_COLLECT = 'CLICK_AND_COLLECT',
	/**
	 * Probably the same as THIRD_PARTY_DELIVERY
	 * NOT USED AT THE MOMENT
	 */
	HOME_DELIVERY = 'HOME_DELIVERY',
	THIRD_PARTY_DELIVERY = 'THIRD_PARTY_DELIVERY',
}
