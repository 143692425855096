@if (viewData$ | async; as viewData) {
	<div class="dk-picker">
		<mat-form-field subscriptSizing="dynamic">
			<input (dateChange)="setWeek('date')" [formControl]="date" [matDatepicker]="picker" matInput #selectedWeek/>
			<span class="selected-week" (click)="picker.open()">{{ weekselected }}</span>
			<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
		</mat-form-field>
		<mat-icon (click)="setWeek('prev')">navigate_before</mat-icon>
		<mat-icon (click)="setWeek('next')">navigate_next</mat-icon>
		<button mat-button [matMenuTriggerFor]="WeeklyReport" class="export-label">{{ viewData.i18n.export }}</button>
		<mat-menu #WeeklyReport="matMenu" yPosition="above" xPosition="before">
			<button mat-menu-item (click)="downloadCsv()">{{ viewData.i18n.exportCsv }}</button>
			<button mat-menu-item (click)="downloadPDF()">{{ viewData.i18n.exportPDF }}</button>
			<button mat-menu-item (click)="downloadXlsx()">{{ viewData.i18n.exportXLSX }}</button>
		</mat-menu>
	</div>
	@if (weekDetails) {
		<p>Week {{ weekDetails.number }}</p>
	}
	<div class="dk-mat-container">
		@if (viewData.weeklyReport) {
			<table class="dki-data-table" id="dki-weekly-report">
				<thead>
				<tr class="border-up">
					<th></th>
					<th></th>
					@for (item of viewData.weeklyReport.free_item_value.by_weekday | keyvalue: originalOrder; track item; let index = $index) {
						<th>
							{{ viewData.i18n.weekdays[item.key] }} {{ weekDetails.dateFrom.plus({ days: index }).toFormat('dd/MM') }}
						</th>
					}
					<th>{{ viewData.i18n.grandTotal }}</th>
				</tr>
				</thead>
				<tbody>
				<tr class="border-up">
					<td>{{ viewData.i18n.sales }}</td>
					<td>{{ viewData.i18n.salesBrute }}</td>
					@for (item of viewData.weeklyReport.gross_value.by_weekday | keyvalue: originalOrder; track item) {
						<td>{{ item.value | currency: 'EUR':'symbol':'1.2-2' }}</td>
					}
					<td>{{ viewData.weeklyReport.gross_value.total | currency: 'EUR':'symbol':'1.2-2' }}</td>
				</tr>
				<tr class="border-up">
					<td></td>
					<td>{{ viewData.i18n.salesNet }}</td>
					@for (item of viewData.weeklyReport.net_value.by_weekday | keyvalue: originalOrder; track item) {
						<td>{{ item.value | currency: 'EUR':'symbol':'1.2-2' }}</td>
					}
					<td>{{ viewData.weeklyReport.net_value.total | currency: 'EUR':'symbol':'1.2-2' }}</td>
				</tr>
					@for (row of viewData.weeklyReport.vat | keyvalue: vatOrder; track row) {
						<tr>
							<td></td>
							<td>{{ viewData.i18n.value_by_vat.vat }} {{ viewData.i18n.value_by_vat[row.key] || row.key }}%</td>
							@for (item of row.value.by_weekday | keyvalue: originalOrder; track item) {
								<td>{{ item.value | currency: 'EUR':'symbol':'1.2-2' }}</td>
							}
							<td>{{ row.value.total | currency: 'EUR':'symbol':'1.2-2' }}</td>
						</tr>
					}
					@for (row of viewData.weeklyReport.value_by_payment_method | keyvalue: sortPaymentMethods; track row; let first = $first; let last = $last) {
						<tr [class.border-up]="first">
							@if (!row.key.includes('RENDU')) {
								<td></td>
								<td>{{ viewData.i18n.value_by_payment_method[row.key] || row.key }}</td>
								@for (item of row.value.by_weekday | keyvalue: originalOrder; track item) {
									<td>{{ item.value | currency: 'EUR':'symbol':'1.2-2' }}</td>
								}
								<td>{{ row.value.total | currency: 'EUR':'symbol':'1.2-2' }}</td>
							}
						</tr>
					}
				<tr class="border-up">
					<td>{{ viewData.i18n.offers }}</td>
					<td></td>
					@for (item of viewData.weeklyReport.free_item_value.by_weekday | keyvalue: originalOrder; track item) {
						<td>{{ item.value | currency: 'EUR':'symbol':'1.2-2' }}</td>
					}
					<td>{{ viewData.weeklyReport.free_item_value.total | currency: 'EUR':'symbol':'1.2-2' }}</td>
				</tr>
				</tbody>
			</table>
		}
	</div>
} @else {
	<div class="dk-in-progress-spinner-container">
		<mat-spinner></mat-spinner>
	</div>
}

