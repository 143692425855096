import { Injectable } from '@angular/core';
import * as api from '@dki/api-client';

import { Range } from '@libs/dash/core/entity';
import {
	MyRestaurantsSelectors,
	ReportsActions,
	ReportsSelectors,
	RootStoreState,
	SafeDepositActions,
	SafeDepositSelectors,
	SessionsHistoryActions,
	SessionsHistorySelectors,
	SynthesisReportActions,
	SynthesisReportSelectors,
} from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { HistoryLogsServiceProvider } from './history-logs-facade.provider.interface';

@Injectable()
export class HistoryLogsFacade implements HistoryLogsServiceProvider {
	myDefaultRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant));
	selectedRestaurants$: Observable<api.Restaurant[]>;

	isFetchingSessions$ = this._store.pipe(select(SessionsHistorySelectors.isLoading));
	isFetchingRecords$ = this._store.pipe(select(SafeDepositSelectors.isLoading));
	isFetchingMovements$ = this._store.pipe(select(ReportsSelectors.isLoadingFinancialMovements));
	isFetchingSynthReports$ = this._store.pipe(select(SynthesisReportSelectors.isLoading));
	isFetchingVoucherReport$ = this._store.pipe(select(ReportsSelectors.isLoadingVouchersReport));

	isLoading$ = this._store.pipe(select(MyRestaurantsSelectors.getIsLoading));

	safeSummary$ = this._store.pipe(select(SafeDepositSelectors.safeRecordsSummary));
	financialMovements$ = this._store.pipe(select(ReportsSelectors.financialMovementsComputed));
	sessionsSynth$ = this._store.pipe(select(SessionsHistorySelectors.sessionsSynth));
	synthReport$ = this._store.pipe(select(SynthesisReportSelectors.depositSynthesis));
	voucherReport$ = this._store.pipe(select(ReportsSelectors.vouchersReport));
	dateRange$ = this._store.pipe(select(SessionsHistorySelectors.range));
	safeDateRange$ = this._store.pipe(select(SafeDepositSelectors.range));
	myRestaurants$ = this._store.pipe(select(MyRestaurantsSelectors.getListOfMyRestaurants));

	range = {
		from: DateTime.now().startOf(Range.Week),
		to: DateTime.now().endOf(Range.Week),
	};

	financialMovementRange = {
		from: DateTime.now().startOf(Range.Week),
		to: DateTime.now().endOf(Range.Week),
	};

	depositSynthRange = {
		from: DateTime.now().startOf(Range.Week),
		to: DateTime.now().endOf(Range.Week),
	};

	vouchersRange = {
		from: DateTime.now().startOf(Range.Week),
		to: DateTime.now().endOf(Range.Week),
	};

	constructor(private _store: Store<RootStoreState>) {}

	getSessionSynthesisReport(from: DateTime, to: DateTime) {
		this.range = { from, to };
		this._store.dispatch(SessionsHistoryActions.getSessionHistory({ from, to }));
	}

	getSafeRecords(from: DateTime, to: DateTime) {
		this.range = { from, to };
		this._store.dispatch(SafeDepositActions.getSafeDepositReports({ from, to }));
	}

	getFinancialMovements(from: DateTime, to: DateTime) {
		this.financialMovementRange = { from, to };
		this._store.dispatch(ReportsActions.getFinancialMovements({ from, to }));
	}

	getSynthesisReports(from: DateTime, to: DateTime) {
		this.depositSynthRange = { from, to };
		this._store.dispatch(SynthesisReportActions.getSynthesisReport({ from, to, singleRestaurant: true }));
	}

	getVouchersReport(from: DateTime, to: DateTime) {
		this.vouchersRange = { from, to };
		this._store.dispatch(ReportsActions.getVouchersReport({ from, to }));
	}
}
