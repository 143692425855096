import { BC_FR_PRODUCT_FAMILIES, BK_FR_PRODUCT_FAMILIES, QUICK_PRODUCT_FAMILIES } from '../mappings';

/**
 * Used for sorting in case ProductFamilyID is not found here.
 * Or when there is no BrandName sorting-mapping defined.
 */
export const DefaultProductOrderByAssemblyArea = 99;

/**
 * Determines sorting order of Products in Order
 * Ordered Products are grouped by the "assembly area",
 * and those Groups are then rendered in given order in ORBp.
 * The lower number means that the Group will be displayed first.
 */
	// key is assembly id, value is priority & order in the print or displayed block
export const BurgerKing_ProductOrderByAssemblyArea = {
	[BK_FR_PRODUCT_FAMILIES.Burgers]: 1,
	[BK_FR_PRODUCT_FAMILIES.FrenchFries]: 2,
	[BK_FR_PRODUCT_FAMILIES.FriedProducts]: 3,
	[BK_FR_PRODUCT_FAMILIES.HotSalads]: 4,
	[BK_FR_PRODUCT_FAMILIES.ColdSalads]: 5,
	[BK_FR_PRODUCT_FAMILIES.FridgeDrings]: 6,
	[BK_FR_PRODUCT_FAMILIES.TapDrinks]: 7,
	[BK_FR_PRODUCT_FAMILIES.Glaces]: 8,
	[BK_FR_PRODUCT_FAMILIES.HotDesserts]: 9,
	[BK_FR_PRODUCT_FAMILIES.FridgeDeserts]: 10,
	[BK_FR_PRODUCT_FAMILIES.PastryKitchen]: 11,
	[BK_FR_PRODUCT_FAMILIES.Boissons_chaudes]: 12,
	[BK_FR_PRODUCT_FAMILIES.SnackSauces]: 13,
	[BK_FR_PRODUCT_FAMILIES.SaladDressings]: 14,
	[BK_FR_PRODUCT_FAMILIES.Jouet_enfant]: 15,
	[BK_FR_PRODUCT_FAMILIES.Ventes_annexes]: 16,
	[BK_FR_PRODUCT_FAMILIES.Autre]: 17,

	MAX: DefaultProductOrderByAssemblyArea, // used for sorting in case ProductFamilyID is not found here
	assemblyOrder: function (id): number {
		return this[id] ? this[id] : this.MAX;
	}
};

export const Quick_ProductOrderByAssemblyArea = {
	[QUICK_PRODUCT_FAMILIES.Boissons_froides]: 1,
	[QUICK_PRODUCT_FAMILIES.Boissons_chaudes]: 2,
	[QUICK_PRODUCT_FAMILIES.Burgers]: 3,
	[QUICK_PRODUCT_FAMILIES.Salads]: 4,
	[QUICK_PRODUCT_FAMILIES.FriedProducts]: 5,
	[QUICK_PRODUCT_FAMILIES.Fries]: 6,
	[QUICK_PRODUCT_FAMILIES.ExtraSauces]: 7,
	[QUICK_PRODUCT_FAMILIES.Desserts]: 8,
	[QUICK_PRODUCT_FAMILIES.PastryKitchen]: 9,
	[QUICK_PRODUCT_FAMILIES.IceCreams]: 10,
	[QUICK_PRODUCT_FAMILIES.Toys]: 11,
	MAX: DefaultProductOrderByAssemblyArea, // used for sorting in case ProductFamilyID is not found here
	assemblyOrder: function (id): number {
		return this[id] ? this[id] : this.MAX;
	}
};

export const BChef_ProductOrderByAssemblyArea = {
	[BC_FR_PRODUCT_FAMILIES.BoissonFroide]: 1,
	[BC_FR_PRODUCT_FAMILIES.BoissonChaude]: 2,
	[BC_FR_PRODUCT_FAMILIES.Patisserie]: 3,
	[BC_FR_PRODUCT_FAMILIES.Dessert]: 4,
	[BC_FR_PRODUCT_FAMILIES.Glace]: 5,
	[BC_FR_PRODUCT_FAMILIES.Burger]: 6,
	[BC_FR_PRODUCT_FAMILIES.Bagel]: 7,
	[BC_FR_PRODUCT_FAMILIES.HotDog]: 8,
	[BC_FR_PRODUCT_FAMILIES.Salades]: 9,
	[BC_FR_PRODUCT_FAMILIES.PetiteFaim]: 10,
	[BC_FR_PRODUCT_FAMILIES.Sauce]: 11,
	[BC_FR_PRODUCT_FAMILIES.Divers]: 12,
	[BC_FR_PRODUCT_FAMILIES.Extra]: 13,
	[BC_FR_PRODUCT_FAMILIES.BonPlan]: 14,
	MAX: DefaultProductOrderByAssemblyArea, // used for sorting in case ProductFamilyID is not found here
	assemblyOrder: function (id): number {
		return this[id] ? this[id] : this.MAX;
	}
};
