@if (viewData$ | async; as viewData) {
	@if (isLoading$ | async) {
		<div class="dk-on-progress-overlay">
			<div class="dk-on-progress-spinner-container">
				<mat-spinner></mat-spinner>
			</div>
		</div>
	} @else {
		<div class="dk-picker" [class.single-display]="singleDaySelection()">
			<ng-content></ng-content>
			<mat-form-field appearance="fill" subscriptSizing="dynamic">
				<mat-label>{{ viewData.i18n.chooseDates }}</mat-label>
				<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
					<input [placeholder]="viewData.i18n.startDate" formControlName="from" matStartDate [hidden]="singleDaySelection()"/>
					<input [placeholder]="viewData.i18n.endDate" formControlName="to" matEndDate [class.single-display]="singleDaySelection()"/>
				</mat-date-range-input>
				<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
				<mat-date-range-picker #picker>
					<mat-date-range-picker-actions>
						<button mat-button matDateRangePickerCancel>{{ viewData.i18n.cancel }}</button>
						<button (click)="setPeriod('period')" color="primary" mat-raised-button matDateRangePickerApply>
							{{ viewData.i18n.apply }}
						</button>
					</mat-date-range-picker-actions>
				</mat-date-range-picker>
			</mat-form-field>
			<button (click)="setPeriod('today')" mat-button>{{ viewData.i18n.today }}</button>
			<button (click)="setPeriod('week')" mat-button>{{ viewData.i18n.week }}</button>
			<button (click)="setPeriod('month')" mat-button>{{ viewData.i18n.month }}</button>
			@if (isFetching$ | async) {
				<mat-spinner class="d-inline" diameter="20"></mat-spinner>
			}
			<button mat-button [matMenuTriggerFor]="synthMenu" class="export-sessions-synth">{{ viewData.i18n.download }}</button>
			<mat-menu #synthMenu="matMenu" yPosition="above" xPosition="before">
				<button id="csv-synth-report" mat-menu-item (click)="download('csv')">{{ viewData.i18n.downloadCSV }}</button>
				<button id="csv-synth-report" mat-menu-item (click)="download('pdf')">{{ viewData.i18n.downloadPDF }}</button>
			</mat-menu>
		</div>
		<table class="mat-table mat-elevation-z8" id="synth-sessions">
			<thead>
			<tr>
				<th class="left-gray">{{ viewData.i18n.restaurant }}</th>
				<th class="left-gray">{{ viewData.i18n.cashier }}</th>
				<th style="width: 30px" class="left-gray">
					{{ viewData.i18n.nb_session }}
				</th>
				<th colspan="3" class="left-gray">{{ viewData.i18n.revenue }}</th>
				<th class="left-gray text-center">{{ viewData.i18n.real_revenue }}</th>
				<th class="left-gray text-center">{{ viewData.i18n.gap }}</th>
				<th colspan="3" class="left-gray">{{ viewData.i18n.cancels }}</th>
				<th class="left-gray text-center">{{ viewData.i18n.refunds }}</th>
				<th class="left-gray text-center">{{ viewData.i18n.withdrawn }}</th>
			</tr>
			<tr class="second-header">
				<td></td>
				<td class="left-gray whiten"></td>
				<td class="left-gray whiten">
					<div class="has-filter">
						<mat-icon (click)="setSorting('sessionCount')">arrow_drop_up</mat-icon>
						<mat-icon (click)="setSorting('sessionCount', false)">arrow_drop_down</mat-icon>
					</div>
				</td>
				<td class="text-center left-gray whiten">
					<div class="has-filter">
						{{ viewData.i18n.declared_amount }}
						<div>
							<mat-icon (click)="setSorting('revenue.declared')">arrow_drop_up</mat-icon>
							<mat-icon (click)="setSorting('revenue.declared', false)">arrow_drop_down</mat-icon>
						</div>
					</div>
				</td>
				<td class="text-center">
					<div class="has-filter">
						{{ viewData.i18n.reductions }}
						<div class="has-filter">
							<mat-icon (click)="setSorting('revenue.discount')">arrow_drop_up</mat-icon>
							<mat-icon (click)="setSorting('revenue.discount')">arrow_drop_down</mat-icon>
						</div>
					</div>
				</td>
				<td class="text-center">%</td>
				<td class="text-center left-gray whiten">
					<div>
						<mat-icon (click)="setSorting('revenue.real')">arrow_drop_up</mat-icon>
						<mat-icon (click)="setSorting('revenue.real', false)">arrow_drop_down</mat-icon>
					</div>
				</td>
				<td class="text-center left-gray whiten">
					<div>
						<mat-icon (click)="setSorting('revenue.gap')">arrow_drop_up</mat-icon>
						<mat-icon (click)="setSorting('revenue.gap', false)">arrow_drop_down</mat-icon>
					</div>
				</td>
				<td class="text-center left-gray whiten">
					<div class="has-filter">
						{{ viewData.i18n.amount }}
						<div>
							<mat-icon (click)="setSorting('cancels.amount')">arrow_drop_up</mat-icon>
							<mat-icon (click)="setSorting('cancels.amount', false)">arrow_drop_down</mat-icon>
						</div>
					</div>
				</td>
				<td class="text-center">{{ viewData.i18n.number }}</td>
				<td class="text-center">%</td>
				<td class="text-center left-gray whiten">
					<div class="has-filter">
						{{ viewData.i18n.amount }}
						<div>
							<mat-icon (click)="setSorting('refund')">arrow_drop_up</mat-icon>
							<mat-icon (click)="setSorting('refund', false)">arrow_drop_down</mat-icon>
						</div>
					</div>
				</td>
				<td class="text-center left-gray whiten">
					<div class="has-filter">
						{{ viewData.i18n.amount }}
						<div>
							<mat-icon (click)="setSorting('withdrawn')">arrow_drop_up</mat-icon>
							<mat-icon (click)="setSorting('withdrawn', false)">arrow_drop_down</mat-icon>
						</div>
					</div>
				</td>
				<!--					<td class="text-center">{{ viewData.i18n.number }}</td>
				<td class="text-center">%</td>-->
			</tr>
			<tr class="filter-header">
				<td></td>
				<td class="left-gray empl">
					<mat-form-field appearance="outline" style="max-width: 120px">
						<mat-select multiple (valueChange)="onEmplChanges($event)">
							<button mat-stroked-button color="primary" (click)="resetEmplFilter()">Réinitialiser</button>
							@for (empl of employees; track empl) {
								<mat-option [value]="empl">{{ empl }}</mat-option>
							}
						</mat-select>
					</mat-form-field>
				</td>
				<td class="left-gray"></td>
				<td class="text-center left-gray">
					<mat-form-field appearance="outline">
						<mat-checkbox color="primary" [(ngModel)]="declaredFilterActive" (ngModelChange)="onFilterChange()"></mat-checkbox>
						<input
							matInput
							type="number"
							placeholder="0 €"
							[(ngModel)]="declaredFilterValue"
							(ngModelChange)="onFilterChange()"
							style="width: 144px; text-align: center; font-size: 18px"
						/>
						<mat-select [(ngModel)]="declaredValueOperator" (ngModelChange)="onFilterChange()">
							<mat-option value="gt">&gt;</mat-option>
							<mat-option value="lt">&lt;</mat-option>
							<mat-option value="eq">=</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
				<td></td>
				<td></td>
				<td class="text-center left-gray">
					<mat-form-field appearance="outline">
						<mat-checkbox color="primary" [(ngModel)]="realFilterActive" (ngModelChange)="onFilterChange()"></mat-checkbox>
						<input
							matInput
							type="number"
							[(ngModel)]="realFilterValue"
							(ngModelChange)="onFilterChange()"
							placeholder="0 €"
							style="width: 144px; text-align: center; font-size: 18px"
						/>
						<mat-select [(ngModel)]="realValueOperator" (ngModelChange)="onFilterChange()">
							<mat-option value="gt">&gt;</mat-option>
							<mat-option value="lt">&lt;</mat-option>
							<mat-option value="eq">=</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
				<td></td>
				<td class="text-center left-gray">
					<mat-form-field appearance="outline">
						<mat-checkbox color="primary" [(ngModel)]="cancelsFilterActive" (ngModelChange)="onFilterChange()"></mat-checkbox>
						<input
							matInput
							type="number"
							[(ngModel)]="cancelsFilterValue"
							(ngModelChange)="onFilterChange()"
							placeholder="O €"
							style="width: 144px; text-align: center; font-size: 18px"
						/>
						<mat-select [(ngModel)]="cancelsValueOperator" (ngModelChange)="onFilterChange()">
							<mat-option value="gt">&gt;</mat-option>
							<mat-option value="lt">&lt;</mat-option>
							<mat-option value="eq">=</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
				<td></td>
				<td></td>
				<td class="text-center left-gray">
					<mat-form-field appearance="outline">
						<mat-checkbox color="primary" [(ngModel)]="correctionsFilterActive" (ngModelChange)="onFilterChange()"></mat-checkbox>
						<input
							matInput
							type="number"
							[(ngModel)]="correctionsFilterValue"
							(ngModelChange)="onFilterChange()"
							placeholder="0 €"
							style="width: 144px; text-align: center; font-size: 18px"
						/>
						<mat-select [(ngModel)]="correctionsValueOperator" (ngModelChange)="onFilterChange()">
							<mat-option value="gt">&gt;</mat-option>
							<mat-option value="lt">&lt;</mat-option>
							<mat-option value="eq">=</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
				<td class="text-center left-gray">
					<mat-form-field appearance="outline">
						<mat-checkbox color="primary" [(ngModel)]="withdrawnFilterActive" (ngModelChange)="onFilterChange()"></mat-checkbox>
						<input
							matInput
							type="number"
							[(ngModel)]="withdrawnFilterValue"
							(ngModelChange)="onFilterChange()"
							placeholder="0 €"
							style="width: 144px; text-align: center; font-size: 18px"
						/>
						<mat-select [(ngModel)]="withdrawnValueOperator" (ngModelChange)="onFilterChange()">
							<mat-option value="gt">&gt;</mat-option>
							<mat-option value="lt">&lt;</mat-option>
							<mat-option value="eq">=</mat-option>
						</mat-select>
					</mat-form-field>
				</td>
				<!--				<td></td>
				<td></td>-->
			</tr>
			</thead>
			<tbody>
				@for (row of viewData.data; track row) {
					<tr [class.hidden]="filteredEmployee(row) || row.hidden">
						<td>{{ row.restaurant }}</td>
						<td class="left-gray">{{ row.cashier }}</td>
						<td class="left-gray">{{ row.sessionCount }}</td>
						<td class="bg-light-blue text-center left-gray">{{ row.revenue.declared | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
						<td class="text-center">{{ row.revenue.discount | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
						<td class="text-center">{{ row.revenue.percent | percent: '1.2-2' }}</td>
						<td class="bg-light-blue text-center left-gray">{{ row.revenue.real | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
						<td class="text-center left-gray">{{ row.revenue.gap | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
						<td class="bg-light-blue text-center left-gray">{{ row.cancels.amount | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
						<td class="text-center">{{ row.cancels.count || '-' }}</td>
						<td class="text-center">{{ (row.cancels.percent | percent: '1.2-2') || '-' }}</td>
						<td class="bg-light-blue text-center left-gray">{{ row.refund | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
						<td class="bg-light-blue text-center left-gray">{{ row.withdrawn | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
						<!--<td>{{ row.corrections.count || '-' }}</td>
						<td>{{ row.corrections.percent || '-' }}</td>-->
					</tr>
				}
			<tr class="total-line">
				<td>{{ viewData.i18n.total }}</td>
				<td></td>
				<td class="left-gray">{{ viewData.total.sessionCount }}</td>
				<td class="bg-light-blue text-center left-gray">{{ viewData.total.revenue.declared | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
				<td class="text-center">{{ viewData.total.revenue.discount | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
				<td class="text-center">{{ viewData.total.revenue.percent | percent: '1.2-2' }}</td>
				<td class="bg-light-blue text-center left-gray">{{ viewData.total.revenue.real | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
				<td class="text-center left-gray">{{ viewData.total.revenue.gap | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
				<td class="bg-light-blue text-center left-gray">{{ viewData.total.cancels.amount | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
				<td class="text-center">{{ viewData.total.cancels.count || '-' }}</td>
				<td class="text-center">{{ (viewData.total.cancels.percent | percent: '1.2-2') || '-' }}</td>
				<td class="bg-light-blue text-center left-gray">{{ viewData.total.refund | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
				<td class="bg-light-blue text-center left-gray">{{ viewData.total.withdrawn | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
				<!--<td>{{ viewData.total.corrections.count || '-' }}</td>
				<td>{{ viewData.total.corrections.percent || '-' }}</td>-->
			</tr>
			</tbody>
		</table>
	}
}
