@if (localisedTexts$ | async; as i18n) {
	<dk-page-container [editButton]="false" [title]="i18n.title">
		@if (isLoading$ | async) {
			<div class="dk-on-progress-overlay">
				<div class="dk-on-progress-spinner-container">
					<mat-spinner></mat-spinner>
				</div>
			</div>
		} @else {
			<dk-report-detail class="zoomed-out" [i18n]="i18n"></dk-report-detail>
		}
	</dk-page-container>
}
