@if (viewData$ | async; as viewData) {
	@if (isLoading$ | async) {
		<div class="dk-on-progress-overlay">
			<div class="dk-on-progress-spinner-container">
				<mat-spinner></mat-spinner>
			</div>
		</div>
	} @else {
		<div class="dk-picker" [class.single-display]="singleDaySelection()">
			<ng-content></ng-content>
			<mat-form-field appearance="fill" subscriptSizing="dynamic">
				<mat-label>{{ viewData.i18n.chooseDates }}</mat-label>
				<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
					<input [placeholder]="viewData.i18n.startDate" formControlName="from" matStartDate [hidden]="singleDaySelection()"/>
					<input [placeholder]="viewData.i18n.endDate" formControlName="to" matEndDate [class.single-display]="singleDaySelection()"/>
				</mat-date-range-input>
				<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
				<mat-date-range-picker #picker>
					<mat-date-range-picker-actions>
						<button mat-button matDateRangePickerCancel>{{ viewData.i18n.cancel }}</button>
						<button (click)="setPeriod('period')" color="primary" mat-raised-button matDateRangePickerApply>
							{{ viewData.i18n.apply }}
						</button>
					</mat-date-range-picker-actions>
				</mat-date-range-picker>
			</mat-form-field>
			<button (click)="setPeriod('today')" mat-button>{{ viewData.i18n.today }}</button>
			<button (click)="setPeriod('week')" mat-button>{{ viewData.i18n.week }}</button>
			<button (click)="setPeriod('month')" mat-button>{{ viewData.i18n.month }}</button>
			@if (isFetching$ | async) {
				<mat-spinner class="d-inline" diameter="20"></mat-spinner>
			}
			<button mat-button [matMenuTriggerFor]="exportMenu" class="export-voucher-report">{{ viewData.i18n.download }}</button>
			<mat-menu #exportMenu="matMenu" yPosition="above" xPosition="before">
				<button mat-menu-item (click)="download('csv', viewData)">{{ viewData.i18n.downloadCSV }}</button>
				<button mat-menu-item (click)="download('pdf', viewData)">{{ viewData.i18n.downloadPDF }}</button>
			</mat-menu>
		</div>
		@if (!noData) {
			<table class="mat-table mat-elevation-z8" #voucherReport>
				<thead>
				<th>{{ viewData.i18n.voucher }}</th>
				<th>{{ viewData.i18n.weekdays.monday }}</th>
				<th>{{ viewData.i18n.weekdays.tuesday }}</th>
				<th>{{ viewData.i18n.weekdays.wednesday }}</th>
				<th>{{ viewData.i18n.weekdays.thursday }}</th>
				<th>{{ viewData.i18n.weekdays.friday }}</th>
				<th>{{ viewData.i18n.weekdays.saturday }}</th>
				<th>{{ viewData.i18n.weekdays.sunday }}</th>
				<th>{{ viewData.i18n.total }}</th>
				</thead>
				<tbody>
					@for (voucher of viewData.data['by_voucher_type'] | keyvalue; track voucher) {
						<tr>
							<td>{{ voucher.key }}</td>
							<td>{{ voucher.value.by_weekday.monday | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
							<td>{{ voucher.value.by_weekday.tuesday | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
							<td>{{ voucher.value.by_weekday.wednesday | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
							<td>{{ voucher.value.by_weekday.thursday | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
							<td>{{ voucher.value.by_weekday.friday | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
							<td>{{ voucher.value.by_weekday.saturday | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
							<td>{{ voucher.value.by_weekday.sunday | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
							<td>{{ voucher.value.total | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
						</tr>
					}
				</tbody>
				<tfoot>
				<tr class="total-line">
					<td>{{ viewData.i18n.total }}</td>
					<td>{{ viewData.data.total.by_weekday.monday | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
					<td>{{ viewData.data.total.by_weekday.tuesday | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
					<td>{{ viewData.data.total.by_weekday.wednesday | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
					<td>{{ viewData.data.total.by_weekday.thursday | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
					<td>{{ viewData.data.total.by_weekday.friday | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
					<td>{{ viewData.data.total.by_weekday.saturday | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
					<td>{{ viewData.data.total.by_weekday.sunday | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
					<td>{{ viewData.data.total.total | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
				</tr>
				</tfoot>
			</table>
		}
	}
}
