import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'dk-performance-bar-chart',
	templateUrl: './bar-chart.component.html',
	styleUrls: ['./bar-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarChartComponent implements AfterViewInit, OnChanges {
	@ViewChild('chart') private chartContainer;
	@Input() channels = [];
	@Input() values = [];
	@Input() i18n = {};
	@Input() dark = false;
	@Input() title = '';
	@Input() noSalesMsg = '';

	componentIsInit = false;

	noSales = false;

	ngAfterViewInit() {
		this.drawChart();
		const resize$ = fromEvent(window, 'resize');
		resize$.pipe(debounceTime(300)).subscribe(() => {
			this.drawChart();
		});
		this.componentIsInit = true;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.componentIsInit) {
			this.drawChart(true);
		}
		if (changes.channels.currentValue.length < 1) {
			this.noSales = true;
		} else {
			this.noSales = false;
		}
	}

	drawChart(update = false) {
		if (!update) {
			echarts.dispose(this.chartContainer.nativeElement);
		}
		const chart = echarts.init(this.chartContainer.nativeElement, null, { renderer: 'svg', height: this.dark ? 380 : 360 });
		const option: EChartsOption = {
			textStyle: {
				color: this.dark ? '#fbfbfb' : null,
				fontSize: '18px',
			},
			yAxis: {
				type: 'category',
				data: this.channels.map((channel) => ({
					value: this.i18n[channel] || channel,
					textStyle: {
						fontSize: '16px',
					},
				})),
				show: true,
				position: 'left',
			},
			xAxis: {
				show: false,
				min: 0,
				max: 100,
			},
			grid: {
				left: 100,
				containLabel: true,
			},
			series: [
				{
					data: this.values,
					type: 'bar',
					showBackground: true,
					backgroundStyle: {
						color: 'rgba(180, 180, 180, 0.2)',
					},
					label: {
						show: true,
						position: 'right',
						valueAnimation: true,
						formatter: (v) => `${v.value} %`,
						color: this.dark ? '#fbfbfb' : null,
					},
				},
			],
		};
		chart.setOption(option);
	}
}
