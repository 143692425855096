@if (viewData$ | async; as viewData) {
	<div class="dk-picker" [class.single-display]="singleDaySelection()">
		<mat-form-field appearance="fill" subscriptSizing="dynamic">
			<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
				<input [placeholder]="viewData.i18n.startDate" formControlName="from" matStartDate [hidden]="singleDaySelection()"/>
				<input [placeholder]="viewData.i18n.endDate" formControlName="to" matEndDate [class.single-display]="singleDaySelection()"/>
			</mat-date-range-input>
			<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
			<mat-date-range-picker #picker displayMode="single">
				<mat-date-range-picker-actions>
					<button mat-button matDateRangePickerCancel>{{ viewData.i18n.cancel }}</button>
					<button (click)="setPeriod('period')" color="primary" mat-raised-button matDateRangePickerApply>
						{{ viewData.i18n.apply }}
					</button>
				</mat-date-range-picker-actions>
			</mat-date-range-picker>
		</mat-form-field>
		<button (click)="setPeriod('today')" mat-button class="today-btn">{{ i18n.today }}</button>
		<button (click)="setPeriod('week')" mat-button>{{ i18n.week }}</button>
		<button (click)="setPeriod('month')" mat-button>{{ i18n.month }}</button>
		@if (isLoading | async) {
			<mat-spinner class="d-inline" diameter="20"></mat-spinner>
		}
		<button mat-button [matMenuTriggerFor]="EmployeeMealReport" class="export-label">{{ viewData.i18n.export }}</button>
		<mat-menu #EmployeeMealReport="matMenu" yPosition="above" xPosition="before">
			<button mat-menu-item (click)="downloadCSV()">{{ viewData.i18n.exportCsv }}</button>
			<button mat-menu-item (click)="downloadPDF()">{{ viewData.i18n.exportPDF }}</button>
			<button mat-menu-item (click)="downloadXlsx()">{{ viewData.i18n.exportXLSX }}</button>
		</mat-menu>
	</div>
	@if (viewData.employeeMeals && viewData.employeeMeals.employee_meals.length > 0) {
		<table mat-table [dataSource]="viewData.employeeMeals.employee_meals" class="mat-elevation-z8 w-100 mt-5" id="employee-meal-report">
			<ng-container matColumnDef="restaurant">
				<th mat-header-cell *matHeaderCellDef>{{ viewData.i18n.restaurant }}</th>
				<td mat-cell *matCellDef="let element">{{ restaurants[element.restaurant_id] }}</td>
				<td mat-footer-cell *matFooterCellDef>{{ viewData.i18n.total }}</td>
			</ng-container>
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef>{{ viewData.i18n.name }}</th>
				<td mat-cell *matCellDef="let element">{{ element.employee.last_name }}</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>
			<ng-container matColumnDef="firstname">
				<th mat-header-cell *matHeaderCellDef>{{ viewData.i18n.firstname }}</th>
				<td mat-cell *matCellDef="let element">{{ element.employee.first_name }}</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>
			<ng-container matColumnDef="quantity">
				<th mat-header-cell *matHeaderCellDef>{{ viewData.i18n.quantity }}</th>
				<td mat-cell *matCellDef="let element">{{ element.count }}</td>
				<td mat-footer-cell *matFooterCellDef>{{ totalCount }}</td>
			</ng-container>
			<ng-container matColumnDef="totalttc">
				<th mat-header-cell *matHeaderCellDef>{{ viewData.i18n.totalttc }}</th>
				<td mat-cell *matCellDef="let element">{{ element.total_value | currency: 'EUR' }}</td>
				<td mat-footer-cell *matFooterCellDef>{{ total | currency: 'EUR' }}</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumnsEntries"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumnsEntries"></tr>
			<tr mat-footer-row *matFooterRowDef="displayedColumnsEntries" class="highlight font-weight-bold"></tr>
		</table>
	} @else {
		<p>{{ viewData.i18n.noData }}</p>
	}
}
