import { Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { MenuModel } from '@libs/shared/models';
import { AvailableLangs } from '@ngneat/transloco';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CommonLayoutService<T> {
	logoutSubject = new Subject<void>();
	languageChangeSubject = new Subject<void>();

	private _menuExpanded: WritableSignal<boolean> = signal(false);
	private _version: WritableSignal<string> = signal('');
	private _menuItems: WritableSignal<MenuModel<T>[]> = signal<MenuModel<T>[]>([]);
	private _userName: WritableSignal<string> = signal('');
	private _title: WritableSignal<string> = signal('');
	private _availableLanguages: WritableSignal<AvailableLangs> = signal([]);

	get menuExpanded(): Signal<boolean> {
		return this._menuExpanded.asReadonly();
	}

	get version(): Signal<string> {
		return this._version.asReadonly();
	}

	get menuItems(): Signal<MenuModel<T>[]> {
		return this._menuItems.asReadonly();
	}

	get userName(): Signal<string> {
		return this._userName.asReadonly();
	}

	get availableLanguages(): Signal<AvailableLangs> {
		return this._availableLanguages.asReadonly();
	}

	get title(): Signal<string> {
		return this._title.asReadonly();
	}

	toggleMenuExpanded(): void {
		this._menuExpanded.update((expanded: boolean) => !expanded);
	}

	setVersion(version: string): void {
		this._version.update(() => version);
	}

	setMenuItems(menuItems: MenuModel<T>[]): void {
		this._menuItems.update(() => menuItems);
	}

	setUserName(name: string): void {
		this._userName.update(() => name);
	}

	setTitle(name: string): void {
		this._title.update(() => name);
	}

	setAvailableLanguages(availableLanguages: AvailableLangs): void {
		this._availableLanguages.update(() => availableLanguages);
	}
}
