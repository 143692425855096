import { IPasswordOptions } from '@libs/shared/interfaces';

const MAX_PW_GENERATE_ATTEMPTS = 100;
const PREDEFINED_PASSWORD_TO_BE_MIXED = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-={}[];',./?~";

export function trimChar(str: string, charToRemove: string): string {
	while (str.charAt(0) === charToRemove) {
		str = str.substring(1);
	}

	while (str.charAt(str.length - 1) === charToRemove) {
		str = str.substring(0, str.length - 1);
	}

	return str.trim();
}

export function generateHashCode(str: Record<string, any> | string): number {
	if (typeof str === 'string') {
		return str.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);
	} else {
		return JSON.stringify(str)
			.split('')
			.reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);
	}
}

export function generatePassword(options: IPasswordOptions = {}): string {
	let { useLowercase = true, useUppercase = true, useNumbers = true, useSpecialChars = true, length = 4 } = options;

	if (!useLowercase && !useUppercase && !useNumbers && !useSpecialChars) {
		throw new Error('At least one character category must be enabled.');
	}

	const categories = [
		{ chars: 'abcdefghijklmnopqrstuvwxyz', enabled: useLowercase },
		{ chars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', enabled: useUppercase },
		{ chars: '0123456789', enabled: useNumbers },
		{ chars: "!@#$%^&*()_+-={}[];',./?~", enabled: useSpecialChars },
	];

	const enabledCategories = categories.filter(({ enabled }) => enabled);
	const requiredCategories = enabledCategories.filter(
		({ chars }) => /[a-z]/.test(chars) || /[A-Z]/.test(chars) || /[0-9]/.test(chars) || /[^a-zA-Z0-9]/.test(chars)
	);

	if (length < requiredCategories.length) {
		throw new Error('Length of the password should be at least the same length as of allowed categories');
	}

	if (requiredCategories.length === 0) {
		throw new Error('At least one category must be present.');
	}

	const passwordChars = [];
	const maxAttempts = MAX_PW_GENERATE_ATTEMPTS; // Set a limit to the number of attempts

	for (let i = 0; i < length; i++) {
		let randomCategory;
		let randomChar;
		let attempts = 0;

		do {
			randomCategory = enabledCategories[Math.floor(Math.random() * enabledCategories.length)];
			randomChar = randomCategory.chars[Math.floor(Math.random() * randomCategory.chars.length)];
			attempts++;
		} while (
			i < requiredCategories.length &&
			(!requiredCategories.some(({ chars }) => chars.includes(randomChar)) || attempts >= maxAttempts)
		);

		if (attempts >= maxAttempts) {
			for (let i = 0; i < 8; i++) {
				passwordChars.push(PREDEFINED_PASSWORD_TO_BE_MIXED.charAt(Math.floor(Math.random() * PREDEFINED_PASSWORD_TO_BE_MIXED.length)));
			}
			return passwordChars.join('');
		}

		passwordChars.push(randomChar);
	}

	return passwordChars.join('');
}

export function isBase64(str: string): boolean {
	const notBase64 = /[^A-Z0-9+\/=]/i;

	const len = str.length;
	if (!len || len % 4 !== 0 || notBase64.test(str)) {
		return false;
	}
	const firstPaddingChar = str.indexOf('=');
	return firstPaddingChar === -1 || firstPaddingChar === len - 1 || (firstPaddingChar === len - 2 && str[len - 1] === '=');
}

export function getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
	let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
	return keys.length > 0 ? keys[0] : '';
}
