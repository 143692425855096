<div class="dk-picker" [class.single-display]="singleDaySelection()">
	<ng-content></ng-content>
	<mat-form-field appearance="fill" subscriptSizing="dynamic">
		<mat-label>{{ localeText.chooseDates }}</mat-label>
		<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
			<input [placeholder]="localeText.startDate" formControlName="from" matStartDate [hidden]="singleDaySelection()"/>
			<input [placeholder]="localeText.endDate" formControlName="to" matEndDate [class.single-display]="singleDaySelection()"/>
		</mat-date-range-input>
		<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
		<mat-date-range-picker #picker>
			<mat-date-range-picker-actions>
				<button mat-button matDateRangePickerCancel>{{ localeText.cancel }}</button>
				<button (click)="setPeriod('period')" color="primary" mat-raised-button matDateRangePickerApply>{{ localeText.apply }}</button>
			</mat-date-range-picker-actions>
		</mat-date-range-picker>
	</mat-form-field>
	<button (click)="setPeriod('today')" mat-button class="today-btn">{{ localeText.today }}</button>
	<button (click)="setPeriod('week')" mat-button>{{ localeText.week }}</button>
	<button (click)="setPeriod('month')" mat-button>{{ localeText.month }}</button>
	@if (isLoading | async) {
		<mat-spinner class="d-inline" diameter="20"></mat-spinner>
	}
	<button mat-button [matMenuTriggerFor]="sessionsMenu" class="export-sessions">{{ localeText.downloadAll }}</button>
	<mat-menu #sessionsMenu="matMenu" yPosition="above" xPosition="before">
		<button id="csv-discount-report" mat-menu-item (click)="downloadAll()">{{ localeText.exportCsv }}</button>
	</mat-menu>
</div>
@if ((isLoading | async) === false) {
	<div class="dk-flex">
		<h4>{{ data.length }} Sessions POS</h4>
		@if (kioskSessionsCount !== 0) {
			<form [formGroup]="formGroup">
				<mat-slide-toggle color="success"
				                  formControlName="toggle">{{ localeText.kioskSessionShow }} {{ kioskSessionsCount }} {{ localeText.kioskSession }}
				</mat-slide-toggle>
			</form>
		}
	</div>
}
@for (session of data; track session) {
	<mat-accordion>
		<mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
			<mat-expansion-panel-header class="pl-0">
				<span>{{ localeText.sessionNumber }} :&nbsp;</span>
				<span class="font-weight-bold">{{ session.sessionNumber }}</span>
				<span class="limiter">|</span>
				<span>{{ localeText.sessionStartLabel }} :&nbsp;</span>
				<span class="font-weight-bold">{{ session.sessionClosing }}</span>
				<span class="limiter">|</span>
				<span>{{ localeText.sessionValidationLabel }} :&nbsp;</span>
				<span class="font-weight-bold"> {{ session.sessionValidation }}</span>
				<span class="limiter">|</span>
				<span>{{ session.personnelsSource[0].label }} :&nbsp;</span>
				<span class="font-weight-bold"> {{ session.personnelsSource[0].name }}</span>
				<span class="limiter">|</span>
				<span>{{ session.personnelsSource[1].label }} :&nbsp;</span>
				<span class="font-weight-bold"> {{ session.personnelsSource[1].name }}</span>
			</mat-expansion-panel-header>
			<table mat-table [dataSource]="session.entriesSource" class="mat-elevation-z8 w-100 mt-5">
				<ng-container matColumnDef="reglements">
					<th mat-header-cell *matHeaderCellDef class="uppercase">{{ localeText.reglements }}</th>
					<td mat-cell *matCellDef="let element">{{ localeText.entries[element.category] || element.category }}</td>
					<td mat-footer-cell *matFooterCellDef>{{ localeText.total }}</td>
				</ng-container>
				<ng-container matColumnDef="amountTh">
					<th mat-header-cell *matHeaderCellDef>{{ localeText.amountTh }}</th>
					<td mat-cell *matCellDef="let element">{{ element.amount.theoretical | currency: 'EUR' }}</td>
					<td mat-footer-cell *matFooterCellDef>{{ totalCost(session, 1) | currency: 'EUR' }}</td>
				</ng-container>
				<ng-container matColumnDef="declaredAmount">
					<th mat-header-cell *matHeaderCellDef>{{ localeText.declaredAmount }}</th>
					<td mat-cell *matCellDef="let element">{{ element.amount.real | currency: 'EUR' }}</td>
					<td mat-footer-cell *matFooterCellDef>{{ totalCost(session, 2) | currency: 'EUR' }}</td>
				</ng-container>
				<ng-container matColumnDef="gap">
					<th mat-header-cell *matHeaderCellDef>{{ localeText.gap }}</th>
					<td mat-cell *matCellDef="let element">{{ element.amount.theoretical - element.amount.real | currency: 'EUR' }}</td>
					<td mat-footer-cell *matFooterCellDef>{{ totalCost(session, 3) | currency: 'EUR' }}</td>
				</ng-container>
				<ng-container matColumnDef="comment">
					<th mat-header-cell *matHeaderCellDef>{{ localeText.comment }}</th>
					<td mat-cell *matCellDef="let element">{{ element.comment }}</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumnsEntries"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumnsEntries"></tr>
				<tr mat-footer-row *matFooterRowDef="displayedColumnsEntries" class="bg-light-blue font-weight-bold"></tr>
			</table>
			<p class="export-one-session" (click)="downloadOne(session)">{{ localeText.downloadOne }}</p>
			<div class="dk-flex recap">
        <span>
          {{ localeText.incVAT }}
        </span>
				<span class="font-weight-bold mx-3">
          {{ localeText.annulations }}<span class="recap-badge ml-1">{{ session.recapLine[0].cancellation | currency: 'EUR' }}</span>
        </span>
				<span class="font-weight-bold mx-3">
          {{ localeText.refunds }}<span class="recap-badge ml-1 red">{{ session.recapLine[0].refund | currency: 'EUR' }}</span>
        </span>
				<span class="font-weight-bold mx-3">
          {{ localeText.reductions }}<span class="recap-badge ml-1">{{ session.recapLine[0].discount | currency: 'EUR' }}</span>
        </span>
				<span class="font-weight-bold mx-3">
          {{ localeText.debit }}<span class="recap-badge ml-1 blue">{{ session.recapLine[0].withdrawn | currency: 'EUR' }}</span>
        </span>
			</div>
		</mat-expansion-panel>
	</mat-accordion>
}
