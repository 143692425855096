import { getUniqueValues, RawValidationMessage, ValidationMessageSeverityEnum } from '@merim/utils';
import { OrbpRole, OrbStatus } from '../../enums/index';
import { getDefaultDssProgramValidation, OrbpValidatedProperties, RawDssProgramValidation } from '../../models/index';
import { OrbpDetail } from '../../types/index';
import { isDependent, isMaster, isMirror } from '../is-role';
import {
	isSortingOrdersAlgorithmValidOnMasterOrbp,
	SortingOrdersInvalidResult,
	SortingOrdersValidationResult
} from './is-sorting-orders-algorithm-valid-on-master';

export class SortingOrdersAlgorithmValidator {

	validate(allProgramOrbps: OrbpDetail[]): RawDssProgramValidation {
		const masterOrbps = allProgramOrbps.filter((orbp) => isMaster(orbp) && orbp.status === OrbStatus.On);
		const dependentOrbps = allProgramOrbps.filter((orbp) => isDependent(orbp) && orbp.status === OrbStatus.On);
		const mirrorOrbps = allProgramOrbps.filter((orbp) => isMirror(orbp) && orbp.status === OrbStatus.On);

		const validationResult: RawDssProgramValidation = getDefaultDssProgramValidation();

		const masterOrbpsValidity: SortingOrdersValidationResult[] = masterOrbps.map(orbp => isSortingOrdersAlgorithmValidOnMasterOrbp(orbp));
		const invalidDependents = dependentOrbps.filter(orbp => isDependentValid(orbp, masterOrbps) === false);
		const invalidMirrors = mirrorOrbps.filter(orbp => isMirrorValid(orbp) === false);

		masterOrbpsValidity
			.filter(x => x.isValid === false)
			.forEach((masterValidity: SortingOrdersInvalidResult) => {
				const orbpId = masterValidity.orbpId;
				const translationKey = masterValidity.errorTranslationKey;

				const validationMessage: RawValidationMessage = {
					severity: ValidationMessageSeverityEnum.INFO,
					propertyName: OrbpValidatedProperties.sortingOrdersAlgorithm,
					translationKey
				};

				validationResult.orbp[orbpId] = [validationMessage];
			});

		invalidDependents.forEach(orbp => {
			const validationMessage = getValidationMessage(
				OrbpRole.Dependent,
				OrbpValidatedProperties.sortingOrdersAlgorithm
			);

			validationResult.orbp[orbp.id] = [validationMessage];
		});

		invalidMirrors.forEach(orbp => {
			const validationMessage = getValidationMessage(
				OrbpRole.Mirror,
				OrbpValidatedProperties.sortingOrdersAlgorithm
			);

			validationResult.orbp[orbp.id] = [validationMessage];
		});

		return validationResult;
	}
}

/**
 * This is just to inform user. Severity is 'info'.
 * When Dependent has different sorting algorithm then his Master(s) it leads to Orders being displayed differently,
 * which may be confusing for the user.
 */
function isDependentValid(dependentOrbp: OrbpDetail, masterOrbps: OrbpDetail[]): boolean {
	// sanity check
	if (isDependent(dependentOrbp) === false) {
		return false;
	}

	const matchingMasters = masterOrbps.filter(m => dependentOrbp.masterIds.includes(m.id));
	const masterSortingAlgo = getUniqueValues(matchingMasters.map(m => m.sortingOrdersAlgorithm));

	// When Dependent has multiple Masters with different sorting algorithm, then the sorting might look chaotic for the user.
	const hasSameSortingAlgoAsMasters = masterSortingAlgo.length === 1 && masterSortingAlgo[0] === dependentOrbp.sortingOrdersAlgorithm;

	return hasSameSortingAlgoAsMasters === true;
}

/**
 * Mirror ORBp has calculated value based on its Master,
 * so it is stored as undefined.
 */
function isMirrorValid(mirrorOrbp: OrbpDetail): boolean {
	// sanity check
	if (isMirror(mirrorOrbp) === false) {
		return false;
	}

	const isValid = mirrorOrbp.sortingOrdersAlgorithm == undefined;
	return isValid;
}

function getValidationMessage(
	orbpRole: OrbpRole,
	propertyName: string
): RawValidationMessage {
	const translationKey = SortingOrderValidationMessagesKeys[orbpRole];

	const validationMessage: RawValidationMessage = {
		severity: ValidationMessageSeverityEnum.INFO,
		propertyName,
		translationKey
	};

	return validationMessage;
}

export const SortingOrderValidationMessagesKeys: Partial<Record<OrbpRole, string>> = {
	[OrbpRole.Dependent]: 'SORTING-ORDERS-ALGORITHM-DEPENDENT-ORBP-DIFFERENT-ALGORITHM-THEN-MASTERS',
	[OrbpRole.Mirror]: 'SORTING-ORDERS-ALGORITHM-MIRROR-ORBP-NON-DEFAULT-WITHOUT-DRIVE'
}
