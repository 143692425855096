import { Injectable } from '@angular/core';

import { MyRestaurantsSelectors, ReportsActions, ReportsSelectors, RootStoreState, RouterStoreSelectors } from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { EmployeeMealsServiceProvider } from './employee-meals-facade.provider.interface';

@Injectable()
export class EmployeeMealsFacade implements EmployeeMealsServiceProvider {
	currentLanguage$ = this._store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam));
	myDefaultRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant));
	restaurantIsOpened$ = this._store.pipe(select(MyRestaurantsSelectors.restaurantIsOpened));
	selectedRestaurants$ = this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurants));
	isLoadingSavedRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.isLoadingPersistedRestaurant));

	isLoading$ = this._store.pipe(select(ReportsSelectors.isLoadingEmployeeMeals));

	employeeMeals$ = this._store.pipe(select(ReportsSelectors.employeeMealsReport));

	weeklyReportParams$ = this._store.pipe(select(ReportsSelectors.weeklyReportParams));

	range = {
		from: DateTime.now().startOf('day'),
		to: DateTime.now().endOf('day'),
	};

	constructor(private _store: Store<RootStoreState>) {}

	getEmployeeMealsReport(from = this.range.from, to = this.range.to) {
		this.range.from = from;
		this.range.to = to;
		this._store.dispatch(ReportsActions.getEmployeeMealsReport({ from, to }));
	}
}
