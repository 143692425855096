import { _BK, CsiLines, ICsiTicket } from "@bk/jscommondatas";

export function getCBTokenFromTicket(ticket: ICsiTicket): string {
	if (!Array.isArray(ticket?.lines) || !ticket?.lines?.length) {
		return undefined;
	}
	const paymentLines = ticket.lines.filter((line) => line.typeLigne === CsiLines.REGLEMENT);
	if (!paymentLines.length) {
		return undefined;
	}
	const paymentLine = paymentLines.pop();
	if (!paymentLine) {
		return undefined;
	}
	const tags = _BK.getQueryVariables(paymentLine.tag);
	return tags?.TOKEN;
}
