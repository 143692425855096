import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';

import { PageContainerModule, SharedMaterialModule } from '@libs/dash/shared';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { AccountingRoutingModule } from './accounting-routing.module';
import { AccountingFacade } from './facade/accounting-facade.service';
import { AccountingDataTableEditComponent } from './partial-component/data-table-edit/data-table-edit.component';
import { AccountingDataTableComponent } from './partial-component/data-table/data-table.component';
import { ReportDetailComponent } from './partial-component/report-detail/report-detail.component';
import { ReviewComponent } from './partial-component/review/review.component';
import { ValidatedReportComponent } from './partial-component/validated-report/validated-report.component';
import { AccountingWaitListComponent } from './partial-component/wait-list/wait-list.component';
import { AccountingComponent } from './view-component/accounting.component';

@NgModule({
	imports: [
		CommonModule,
		AccountingRoutingModule,
		ReactiveFormsModule,
		PageContainerModule,
		SharedMaterialModule,
		MatMenuModule,
		MatTabsModule,
		FormsModule,
		MatIconModule,
		NgxDaterangepickerMd.forRoot(),
		MatDatepickerModule,
		MatFormFieldModule,
		MatInputModule,
	],
	declarations: [
		AccountingComponent,
		AccountingDataTableComponent,
		AccountingDataTableEditComponent,
		AccountingWaitListComponent,
		ReportDetailComponent,
		ReviewComponent,
		ValidatedReportComponent,
	],
	providers: [AccountingFacade],
	exports: [],
	bootstrap: [],
})
export class AccountingModule {}
