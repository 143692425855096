@if (localisedTexts$ | async; as i18n) {
	@if (!isNewRoute) {
		<dk-page-container [editButton]="false" [title]="i18n.title">
			@if (isLoading$ | async) {
				<div class="dk-on-progress-overlay">
					<div class="dk-on-progress-spinner-container">
						<mat-spinner></mat-spinner>
					</div>
				</div>
			} @else {
				<mat-tab-group class="zoomed-out">
					<mat-tab [label]="i18n['toReview']">
						<dk-accounting-wait-list [i18n]="i18n"></dk-accounting-wait-list>
					</mat-tab>
					<mat-tab [label]="i18n['validated']">
						<dk-accounting-done [i18n]="i18n"></dk-accounting-done>
					</mat-tab>
				</mat-tab-group>
			}
		</dk-page-container>
	} @else {
		@if (isLoading$ | async) {
			<div class="dk-on-progress-overlay">
				<div class="dk-on-progress-spinner-container">
					<mat-spinner></mat-spinner>
				</div>
			</div>
		} @else {
			<mat-tab-group class="zoomed-out">
				<mat-tab [label]="i18n['toReview']">
					<dk-accounting-wait-list [i18n]="i18n"></dk-accounting-wait-list>
				</mat-tab>
				<mat-tab [label]="i18n['validated']">
					<dk-accounting-done [i18n]="i18n"></dk-accounting-done>
				</mat-tab>
			</mat-tab-group>
		}
	}
}
