import { computed, Inject, inject, Injectable, InjectionToken, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { IBKMachine } from '@bk/jscommondatas';
import { IDefaultConfig } from '@libs/shared/interfaces';
import { DeviceFacade } from '@libs/shared/store/device-state';
import { IDefaultDevice } from '../models';

import { ConfigurationService } from './configuration.service';

const DeviceToken = new InjectionToken('Device token');

@Injectable({ providedIn: 'root' })
export class DeviceStateService<T extends IDefaultConfig> {
	private readonly _configurationService = inject<ConfigurationService<T>>(ConfigurationService);
	private readonly _deviceFacade = inject(DeviceFacade);
	private readonly _route = inject(ActivatedRoute);

	private readonly _queryParamsSig = toSignal(this._route.queryParams);
	private readonly _machineInfo = toSignal(this._deviceFacade.getMachineInfo());

	private readonly _deviceInfoSig = computed<IBKMachine>(() => {
		return {
			name: this._machineInfo()?.name ?? this.defaultDeviceInformation.name,
			role: this._machineInfo()?.role ?? this.defaultDeviceInformation.role,
			idx: (Number(this._queryParamsSig()?.deviceId) || this._machineInfo()?.idx) ?? this.defaultDeviceInformation.idx,
			ip: this._machineInfo()?.ip ?? this.defaultDeviceInformation.ip,
		};
	});

	constructor(@Inject(DeviceToken) private readonly defaultDeviceInformation: IDefaultDevice) {}

	/**
	 * Precedence of big data URL is:
	 * Take it from bo query parameter
	 * Take it from direct link to big data url defined in config file
	 * Take if from rap link defined in config file
	 */
	get deviceInfo(): Signal<IBKMachine> {
		return this._deviceInfoSig;
	}
}
