@if (localisedTexts$ | async; as texts) {
	<form [formGroup]="requestPasswordRenewalForm">
		<legend>
			{{ texts.title }}
		</legend>
		<mat-form-field>
			<mat-label>
				{{ texts.inputs.email.label }}
			</mat-label>
			<input id="dk-request-password-email" matInput type="email" formControlName="email"/>
			@if (requestPasswordRenewalForm.controls.email.invalid) {
				<mat-error> * {{ texts.inputs.email['error-message'] }}</mat-error>
			}
		</mat-form-field>
	</form>
	<dk-action-buttons [buttons]="actionButtons$ | async" (positiveAction)="promptRequestPasswordRenewal()"></dk-action-buttons>
}
