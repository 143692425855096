import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';

import { SharedMaterialModule } from '@libs/dash/shared';
import { EmployeeMealsRoutingModule } from './employee-meals-routing.module';
import { EMPLOYEE_MEAL_FACADE } from './facade/employee-meals-facade.injection.token';
import { EmployeeMealsFacade } from './facade/employee-meals-facade.service';
import { EmployeeMealsComponent } from './view-component/employee-meals.component';

@NgModule({
	declarations: [EmployeeMealsComponent],
	imports: [CommonModule, EmployeeMealsRoutingModule, ReactiveFormsModule, SharedMaterialModule, MatMenuModule],
	providers: [
		{
			provide: EMPLOYEE_MEAL_FACADE,
			useClass: EmployeeMealsFacade,
		},
	],
	bootstrap: [],
	exports: [EmployeeMealsComponent],
})
export class EmployeeMealsModule {}
