@if (localisedTexts$ | async; as i18n) {
	@if (!isNewRoute) {
		<dk-page-container [editButton]="false" [title]="i18n.title" [sourced]="sourced">
			@if (isLoading$ | async) {
				<div class="dk-on-progress-overlay">
					<div class="dk-on-progress-spinner-container">
						<mat-spinner></mat-spinner>
					</div>
				</div>
			} @else {
				@if (viewData$ | async; as viewData) {
					<div [class.full-container]="sourced">
						<div class="flex col-md" [class.space-evenly]="!sourced">
							<div class="revenue-charts col-sm">
								<dk-performance-pie-chart
									[rawValue]="viewData.currentRevenuePerTimeInterval.total.cumulative_value"
									[label]="i18n.revenue_tile"
									[goalValue]="viewData.predictedAccumulated || 0"
									[dark]="sourced"
									[tooltip]="i18n.tooltip_revenue"
								></dk-performance-pie-chart>
								<dk-performance-pie-chart
									[rawValue]="viewData.currentRevenuePerTimeInterval.total.cumulative_value"
									[label]="i18n.predicted_tile"
									[goalValue]="viewData.totalPredicted"
									[dark]="sourced"
									[tooltip]="i18n.tooltip_goal"
								></dk-performance-pie-chart>
								<dk-performance-pie-chart
									[rawValue]="viewData.currentRevenuePerTimeInterval.total.cumulative_value"
									[label]="i18n.previous_revenue"
									[goalValue]="viewData.pastTotalRevenue"
									[dark]="sourced"
									[tooltip]="i18n.tooltip_past"
								></dk-performance-pie-chart>
							</div>
							<dk-performance-bar-chart
								[channels]="viewData.channelsSales.channels"
								[values]="viewData.channelsSales.values"
								[i18n]="viewData.i18n.channels"
								[title]="viewData.i18n.sales_channels"
								[noSalesMsg]="viewData.i18n.noSalesMsg"
								[dark]="sourced"
								[class.wide]="!sourced"
								[class.justify]="sourced"
							></dk-performance-bar-chart>
							@if (sourced) {
								<div class="container" [class.sourced-container]="sourced">
									<dk-performance-mode-btn btnLabel="PMS" [modLabel]="pmsMod" [dark]="sourced"></dk-performance-mode-btn>
								</div>
							}
						</div>
						<div class="histogram">
							<dk-performance-graph-chart
								[xAxisLabels]="viewData.revenueByPrediction.times"
								[barSeries]="viewData.revenueByPrediction.prediction"
								[graphSeries]="viewData.revenueByPrediction.revenue"
								[barName]="i18n.actual_predicted_revenue"
								[graphName]="i18n.revenue"
								[dark]="sourced"
							></dk-performance-graph-chart>
						</div>
					</div>
				}
				@if (sourced) {
					<div id="progress-bar">
						<div id="fill-progress" [style.width]="fillProgressWidth"></div>
					</div>
				}
			}
			@if (isFetching$ | async) {
				<mat-spinner class="spinner-fetching" [class.default-view]="!sourced"></mat-spinner>
			}
		</dk-page-container>
	} @else {
		@if (isLoading$ | async) {
			<div class="dk-on-progress-overlay">
				<div class="dk-on-progress-spinner-container">
					<mat-spinner></mat-spinner>
				</div>
			</div>
		} @else {
			@if (viewData$ | async; as viewData) {
				<div [class.full-container]="sourced">
					<div class="flex col-md" [class.space-evenly]="!sourced">
						<div class="revenue-charts col-sm">
							<dk-performance-pie-chart
								[rawValue]="viewData.currentRevenuePerTimeInterval.total.cumulative_value"
								[label]="i18n.revenue_tile"
								[goalValue]="viewData.predictedAccumulated || 0"
								[dark]="sourced"
								[tooltip]="i18n.tooltip_revenue"
							></dk-performance-pie-chart>
							<dk-performance-pie-chart
								[rawValue]="viewData.currentRevenuePerTimeInterval.total.cumulative_value"
								[label]="i18n.predicted_tile"
								[goalValue]="viewData.totalPredicted"
								[dark]="sourced"
								[tooltip]="i18n.tooltip_goal"
							></dk-performance-pie-chart>
							<dk-performance-pie-chart
								[rawValue]="viewData.currentRevenuePerTimeInterval.total.cumulative_value"
								[label]="i18n.previous_revenue"
								[goalValue]="viewData.pastTotalRevenue"
								[dark]="sourced"
								[tooltip]="i18n.tooltip_past"
							></dk-performance-pie-chart>
						</div>
						<dk-performance-bar-chart
							[channels]="viewData.channelsSales.channels"
							[values]="viewData.channelsSales.values"
							[i18n]="viewData.i18n.channels"
							[title]="viewData.i18n.sales_channels"
							[noSalesMsg]="viewData.i18n.noSalesMsg"
							[dark]="sourced"
							[class.wide]="!sourced"
							[class.justify]="sourced"
						></dk-performance-bar-chart>
						@if (sourced) {
							<div class="container" [class.sourced-container]="sourced">
								<dk-performance-mode-btn btnLabel="PMS" [modLabel]="pmsMod" [dark]="sourced"></dk-performance-mode-btn>
							</div>
						}
					</div>
					<div class="histogram">
						<dk-performance-graph-chart
							[xAxisLabels]="viewData.revenueByPrediction.times"
							[barSeries]="viewData.revenueByPrediction.prediction"
							[graphSeries]="viewData.revenueByPrediction.revenue"
							[barName]="i18n.actual_predicted_revenue"
							[graphName]="i18n.revenue"
							[dark]="sourced"
						></dk-performance-graph-chart>
					</div>
				</div>
			}
			@if (sourced) {
				<div id="progress-bar">
					<div id="fill-progress" [style.width]="fillProgressWidth"></div>
				</div>
			}
		}
		@if (isFetching$ | async) {
			<mat-spinner class="spinner-fetching" [class.default-view]="!sourced"></mat-spinner>
		}
	}
}
