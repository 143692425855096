<table>
	<thead>
		@for (col of columns; track col) {
			<th>
				{{ i18n[col] }}
			</th>
		}
	</thead>
	<tbody [class.total]="total">
		@for (row of dataSource; track row; let rowIndex = $index) {
			<tr>
				@for (col of columns; track col; let last = $last; let colIndex = $index) {
					<td [ngClass]="{'actions': last }">
						<span [hidden]="editing[rowIndex][colIndex]" (click)="edit(rowIndex,colIndex)">{{ row[col] }}</span>
						<input [hidden]="!editing[rowIndex][colIndex]" (input)="change(rowIndex,col,$event)" type="text" [value]="row[col]" size="10"
						       type="number"/>
					</td>
				}
			</tr>
		}
	</tbody>
</table>
