import { createReducer, on } from '@ngrx/store';
import { compose, lensPath, set } from 'ramda';
import { IClerkRootState } from '../interfaces';
import { ClerkPaymentLoad, ClerkPaymentLoadSuccess, ClerkPaymentLoadType } from './actions';

export const initialState = {
	payments: {
		loading: true,
		data: [],
	},
};

const setPaymentMethods = (state: IClerkRootState, action: ClerkPaymentLoadType) => {
	return <IClerkRootState>compose(set(lensPath(['payments', 'loading']), false), set(lensPath(['payments', 'data']), action.data))(state);
};

const setPaymentMethodsLoad = (state: IClerkRootState) => {
	return <IClerkRootState>compose(set(lensPath(['payments', 'loading']), true))(state);
};

export const reducers = createReducer(
	initialState,
	on(ClerkPaymentLoad, setPaymentMethodsLoad),
	on(ClerkPaymentLoadSuccess, setPaymentMethods)
);
