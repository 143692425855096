import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as api from '@dki/api-client';
import { inOutAnimation } from '../inoutanimation/in-out-animation';

@Component({
	selector: 'app-restaurant-picker',
	templateUrl: './restaurant-picker.component.html',
	styleUrls: ['./restaurant-picker.component.scss'],
	animations: [inOutAnimation],
})
export class RestaurantPickerComponent implements OnInit {
	@Input() restaurants: api.Restaurant[];
	@Input() initialRestaurant: api.Restaurant[];
	@Output() restaurantSelection = new EventEmitter<api.Restaurant[]>();

	filteredRestaurants: api.Restaurant[];

	ngOnInit(): void {
		this.filteredRestaurants = this.restaurants;
		this.selectedRestaurant = this.initialRestaurant[0];
		this.submittedRestaurant = this.initialRestaurant[0];
		this.selectedRestaurants = this.initialRestaurant;
	}

	toggled = false;

	selectedRestaurant: api.Restaurant;

	selectedRestaurants: api.Restaurant[] = [];

	submittedRestaurant: api.Restaurant;

	toggleSelector() {
		this.toggled = !this.toggled;
	}

	get isOpen() {
		return this.toggled;
	}

	apply() {
		this.emitSelectedRestaurants(this.selectedRestaurants);
		this.submittedRestaurant = this.selectedRestaurant;
		this.toggled = false;
	}

	emitSelectedRestaurants(value: api.Restaurant[]) {
		this.restaurantSelection.emit([...value]);
	}

	handleSelection(rest: api.Restaurant) {
		const index = this.selectedRestaurants.findIndex((e) => e.id === rest.id);
		if (index > -1) {
			this.selectedRestaurants.splice(index, 1);
		} else {
			this.selectedRestaurants.push(rest);
		}
		this.selectedRestaurant = this.selectedRestaurants[this.selectedRestaurants.length - 1] || null;
	}

	handleSearch(text) {
		this.filteredRestaurants = this.restaurants.filter((e) => e.name.toLowerCase().includes(text.toLowerCase()));
	}

	isChecked(restaurant: api.Restaurant) {
		return this.selectedRestaurants.filter((e) => e.id === restaurant.id)[0] != undefined;
	}
}
