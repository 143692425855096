import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { UiVersionDetectorService } from '@libs/shared/services';
import { UntilDestroy } from '@ngneat/until-destroy';

import { AccountingFacade } from '../facade/accounting-facade.service';

@UntilDestroy()
@Component({
	selector: 'dk-accounting',
	templateUrl: './accounting.component.html',
	styleUrls: ['./accounting.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountingComponent implements OnInit {
	localisedTexts$ = this._accountingFacade.i18n$;
	isLoading$ = this._accountingFacade.isLoading$;

	private readonly _newUiDetector: UiVersionDetectorService = inject(UiVersionDetectorService);
	isNewRoute = this._newUiDetector.isNewUi();

	constructor(private readonly _accountingFacade: AccountingFacade) {}

	ngOnInit(): void {
		void 0;
		// this._accountingFacade.myDefaultRestaurant$.subscribe(() => this._accountingFacade.fetchAccountingReport());

		// this.reports$.subscribe(console.log);
	}
}
