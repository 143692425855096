import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DayOfWeek, IDaySelection } from '@libs/dash/core/entity';
import { TranslocoService } from '@ngneat/transloco';

@Component({
	selector: 'app-days-selector',
	templateUrl: './days-selector.component.html',
	styleUrls: ['./days-selector.component.scss'],
})
export class DaysSelectorComponent implements OnInit {
	@Output() daysChanged = new EventEmitter<string[]>();

	days: IDaySelection[] = [];

	constructor(private translocoService: TranslocoService) {}

	ngOnInit(): void {
		this._initializeDays();
	}

	private _initializeDays(): void {
		this.days = Object.values(DayOfWeek).map((day) => ({
			name: this.translocoService.translate(`sosKioskTile.days.${day}`),
			selected: false,
			value: day,
		}));
	}

	toggleDay(index: number) {
		this.days[index].selected = !this.days[index].selected;
		this.updateDays();
	}

	private updateDays(): void {
		const selectedDays = this.days.filter((day) => day.selected).map((day) => day.value);
		this.daysChanged.emit(selectedDays);
	}
}
