@if (localisedTexts$ | async; as i18n) {
	@if (!isNewRoute) {
	<dk-page-container [editButton]="false" [title]="i18n.title">
		<mat-tab-group class="zoomed-out">
			@if (featuremap?.history?.posValidations) {
				<mat-tab [label]="i18n['sessions-history']">
					<dk-sessions-history></dk-sessions-history>
				</mat-tab>
			}
			@if (featuremap?.history?.safeValidations) {
				<mat-tab [label]="i18n['safe-deposit']">
					<dk-safe-deposit></dk-safe-deposit>
				</mat-tab>
			}
			@if (featuremap?.history?.synthSessions) {
				<mat-tab [label]="i18n['receipts-synth']">
					<dk-receipts-synth></dk-receipts-synth>
				</mat-tab>
			}
			@if (featuremap?.history?.depositSummary) {
				<mat-tab [label]="i18n['deposit-synth']">
					<dk-deposits-summary></dk-deposits-summary>
				</mat-tab>
			}
			@if (featuremap?.history?.financialMovements) {
				<mat-tab [label]="i18n['financial-movements']">
					<dk-financial-movments></dk-financial-movments>
				</mat-tab>
			}
			@if (featuremap?.history?.vouchers) {
				<mat-tab [label]="i18n['vouchers']">
					<dk-vouchers-report></dk-vouchers-report>
				</mat-tab>
			}
		</mat-tab-group>
	</dk-page-container>
	} @else {
		<mat-tab-group class="zoomed-out">
			@if (featuremap?.history?.posValidations) {
				<mat-tab [label]="i18n['sessions-history']">
					<dk-sessions-history></dk-sessions-history>
				</mat-tab>
			}
			@if (featuremap?.history?.safeValidations) {
				<mat-tab [label]="i18n['safe-deposit']">
					<dk-safe-deposit></dk-safe-deposit>
				</mat-tab>
			}
			@if (featuremap?.history?.synthSessions) {
				<mat-tab [label]="i18n['receipts-synth']">
					<dk-receipts-synth></dk-receipts-synth>
				</mat-tab>
			}
			@if (featuremap?.history?.depositSummary) {
				<mat-tab [label]="i18n['deposit-synth']">
					<dk-deposits-summary></dk-deposits-summary>
				</mat-tab>
			}
			@if (featuremap?.history?.financialMovements) {
				<mat-tab [label]="i18n['financial-movements']">
					<dk-financial-movments></dk-financial-movments>
				</mat-tab>
			}
			@if (featuremap?.history?.vouchers) {
				<mat-tab [label]="i18n['vouchers']">
					<dk-vouchers-report></dk-vouchers-report>
				</mat-tab>
			}
		</mat-tab-group>
	}
}
