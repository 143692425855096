@if (viewData$ | async; as viewData) {
	<h4>{{ viewData.i18n.title }}</h4>
	@if (viewData.revenueProgress) {
		<table>
			<tr>
				<td>{{ viewData.i18n.table.selectedDayTotal }}</td>
				<td>€{{ viewData?.revenueProgress?.todaysTotalRevenue }}</td>
			</tr>
			<tr>
				<td>{{ viewData.i18n.table.selectedDayTotal }}</td>
				<td>€{{ viewData?.revenueProgress?.averageTotalRevenue }}</td>
			</tr>
		</table>
	}
	<!-- <div class="dk-chart-container">
	<div class="dk-chart-flex-container">
		<div *ngIf="viewData?.revenueProgress?.horizontalSteps" class="dk-chart-y-axis-labels-container">
			<ng-container *ngFor="let item of viewData.revenueProgress.horizontalSteps; let first = first; let last = last">
				<div *ngIf="!first" class="dk-chart-y-axis-spacer"></div>
				<div class="dk-chart-y-axis-label"> {{ !!+item || last ? '&euro;' + item : '' }}</div>
			</ng-container>
		</div>
		<div *ngIf="viewData?.revenueProgress" #svgContainer class="dk-svg-container">
			<svg xmlns="http://www.w3.org/2000/svg">
				<path [attr.d]="composePath(viewData.revenueProgress.averageSeries)" stroke="#CFC0BB" stroke-width="1" fill="rgba(0,0,0,0.1)" />
				<ng-container *ngFor="let item of viewData.revenueProgress.averageSeries; let index = index">
					<circle
						*ngIf="item.value !== '0.00'"
						[attr.cx]="index * columnWidth"
						[attr.cy]="calculateCy(item.value)"
						r="4"
						fill="#CFC0BB"
						(mouseenter)="setTootltip(true, item, '#CFC0BB', index * columnWidth, calculateCy(item.value))"
						(mouseleave)="hideTootltip()"
						/>
				</ng-container>
				<path [attr.d]="composePath(viewData.revenueProgress.currentSeries)" stroke="#7aa3e5" stroke-width="2" fill="rgba(122,163,229,0.1)" />
				<ng-container *ngFor="let item of viewData.revenueProgress.currentSeries; let index = index">
					<circle
						*ngIf="item.value !== '0.00'"
						[attr.cx]="index * columnWidth"
						[attr.cy]="calculateCy(item.value)"
						r="4"
						fill="#7aa3e5"
						(mouseenter)="setTootltip(true, item, '#7aa3e5', index * columnWidth, calculateCy(item.value))"
						(mouseleave)="hideTootltip()"
						/>
				</ng-container>
				<g>
					<rect *ngIf="tooltip.shown" [attr.x]="tooltip.x" [attr.y]="tooltip.y" rx="3" ry="3" width="120" height="24" [attr.fill]="tooltip.color" />
					<text [attr.x]="tooltip.x + 15" [attr.y]="tooltip.y + 15" font-family="Roboto" font-size="10" fill="white">{{ tooltip.text }}</text>
				</g>
			</svg>
		</div>
	</div>
	<div *ngIf="viewData?.revenueProgress?.activeHours" class="dk-chart-x-axis-labels-container">
		<div *ngFor="let item of viewData.revenueProgress.activeHours" class="dk-chart-x-axis-label-wrapper">
			<div class="dk-chart-x-axis-label">
				{{ item }}
			</div>
		</div>
	</div>
</div> -->
	<dk-graph [dataSource]="viewData.revenueProgress" [history]="true"></dk-graph>
	<div class="dk-line-chart-legend">
		<div class="dk-line-chart-legend-item">
			<div class="dk-line-chart-legend-stripe" [style.backgroundColor]="'#7aa3e5'"></div>
			<div class="dk-line-chart-legend-label">{{ viewData.i18n.legend.selectedDayTotal }}</div>
		</div>
		<div class="dk-line-chart-legend-item">
			<div class="dk-line-chart-legend-stripe" [style.backgroundColor]="'#CFC0BB'"></div>
			<div class="dk-line-chart-legend-label">{{ viewData.i18n.legend.longTermAverage }}</div>
		</div>
	</div>
} @else {
	<div class="dk-in-progress-spinner-container">
		<mat-spinner></mat-spinner>
	</div>
}

