<p class="label" [class.whiten]="dark">
	{{
		label
	}}<span
	class="dot"
	[ngClass]="[computeColors(valuePercent()) === red ? 'red' : computeColors(valuePercent()) === yellow ? 'yellow' : 'blue']"
	[matTooltip]="tooltip"
	matTooltipPosition="below"
	matTooltipHideDelay="500"
></span>
</p>
<div #chart [ngStyle]="{ height: dark ? '280px' : '240px' }"></div>
<div class="values">
	<p [style.color]="computeColors(valuePercent())" class="raw">{{ rawValue | currency: 'EUR':'symbol':'1.2-2' }}</p>
	<p class="sep">/</p>
	<p class="goal" [class.whiten]="dark">{{ goalValue ? (goalValue | currency: 'EUR':'symbol':'1.2-2') : 'N/A' }}</p>
</div>
