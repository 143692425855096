<form [formGroup]="lineTresholdsForm">
	<fieldset>
		<legend>
			{{ texts.title }}
		</legend>

		<mat-form-field>
			<mat-label>T-Line 1</mat-label>
			<input matInput type="number" min="0" [formControlName]="'lineTreshold1'"/>
		</mat-form-field>

		<mat-form-field>
			<mat-label>T-Line 2</mat-label>
			<input matInput type="number" min="0" [formControlName]="'lineTreshold2'"/>
		</mat-form-field>
	</fieldset>
</form>
