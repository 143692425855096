import { SosDttReport } from '@dki/api-client';
import { ExportToCsv } from 'export-to-csv';
import { cloneDeep } from 'lodash-es';

export const totalTime = (row) => {
	if (!row) return 0;
	let total = 0;
	total += row.mean_sos_waittime ? parseFloat(row.mean_sos_waittime) : 0;
	total += row.mean_sos_ordertime ? parseFloat(row.mean_sos_ordertime) : 0;
	total += row.mean_sos_linetime ? parseFloat(row.mean_sos_linetime) : 0;
	total += row.mean_sos_paytime ? parseFloat(row.mean_sos_paytime) : 0;
	total += row.mean_sos_hardservetime ? parseFloat(row.mean_sos_hardservetime) : 0;
	total += row.mean_sos_deliverytime ? parseFloat(row.mean_sos_deliverytime) : 0;
	// total += row.mean_sos_departtime ? parseFloat(row.mean_sos_departtime) : 0;
	return total;
};

export const objectiveAchieved = (type, value) => {
	if (!value) return false;
	if (type === 'order') {
		return value <= 45;
	} else if (type === 'delivery') {
		return value <= 30;
	} else if (type === 'total') {
		return value <= 185;
	}
	return false;
};

export const totalAverage = (data: SosDttReport[], key: any, isFloor: boolean) => {
	let total = 0;
	if (key === 'total') {
		let count = 0;
		data.forEach((a) => {
			const totalPerRow = totalTime(a);
			totalPerRow ? (count += a.count - a.sos_samecar) : void 0;
			total += totalPerRow * (a.count - a.sos_samecar);
		});
		total = total / count;
	} else if (key === 'parked_cars') {
		total = data.filter((entry) => entry[key] && entry[key] > 0).reduce((a, b) => a + b[key], 0);
	} else if (key === 'mean_sos_parktime') {
		const count = data.filter((entry) => entry[key] && entry[key] > 0).length;
		total = data.filter((entry) => entry[key] && entry[key] > 0).reduce((a, b) => a + b[key], 0);
		total /= count;
	} else {
		const totalCars = data.reduce((acc, obj) => {
			return acc + (obj.count - obj.sos_samecar);
		}, 0);
		total = data
			.filter((entry) => entry[key] && entry[key] > 0)
			.reduce((a, b) => {
				return a + (b[key] * (b.count - b.sos_samecar)) / totalCars;
			}, 0);
	}
	return isFloor ? Math.floor(total) : total ? total : 0;
};

export const total = (data: SosDttReport[], key: any) => {
	let total = 0;
	data.forEach((entry) => {
		total += entry[key] ? entry[key] : 0;
	});
	return total;
};

export const getDeliveryTime = (data: SosDttReport) => {
	const deliveryTime = data.mean_sos_deliverytime || 0;
	// const departtime = data.mean_sos_departtime || 0;
	return deliveryTime;
};

export const downloadCSV = (csvString, filename) => {
	// Create a Blob object from the CSV string.
	const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });

	// Create an anchor element and set its download attribute to the filename.
	const anchor = document.createElement('a');
	anchor.download = filename;

	// Set the anchor element's href attribute to the Blob object.
	anchor.href = window.URL.createObjectURL(blob);

	// Click the anchor element to download the CSV file.
	anchor.click();

	// Remove the anchor element from the document.
	anchor.remove();
};

export const exportBKFCSV = (title, viewdata, type, source_type, footer, shouldReturnCsv, currencyPipe, pipe) => {
	const csv = [];

	viewdata.dttReport.forEach((dttReport) => {
		const report = {
			[viewdata.i18n.dtt_report['header']['day_part']]: viewdata.i18n.dtt_report['day_part'][dttReport.day_part],
			[viewdata.i18n.dtt_report['header']['ca']]: currencyPipe.transform(dttReport.total_revenue, 'EUR', 'symbol', '1.2-2') || '-',
			[viewdata.i18n.dtt_report['header']['avrg_ticket']]:
				currencyPipe.transform(dttReport.total_revenue / dttReport.count, 'EUR', 'symbol', '1.2-2') || '-',
			[viewdata.i18n.dtt_report['header']['orders']]: dttReport.count || '-',
			[viewdata.i18n.dtt_report['header']['cars']]: dttReport.count - dttReport.sos_samecar || '-',
			[viewdata.i18n.dtt_report['header']['wait']]: pipe.transform(dttReport.mean_sos_waittime),
			[viewdata.i18n.dtt_report['header']['order_time']]: pipe.transform(dttReport.mean_sos_ordertime),
			[viewdata.i18n.dtt_report['header']['order']]: pipe.transform(dttReport.mean_sos_ordertime + dttReport.mean_sos_waittime),
			[viewdata.i18n.dtt_report['header']['transit']]: pipe.transform(dttReport.mean_sos_linetime),
			[viewdata.i18n.dtt_report['header']['payment']]: pipe.transform(dttReport.mean_sos_paytime),
			[viewdata.i18n.dtt_report['header']['transit_delivery']]: pipe.transform(dttReport.mean_sos_hardservetime),
			[viewdata.i18n.dtt_report['header']['delivery']]: pipe.transform(dttReport.mean_sos_deliverytime),
			[viewdata.i18n.dtt_report['header']['total']]: pipe.transform(totalTime(dttReport)),
			[viewdata.i18n.dtt_report['header']['park']]: pipe.transform(dttReport.mean_sos_parktime),
			[viewdata.i18n.dtt_report['header']['depart']]: pipe.transform(dttReport.mean_sos_departtime),
		};
		csv.push(report);
	});
	csv.push({
		[viewdata.i18n.dtt_report['header']['day_part']]: [viewdata.i18n.dtt_report['day_part']['avrg_day']],
		[viewdata.i18n.dtt_report['header']['ca']]: currencyPipe.transform(
			total(viewdata.dttReport, 'total_revenue'),
			'EUR',
			'symbol',
			'1.2-2'
		),
		[viewdata.i18n.dtt_report['header']['avrg_ticket']]: currencyPipe.transform(
			total(viewdata.dttReport, 'total_revenue') / total(viewdata.dttReport, 'count'),
			'EUR',
			'symbol',
			'1.2-2'
		),
		[viewdata.i18n.dtt_report['header']['orders']]: total(viewdata.dttReport, 'count'),
		[viewdata.i18n.dtt_report['header']['cars']]: total(viewdata.dttReport, 'count') - total(viewdata.dttReport, 'sos_samecar'),
		[viewdata.i18n.dtt_report['header']['wait']]: pipe.transform(totalAverage(viewdata.dttReport, 'mean_sos_waittime', false)),
		[viewdata.i18n.dtt_report['header']['order_time']]: pipe.transform(totalAverage(viewdata.dttReport, 'mean_sos_ordertime', false)),
		[viewdata.i18n.dtt_report['header']['order']]: pipe.transform(
			totalAverage(viewdata.dttReport, 'mean_sos_ordertime', false) + totalAverage(viewdata.dttReport, 'mean_sos_waittime', false)
		),
		[viewdata.i18n.dtt_report['header']['transit']]: pipe.transform(totalAverage(viewdata.dttReport, 'mean_sos_linetime', false)),
		[viewdata.i18n.dtt_report['header']['payment']]: pipe.transform(totalAverage(viewdata.dttReport, 'mean_sos_paytime', false)),
		[viewdata.i18n.dtt_report['header']['transit_delivery']]: pipe.transform(
			totalAverage(viewdata.dttReport, 'mean_sos_hardservetime', false)
		),
		[viewdata.i18n.dtt_report['header']['delivery']]: pipe.transform(totalAverage(viewdata.dttReport, 'mean_sos_deliverytime', false)),
		[viewdata.i18n.dtt_report['header']['total']]: pipe.transform(totalAverage(viewdata.dttReport, 'total', true)),
		[viewdata.i18n.dtt_report['header']['park']]: pipe.transform(totalAverage(viewdata.dttReport, 'mean_sos_parktime', false)),
		[viewdata.i18n.dtt_report['header']['depart']]: pipe.transform(totalAverage(viewdata.dttReport, 'mean_sos_departtime', false)),
	});
	if (footer) {
		// as there is no way to set a footer with this lib in csv, some dark magic is necessary
		// we taking the last object, emptying its values, and giving it the footer
		const footerObject = cloneDeep(csv[csv.length - 1]);
		for (const key in footerObject) {
			footerObject[key] = '';
		}
		footerObject[viewdata.i18n.dtt_report['header']['day_part']] = footer;
		csv.push(footerObject);
	}
	const options = {
		filename: title,
		decimalSeparator: '.',
		fieldSeparator: ';',
		showLabels: true,
		showTitle: true,
		title: `${title}_${type}_${source_type}`,
		useKeysAsHeaders: true,
	};
	const csvExporter = new ExportToCsv(options);
	if (csv.length > 0) {
		return csvExporter.generateCsv(csv, shouldReturnCsv);
	}
};

export const exportBKCCSV = (title, viewdata, type, source_type, footer, shouldReturnCsv, currencyPipe, pipe) => {
	const csv = [];

	viewdata.dttReport.forEach((dttReport) => {
		const report = {
			[viewdata.i18n.dtt_report['header']['day_part']]: viewdata.i18n.dtt_report['day_part'][dttReport.day_part],
			[viewdata.i18n.dtt_report['header']['ca']]: currencyPipe.transform(dttReport.total_revenue, 'EUR', 'symbol', '1.2-2') || '-',
			[viewdata.i18n.dtt_report['header']['avrg_ticket']]:
				currencyPipe.transform(dttReport.total_revenue / dttReport.count, 'EUR', 'symbol', '1.2-2') || '-',
			[viewdata.i18n.dtt_report['header']['orders']]: dttReport.count || '-',
			[viewdata.i18n.dtt_report['header']['cars']]: dttReport.count - dttReport.sos_samecar || '-',
			[viewdata.i18n.dtt_report['header']['order']]: pipe.transform(dttReport.mean_sos_ordertime + dttReport.mean_sos_waittime),
			[viewdata.i18n.dtt_report['header']['payment']]: pipe.transform(
				dttReport.mean_sos_paytime + dttReport.mean_sos_hardservetime + dttReport.mean_sos_linetime
			),
			[viewdata.i18n.dtt_report['header']['delivery']]: pipe.transform(dttReport.mean_sos_deliverytime),
			[viewdata.i18n.dtt_report['header']['total']]: pipe.transform(totalTime(dttReport)),
		};
		csv.push(report);
	});
	csv.push({
		[viewdata.i18n.dtt_report['header']['day_part']]: [viewdata.i18n.dtt_report['day_part']['avrg_day']],
		[viewdata.i18n.dtt_report['header']['ca']]: currencyPipe.transform(
			total(viewdata.dttReport, 'total_revenue'),
			'EUR',
			'symbol',
			'1.2-2'
		),
		[viewdata.i18n.dtt_report['header']['avrg_ticket']]: currencyPipe.transform(
			total(viewdata.dttReport, 'total_revenue') / total(viewdata.dttReport, 'count'),
			'EUR',
			'symbol',
			'1.2-2'
		),
		[viewdata.i18n.dtt_report['header']['orders']]: total(viewdata.dttReport, 'count'),
		[viewdata.i18n.dtt_report['header']['cars']]: total(viewdata.dttReport, 'count') - total(viewdata.dttReport, 'sos_samecar'),
		[viewdata.i18n.dtt_report['header']['order']]: pipe.transform(
			totalAverage(viewdata.dttReport, 'mean_sos_ordertime', false) + totalAverage(viewdata.dttReport, 'mean_sos_waittime', false)
		),
		[viewdata.i18n.dtt_report['header']['payment']]: pipe.transform(
			totalAverage(viewdata.dttReport, 'mean_sos_paytime', false) +
				totalAverage(viewdata.dttReport, 'mean_sos_linetime', false) +
				totalAverage(viewdata.dttReport, 'mean_sos_hardservetime', false)
		),
		[viewdata.i18n.dtt_report['header']['delivery']]: pipe.transform(totalAverage(viewdata.dttReport, 'mean_sos_deliverytime', false)),
		[viewdata.i18n.dtt_report['header']['total']]: pipe.transform(totalAverage(viewdata.dttReport, 'total', true)),
	});
	if (footer) {
		// as there is no way to set a footer with this lib in csv, some dark magic is necessary
		// we taking the last object, emptying its values, and giving it the footer
		const footerObject = cloneDeep(csv[csv.length - 1]);
		for (const key in footerObject) {
			footerObject[key] = '';
		}
		footerObject[viewdata.i18n.dtt_report['header']['day_part']] = footer;
		csv.push(footerObject);
	}
	const options = {
		filename: title,
		decimalSeparator: '.',
		fieldSeparator: ';',
		showLabels: true,
		showTitle: true,
		title: `${title}_${type}_${source_type}`,
		useKeysAsHeaders: true,
	};
	const csvExporter = new ExportToCsv(options);
	if (csv.length > 0) {
		return csvExporter.generateCsv(csv, shouldReturnCsv);
	}
};
