import { Injectable } from '@angular/core';

import { AuthStoreActions, AuthStoreSelectors, RootStoreState } from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AccountServiceProvider } from './account-facade.provider.interface';

@Injectable()
export class AccountFacade implements AccountServiceProvider {
	isLoading$: Observable<boolean> = this._store.pipe(select(AuthStoreSelectors.getIsLoading));
	isAuthenticated$: Observable<boolean> = this._store.pipe(select(AuthStoreSelectors.isAuthenticated));

	constructor(private _store: Store<RootStoreState>) {}

	promptLogin(userName: string, password: string): void {
		this._store.dispatch(AuthStoreActions.login({ userName, password }));
	}

	promptLogout(): void {
		this._store.dispatch(AuthStoreActions.logout());
	}

	promptPasswordSetup(password: string): void {
		this._store.dispatch(AuthStoreActions.passwordSetup({ password }));
	}

	promptRequestPasswordRenewal(email: string): void {
		this._store.dispatch(AuthStoreActions.requestPasswordRenewal({ email }));
	}
}
