import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { PageContainerModule, SharedMaterialModule } from '@libs/dash/shared';
import { DttExportModule } from '../dtt-export/dtt-export.module';
import { SpeedOfServiceModule } from '../speed-of-service/speed-of-service.module';
import { TimeOfServiceRoutingModule } from './time-of-service-routing.module';
import { TimeOfServiceComponent } from './view-component/time-of-service.component';

@NgModule({
	imports: [
		CommonModule,
		TimeOfServiceRoutingModule,
		MatProgressBarModule,
		SharedMaterialModule,
		ReactiveFormsModule,
		MatCardModule,
		SpeedOfServiceModule,
		DttExportModule,
		MatTabsModule,
		PageContainerModule,
	],
	declarations: [TimeOfServiceComponent],
	providers: [
		//{
		//	provide: SAFE_DEPOSIT_FACADE,
		//	useClass: SafeDepositFacade,
		//},
	],
	exports: [],
	bootstrap: [],
})
export class TimeOfServiceModule {}
