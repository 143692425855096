import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { downloadBlob } from '@libs/dash/core';

import { Range } from '@libs/dash/core/entity';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import { SAFE_DEPOSIT_FACADE } from '../../facade/safe-deposit-facade.injection.token';
import { SafeDepositServiceProvider } from '../../facade/safe-deposit-facade.provider.interface';

@UntilDestroy()
@Component({
	selector: 'dk-safe-reports',
	templateUrl: './safe-deposit-reports.component.html',
	styleUrls: ['./safe-deposit-reports.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SafeDepositReportsComponent implements OnInit {
	dateRange = new FormGroup({
		from: new FormControl(this.safeDepositServiceProvider.range.from.toJSDate()),
		to: new FormControl(this.safeDepositServiceProvider.range.to.toJSDate()),
	});

	@Input() localeText: any;

	public isLoading = this.safeDepositServiceProvider.isLoading$;

	data: any;

	displayedColumnsManager = ['name', 'id'];
	displayedColumnsEntries: string[] = ['accounts', 'amountTh', 'declaredAmount', 'gap', 'comment'];

	constructor(@Inject(SAFE_DEPOSIT_FACADE) private readonly safeDepositServiceProvider: SafeDepositServiceProvider) {}

	ngOnInit() {
		this.safeDepositServiceProvider.safeDepositRecords.pipe(untilDestroyed(this)).subscribe((data) => {
			this.data = data;
		});
		this.safeDepositServiceProvider.myDefaultRestaurant$.pipe(untilDestroyed(this)).subscribe(() => this.setPeriod());
		this.safeDepositServiceProvider.safeDateRange$.pipe(untilDestroyed(this)).subscribe((v) => {
			this.dateRange.setValue({ from: v.from.toJSDate(), to: v.to.toJSDate() });
		});
	}

	setPeriod(period?: string) {
		const today = DateTime.now();
		let from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		switch (period) {
			case Range.Today:
				from = today;
				to = today;
				break;
			case Range.Week:
				from = today.startOf(Range.Week);
				to = today.endOf(Range.Week);
				break;
			case Range.Month:
				from = today.startOf(Range.Month);
				to = today.endOf(Range.Month);
				break;
			case Range.Period:
				to = !this.dateRange.controls.to.value ? from : to;
				break;
		}
		this.dateRange.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this.safeDepositServiceProvider.getRecords(from, to);
	}

	totalCost(record, colCount) {
		const key = colCount == 1 ? 'theoretical' : colCount == 2 ? 'real' : 'gap';
		if (key !== 'gap') return record.entries.reduce((acc, y) => acc + y.amount[key], 0);
		else return this.totalCost(record, 1) - this.totalCost(record, 2);
	}

	downloadAll() {
		const csv = this.data.map((record) => this.recordToCSV(record)).join('\r\n');
		const from = DateTime.fromJSDate(this.dateRange.value.from).toFormat('yyyy MM dd');
		const to = DateTime.fromJSDate(this.dateRange.value.to).toFormat('yyyy MM dd');
		const filename = `${this.localeText.safe} ${from} - ${to}`;
		downloadBlob(csv, filename, 'text/csv;charset=utf-8');
	}

	downloadOne(record) {
		const recordCSV = this.recordToCSV(record);
		downloadBlob(recordCSV, `${this.localeText.safe} - ${record.date}`, 'text/csv;charset=utf-8');
	}

	recordToCSV(record) {
		let csv = `${this.localeText.name};${record.managerSource[0].login}\r\n`;
		csv += `${this.localeText.validationTime};${record.date};\r\n`;
		csv += `${this.localeText.accounts};${this.localeText.amountTh};${this.localeText.declaredAmount};${this.localeText.gap};${this.localeText.comment}\r\n`;
		csv += Object.values(record.entries)
			.map(
				(entry) =>
					`${this.localeText.paymentMethods[entry['label']] || entry['label']};${entry['amount'].theoretical};${entry['amount'].real};${
						entry['amount'].theoretical - entry['amount'].real
					};${entry['comment']}`
			)
			.join('\r\n');
		csv += `\r\n${this.localeText.total};${this.totalCost(record, 1)};${this.totalCost(record, 2)};${this.totalCost(record, 3)}\r\n`;
		return csv;
	}

	singleDaySelection() {
		const from = this.dateRange.controls.from.value as Date;
		const to = this.dateRange.controls.to.value as Date;
		return from.getDate() === to.getDate() && from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
	}
}
