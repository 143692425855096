@if (viewData$ | async; as viewData) {
	@if (isLoading$ | async) {
		<div class="dk-on-progress-overlay">
			<div class="dk-on-progress-spinner-container">
				<mat-spinner></mat-spinner>
			</div>
		</div>
	} @else {
		<div class="filters">
			<mat-form-field appearance="outline">
				<mat-label>{{ viewData.i18n.entries }}</mat-label>
				<mat-select [formControl]="entries" multiple (selectionChange)="setFilter()">
					@for (entry of entriesList; track entry) {
						<mat-option [value]="entry">{{ entry }}</mat-option>
					}
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>{{ viewData.i18n.sorties }}</mat-label>
				<mat-select [formControl]="sorties" multiple (selectionChange)="setFilter()">
					@for (sortie of sortiesList; track sortie) {
						<mat-option [value]="sortie">{{ sortie }}</mat-option>
					}
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>{{ viewData.i18n.depenses }}</mat-label>
				<mat-select [formControl]="depenses" multiple (selectionChange)="setFilter()">
					@for (depense of depensesList; track depense) {
						<mat-option [value]="depense">{{ depense }}</mat-option>
					}
				</mat-select>
			</mat-form-field>
		</div>
		<div class="dk-picker" [class.single-display]="singleDaySelection()">
			<ng-content></ng-content>
			<mat-form-field appearance="fill" subscriptSizing="dynamic">
				<mat-label>{{ viewData.i18n.chooseDates }}</mat-label>
				<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
					<input [placeholder]="viewData.i18n.startDate" formControlName="from" matStartDate [hidden]="singleDaySelection()"/>
					<input [placeholder]="viewData.i18n.endDate" formControlName="to" matEndDate [class.single-display]="singleDaySelection()"/>
				</mat-date-range-input>
				<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
				<mat-date-range-picker #picker>
					<mat-date-range-picker-actions>
						<button mat-button matDateRangePickerCancel>{{ viewData.i18n.cancel }}</button>
						<button (click)="setPeriod('period')" color="primary" mat-raised-button matDateRangePickerApply>
							{{ viewData.i18n.apply }}
						</button>
					</mat-date-range-picker-actions>
				</mat-date-range-picker>
			</mat-form-field>
			<button (click)="setPeriod('today')" mat-button>{{ viewData.i18n.today }}</button>
			<button (click)="setPeriod('week')" mat-button>{{ viewData.i18n.week }}</button>
			<button (click)="setPeriod('month')" mat-button>{{ viewData.i18n.month }}</button>
			@if (isFetching$ | async) {
				<mat-spinner class="d-inline" diameter="20"></mat-spinner>
			}
		</div>
		@if (viewData.data?.entries?.length) {
			<h2>{{ viewData.i18n.entries }}</h2>
			<table class="mat-table mat-elevation-z8">
				<thead>
				<tr>
					@for (header of viewData.i18n.headers | keyvalue: originalOrder; track header) {
						<th class="left-gray">{{ header.value }}</th>
					}
				</tr>
				</thead>
				<tbody>
					@for (tx of viewData.data.entries; track tx) {
						<tr>
							<td>{{ tx.operation.name }}</td>
							<td>{{ tx.employee.login.split(' ')[0] }}</td>
							<td>{{ tx.employee.login.split(' ').slice(1).join(' ') }}</td>
							<td>{{ tx.amounts.without_taxes | currency: 'EUR':'symbol':'1.2-2' }}</td>
							<td>{{ tx.effective_time | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
							<td>
								<mat-icon (click)="download(tx)">cloud_download</mat-icon>
							</td>
						</tr>
					}
				</tbody>
			</table>
		}
		@if (viewData.data?.sorties?.length) {
			<h2>{{ viewData.i18n.sorties }}</h2>
			<table class="mat-table mat-elevation-z8">
				<thead>
				<tr>
					@for (header of viewData.i18n.headers | keyvalue: originalOrder; track header) {
						<th class="left-gray">{{ header.value }}</th>
					}
				</tr>
				</thead>
				<tbody>
					@for (tx of viewData.data.sorties; track tx) {
						<tr>
							<td>{{ tx.operation.name }}</td>
							<td>{{ tx.employee.login.split(' ')[0] }}</td>
							<td>{{ tx.employee.login.split(' ').slice(1).join(' ') }}</td>
							<td>{{ tx.amounts.without_taxes | currency: 'EUR':'symbol':'1.2-2' }}</td>
							<td>{{ tx.effective_time | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
							<td>
								<mat-icon (click)="download(tx)">cloud_download</mat-icon>
							</td>
						</tr>
					}
				</tbody>
			</table>
		}
		@if (viewData.data?.depenses?.length) {
			<h2>{{ viewData.i18n.depenses }}</h2>
			<table class="mat-table mat-elevation-z8">
				<thead>
				<tr>
					@for (header of viewData.i18n.headers | keyvalue: originalOrder; track header) {
						<th class="left-gray">{{ header.value }}</th>
					}
				</tr>
				</thead>
				<tbody>
					@for (tx of viewData.data.depenses; track tx) {
						<tr>
							<td>{{ tx.operation.name }}</td>
							<td>{{ tx.employee.login.split(' ')[0] }}</td>
							<td>{{ tx.employee.login.split(' ').slice(1).join(' ') }}</td>
							<td>{{ tx.amounts.without_taxes | currency: 'EUR':'symbol':'1.2-2' }}</td>
							<td>{{ tx.effective_time | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
							<td>
								<mat-icon (click)="download(tx)">cloud_download</mat-icon>
							</td>
						</tr>
					}
				</tbody>
			</table>
		}
	}
}
