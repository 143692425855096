import { createSelector } from '@ngrx/store';
import { IErrorRootState } from '../interfaces';

export const selectAppState = (state) => state['error'];

export const getLastError = createSelector(selectAppState, (error: IErrorRootState) => error.data?.[0]);

export const getAllErrors = createSelector(selectAppState, (error: IErrorRootState) => error.data || []);

export const getLastUnseenError = createSelector(selectAppState, (error: IErrorRootState) => {
	return error.data.find((item) => {
		return !item.seen;
	});
});
