import { SafeRecord } from '@dki/api-client';
import { DateTime } from 'luxon';

const mapApiResponse = (records: SafeRecord[]) => {
	const mappedApiReponse = [];
	records.forEach((record: SafeRecord) => {
		const mappedRecord: any = { ...record };
		mappedRecord['managerSource'] = [record.manager];
		mappedRecord.date = DateTime.fromMillis(mappedRecord.validationTime).toFormat('yyyy-MM-dd HH:mm');
		mappedApiReponse.push(mappedRecord);
	});
	return mappedApiReponse;
};

export { mapApiResponse };
