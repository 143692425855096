import { Injectable } from '@angular/core';

import { Range } from '@libs/dash/core/entity';
import {
	MyRestaurantsSelectors,
	RootStoreState,
	RouterStoreSelectors,
	SessionsHistoryActions,
	SessionsHistorySelectors,
} from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { map } from 'rxjs/operators';
import { mapApiResponse } from '../helpers/sessionHistoryMapper';
import { SessionsHistoryServiceProvider } from './sessions-history-facade.provider.interface';

@Injectable()
export class SessionsHistoryFacade implements SessionsHistoryServiceProvider {
	currentLanguage$ = this._store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam));
	myDefaultRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant));
	restaurantIsOpened$ = this._store.pipe(select(MyRestaurantsSelectors.restaurantIsOpened));
	dateRange$ = this._store.pipe(select(SessionsHistorySelectors.range));
	restaurantsLoading$ = this._store.pipe(select(MyRestaurantsSelectors.getIsLoading));

	isLoading$ = this._store.pipe(select(SessionsHistorySelectors.isLoading));

	sessionsHistory$ = this._store.pipe(select(SessionsHistorySelectors.sessionHistory), map(mapApiResponse));

	constructor(private _store: Store<RootStoreState>) {}

	range = {
		from: DateTime.now().startOf(Range.Week),
		to: DateTime.now().endOf(Range.Week),
	};

	getSessionsHistory(from: DateTime, to: DateTime) {
		this.range = { from, to };
		this._store.dispatch(SessionsHistoryActions.getSessionHistory({ from, to }));
	}
}
