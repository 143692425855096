import { Component } from '@angular/core';

@Component({
	selector: 'app-export-button',
	templateUrl: `./export-button.component.html`,
	styleUrls: ['./export-button.component.scss'],
})
export class ExportButtonComponent {
	export() {
		// Implementation for export functionality
		console.log('Exporting...');
	}
}
