import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PageContainerModule } from '@libs/dash/shared';
import { PERFORMANCE_FACADE } from './facade/performance-facade.injection.token';
import { PerformanceFacade } from './facade/performance-facade.service';
import { BarChartComponent } from './partial-components/bar-chart/bar-chart.component';
import { GraphChartComponent } from './partial-components/graph-chart/graph-chart.component';
import { ModeBtnComponent } from './partial-components/mode-btn/mode-btn.component';
import { PieChartComponent } from './partial-components/pie-chart/pie-chart.component';
import { PerformanceRoutingModule } from './performance-routing.module';
import { PerformanceComponent } from './view-component/performance.component';

@NgModule({
	declarations: [PerformanceComponent, BarChartComponent, PieChartComponent, GraphChartComponent, ModeBtnComponent],
	imports: [CommonModule, PerformanceRoutingModule, PageContainerModule, MatProgressSpinnerModule, MatTooltipModule],
	providers: [
		{
			provide: PERFORMANCE_FACADE,
			useClass: PerformanceFacade,
		},
	],
	bootstrap: [],
})
export class PerformanceModule {}
