import { MEDIA_TARGET } from '@bk/frontend-common';
import {
	BKMediaTargetFamilyEnum,
	BKMediaTypeEnum,
	BKMenuBaseImpl,
	BKProductBaseImpl,
	IBKMediaData,
	IBKProductBase,
	IBKSelectionPattern,
	ItemToSell,
	ItemToSellType,
} from '@bk/jscommondatas';
import {
	AvailabilityMap,
	AvailabilityStoreModel,
	AVAILABLE_JSON,
	BigDataStoreModel,
	CONFIGURATION_DOWNLOAD_TYPES,
	GlobalAppsConfiguration,
	IBKBigData,
	IRestoConfig,
	IRestoSettings,
	NavScreenContentOptions,
	RemoteConfigs,
} from '@libs/shared/models';
import {
	getActiveCampaignMediasPlaylistsIds,
	getNavScreenContent,
	getValidNavScreenContent,
	isMediaFromTargetFamily,
	isMenuWithAllDefaultProductsAvailable,
	isProductActive as isProductActivated,
	isProductWithIngredientsAvailable,
	isProductWithIngredientsAvailableAndActive,
	selectOnlyAvailableCampaigns,
} from '@libs/shared/utils';
import { createSelector } from '@ngrx/store';
import { filter, flatten, pluck, values } from 'ramda';
import { IFeatureConfigurationState, IRootState } from '../interfaces';

export const selectAppState = (state: IRootState) => state['configuration'];

export const isLoading = createSelector(selectAppState, (state, downloadType: CONFIGURATION_DOWNLOAD_TYPES) => state[downloadType].loading);

export const getData = createSelector(selectAppState, (state, downloadType: CONFIGURATION_DOWNLOAD_TYPES) => state[downloadType].data);

export const getBigData = createSelector(getData, (state) => state[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA]);

export const getProductById = createSelector(
	selectAppState,
	(state, data) => state[data.downloadType]?.data?.products?.[data.id] as IBKProductBase
);

export type GetProductByIdProps = {
	downloadType: CONFIGURATION_DOWNLOAD_TYPES;
	id: number;
};
// for Big_Datas only
export const getProductByIdSelectorFactory = (props: GetProductByIdProps) => {
	return createSelector(selectAppState, (state: IFeatureConfigurationState) => {
		if (props.downloadType !== CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA) {
			const errMessage = `getProductsOrMenusSelectorFromBigDatas() is for BIG_DATAS only.`;
			console.error(errMessage);
			throw new Error(errMessage);
		}

		const bigDatas: IBKBigData = state[props.downloadType].data || ({} as IBKBigData);
		return (bigDatas.products || {})[props.id];
	});
};

export type GetItemByIdProps = {
	downloadType: CONFIGURATION_DOWNLOAD_TYPES;
	id: number;
	itemType: ItemToSellType;
};
// for Big_Datas only
export const getItemByIdSelectorFactory = (props: GetItemByIdProps) =>
	createSelector(selectAppState, (state: IFeatureConfigurationState) => {
		if (props.downloadType !== CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA) {
			const errMessage = `getProductsOrMenusSelectorFromBigDatas() is for BIG_DATAS only.`;
			console.error(errMessage);
			throw new Error(errMessage);
		}

		const bigDatas: IBKBigData = state[props.downloadType].data || ({} as IBKBigData);
		const bigDatasItemType =
			props.itemType === ItemToSellType.Product ? 'products' : props.itemType === ItemToSellType.Menu ? 'menus' : undefined;
		return (bigDatas?.[bigDatasItemType] || {})[props.id];
	});

export const getGlobalAppsCredentialsSelector = () => {
	return createSelector(selectAppState, (state: IFeatureConfigurationState) => {
		const apiData: Partial<GlobalAppsConfiguration> = state.api.data.dkiBe || ({} as Partial<GlobalAppsConfiguration>);
		return apiData || {};
	});
};

export const isApiLoading = createSelector(selectAppState, (state) => state.api.loading);

export type getProductsOrMenusListParams = {
	downloadType: CONFIGURATION_DOWNLOAD_TYPES;
	items: ItemToSell[];
};

export type getProductsOrMenusAvailabilityParams = {
	items: ItemToSell[];
};

// for Big_Datas only
export const getProductsOrMenusSelectorFactory = (props: getProductsOrMenusListParams) => {
	return createSelector(selectAppState, (state: IFeatureConfigurationState) => {
		if (props.downloadType !== CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA) {
			const errMessage = `getProductsOrMenusSelectorFromBigDatas() is for BIG_DATAS only.`;
			console.error(errMessage);
			throw new Error(errMessage);
		}

		const bigDatas: IBKBigData = state[props.downloadType].data || ({} as IBKBigData);
		const productsMap = bigDatas.products || {};
		const menusMap = bigDatas.menus || {};

		const items = props.items
			.map((item) => {
				switch (item.type) {
					case ItemToSellType.Product: {
						const productItem = productsMap[item.id];
						return productItem ? new BKProductBaseImpl(productItem) : undefined;
					}
					case ItemToSellType.Menu: {
						const menuItem = menusMap[item.id];
						return menuItem ? new BKMenuBaseImpl(menuItem) : undefined;
					}
					default: {
						const errMessage = `getProductsOrMenusList() selector: Unexpected type ${item.type}. It will be skipped.`;
						console.warn(errMessage);
						return undefined;
					}
				}
			})
			.filter((item) => !!item);

		return items as (BKProductBaseImpl | BKMenuBaseImpl)[];
	});
};

/**
 * Check if both Products and Menus are available.
 *
 * In Products all default Ingredients must be available.
 * In Menus all default Products must be available.
 */
export const getProductsAvailabilityMapSelectorFactory = (props: getProductsOrMenusAvailabilityParams) => {
	return createSelector(selectAppState, (state: IFeatureConfigurationState) => {
		const bigDatas: BigDataStoreModel = state[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA];
		const availability: AvailabilityStoreModel = state[CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY];

		const result: AvailabilityMap = props.items.reduce((acc, itemToSell) => {
			let isAvailable = false;
			switch (itemToSell.type) {
				case ItemToSellType.Product:
					isAvailable = isProductWithIngredientsAvailableAndActive(bigDatas, availability, itemToSell.id);
					break;
				case ItemToSellType.Menu:
					isAvailable = isMenuWithAllDefaultProductsAvailable(bigDatas, availability, itemToSell.id);
					break;
				default:
					console.error(`getProductsAvailabilityMapSelectorFactory(): Unexpected type ${itemToSell.type} of item id ${itemToSell.id}`);
					break;
			}

			acc[itemToSell.id] = isAvailable;
			return acc;
		}, {}) as AvailabilityMap;

		return result;
	});
};

export type GetMediasByMediaTargetProps = {
	downloadType: CONFIGURATION_DOWNLOAD_TYPES;
	mediaTargetFamily: BKMediaTargetFamilyEnum;
};
// for Big_Datas only
export const getMediasByTargetSelectorFactory = (props: GetMediasByMediaTargetProps) => {
	return createSelector(selectAppState, (state: IFeatureConfigurationState) => {
		if (props.downloadType !== CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA) {
			const errMessage = `getMediasByTargetSelectorFactory() is for BIG_DATAS only.`;
			console.error(errMessage);
			throw new Error(errMessage);
		}
		const bigDatas: IBKBigData = state[props.downloadType].data || ({} as IBKBigData);

		let medias: IBKMediaData[] = Object.values(bigDatas.medias).filter((media: IBKMediaData) => {
			return isMediaFromTargetFamily(media, props.mediaTargetFamily);
		});
		return medias;
	});
};

export const isProductActive = createSelector(selectAppState, (state, id: number) => {
	return isProductActivated(state[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA], state[CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY], id);
});

export const isProductAndIngredientsAvailable = createSelector(selectAppState, (state, id: number) => {
	return isProductWithIngredientsAvailable(
		state[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA],
		state[CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY],
		id
	);
});

export const isProductActiveAndAvailable = createSelector(selectAppState, (state, id: number) => {
	return isProductWithIngredientsAvailableAndActive(
		state[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA],
		state[CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY],
		id
	);
});

export const getRestoSettings = createSelector(selectAppState, (state) => {
	return state.restoSettings.data;
});

export const isMultiPaymentEnabled = createSelector(selectAppState, (state) => {
	return state.restoSettings.data.enableMultipayOnKiosk;
});

export type GetNavigationScreenContentProps = {
	selectionPattern: IBKSelectionPattern[];
	navScreenContentOptions: NavScreenContentOptions;
};

export const getNavigationScreenContent = createSelector(
	selectAppState,
	(state: IFeatureConfigurationState, props: GetNavigationScreenContentProps) => {
		const remoteConfigs: RemoteConfigs = {
			bigData: state[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA].data,
			availability: state[CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY].data,
			restoConfig: state[CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG].data,
		};

		if (!remoteConfigs.bigData || !remoteConfigs.restoConfig || !remoteConfigs.availability) {
			return {
				products: [],
				menus: [],
				games: [],
				navigationScreens: [],
			};
		}

		const screenContent = getNavScreenContent(props.selectionPattern, remoteConfigs.bigData, remoteConfigs.restoConfig);
		const validScreenContent = getValidNavScreenContent(screenContent, remoteConfigs, props.navScreenContentOptions);
		return validScreenContent;
	}
);

export const getKioskNavScreenConfiguration = (configNumber?: number) =>
	createSelector(selectAppState, (state: IFeatureConfigurationState) => {
		const restoConfig: IRestoConfig = state[CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG].data;
		const restoSettings: IRestoSettings = state[CONFIGURATION_DOWNLOAD_TYPES.RESTO_SETTINGS].data;

		const navScreenConf =
			restoConfig.kioskConfigs?.[configNumber || restoSettings.restosettings.currentKioskMenu] ||
			restoConfig.kioskConfigs?.[restoConfig.kioskConfigsDefault];

		return navScreenConf;
	});

export const getMediasFromPlaylistForKioskFactory = () => {
	const target = MEDIA_TARGET.KIOSK_WAITING_VIDEO_SCREEN;

	return createSelector(selectAppState, (state: IFeatureConfigurationState): IBKMediaData[] => {
		const bigDatas = state[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA].data ?? ({} as IBKBigData);
		const availability = state[CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY].data ?? ({} as AVAILABLE_JSON);

		const getMediasNotInCampaign = (): IBKMediaData[] => {
			const mediaIdsFromPlaylistsNotInCampaign = values(bigDatas.mediasPlayList)
				.filter((playlist) => !playlist.campaignPlaylist)
				.reduce((acc: number[], playlist) => [...acc, ...playlist.content.map((content) => content.mediaId)], []);

			return mediaIdsFromPlaylistsNotInCampaign
				.map((id) => bigDatas.medias[id])
				.filter((media) => media && media.mediaTargets.includes(target) && !media.campaignMedia);
		};

		const getMediasInCampaign = (): IBKMediaData[] => {
			const mediaIdsFromPlaylistsInCampaign = filter(
				(playlist) =>
					playlist.campaignPlaylist &&
					getActiveCampaignMediasPlaylistsIds(selectOnlyAvailableCampaigns(bigDatas, availability)).includes(playlist.id),
				values(bigDatas.mediasPlayList)
			);

			const filteredMediaIds = filter(
				(id: number) => bigDatas.medias[id]?.mediaTargets.includes(target),
				pluck('mediaId', flatten(pluck('content', mediaIdsFromPlaylistsInCampaign)))
			);

			return filteredMediaIds.map((id) => bigDatas.medias[id]).filter((media) => !!media);
		};

		const medias = [...getMediasInCampaign(), ...getMediasNotInCampaign()];
		// TODO: DEV-4715: add HTML5 media rendering
		return medias.filter((media) => media.mediaType !== BKMediaTypeEnum.HTML5);
	});
};

export const getMediasForDisabledKioskFactory = () => {
	const target = MEDIA_TARGET.KIOSK_DISABLED;

	return createSelector(selectAppState, (state: IFeatureConfigurationState): IBKMediaData[] => {
		const bigDatas = state[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA].data ?? ({} as IBKBigData);

		return Object.values(bigDatas.medias).filter((m) => m.mediaTargets.includes(target));
	});
};

export const isGlobalConfigurationLoading = createSelector(selectAppState, (state) => {
	return state.globalConfiguration.loading;
});

export const getGlobalConfigurationData = createSelector(selectAppState, (state) => {
	return state.globalConfiguration.data;
});
