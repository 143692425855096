import { CurrencyPipe, KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import * as api from '@dki/api-client';
import { Restaurant } from '@dki/api-client';
import { getLocale } from '@libs/dash/core';

import { Range } from '@libs/dash/core/entity';
import { FeatureMapService } from '@libs/dash/guards';
import { UiVersionDetectorService } from '@libs/shared/services';
import { TranslocoService } from '@ngneat/transloco';
import { jsPDF } from 'jspdf';
import { groupBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { DTT_EXPORT_FACADE } from '../facade/dtt-export.facade.injection.token';
import { DttExportServiceProvider } from '../facade/dtt-export.facade.provider.interface';
import { SecondsToMins } from '../pipes/seconds-to-mins.pipe';
import { downloadCSV, exportBKCCSV, exportBKFCSV } from '../utils';

const GOALS = {
	mean_sos_ordertime: '00:45',
	mean_sos_servetime: '00:30',
	total: '02:45',
};

enum SourceType {
	cod = '2',
	tablet = '4', // to be verified
	boths = 'both',
}

@Component({
	selector: 'dk-dtt-export',
	templateUrl: './dtt-export.component.html',
	styleUrls: ['./dtt-export.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DttExportComponent implements OnInit, OnDestroy {
	dateRange = new FormGroup({
		from: new FormControl(this.dttExportService.range.from.toJSDate()),
		to: new FormControl(this.dttExportService.range.to.toJSDate()),
	});

	private readonly _newUiDetector: UiVersionDetectorService = inject(UiVersionDetectorService);
	isNewRoute = this._newUiDetector.isNewUi();

	goals = GOALS;
	dirtyForm = false;
	startedFetching = false;
	isExporting = false;

	weekDays = new Set<api.WeekDay>();
	data = [];
	sourceType: SourceType = SourceType.boths;
	i18n$ = this._translocoService.selectTranslateObject('dtt-export');
	isLoading$ = this.dttExportService.isLoading$;
	isLoadingDTT$ = this.dttExportService.isLoadingDTT$;
	selectedRestaurant$ = this.dttExportService.myDefaultRestaurant$;
	selectedRestaurants$ = this.dttExportService.selectedRestaurants$;
	restaurant: Restaurant;
	selectedRestaurants: Array<Restaurant>;
	allAggregated = [
		api.DkiBeClickhouseAnalyticsSosDttReportAggregation.Restaurant,
		api.DkiBeClickhouseAnalyticsSosDttReportAggregation.Businessday,
	];
	restAggregated = [api.DkiBeClickhouseAnalyticsSosDttReportAggregation.Businessday];
	groupType: 'cumul_all' | 'cumul_res' = 'cumul_all';

	pipe = new SecondsToMins();
	currencyPipe = new CurrencyPipe(getLocale());

	destroy$: Subject<boolean> = new Subject<boolean>();

	isBKC = false;

	featureMap = this.fm.featureMap;

	sourced: boolean;

	restaurantNames: string;

	viewData$ = combineLatest([this.dttExportService.dttReport$, this.i18n$]).pipe(
		map(([dttReport, i18n]) => {
			this.viewData = { dttReport, i18n };
			if (this.multiSelection()) {
				const dttReportPerRestaurant = groupBy(dttReport, 'restaurant_id') as Record<string, api.SosDttReport[]>;
				this.restaurantNames = Object.keys(dttReportPerRestaurant)
					.map((id) => this.selectedRestaurants.find((rest) => rest.id === id).name)
					.join(', ');
				return { dttReport: dttReportPerRestaurant, i18n };
			}
			return { dttReport, i18n };
		})
	);

	viewData: {
		dttReport: api.SosDttReport[];
		i18n: Record<string, unknown>;
	};

	dayParts = [
		['7', '12'],
		['12', '14'],
		['14', '19'],
		['19', '21'],
		['21', '23'],
		['23', '4'],
		['4', '7'],
	];

	constructor(
		private _translocoService: TranslocoService,
		@Inject(DTT_EXPORT_FACADE) private readonly dttExportService: DttExportServiceProvider,
		private fm: FeatureMapService,
		private ref: ChangeDetectorRef,
		private router: Router
	) {}

	ngOnInit(): void {
		this.dttExportService.switchMultiSeleciton(true);
		this.selectedRestaurants$
			.pipe(
				filter((restaurant) => !!restaurant),
				tap((restaurants) => {
					this.selectedRestaurants = restaurants;
				}),
				filter(() => this.startedFetching),
				takeUntil(this.destroy$)
			)
			.subscribe(() => this.fetchDTT());
		this.sourced = this.router.url.includes('sourced');
		if (this.sourced) {
			this.dttExportService.fetchRestaurants();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
		this.dttExportService.switchMultiSeleciton(false);
	}

	setPeriod(period?: string) {
		const today = DateTime.now();
		let from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		switch (period) {
			case Range.Today:
				from = today;
				to = today;
				break;
			case Range.Week:
				from = today.startOf(Range.Week);
				to = today.endOf(Range.Week);
				break;
			case Range.Month:
				from = today.startOf(Range.Month);
				to = today.endOf(Range.Month);
				break;
			case Range.Period:
				to = !this.dateRange.controls.to.value ? from : to;
				break;
		}
		this.dateRange.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this.dirtyForm = false;
		this.startedFetching = true;
		const aggregation = this.groupType === 'cumul_all' ? this.allAggregated : this.restAggregated;
		this.dttExportService.fetch(from, to, aggregation, Array.from(this.weekDays), SourceType[this.sourceType]);
	}

	selectDay(day, event: MouseEvent) {
		if (this.weekDays.has(day)) {
			this.weekDays.delete(day);
		} else {
			this.weekDays.add(day);
		}
		event.target['classList'].toggle('selected');
		this.dirtyForm = true;
	}

	fetchDTT() {
		const from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		const aggregation = this.groupType === 'cumul_all' ? this.allAggregated : this.restAggregated;
		this.dttExportService.fetch(from, to, aggregation, Array.from(this.weekDays), SourceType[this.sourceType]);
		this.dirtyForm = false;
		this.startedFetching = true;
	}

	originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
		return 0;
	};

	setReportType(event: MatTabChangeEvent) {
		this.isBKC = event.index === 1;
	}

	sourceChanged(event) {
		this.dirtyForm = true;
	}

	singleDaySelection() {
		const from = this.dateRange.controls.from.value as Date;
		const to = this.dateRange.controls.to.value as Date;
		return from.getDate() === to.getDate() && from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
	}

	multiSelection() {
		return this.selectedRestaurants?.length > 0 && this.groupType === 'cumul_res';
	}

	restaurantName(id) {
		return this.selectedRestaurants?.find((rest) => rest.id === id)?.name;
	}

	exportAllCSV(viewdata) {
		const footer = this.selectedRestaurants.length === 1 ? '' : this.selectedRestaurants.map((rest) => rest.name).join('_');
		const type = this.isBKC ? 'BKC' : 'BKF';
		const source_type = viewdata.i18n['source_types'][this.sourceType];
		let csv = '';
		if (!this.isBKC) {
			Object.entries(viewdata.dttReport).forEach(([restid, data]) => {
				const header = this.restaurantName(restid);
				let title = String(`SOS_DRIVE_${header}_${DateTime.fromJSDate(this.dateRange.value.from).toFormat('yyyy-MM-dd')}`);
				if (DateTime.fromJSDate(this.dateRange.value.from).toMillis() !== DateTime.fromJSDate(this.dateRange.value.to).toMillis()) {
					title += '_' + DateTime.fromJSDate(this.dateRange.value.to).toFormat('yyyy-MM-dd');
				}
				csv += exportBKFCSV(
					title,
					{
						dttReport: data,
						i18n: viewdata.i18n,
					},
					type,
					source_type,
					null,
					true,
					this.currencyPipe,
					this.pipe
				);
				csv += '\r\n\n';
			});
			csv += footer;
		} else {
			Object.entries(viewdata.dttReport).forEach(([restid, data]) => {
				const header = this.restaurantName(restid);
				let title = String(`SOS_DRIVE_${header}_${DateTime.fromJSDate(this.dateRange.value.from).toFormat('yyyy-MM-dd')}`);
				if (DateTime.fromJSDate(this.dateRange.value.from).toMillis() !== DateTime.fromJSDate(this.dateRange.value.to).toMillis()) {
					title += '_' + DateTime.fromJSDate(this.dateRange.value.to).toFormat('yyyy-MM-dd');
				}
				csv += exportBKCCSV(
					title,
					{
						dttReport: data,
						i18n: viewdata.i18n,
					},
					type,
					source_type,
					null,
					true,
					this.currencyPipe,
					this.pipe
				);
				csv += '\r\n\n';
			});
			csv += footer;
		}
		downloadCSV(csv, String(`SOS_DRIVE_Multi_Restaurant_${DateTime.fromJSDate(this.dateRange.value.from).toFormat('yyyy-MM-dd')}`));
	}

	async exportAllPDF(viewdata) {
		this.isExporting = true;
		const header = this.selectedRestaurants.length === 1 ? this.selectedRestaurants[0].name : 'Multi Restaurants';
		let title = String(`SOS_DRIVE_${header}_${DateTime.fromJSDate(this.dateRange.value.from).toFormat('yyyy-MM-dd')}`);
		if (DateTime.fromJSDate(this.dateRange.value.from).toMillis() !== DateTime.fromJSDate(this.dateRange.value.to).toMillis()) {
			title += '_' + DateTime.fromJSDate(this.dateRange.value.to).toFormat('yyyy-MM-dd');
		}
		const type = this.isBKC ? 'BKC' : 'BKF';
		const source_type = viewdata.i18n['source_types'][this.sourceType];
		// PDF code below
		const source = document.getElementById('multiple-dtt-report');
		const nodes = Array.prototype.filter.call(source.childNodes, (el) => {
			return el.getAttributeNames && !el.getAttributeNames().includes('hidden');
		});
		const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true });

		const from = DateTime.fromJSDate(this.dateRange.controls.from.value).setLocale(getLocale()).toFormat('yyyy LLL dd'),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value).setLocale(getLocale()).toFormat('yyyy LLL dd');
		const date = from === to ? from : `${from} - ${to}`;
		doc.setFontSize(12);
		doc.setFont(undefined, 'light');
		doc.text(`${date}`, 20, 20);
		doc.text(`${source_type}`, 285, 20, { align: 'right' });

		let page = 0;
		for (const node of nodes) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			await doc.html(node, {
				html2canvas: {
					scale: 0.24,
					letterRendering: false,
					ignoreElements: (e) => e.classList.contains('export-buttons'),
				},
				margin: [30, 5, 5, 5],
				windowWidth: 1000,
				width: 900,
				fontFaces: [
					{
						family: 'Roboto',
						src: [
							{
								url: '/assets/fonts/roboto.ttf',
								format: 'truetype',
							},
						],
					},
				],
				y: page * 175,
				x: 15,
			});
			doc.setFontSize(12);
			doc.setFont(undefined, 'bold');
			doc.text(header, 20, 15);
			doc.text(type, 285, 15, { align: 'right' });
			doc.setFontSize(12);
			doc.setFont(undefined, 'light');
			doc.text(`${date}`, 20, 20);
			doc.text(`${source_type}`, 285, 20, { align: 'right' });
			doc.text(`${this.restaurantNames}`, 20, 200, { align: 'left' });
			page++;
		}

		doc.save(title);
		this.isExporting = false;
		this.ref.markForCheck();
	}
}
