<svg viewBox="0 0 400 200">
	@if (xAxis.length > 0) {
		<polygon [attr.points]="polygon" style="fill: #e5e5e5"></polygon>
	}
	@for (p of xAxis; track p; let index = $index) {
		<line
			[attr.x1]="xAxis[index - 1] ? xAxis[index - 1].x : 0"
			[attr.y1]="xAxis[index - 1] ? isFinite(xAxis[index - 1].y) : 0"
			[attr.x2]="xAxis[index].x"
			[attr.y2]="isFinite(xAxis[index].y)"
			stroke="#cfc0bb"
			stroke-width="1px"
			style="opacity: 0.5"
		/>
	}
	@for (p of xAxis; track p) {
		<circle [attr.cx]="p.x" [attr.cy]="isFinite(p.y)" r="4" fill="#CFC0BB" (mouseenter)="showTooltip($event, p, '#CFC0BB')"
		        (mouseleave)="hideTooltip()"/>
	}
	@for (p of current; track p; let index = $index) {
		<line
			[attr.x1]="current[index - 1] ? current[index - 1].x : 0"
			[attr.y1]="current[index - 1] ? isFinite(current[index - 1].y) : 0"
			[attr.x2]="current[index].x"
			[attr.y2]="isFinite(current[index].y)"
			stroke="#7aa3e5"
			stroke-width="2px"
		/>
	}
	@for (p of current; track p; let last = $last) {
		<circle
			[attr.cx]="p.x"
			[attr.cy]="isFinite(p.y)"
			(mouseenter)="showTooltip($event, p, '#7aa3e5')"
			(mouseleave)="hideTooltip()"
			r="4"
			fill="#7aa3e5"
			[class]="{
			'last-point': last
		}"
		/>
	}
	<g class="x-label">
		@for (p of xAxis; track p; let index = $index) {
			<text
				font-family="Roboto"
				font-size="12"
				fill="black"
				text-anchor="middle"
				[attr.x]="p.x"
				dominant-baseline="central"
				transform="translate(-20,30) rotate(-60)"
				[attr.transform-origin]="p.x + 'px -20px'"
			>
				{{ dataSource['averageSeries'][index]?.label }}
			</text>
		}
	</g>
	<g class="y-label">
		<text font-family="Roboto" font-size="12" fill="black" text-anchor="middle" x="0" dominant-baseline="central"
		      transform="translate(-20,0)">
			€{{ 0 }}
		</text>
		<text font-family="Roboto" font-size="12" fill="black" text-anchor="middle" x="0" dominant-baseline="central"
		      transform="translate(-20,-100)">€ {{ (yMax / 2).toFixed(0) }}
		</text>
		<text font-family="Roboto" font-size="12" fill="black" text-anchor="middle" x="0" dominant-baseline="central"
		      transform="translate(-20,-200)">€ {{ yMax.toFixed(0) }}
		</text>
	</g>
	<g [class]="{ hidden: hidden }">
		<rect rx="3" ry="3" [attr.fill]="tooltip.fill" [attr.x]="tooltip.x" [attr.y]="-tooltip.y" width="120" height="24" fill="#7aa3e5"></rect>
		<text font-family="Roboto" font-size="10px" font-size="300" fill="white" [attr.x]="tooltip.x + 13" [attr.y]="-tooltip.y + 16">
			{{ tooltip.text }}
		</text>
	</g>
</svg>
