import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';

import {
	ChartSuplementTableModule,
	GaugeModule,
	HorizontalBarsModule,
	PageContainerModule,
	PassedKioskSosTileModule,
	PassedSosTileModule,
	PipesModule,
	VerticalTresholdBarModule,
} from '@libs/dash/shared';
import { TodaysOverviewRoutingModule } from '../todays-overview/todays-overview-routing.module';
import { TodaysOverviewModule } from '../todays-overview/todays-overview.module';
import { AnalyzeHistoryRoutingModule } from './analyze-history-routing.module';
import { ANALYZE_HISTORY_FACADE } from './facade/analyze-history-facade.injection.token';
import { AnalyzeHistoryFacade } from './facade/analyze-history-facade.service';
import { ChannelPerformanceTileComponent } from './partial-components/passed-channel-performance-tile/passed-channel-performance-tile.component';
import { PassedRevenueTileComponent } from './partial-components/passed-revenue-tile/passed-revenue-tile.component';
import { TopTenProductsTileComponent } from './partial-components/passed-top-ten-products-tile/passed-top-ten-products-tile.component';
import { PassedWaitingTimeTileComponent } from './partial-components/waiting-time-tile/waiting-time-tile.component';
import { AnalyzeHistoryComponent } from './view-component/analyze-history.component';

const ANGULAR_MATERIAL_ELEMENTS = [MatProgressSpinnerModule, MatButtonModule, MatIconModule];

@NgModule({
	declarations: [
		AnalyzeHistoryComponent,
		PassedRevenueTileComponent,
		PassedWaitingTimeTileComponent,
		ChannelPerformanceTileComponent,
		TopTenProductsTileComponent,
	],
	imports: [
		CommonModule,
		AnalyzeHistoryRoutingModule,
		PageContainerModule,
		RouterModule,
		...ANGULAR_MATERIAL_ELEMENTS,
		TodaysOverviewRoutingModule,
		PipesModule,
		GaugeModule,
		HorizontalBarsModule,
		VerticalTresholdBarModule,
		PassedKioskSosTileModule,
		PassedSosTileModule,
		ChartSuplementTableModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		TodaysOverviewModule,
	],
	providers: [
		{
			provide: ANALYZE_HISTORY_FACADE,
			useClass: AnalyzeHistoryFacade,
		},
	],
	exports: [AnalyzeHistoryComponent],
})
export class AnalyzeHistoryModule {}
