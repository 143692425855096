<table>
	<thead>
		@for (col of columns; track col) {
			<th>
				{{ i18n[col] }}
			</th>
		}
	</thead>
	<tbody>
	<tr class="filter-row">
		@for (col of columns; track col; let last = $last; let index = $index) {
			<td>
				@if (!last) {
					<input type="text" [placeholder]="i18n[col]" (input)="onFilterChange($event.target, index)"/>
				}
			</td>
		}
	</tr>
		@for (row of dataSource; track row?.bknumber) {
			<tr [class.hidden]="!isFiltered(row)" (click)="action(row)">
				@for (col of columns; track col; let last = $last) {
					<td [ngClass]="{'actions': last}">
						@if (col == 'date') {
							{{ row[col] | date: 'd MMMM y' }}
						} @else {
							{{ row[col] }}
						}
					</td>
				}
			</tr>
		}
	</tbody>
</table>
