import { SingleAPIConfig } from '@libs/shared/models';
import { BrandName } from '@merim/utils';
import { ENVIRONMENT_ENUM } from '@shared/models';

export type ConfigType = {
	environment: ENVIRONMENT_ENUM;
	brand: BrandName;
	API: Record<string, SingleAPIConfig>;
	isLocal: boolean;
	currency: CurrencyConfig;
	showProductDynamicDistribution: boolean;
	__description_showProductDynamicDistribution?: string;
	validateDefaultOrbp: boolean;
	enableProductionManagementSystem: boolean;
	enableDssOrderSortConfiguration: boolean;
};

export type CurrencyConfig = {
	symbol: string;
	code: string;
};

export enum MODULES {
	DSS = 'dss',
	PRICE_MANAGEMENT = 'priceManagement',
	PMS = 'ownerManagement',
	PRICE_PRODUCTS_MANAGEMENT = 'priceProductsManagement',
	PRICE_MENUS_MANAGEMENT = 'priceMenusManagement',
	PRICE_INGREDIENTS_MANAGEMENT = 'priceIngredientsManagement',
}
