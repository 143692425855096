import { IBKMachine } from '@bk/jscommondatas';
import { IBKTechShar3dInfos } from '@libs/shared/interfaces';
import { createAction, props } from '@ngrx/store';

export type DeviceInformationLoadType = { defaultDeviceInfo: IBKMachine };
export const DeviceInformationLoad = createAction('[App] Get information about device', props<DeviceInformationLoadType>());

export const DeviceInformationLoadSuccess = createAction(
	'[App] Get information about device success',
	props<{
		information: IBKTechShar3dInfos | {};
	}>()
);
