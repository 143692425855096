import { PosRecord } from '@dki/api-client';
import { getOrder } from '@libs/dash/core/entity';
import { DateTime } from 'luxon';

import { initialEntriesSource, TypeOfPaymentCategIndexMap } from '../../../../../apps/dash/src/assets/brand/HistoryMappings';

interface mappedApiResponse {
	pos: string;
	sessionNumber: string;
	sessionClosing: DateTime | number | string;
	sessionValidation: DateTime | number | string;
	personnelsSource: Array<any>;
	entriesSource: Array<any>;
	recapLine: Array<any>;
}

const mapApiResponse = (data: PosRecord[] | any): mappedApiResponse[] | any => {
	const mappedArray: mappedApiResponse[] | any = [];

	data.forEach((e: PosRecord) => {
		const session: mappedApiResponse | any = {
			pos: '',
			sessionNumber: '',
			sessionClosing: 0,
			sessionValidation: 0,
			personnelsSource: [],
			entriesSource: [],
			recapLine: [],
		};
		// session.pos = e.sessionDetail.pos ? e.sessionDetail.pos : '-';
		session.sessionClosing = DateTime.fromMillis(e.sessionDetail.closingTime).toFormat('yyyy-MM-dd HH:mm');
		session.sessionValidation = DateTime.fromMillis(e.sessionDetail.validationTime).toFormat('yyyy-MM-dd HH:mm');
		session.sessionNumber = e.sessionDetail.sessionId;
		session.personnelsSource[0] = {
			label: 'Manager',
			id: e.manager.id,
			name: e.manager.login,
		};
		session.personnelsSource[1] = {
			label: 'Vendeur',
			id: e.employee.id,
			name: e.employee.login,
		};
		session.recapLine[0] = {
			cancellation: e.sessionDetail.cancellation,
			refund: e.sessionDetail.refund,
			discount: e.sessionDetail.discount,
			withdrawn: e.sessionDetail.withdrawn,
		};
		session.entriesSource = [...initialEntriesSource];
		const groupedCategories = groupBy(e.entries, 'category');
		const unknownCategories = [];
		Object.keys(groupedCategories).forEach((category) => {
			const index = TypeOfPaymentCategIndexMap[category];
			if (index != undefined) {
				session.entriesSource[index] = { ...session.entriesSource[index], ...groupedCategories[category] };
			} else {
				unknownCategories.push({ ...session.entriesSource[index], ...groupedCategories[category] });
			}
		});
		session.entriesSource.push(...unknownCategories);
		session.entriesSource.sort((b, a) => {
			return getOrder(b.category) - getOrder(a.category);
		});
		mappedArray.push(session);
	});
	return mappedArray;
};

const groupBy = function (data, key) {
	// `data` is an array of objects, `key` is the key (or property accessor) to group by
	// reduce runs this anonymous function on each element of `data` (the `item` parameter,
	// returning the `storage` parameter at the end
	return data.reduce(function (storage, item) {
		// get the first instance of the key by which we're grouping
		const group = item[key];

		// set `storage` for this instance of group to the outer scope (if not empty) or initialize it
		storage[group] = storage[group] || { category: group, amount: { real: 0, theoretical: 0 }, comment: '' };

		// add this item to its group within `storage`
		storage[group].amount.real += item.amount.real;
		storage[group].amount.theoretical += item.amount.theoretical;
		storage[group].comment += item.comment;

		// return the updated storage to the reduce function, which will then loop through the next
		return storage;
	}, {}); // {} is the initial value of the storage
};

export { mapApiResponse };
