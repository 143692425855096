import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { PageContainerModule } from '@libs/dash/shared';
import { LineTresholdsComponent } from './dumb-components/line-tresholds/line-tresholds.component';
import { OpeningTimesComponent } from './dumb-components/opening-times/opening-times.component';
import { ServiceTimeTresholdsComponent } from './dumb-components/service-time-tresholds/service-time-tresholds.component';
import { TimeRangeSelectComponent } from './dumb-components/time-range-select/time-range-select.component';
import { SETTINGS_FACADE } from './facade/settings-facade.injection.token';
import { SettingsFacade } from './facade/settings-facade.service';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './view-component/settings.component';

@NgModule({
	declarations: [SettingsComponent, ServiceTimeTresholdsComponent, OpeningTimesComponent, LineTresholdsComponent, TimeRangeSelectComponent],
	imports: [
		CommonModule,
		SettingsRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		FlexLayoutModule,
		PageContainerModule,
		MatSelectModule,
		MatInputModule,
		MatIconModule,
		MatButtonModule,
		MatGridListModule,
	],
	providers: [
		{
			provide: SETTINGS_FACADE,
			useClass: SettingsFacade,
		},
	],
})
export class SettingsModule {}
