@if (localisedTexts$ | async; as texts) {
	<h4>
		{{ texts.title }}
	</h4>
	<p>
		{{ texts.paragraphs.main }}
	</p>
	<p class="dk-blue-highlight-text">
		{{ texts.paragraphs.highlight }}
	</p>
	<dk-action-buttons [buttons]="actionButtons$ | async"></dk-action-buttons>
}
