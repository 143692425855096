import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as api from '@dki/api-client';
import { getLocale } from '@libs/dash/core';

import { Range } from '@libs/dash/core/entity';
import { TranslocoService } from '@ngneat/transloco';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import { DateTime } from 'luxon';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { HISTORY_LOGS_FACADE } from '../../facade/history-logs-facade.injection.token';
import { HistoryLogsServiceProvider } from '../../facade/history-logs-facade.provider.interface';

@Component({
	selector: 'dk-vouchers-report',
	templateUrl: './vouchers-report.component.html',
	styleUrls: ['./vouchers-report.component.scss'],
})
export class VouchersReportComponent implements OnInit {
	@ViewChild('voucherReport', { static: false }) reportElementRef: ElementRef;

	i18n$ = this._translocoService.selectTranslateObject('vouchers-report');
	weeklyVoucherReport$ = this._historylogService.voucherReport$;
	isFetching$ = this._historylogService.isFetchingVoucherReport$;
	isLoading$ = this._historylogService.isLoading$;
	selectedRestaurant: api.Restaurant;

	noData = true;

	viewData$ = combineLatest([this.i18n$, this.weeklyVoucherReport$]).pipe(
		map(([i18n, data]) => {
			if (data && Object.keys(data?.by_voucher_type).length > 0) {
				this.noData = false;
			} else {
				this.noData = true;
			}
			return { i18n, data };
		})
	);

	dateRange = new FormGroup({
		from: new FormControl(this._historylogService.range.from.toJSDate()),
		to: new FormControl(this._historylogService.range.to.toJSDate()),
	});

	constructor(
		private _translocoService: TranslocoService,
		@Inject(HISTORY_LOGS_FACADE) private readonly _historylogService: HistoryLogsServiceProvider,
		private currencyPipe: CurrencyPipe
	) {}

	ngOnInit(): void {
		this._historylogService.myDefaultRestaurant$.pipe(filter((restaurant) => !!restaurant)).subscribe((restaurant) => {
			this.selectedRestaurant = restaurant;
			this.setPeriod();
		});
	}

	download(ext: 'pdf' | 'csv', viewData: { i18n: any; data: api.WeeklyVouchersReport }) {
		const fileName = `${viewData.i18n.title}_${this.selectedRestaurant.name}_${DateTime.fromJSDate(this.dateRange.value.from).toISODate()}_${DateTime.fromJSDate(
			this.dateRange.value.to
		).toISODate()}`;
		if (ext === 'pdf') {
			const source = this.reportElementRef.nativeElement;
			const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true, format: 'letter' });
			doc.html(source, {
				html2canvas: {
					scale: 0.2,
					letterRendering: true,
					ignoreElements: (e) => e.nodeName.includes('MAT'),
					logging: true,
				},
				margin: [40, 5, 5, 5],
				windowWidth: 1300,
				width: 1300,
				fontFaces: [
					{
						family: 'Roboto',
						src: [
							{
								url: '/assets/fonts/roboto.ttf',
								format: 'truetype',
							},
						],
					},
				],
				callback: (doc) => {
					doc.setFontSize(16);
					doc.text(`${this.selectedRestaurant.name}`, 20, 20);
					doc.setFont(undefined, 'bold');
					const from = DateTime.fromJSDate(this.dateRange.controls.from.value).setLocale(getLocale()).toFormat('yyyy LLL dd'),
						to = DateTime.fromJSDate(this.dateRange.controls.to.value).setLocale(getLocale()).toFormat('yyyy LLL dd');
					const date = from === to ? from : `${from} - ${to}`;
					doc.setFontSize(12);
					doc.text(`${viewData.i18n.title}`, 20, 25);
					doc.text(`${date}`, 200, 20);
					doc.save(fileName);
				},
			});
		} else {
			const csv = [];
			const pipeOption = ['EUR', 'symbol', '1.2-2'];
			const currency = (v) => this.currencyPipe.transform(v, ...pipeOption);
			Object.entries(viewData.data.by_voucher_type).forEach(([voucher, voucherData]) => {
				csv.push({
					[viewData.i18n.voucher]: voucher,
					[viewData.i18n.weekdays.monday]: currency(voucherData.by_weekday.monday),
					[viewData.i18n.weekdays.tuesday]: currency(voucherData.by_weekday.tuesday),
					[viewData.i18n.weekdays.wednesday]: currency(voucherData.by_weekday.wednesday),
					[viewData.i18n.weekdays.thursday]: currency(voucherData.by_weekday.thursday),
					[viewData.i18n.weekdays.friday]: currency(voucherData.by_weekday.friday),
					[viewData.i18n.weekdays.saturday]: currency(voucherData.by_weekday.saturday),
					[viewData.i18n.weekdays.sunday]: currency(voucherData.by_weekday.sunday),
					[viewData.i18n.total]: currency(voucherData.total),
				});
			});
			csv.push({
				[viewData.i18n.voucher]: viewData.i18n.total,
				[viewData.i18n.weekdays.monday]: currency(viewData.data.total.by_weekday.monday),
				[viewData.i18n.weekdays.tuesday]: currency(viewData.data.total.by_weekday.tuesday),
				[viewData.i18n.weekdays.wednesday]: currency(viewData.data.total.by_weekday.wednesday),
				[viewData.i18n.weekdays.thursday]: currency(viewData.data.total.by_weekday.thursday),
				[viewData.i18n.weekdays.friday]: currency(viewData.data.total.by_weekday.friday),
				[viewData.i18n.weekdays.saturday]: currency(viewData.data.total.by_weekday.saturday),
				[viewData.i18n.weekdays.sunday]: currency(viewData.data.total.by_weekday.sunday),
				[viewData.i18n.total]: currency(viewData.data.total.total),
			});
			const options = {
				filename: fileName,
				decimalSeparator: '.',
				fieldSeparator: ';',
				showLabels: true,
				showTitle: true,
				title: fileName,
				useKeysAsHeaders: true,
			};
			const csvExporter = new ExportToCsv(options);
			if (csv.length > 0) {
				csvExporter.generateCsv(csv);
			}
		}
	}

	singleDaySelection() {
		const from = this.dateRange.controls.from.value as Date;
		const to = this.dateRange.controls.to.value as Date;
		return from.getDate() === to.getDate() && from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
	}

	setPeriod(period?: string) {
		const today = DateTime.now();
		let from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		switch (period) {
			case Range.Today:
				from = today;
				to = today;
				break;
			case Range.Week:
				from = today.startOf(Range.Week);
				to = today.endOf(Range.Week);
				break;
			case Range.Month:
				from = today.startOf(Range.Month);
				to = today.endOf(Range.Month);
				break;
			case Range.Period:
				to = !this.dateRange.controls.to.value ? from : to;
				break;
		}
		this.dateRange.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this._historylogService.getVouchersReport(from, to);
	}
}
