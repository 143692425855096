import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { OpeningTime, OpeningTimes } from '@libs/dash/core/entity';

@Component({
	selector: 'dk-opening-times',
	templateUrl: './opening-times.component.html',
	styleUrls: ['./opening-times.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpeningTimesComponent {
	@Input() openingTimes: OpeningTimes;

	// TO DO: PROVIDE INTERFACE
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() texts: { [key: string]: any };

	@Output() openingTimesChange = new EventEmitter<{ day: keyof OpeningTimes; timeRange: OpeningTime }>();

	// TO DO: PROVIDE INTERFACE
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getOpeningTime(day: keyof OpeningTimes): { [key: string]: any } {
		let openingTime = {
			from: {
				hours: '00',
				minutes: '00',
			},
			to: {
				hours: '24',
				minutes: '00',
			},
		};

		if (this.openingTimes && this.openingTimes[day]) {
			const selectedDayOpeningTimes = this.openingTimes[day];
			const from = selectedDayOpeningTimes.from.split(':');
			const to = selectedDayOpeningTimes.to.split(':');

			openingTime = {
				from: {
					hours: from[0],
					minutes: from[1],
				},
				to: {
					hours: to[0],
					minutes: to[1],
				},
			};
		}

		return openingTime;
	}

	// DUE TO API SPECIFICS, MAX CLOSING HOUR
	// IS OPENING HOUR OF NEXT DAY
	getNextDayOpeingHour(day: keyof OpeningTimes): number {
		let nextDay: keyof OpeningTimes;
		let nextDayOpeingHour = 0;

		switch (day) {
			case 'saturday':
				nextDay = 'sunday';
				break;

			case 'sunday':
				nextDay = 'monday';
				break;

			default:
				nextDay = 'saturday';
				break;
		}

		if (this.openingTimes && this.openingTimes[day]) {
			const openingTimeReferrenceValue = this.openingTimes[nextDay].from.split(':');
			nextDayOpeingHour = +openingTimeReferrenceValue[0];
		}

		return nextDayOpeingHour;
	}

	emitNewTimeRange(timeRange: OpeningTime, day: keyof OpeningTimes): void {
		this.openingTimesChange.emit({ day, timeRange });
	}
}
