import { ChangeDetectionStrategy, Component, ElementRef, HostListener, inject, Inject, OnDestroy, OnInit } from '@angular/core';

import { OpeningTime, OpeningTimes, RestaurantSettings } from '@libs/dash/core/entity';

import { UiVersionDetectorService } from '@libs/shared/services';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { SETTINGS_FACADE } from '../facade/settings-facade.injection.token';
import { SettingsServiceProvider } from '../facade/settings-facade.provider.interface';

@Component({
	selector: 'dk-settings',
	templateUrl: './settings.component.html',
	styleUrls: ['./settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnInit, OnDestroy {
	private _originalOpeningTimeSnapshot: OpeningTimes;
	private _originalLineThresholdsSnapshot: Array<number>;
	private _unsubscribeAll = new Subject();

	public defaultNumberOfColumns = 3;
	public displayedNumberOfColumns = this.defaultNumberOfColumns;
	public isLoading$ = this._settingsFacade.isLoading$;
	public lineThresholds: Array<number>;
	public localisedTexts$ = this._translocoService.selectTranslateObject('settings');
	public openingTimes: OpeningTimes;
	public pageTitleText = 'Settings';

	private readonly _newUiDetector: UiVersionDetectorService = inject(UiVersionDetectorService);
	isNewRoute = this._newUiDetector.isNewUi();

	constructor(
		private _translocoService: TranslocoService,
		private _elementRef: ElementRef,
		@Inject(SETTINGS_FACADE)
		private _settingsFacade: SettingsServiceProvider
	) {
		combineLatest([this._settingsFacade.isLoading$, this._settingsFacade.myDefaultRestaurant$])
			.pipe(
				// FYI: myDefaultRestaurant must be referrenced in order to keep map
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				filter(([isLoading, myDefaultRestaurant]) => !isLoading),
				// FYI: isLoading must be referrenced in order to keep map
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				map(([isLoading, myDefaultRestaurant]) => {
					let openingTimes: OpeningTimes = null;
					let lineThresholds: Array<number> = null;

					if (myDefaultRestaurant?.opening_times) {
						openingTimes = { ...myDefaultRestaurant.opening_times };

						this._originalOpeningTimeSnapshot = openingTimes;
						this.openingTimes = openingTimes;

						if (myDefaultRestaurant.line_thresholds) {
							lineThresholds = [...myDefaultRestaurant.line_thresholds];

							this._originalLineThresholdsSnapshot = lineThresholds;
							this.lineThresholds = lineThresholds;
						} else {
							this._originalLineThresholdsSnapshot = [0, 0];
							this.lineThresholds = [0, 0];
						}
					} else {
						this._settingsFacade.fetchDefaultRestaurantData();
					}

					return openingTimes;
				}),
				takeUntil(this._unsubscribeAll)
			)
			.subscribe();
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this._calculateNumberOfColumns();
	}

	ngOnInit(): void {
		this._calculateNumberOfColumns();
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	public get settingsUpdated(): boolean {
		return this._openingTimesUpdated || this._lineTresholdsUpdated;
	}

	public updateLineTresholds(updatedLineTresholds: Array<number>): void {
		this.lineThresholds = updatedLineTresholds;
	}

	public updateOpeningTimes(updatedOpeningTimeSegment: { day: keyof OpeningTimes; timeRange: OpeningTime }): void {
		if (this.openingTimes) {
			if (updatedOpeningTimeSegment.day !== 'monday') {
				this.openingTimes = {
					...this.openingTimes,
					[updatedOpeningTimeSegment.day]: {
						...updatedOpeningTimeSegment.timeRange,
					},
				};
			} else {
				this.openingTimes = {
					...this.openingTimes,
					monday: {
						...updatedOpeningTimeSegment.timeRange,
					},
					tuesday: {
						...updatedOpeningTimeSegment.timeRange,
					},
					wednesday: {
						...updatedOpeningTimeSegment.timeRange,
					},
					thursday: {
						...updatedOpeningTimeSegment.timeRange,
					},
					friday: {
						...updatedOpeningTimeSegment.timeRange,
					},
				};
			}
		}
	}

	public sendUpdatedDataToBackend(): void {
		const restaurantSettings: RestaurantSettings = {
			opening_times: this.openingTimes,
			line_thresholds: this.lineThresholds,
		};

		this._settingsFacade.sendUpdatedDataToBackend(restaurantSettings);
	}

	public normalizeTileColspan(gridTileColspan: number): number {
		let normalizedColspan = 1;

		if (gridTileColspan === 1 && this.displayedNumberOfColumns < this.defaultNumberOfColumns) {
			normalizedColspan = this.displayedNumberOfColumns;
		} else {
			normalizedColspan = gridTileColspan < this.displayedNumberOfColumns ? gridTileColspan : this.displayedNumberOfColumns;
		}

		return normalizedColspan;
	}

	public discardChanges(): void {
		this.openingTimes = { ...this._originalOpeningTimeSnapshot };
		this.lineThresholds = this._originalLineThresholdsSnapshot ? [...this._originalLineThresholdsSnapshot] : [0, 0];
	}

	private _calculateNumberOfColumns(): void {
		const maxColumns = 3;
		const viewCanFitnumberOfColumns = Math.ceil((this._elementRef.nativeElement.clientWidth - 72) / 460);

		const numberOfColumns = viewCanFitnumberOfColumns <= maxColumns ? viewCanFitnumberOfColumns : maxColumns;

		this.displayedNumberOfColumns = numberOfColumns;
	}

	private get _openingTimesUpdated(): boolean {
		let openingTimesUpdated = false;

		if (this.openingTimes && this._originalOpeningTimeSnapshot) {
			Object.keys(this._originalOpeningTimeSnapshot).forEach((openingTimeKey) => {
				if (
					this.openingTimes[openingTimeKey].from !== this._originalOpeningTimeSnapshot[openingTimeKey].from ||
					this.openingTimes[openingTimeKey].to !== this._originalOpeningTimeSnapshot[openingTimeKey].to
				) {
					openingTimesUpdated = true;
				}
			});
		}

		return openingTimesUpdated;
	}

	private get _lineTresholdsUpdated(): boolean {
		let lineTresholdsUpdated = false;

		if (this.lineThresholds && this.lineThresholds.length > 0) {
			lineTresholdsUpdated = !!this.lineThresholds.find((updatedTreshold, index) => {
				return this._originalLineThresholdsSnapshot && this._originalLineThresholdsSnapshot[index]
					? updatedTreshold !== this._originalLineThresholdsSnapshot[index]
					: true;
			});
		}

		return lineTresholdsUpdated;
	}
}
