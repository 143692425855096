import { BKOrderSourceEnum } from '@bk/jscommondatas';

import { BKOrbRapOrder } from '../models/bk-orb-rap-order';
import { getAlienId } from './get-alien-id';
import { getDeliveryOptionsFromEvents } from './get-delivery-options-from-events';
import { getOrderNumberForORBp } from './get-order-number-orbp';

const allowedSources = [
	BKOrderSourceEnum.ORDER_SOURCE_KIOSK,
	BKOrderSourceEnum.ORDER_SOURCE_CASH_MACHINE,
	BKOrderSourceEnum.ORDER_SOURCE_DRIVE,
	BKOrderSourceEnum.ORDER_SOURCE_WALK
];

export function getOrderIdToDisplay(order: BKOrbRapOrder): string {
	const alienId = getAlienId(order);
	if (alienId) {
		return alienId;
	} else {
		let orderId: string;

		if (allowedSources.includes(order.source)) {
			orderId = getOrderNumberForORBp(order);
		}

		if (!order.deliveryOptions) {
			// hack for logging messages
			const diffOrderType = order as any; // "any" means BKOrder from bk-js-lib, but we do not want to depend on bk-js-lib here
			const deliveryOptions = getDeliveryOptionsFromEvents(diffOrderType.event || []);
			const newOrder = {
				...order,
				deliveryOptions
			};
			return getAlienId(newOrder);
		}

		return orderId;
	}
}
