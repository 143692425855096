@if (synthesisReport$ | async; as data) {
	@if (data.by_label && data.by_label.length > 0) {
		<div id="general-vat-report">
			<table mat-table [dataSource]="data.by_label" class="mat-elevation-z8 w-100 mt-5">
				<ng-container matColumnDef="reglements">
					<th mat-header-cell *matHeaderCellDef class="uppercase">{{ i18n.reglements }}</th>
					<td mat-cell
					    *matCellDef="let element">{{ i18n.paymentMethods[element.label] ? i18n.paymentMethods[element.label] : element.category }}
					</td>
					<td mat-footer-cell *matFooterCellDef>{{ i18n.total_ca_employee }}</td>
				</ng-container>
				<ng-container matColumnDef="amountTh">
					<th mat-header-cell *matHeaderCellDef>{{ i18n.amountTh }}</th>
					<td mat-cell *matCellDef="let element">{{ element.amount.theoretical | currency: 'EUR' }}</td>
					<td mat-footer-cell *matFooterCellDef>{{ data.total_with_employee_meals.theoretical | currency: 'EUR' }}</td>
				</ng-container>
				<ng-container matColumnDef="declaredAmount">
					<th mat-header-cell *matHeaderCellDef>{{ i18n.declaredAmount }}</th>
					<td mat-cell *matCellDef="let element">{{ element.amount.real | currency: 'EUR' }}</td>
					<td mat-footer-cell *matFooterCellDef>{{ data.total_with_employee_meals.real | currency: 'EUR' }}</td>
				</ng-container>
				<ng-container matColumnDef="gap">
					<th mat-header-cell *matHeaderCellDef>{{ i18n.gap }}</th>
					<td mat-cell *matCellDef="let element">{{ element.amount.gap | currency: 'EUR' }}</td>
					<td mat-footer-cell *matFooterCellDef>{{ data.total_with_employee_meals.gap | currency: 'EUR' }}</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumnsEntries"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumnsEntries" [ngClass]="{ highlight: highlightedRow(row) }"></tr>
				<tr mat-footer-row *matFooterRowDef="displayedColumnsEntries" class="bg-light-blue font-weight-bold"></tr>
			</table>
			<div class="dk-flex">
				<button mat-stroked-button [matMenuTriggerFor]="synthReport" class="export-label">{{ i18n.export }}</button>
				<mat-menu #synthReport="matMenu" yPosition="above" xPosition="before">
					<button mat-menu-item (click)="exportCsv()">{{ i18n.exportCsv }}</button>
					<button mat-menu-item (click)="exportPDF()">{{ i18n.exportPDF }}</button>
				</mat-menu>
			</div>
			<div class="recap">
        <span>
          {{ i18n.incVAT }}
        </span>
				<span class="font-weight-bold mx-3">
          {{ i18n.annulations }}<span class="recap-badge ml-1">{{ data.recap[0].cancelled | currency: 'EUR' }}</span>
        </span>
				<span class="font-weight-bold mx-3">
          {{ i18n.refunds }}<span class="recap-badge ml-1 red">{{ data.recap[0].refund | currency: 'EUR' }}</span>
        </span>
				<span class="font-weight-bold mx-3">
          {{ i18n.reductions }}<span class="recap-badge ml-1">{{ data.recap[0].discount | currency: 'EUR' }}</span>
        </span>
				<span class="font-weight-bold mx-3">
          {{ i18n.debit }}<span class="recap-badge ml-1 blue">{{ data.recap[0].withdrawn | currency: 'EUR' }}</span>
        </span>
			</div>
		</div>
	} @else {
		@if ((vatSynthesisReportEmpty$ | async) === true && synthesisReportMapped && synthesisReportMapped.by_label && synthesisReportMapped.by_label.length === 0) {
			<p>
				{{ i18n.noData }}
			</p>
		}
	}
}
