import { ElementRef, OnInit, Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Pipe({
	name: 'translate',
})
export class TranslatePipe implements OnInit, PipeTransform {
	readonly ngUnsubscribe$: Subject<void> = new Subject<void>();
	private value: string = '';
	private partial: Record<string, string | number> = {};

	constructor(
		private translateService: TranslocoService,
		private el: ElementRef
	) {
		this.translateService.events$
			.pipe(
				filter((e) => e.type === 'translationLoadSuccess' || e.type === 'langChanged'),
				debounceTime(200),
				takeUntil(this.ngUnsubscribe$)
			)
			.subscribe((item) => {
				this.el.nativeElement.textContent = this.translateService.translate(this.value, this.partial);
			});
	}

	ngOnInit() {}

	transform(value: string, partial: Record<string, string | number> = {}): string {
		(this.value = value), (this.partial = partial);

		return this.translateService.translate(value, partial);
	}

	ngOnDestroy() {
		this.ngUnsubscribe$.next();
		this.ngUnsubscribe$.complete();
	}
}
