import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'dk-line-tresholds',
	templateUrl: './line-tresholds.component.html',
	styleUrls: ['./line-tresholds.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineTresholdsComponent implements OnChanges, OnDestroy {
	private _unsubscribeAll = new Subject();
	lineTresholdsForm = new FormGroup({
		lineTreshold1: new FormControl(0),
		lineTreshold2: new FormControl(0),
	});

	@Input() lineThresholds: Array<number>;

	// TO DO: PROVIDE INTERFACE
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() texts: { [key: string]: any };

	@Output() lineTresholdsChange = new EventEmitter<Array<number>>();

	constructor() {
		this.lineTresholdsForm.valueChanges
			.pipe(
				map((lineTresholdsValue) => {
					const lineTresholds: number[] = Object.values(lineTresholdsValue);

					this.lineTresholdsChange.emit(lineTresholds);
				}),
				takeUntil(this._unsubscribeAll)
			)
			.subscribe();
	}

	ngOnChanges(): void {
		if (this.lineThresholds && this.lineThresholds.length > 0) {
			this.lineThresholds.forEach((lineThreshold, index) => {
				this.lineTresholdsForm.controls[`lineTreshold${index + 1}`].setValue(lineThreshold);
			});
		}
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}
}
