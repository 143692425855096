import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval } from 'rxjs';
import { map, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
	selector: 'dk-refresh',
	templateUrl: './refresh.component.html',
	styleUrls: ['./refresh.component.scss'],
})
export class RefreshComponent {
	currentTime = 10;
	countdownTimer = 10;

	constructor(private router: Router) {
		const timer$ = interval(1000); // 1000 = 1 second
		timer$
			.pipe(
				untilDestroyed(this),
				take(this.countdownTimer),
				map((v) => this.countdownTimer - 1 - v)
			) // to reach zero
			.subscribe((v) => {
				this.currentTime = v;
				if (v === 0) {
					this.redirectToLogin();
				}
			});
	}

	redirectToLogin(): void {
		console.log('refresh!!');
		this.router.navigate(['/']);
	}
}
