import { Component, Inject, OnDestroy } from '@angular/core';
import * as api from '@dki/api-client';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExportToCsv } from 'export-to-csv';
import { combineLatest, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';

import { TODAYS_OVERVIEW_FACADE } from '../../facade/todays-overview-facade.injection.token';
import { TodaysOverviewServiceProvider } from '../../facade/todays-overview-facade.provider.interface';

@UntilDestroy()
@Component({
	selector: 'dk-top-ten-products-tile',
	templateUrl: './top-ten-products-tile.component.html',
	styleUrls: ['./top-ten-products-tile.component.scss'],
})
export class TopTenProductsTileComponent implements OnDestroy {
	private i18n: any;
	destroy$ = new Subject<boolean>();

	public viewData$ = combineLatest([
		this.todaysOverviewFacade.topTenProductsTableData$,
		this.translocoService.selectTranslateObject('topTenProductsTile'),
	]).pipe(
		map(([topTenProductsTableData, i18n]) => {
			this.i18n = i18n;
			return { topTenProductsTableData, i18n };
		}),
		takeUntil(this.destroy$)
	);

	constructor(
		@Inject(TODAYS_OVERVIEW_FACADE) private readonly todaysOverviewFacade: TodaysOverviewServiceProvider,
		private readonly translocoService: TranslocoService,
		private readonly dataApiClient: api.DataApiClient,
		private readonly reportsApiClient: api.ReportsApiClient
	) {}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	download() {
		this.todaysOverviewFacade.myDefaultRestaurant$
			.pipe(
				untilDestroyed(this),
				take(1),
				switchMap((x) => this.dataApiClient.ordersByProductV1DataOrdersByProductGet([x.id]))
			)
			.subscribe((x) => {
				const data = x.map((d) => ({
					[this.i18n.tableHeaderCells.product]: d.product,
					[this.i18n.tableHeaderCells.count]: d.count,
					// averagecount: d.mean_count,
					[this.i18n.tableHeaderCells.value]: d.value,
					[this.i18n.tableHeaderCells.product_plu]: d.product_plu,
				}));

				if (!data?.length) {
					window.alert(this.i18n.noData);
					return;
				}

				const options = {
					filename: `${this.i18n.title.replace(' ', '_').toLowerCase()}-${new Date().toISOString()}`,
					showLabels: true,
					useKeysAsHeaders: true,
					headers: [
						this.i18n.tableHeaderCells.product,
						this.i18n.tableHeaderCells.count,
						// this.i18n.tableHeaderCells.averagecount,
						this.i18n.tableHeaderCells.value,
						this.i18n.tableHeaderCells.product_plu,
					],
				};

				const csvExporter = new ExportToCsv(options);
				csvExporter.generateCsv(data);
			});
	}

	downloadIngredients() {
		this.todaysOverviewFacade.myDefaultRestaurant$
			.pipe(
				untilDestroyed(this),
				take(1),
				switchMap((x) => this.reportsApiClient.getShoppingListReportV1ReportsShoppingListGet([x.id]))
			)
			.subscribe((x) => {
				const data = x.map((d) => ({
					[this.i18n.tableHeaderCells.item]: d.item,
					[this.i18n.tableHeaderCells.item_plu]: d.item_plu,
					[this.i18n.tableHeaderCells.count]: d.count,
				}));

				if (!data?.length) {
					window.alert(this.i18n.noData);
					return;
				}

				const options = {
					filename: `${this.i18n.filename_shoppinglist}-${new Date().toISOString()}`,
					showLabels: true,
					headers: [this.i18n.tableHeaderCells.item, this.i18n.tableHeaderCells.item_plu, this.i18n.tableHeaderCells.count],
				};

				const csvExporter = new ExportToCsv(options);
				csvExporter.generateCsv(data);
			});
	}
}
