import { CurrencyPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OperatingReport } from '@dki/api-client';
import { getLocale } from '@libs/dash/core';

import { Range, sortChannels } from '@libs/dash/core/entity';
import { generateXlsx, ReportOptions } from '@libs/dash/shared';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import { DateTime } from 'luxon';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { REPORTS_FACADE } from '../../facade/reports-facade.injection.token';
import { ReportsServiceProvider } from '../../facade/reports-facade.provider.interface';

@Component({
	selector: 'dk-operating-report-tile',
	templateUrl: './operating-report-tile.component.html',
	styleUrls: ['./operating-report-tile.component.scss'],
})
@UntilDestroy()
export class OperatingReportTileComponent implements OnInit {
	isLoading = this.reportsServiceProvider.isLoadingOperatingReport$;

	hidden_channels = ['EAT_IN', 'TAKE_OUT', 'KIOSK_EAT_IN', 'KIOSK_TAKE_OUT'];

	i18n: Record<string, any>;
	operatingReport: OperatingReport;
	channels: Array<string>;

	filteredChannels = [];

	dateRange = new FormGroup({
		from: new FormControl(this.reportsServiceProvider.ranges.operatingReport.from.toJSDate()),
		to: new FormControl(this.reportsServiceProvider.ranges.operatingReport.to.toJSDate()),
	});

	currencyPipe = new CurrencyPipe(getLocale());

	public viewData$ = combineLatest([
		this.reportsServiceProvider.operatingReport$,
		this.translocoService.selectTranslateObject('operatingReportTile'),
	]).pipe(
		filter(([operatingReport, i18n]) => !!operatingReport),
		map(([operatingReport, i18n]) => {
			this.operatingReport = operatingReport;
			const channels = Object.keys(operatingReport.total.by_channel);
			this.channels = channels;
			this.i18n = i18n;
			// TODO: get rid of this, if changes are made to the backend
			// const oneDaySelected = DateTime.fromJSDate(this.dateRange.controls.from.value).startOf('day').toSeconds() === DateTime.fromJSDate(this.dateRange.controls.to.value).startOf('day').toSeconds();
			return { operatingReport, i18n };
		}, untilDestroyed(this))
	);

	selectedRestaurants: Array<string>;

	constructor(
		@Inject(REPORTS_FACADE) protected readonly reportsServiceProvider: ReportsServiceProvider,
		private readonly translocoService: TranslocoService
	) {}

	ngOnInit(): void {
		this.reportsServiceProvider.selectedRestaurants$
			.pipe(
				untilDestroyed(this),
				filter((restaurants) => restaurants.length > 0 && !!restaurants[0])
			)
			.subscribe((restaurants) => {
				this.selectedRestaurants = restaurants.map((restaurant) => restaurant.name);
			});
	}

	setPeriod(period?: string) {
		const today = DateTime.now();
		let from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		switch (period) {
			case Range.Today:
				from = today;
				to = today;
				break;
			case Range.Week:
				from = today.startOf(Range.Week);
				to = today.endOf(Range.Week);
				break;
			case Range.Month:
				from = today.startOf(Range.Month);
				to = today.endOf(Range.Month);
				break;
			case Range.Period:
				to = !this.dateRange.controls.to.value ? from : to;
				break;
			case Range.Next:
				from = from.plus({ days: 7 });
				to = to.plus({ days: 7 });
				break;
			case Range.Prev:
				from = from.plus({ days: -7 });
				to = to.plus({ days: -7 });
				break;
		}
		this.dateRange.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this.reportsServiceProvider.getOperatingReport(from, to);
	}

	handleFilterChange(channels: string[]): void {
		this.filteredChannels = channels;
	}

	isVisible(channel: string): boolean {
		return this.filteredChannels.length ? this.filteredChannels.includes(channel) : true;
	}

	downloadCsv() {
		const reportDays = Object.keys(this.operatingReport.by_time);
		const channels = Object.keys(this.operatingReport.total.by_channel);
		const operatingReportCsv = [];
		const transform = (value) => this.currencyPipe.transform(value, 'EUR', true, '1.2-2');
		reportDays.forEach((day) => {
			const newDay = DateTime.fromISO(day);
			const translatedDate = newDay.setLocale(this.translocoService.getActiveLang()).toFormat('dd LLL yyyy');
			const entry = {
				_: translatedDate,
				[this.i18n.total]: transform(this.operatingReport.by_time[day].total.value),
			};
			entry[this.i18n.channels['POS']] = transform(
				this.operatingReport.by_time[day].by_channel['EAT_IN'].value + this.operatingReport.by_time[day].by_channel['TAKE_OUT'].value
			);
			entry[this.i18n.channels['KIOSK']] = transform(
				this.operatingReport.by_time[day].by_channel['KIOSK_EAT_IN'].value +
					this.operatingReport.by_time[day].by_channel['KIOSK_TAKE_OUT'].value
			);
			channels.forEach((channel) => {
				if (!this.hidden_channels.includes(channel)) {
					const translatedChannel = this.i18n.channels[channel] || channel;
					entry[translatedChannel] = transform(this.operatingReport.by_time[day].by_channel[channel].value);
				}
			});
			operatingReportCsv.push(entry);
		});
		const total = {
			_: this.i18n.total,
			[this.i18n.total]: transform(this.operatingReport.total.total.value),
		};
		total[this.i18n.channels['POS']] = transform(
			this.operatingReport.total.by_channel['EAT_IN'].value + this.operatingReport.total.by_channel['TAKE_OUT'].value
		);
		total[this.i18n.channels['KIOSK']] = transform(
			this.operatingReport.total.by_channel['KIOSK_EAT_IN'].value + this.operatingReport.total.by_channel['KIOSK_TAKE_OUT'].value
		);
		channels.forEach((channel) => {
			if (!this.hidden_channels.includes(channel)) {
				const translatedChannel = this.i18n.channels[channel] || channel;
				total[translatedChannel] = transform(this.operatingReport.total.by_channel[channel].value);
			}
		});
		operatingReportCsv.push(total);

		const options = {
			filename: this.i18n.title,
			decimalSeparator: '.',
			fieldSeparator: ';',
			showLabels: true,
			showTitle: true,
			title: this.i18n.title,
			useKeysAsHeaders: true,
		};
		const csvExporter = new ExportToCsv(options);
		csvExporter.generateCsv(operatingReportCsv);
	}

	downloadPDF() {
		this.pdfExport('operating-report', 0.19);
	}

	sortChannels(a, b) {
		return sortChannels(a.key, b.key);
	}

	singleDaySelection() {
		const from = this.dateRange.controls.from.value as Date;
		const to = this.dateRange.controls.to.value as Date;
		return from.getDate() === to.getDate() && from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
	}

	pdfExport(id, scale = 0.28) {
		const dateSelected = `${DateTime.fromJSDate(this.dateRange.controls.from.value).toFormat('yyyy-MM-dd')} - ${DateTime.fromJSDate(
			this.dateRange.controls.from.value
		).toFormat('yyyy-MM-dd')}`;
		const title = String(`${this.i18n.title}_${dateSelected}`);
		const source = document.getElementById(id);
		const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true });
		doc.setFontSize(16);
		doc.setFont(undefined, 'bold');
		doc.text(this.selectedRestaurants.join(','), 20, 15);
		doc.setFontSize(12);
		doc.setFont(undefined, 'light');
		doc.text(`${dateSelected}`, 20, 20);
		doc.html(source, {
			html2canvas: {
				scale: scale,
				letterRendering: false,
				ignoreElements: (e) => e.classList.contains('export-buttons'),
			},
			margin: [30, 5, 5, 5],
			windowWidth: 1000,
			width: 900,
			fontFaces: [
				{
					family: 'Roboto',
					src: [
						{
							url: '/assets/fonts/roboto.ttf',
							format: 'truetype',
						},
					],
				},
			],
			callback: (doc) => {
				doc.save(title);
			},
		});
	}

	downloadXlsx(): void {
		const operationalReportOptions: ReportOptions = {
			setupHeaders: (sheet) => {
				const headers = ['Date', 'Total', 'POS', 'KIOSK'];
				this.channels.forEach((channel) => {
					if (!this.hidden_channels.includes(channel)) {
						headers.push(this.i18n.channels[channel] || channel);
					}
				});
				const headerRow = sheet.addRow(headers);
				return headerRow;
			},

			prepareDataRows: (sheet) => {
				Object.entries(this.operatingReport.by_time).forEach(([date, data]) => {
					if (!data.by_channel) {
						console.warn(`Données manquantes pour by_channel à la date ${date}`);
						return;
					}

					const row = [
						DateTime.fromISO(date).toFormat('dd/LL/yyyy'),
						this.currencyPipe.transform(data.total.value, 'EUR', 'symbol', '1.2-2'),
						this.currencyPipe.transform(
							(data.by_channel['EAT_IN']?.value || 0) + (data.by_channel['TAKE_OUT']?.value || 0),
							'EUR',
							'symbol',
							'1.2-2'
						),
						this.currencyPipe.transform(
							(data.by_channel['KIOSK_EAT_IN']?.value || 0) + (data.by_channel['KIOSK_TAKE_OUT']?.value || 0),
							'EUR',
							'symbol',
							'1.2-2'
						),
					];
					this.channels.forEach((channel) => {
						if (!this.hidden_channels.includes(channel) && data.by_channel[channel]) {
							row.push(this.currencyPipe.transform(data.by_channel[channel].value, 'EUR', 'symbol', '1.2-2'));
						} else if (!this.hidden_channels.includes(channel)) {
							row.push(this.currencyPipe.transform(0, 'EUR', 'symbol', '1.2-2'));
						}
					});
					sheet.addRow(row);
				});

				const totalRow = [
					'Total',
					this.currencyPipe.transform(this.totalPerData(), 'EUR', 'symbol', '1.2-2'),
					this.currencyPipe.transform(this.totalPos(this.operatingReport, false), 'EUR', 'symbol', '1.2-2'),
					this.currencyPipe.transform(this.totalKiosk(this.operatingReport, false), 'EUR', 'symbol', '1.2-2'),
				];
				this.channels.forEach((channel) => {
					if (!this.hidden_channels.includes(channel)) {
						totalRow.push(
							this.currencyPipe.transform(this.operatingReport.total.by_channel[channel]?.value || 0, 'EUR', 'symbol', '1.2-2')
						);
					}
				});
				sheet.addRow(totalRow);
			},

			generateFileName: () => {
				const from = DateTime.fromJSDate(this.dateRange.controls.from.value).toFormat('dd/LL/yyyy');
				const to = DateTime.fromJSDate(this.dateRange.controls.to.value).toFormat('dd/LL/yyyy');
				return `RapportOperationnel-${from}-${to}.xlsx`;
			},
		};

		const from = DateTime.fromJSDate(this.dateRange.controls.from.value).toFormat('dd/LL/yyyy');
		const to = DateTime.fromJSDate(this.dateRange.controls.to.value).toFormat('dd/LL/yyyy');
		const detailText = `Restaurants : ${this.selectedRestaurants.join(', ')}\nDu ${from} au ${to}`;

		generateXlsx('Rapport Opérationnel', detailText, operationalReportOptions);
	}

	totalPerRow(row) {
		if (this.filteredChannels.length === 0) {
			return row.value.total.value;
		}
		let total = 0;
		Object.entries(row.value.by_channel).forEach((entries) => {
			if (this.filteredChannels.length && this.filteredChannels.includes(entries[0])) {
				total += entries[1]['value'];
			}
		});
		return total;
	}

	totalPerData() {
		let total = 0;
		if (this.filteredChannels.length === 0) {
			return this.operatingReport.total.total.value;
		}
		Object.entries(this.operatingReport.total.by_channel).forEach((entry) => {
			if (this.filteredChannels.includes(entry[0])) {
				total += entry[1].value;
			}
		});
		return total;
	}

	hiddenChannel(key: string) {
		return this.hidden_channels.includes(key);
	}

	totalPos(data: OperatingReport | any, perRow: boolean) {
		if (perRow) {
			return data.value.by_channel['EAT_IN'].value + data.value.by_channel['TAKE_OUT'].value;
		}
		return (data as OperatingReport).total.by_channel['EAT_IN'].value + (data as OperatingReport).total.by_channel['TAKE_OUT'].value;
	}

	totalKiosk(data: OperatingReport | any, perRow: boolean) {
		if (perRow) {
			return data.value.by_channel['KIOSK_EAT_IN'].value + data.value.by_channel['KIOSK_TAKE_OUT'].value;
		}
		return (
			(data as OperatingReport).total.by_channel['KIOSK_EAT_IN'].value + (data as OperatingReport).total.by_channel['KIOSK_TAKE_OUT'].value
		);
	}
}
