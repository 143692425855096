export function saveToLocalStorage(key: string, value: any): void {
	try {
		localStorage.setItem(key, JSON.stringify(value));
	} catch (e) {
		console.warn('Local storage warning: ', e, JSON.stringify(value), JSON.stringify(value).length);
		return;
	}
}

export function readFromLocalStorage<T>(key: string): T | null {
	try {
		const data = localStorage.getItem(key) || '';
		return JSON.parse(data);
	} catch (e) {
		return null;
	}
}
