import { Component, Inject } from '@angular/core';

import { ACCOUNT_FACADE } from '../facade/account-facade.injection.token';
import { AccountServiceProvider } from '../facade/account-facade.provider.interface';

@Component({
	selector: 'dk-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.scss'],
})
export class AccountComponent {
	isLoading$ = this._accountFacade.isLoading$;

	constructor(@Inject(ACCOUNT_FACADE) private _accountFacade: AccountServiceProvider) {}
}
