import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
	selector: 'dk-graph',
	templateUrl: './revenue-graph.component.html',
	styleUrls: ['./revenue-graph.component.scss'],
})
export class DkGraphComponent implements OnChanges {
	@Input() dataSource = [];
	@Input() history = false;

	width = 450;
	height = 200;

	averageData = [];

	xAxis: { x: number; y: number; value: string | number; label: string }[] = [];
	polygon = '';
	current: { x: number; y: number; value: string | number; label: string }[] = [];
	hidden = true;
	tooltip = {
		x: 0,
		y: 0,
		fill: '#CFC0BB',
		text: '',
	};

	yMax = 0;
	currentHourIndex = 0;

	source() {
		if (this.dataSource['currentSeries'] && this.dataSource['currentSeries'].length) {
			const nearestStep = 500;
			let yMax = Math.max(...this.averageData, ...this.dataSource['currentSeries'].map((e) => Number(e.value)));
			yMax = Math.round(yMax / nearestStep) * nearestStep;
			this.yMax = yMax;
			const xAxis: { x: number; y: number; value: string | number; label: string }[] = [];
			const count = this.averageData.length;
			const xStep = (this.width / count) * 0.5;
			this.averageData.forEach((e, index) => {
				const p = {
					x: (index + 1) * xStep * 2,
					y: (e * this.height) / yMax || 0,
					value: e,
					label: this.dataSource['averageSeries'][index].label,
				};
				xAxis.push(p);
				this.current.push({
					x: p.x,
					y: (this.dataSource['currentSeries'][index]?.value * this.height) / yMax || 0,
					value: this.dataSource['currentSeries'][index]?.value + '',
					label: this.dataSource['currentSeries'][index]?.label,
				});
			});
			// this.yMax = Math.max(...this.current.map((e) => Number(e.value)), this.yMax);
			// this.yMax = Math.round(this.yMax / nearestStep) * nearestStep || 0;
			this.polygon = xAxis.map((e) => `${this.isFinite(e.x)},${this.isFinite(e.y)}`).join(' ');
			this.polygon = `0 0,${this.polygon},${this.width} 0`;
			this.xAxis = xAxis;
			this.current = this.current.splice(0, this.currentHourIndex);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		this.averageData = [];
		this.current = [];
		changes.dataSource.currentValue.averageSeries.forEach((e) => {
			this.averageData.push(Number(e.value));
		});
		this.source();
		this.currentHourIndex = this.history
			? 24
			: changes.dataSource.currentValue.activeHours.indexOf(changes.dataSource.currentValue.currentHourSeries[1]?.label);
	}

	showTooltip(event, p, fill) {
		this.tooltip.x = p.x - 60;
		this.tooltip.y = p.y + 30;
		this.tooltip.fill = fill;
		this.tooltip.text = `${p.label} : ${parseFloat(p.value).toFixed(2)} EUR`;
		this.hidden = false;
	}

	hideTooltip() {
		this.hidden = true;
	}

	isFinite(a) {
		return Number.isFinite(a) ? a : 0;
	}
}
