import { BKOrderSourceEnum } from '@bk/jscommondatas';
import { BKTypePreparationDelivery } from '@merim/utils';

import { BKOrbRapOrder } from '../models/bk-orb-rap-order';
import { IBKDeliveryOptions } from '../models/ibk-delivery-options';
import { getDeliveryType } from './get-delivery-type';

const allienSources = [BKOrderSourceEnum.ORDER_SOURCE_CLICK_AND_COLLECT, BKOrderSourceEnum.ORDER_SOURCE_HOME_DELIVERY, BKOrderSourceEnum.ORDER_SOURCE_THIRD_PARTY_DELIVERY];

export function getAlienId(order: BKOrbRapOrder): string | null {
	if (allienSources.includes(order.source)) {
		const deliveryOptions: IBKDeliveryOptions | undefined = order.deliveryOptions;
		const deliveryType = getDeliveryType(deliveryOptions);

		if (order.source === BKOrderSourceEnum.ORDER_SOURCE_CLICK_AND_COLLECT) {
			return getClickAndCollectAlienId(deliveryOptions);
		}

		if (deliveryOptions) {
			if (deliveryOptions.weborderdisplay && deliveryOptions.weborderdisplay.length > 0) {
				return deliveryOptions.weborderdisplay.toUpperCase();
			} else if (deliveryOptions.weborderid) {
				return getSubstringFromWeborderId(deliveryOptions.weborderid, deliveryType).toUpperCase();
			}
		}
	}

	return null;
}

function getClickAndCollectAlienId(deliveryOptions: IBKDeliveryOptions | undefined | null): string | null {
	if (deliveryOptions) {
		if (deliveryOptions.weborderdisplay && deliveryOptions.weborderdisplay.length > 0) {
			return deliveryOptions.weborderdisplay.toUpperCase();
		} else if (deliveryOptions.weborderid) {
			return deliveryOptions.weborderid.substring(deliveryOptions.weborderid.length - 4).toUpperCase();
		}
	}
	return null;
}

function getSubstringFromWeborderId(weborderId: string, deliveryType: BKTypePreparationDelivery): string {
	let numberOfCharsFromTheEnd: number = weborderId.length;
	switch (deliveryType) {
		case BKTypePreparationDelivery.DELIVEROO:
			numberOfCharsFromTheEnd = 4;
			break;
		case BKTypePreparationDelivery.UBER_EATS:
			numberOfCharsFromTheEnd = 5;
			break;
		case BKTypePreparationDelivery.JUST_EAT:
			numberOfCharsFromTheEnd = 6;
			break;
	}

	const result = weborderId.substring(weborderId.length - numberOfCharsFromTheEnd);
	return result;
}
