<div class="flex picker-container">
	<span><mat-icon>calendar_month</mat-icon></span>
	<input
		type="text"
		matInput
		ngxDaterangepickerMd
		startKey="start"
		endKey="end"
		[(ngModel)]="selected"
		[ranges]="ranges"
		[alwaysShowCalendars]="true"
		[autoApply]="true"
		(datesUpdated)="datesUpdated($event)"

	/>
	<span><mat-icon>navigate_before</mat-icon></span>
	<span><mat-icon>navigate_next</mat-icon></span>
	@if ((filters | async).set) {
		<button class="show-btn" (click)="unsetFilters()">{{ i18n.showCompleteList }}</button>
	}
</div>
<div class="body-container">
	<h2>{{ i18n.waitListTitle }}</h2>
	@if (pendingReports$ | async; as pendingReports) {
		@if (pendingReports.length) {
			<dk-data-table [dataSource]="pendingReports" [i18n]="i18n"></dk-data-table>
		}
		@if (!pendingReports.length) {
			<p>{{ i18n.noData }}</p>
		}
	}
</div>
