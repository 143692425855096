import { Injectable } from '@angular/core';

import { FeatureMapService } from '@libs/dash/guards';
import {
	MyRestaurantsSelectors,
	RootStoreState,
	RouterStoreSelectors,
	SynthesisReportActions,
	SynthesisReportSelectors,
} from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { SynthesisReportServiceProvider } from './synthesis-report-facade.provider.interface';

@Injectable()
export class SynthesisReportFacade implements SynthesisReportServiceProvider {
	currentLanguage$ = this._store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam));
	myDefaultRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant));
	restaurantIsOpened$ = this._store.pipe(select(MyRestaurantsSelectors.restaurantIsOpened));
	selectedRestaurants$ = this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurants));
	isLoadingSavedRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.isLoadingPersistedRestaurant));

	isLoading$ = this._store.pipe(select(SynthesisReportSelectors.isLoading));

	synthesisReport$ = this._store.pipe(select(SynthesisReportSelectors.synthesisReport));
	vatSynthesisReport$ = this._store.pipe(select(SynthesisReportSelectors.vatSynthesisReport));

	synthesisEnabled = this.fm.featureMap?.synthesisReport?.enabled || false;

	range = {
		from: DateTime.now().startOf('day'),
		to: DateTime.now().endOf('day'),
	};

	constructor(
		private _store: Store<RootStoreState>,
		private fm: FeatureMapService
	) {}

	getSynthesisReport(from: DateTime, to: DateTime) {
		this.range.from = from;
		this.range.to = to;
		if (this.synthesisEnabled) {
			this._store.dispatch(SynthesisReportActions.getSynthesisReport({ from, to }));
		}
	}

	getVatSynthesisReport(from: DateTime, to: DateTime) {
		this._store.dispatch(SynthesisReportActions.getSynthesisVatReport({ from, to }));
	}

	fetch(from = this.range.from, to = this.range.to) {
		this.range = { from, to };
		this.getSynthesisReport(from, to);
		this.getVatSynthesisReport(from, to);
	}
}
