@if (i18n$ | async; as i18n) {
	<div class="dk-picker" [class.single-display]="singleDaySelection()">
		<mat-form-field appearance="fill" subscriptSizing="dynamic">
			<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
				<input [placeholder]="i18n.startDate" formControlName="from" matStartDate [hidden]="singleDaySelection()"/>
				<input [placeholder]="i18n.endDate" formControlName="to" matEndDate [class.single-display]="singleDaySelection()"/>
			</mat-date-range-input>
			<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
			<mat-date-range-picker #picker displayMode="single">
				<mat-date-range-picker-actions>
					<button mat-button matDateRangePickerCancel>{{ i18n.cancel }}</button>
					<button (click)="setPeriod('period')" color="primary" mat-raised-button matDateRangePickerApply>
						{{ i18n.apply }}
					</button>
				</mat-date-range-picker-actions>
			</mat-date-range-picker>
		</mat-form-field>
		<button (click)="setPeriod('today')" mat-button class="today-btn">{{ i18n.today }}</button>
		<button (click)="setPeriod('week')" mat-button>{{ i18n.week }}</button>
		<button (click)="setPeriod('month')" mat-button>{{ i18n.month }}</button>
		@if (isLoading | async) {
			<mat-spinner class="d-inline" diameter="20"></mat-spinner>
		}
		<button mat-button [matMenuTriggerFor]="DiscountReport" class="export-label">{{ i18n.export }}</button>
		<mat-menu #DiscountReport="matMenu" yPosition="above" xPosition="before">
			<button mat-menu-item (click)="downloadCSV()">{{ i18n.exportCsv }}</button>
			<button mat-menu-item (click)="downloadPDF()">{{ i18n.exportPDF }}</button>
		</mat-menu>
	</div>
	@if (discountsReport && discountsReport.by_discount_type && discountsReport.by_discount_type.length) {
		<table mat-table [dataSource]="discountsReport.by_discount_type" class="mat-elevation-z8 w-100 mt-5" id="discount-report">
			<ng-container matColumnDef="libelle">
				<th mat-header-cell *matHeaderCellDef>{{ i18n.libelle }}</th>
				<td mat-cell *matCellDef="let element">{{ element.name }}</td>
				<td mat-footer-cell *matFooterCellDef class="uppercase">{{ i18n.total }}</td>
			</ng-container>
			<ng-container matColumnDef="percent">
				<th mat-header-cell *matHeaderCellDef>{{ i18n.percent }}</th>
				<td mat-cell *matCellDef="let element">{{ element.percent ? i18n.yes : i18n.no }}</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>
			<ng-container matColumnDef="value">
				<th mat-header-cell *matHeaderCellDef>{{ i18n.value }}</th>
				<td mat-cell *matCellDef="let element">{{ element.value }}</td>
				<td mat-footer-cell *matFooterCellDef></td>
			</ng-container>
			<ng-container matColumnDef="quantity">
				<th mat-header-cell *matHeaderCellDef>{{ i18n.quantity }}</th>
				<td mat-cell *matCellDef="let element">{{ element.count }}</td>
				<td mat-footer-cell *matFooterCellDef>{{ discountsReport.total.count }}</td>
			</ng-container>
			<ng-container matColumnDef="discountvat">
				<th mat-header-cell *matHeaderCellDef>{{ i18n.discountAmountVat }}</th>
				<td mat-cell *matCellDef="let element">{{ element.total_value | currency: 'EUR' }}</td>
				<td mat-footer-cell *matFooterCellDef>{{ discountsReport.total.total_value | currency: 'EUR' }}</td>
			</ng-container>
			<ng-container matColumnDef="discount">
				<th mat-header-cell *matHeaderCellDef>{{ i18n.discountAmountGross }}</th>
				<td mat-cell *matCellDef="let element">{{ element.total_value_excluding_vat | currency: 'EUR' }}</td>
				<td mat-footer-cell *matFooterCellDef>{{ discountsReport.total.total_value_excluding_vat | currency: 'EUR' }}</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumnsEntries"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumnsEntries"></tr>
			<tr mat-footer-row *matFooterRowDef="displayedColumnsEntries" class="highlight font-weight-bold"></tr>
		</table>
	} @else {
		<p>{{ i18n.noData }}</p>
	}
}
