import { inject, Injectable, InjectionToken } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SESSION_TYPE } from '@bk/fullstack-common';
import { CsiLoginResultEnum } from '@bk/jscommondatas';
import { IAdditionalInformationLogin, IClerkIsLoggedUserData, IClerkLoginResponse, LOGIN_SESSION } from '@libs/shared/interfaces';
import { DEVICES_USER_ROLES, IS_LOGGED_IN_USER_ROLES, LOCAL_STORAGE_KEYS, UsersModel } from '@libs/shared/models';
import { ClerkService } from '@libs/shared/services';
import { DeviceFacade } from '@libs/shared/store/device-state';
import { readFromLocalStorage, saveToLocalStorage } from '@libs/shared/utils';
import { Observable, of, tap } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

export const USER_INJECTION_TOKEN = new InjectionToken<UsersModel>('USER_INJECTION_TOKEN');
export const MANAGER_INJECTION_TOKEN = new InjectionToken<UsersModel>('MANAGER_INJECTION_TOKEN');

@Injectable()
export class LoginService {
	private readonly _clerkService = inject(ClerkService);
	private readonly _deviceFacade = inject(DeviceFacade);
	private readonly _injectedUser = inject(USER_INJECTION_TOKEN);
	private readonly _injectedManager = inject(MANAGER_INJECTION_TOKEN);

	private _user: UsersModel;
	private _manager: UsersModel;

	private readonly _device = toSignal(this._deviceFacade.getMachineInfo());

	constructor() {
		this._user = this._injectedUser;
		this._manager = this._injectedManager;
	}

	set setManager(manager: UsersModel) {
		this._manager = manager;
	}

	set setUser(user: UsersModel) {
		this._user = user;
	}

	login(): Observable<IClerkLoginResponse | null> {
		const additionalLoginInformation: IAdditionalInformationLogin = {
			sessionType: SESSION_TYPE.AUTONOMOUS,
			session: LOGIN_SESSION.EXPIRED,
			initialAmounts: null,
			metadata: { ...this._device() },
		};

		if (!this._user) {
			console.error('[APP]: User is not set in the configuration');
			return of(null);
		}

		if (!this._manager) {
			console.error('[APP]: User is not set in the configuration');
			return of(null);
		}

		const doLogin = (): Observable<IClerkLoginResponse> => {
			return this._clerkService.login(this._user, this._manager, additionalLoginInformation).pipe(
				tap((item) => {
					const { jwt } = item;
					if (jwt) {
						saveToLocalStorage(LOCAL_STORAGE_KEYS.CLERK_JWT, jwt);
					}
				})
			);
		};

		const currentJwt = readFromLocalStorage<string>(LOCAL_STORAGE_KEYS.CLERK_JWT);
		if (currentJwt) {
			return this._clerkService.checkIsLoggedIn(currentJwt).pipe(
				mergeMap((result) => {
					if (result) {
						this._manager = this.dtoIsLoggedInResponseToUsersModel(result.response.operateur, IS_LOGGED_IN_USER_ROLES.MANAGER);
						this._user = this.dtoIsLoggedInResponseToUsersModel(result.response.manager, IS_LOGGED_IN_USER_ROLES.MANAGER);
						return of<IClerkLoginResponse>({
							code: CsiLoginResultEnum.OK,
							jwt: currentJwt,
							responses: [],
						});
					}

					return doLogin();
				}),
				catchError(() => doLogin())
			);
		}

		return doLogin();
	}

	dtoIsLoggedInResponseToUsersModel(data: IClerkIsLoggedUserData, role: IS_LOGGED_IN_USER_ROLES): UsersModel {
		let usersRole = undefined;
		switch (role) {
			case IS_LOGGED_IN_USER_ROLES.MANAGER:
				usersRole = DEVICES_USER_ROLES.MANAGER;
				break;
			case IS_LOGGED_IN_USER_ROLES.OPERATEUR:
				usersRole = DEVICES_USER_ROLES.MAINTAINER;
				break;
			default:
				console.warn('[APP]: Unable to assign user role');
		}

		return new UsersModel({ id: data.userId, login: data.userName, roles: usersRole });
	}
}
