import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { OpeningTimes } from '@libs/dash/core/entity';
import { FeatureMapService } from '@libs/dash/guards';
import { DashboardTimerange } from '@libs/dash/shared';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';
import { filter, map } from 'rxjs/operators';
import { ANALYZE_HISTORY_FACADE } from '../facade/analyze-history-facade.injection.token';
import { AnalyzeHistoryServiceProvider } from '../facade/analyze-history-facade.provider.interface';

export const MY_FORMATS = {
	parse: {
		dateInput: 'LL',
	},
	display: {
		dateInput: 'LL',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@UntilDestroy()
@Component({
	selector: 'dk-analyze-history',
	templateUrl: './analyze-history.component.html',
	styleUrls: ['./analyze-history.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	],
})
export class AnalyzeHistoryComponent implements OnInit, OnChanges {
	maxDate = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

	@Input() pickedDate: DateTime;

	dateSelected = false;

	private _displayedNumberOfColumns = 4;
	private _defaultRestaurantsOpeningTimes: OpeningTimes;

	dashboardTimerange: DashboardTimerange;
	localisedTexts$ = this._translocoService.selectTranslateObject('analyze-history');
	myDefaultRestaurant$ = this._analyzeHistoryFacade.myDefaultRestaurant$.pipe(
		map((myDefaultRestaurant) => {
			if (myDefaultRestaurant && myDefaultRestaurant.id) {
				this._defaultRestaurantsOpeningTimes = myDefaultRestaurant.opening_times;
				const from = this.pickedDate.startOf('day').toJSDate();
				const to = this.pickedDate.endOf('day').toJSDate();
				this._setTimeRange({ from, to });
			}
			return myDefaultRestaurant;
		})
	);

	channelPermoranceInPercentage$ = this._analyzeHistoryFacade.channelPermoranceInPercentage$;

	featureMap = this.fm.featureMap.globalOverview.todays;

	constructor(
		private _translocoService: TranslocoService,
		private _elementRef: ElementRef,
		@Inject(ANALYZE_HISTORY_FACADE) private _analyzeHistoryFacade: AnalyzeHistoryServiceProvider,
		private fm: FeatureMapService
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		this.handleDate(changes.pickedDate.currentValue);
	}

	ngOnInit(): void {
		this._analyzeHistoryFacade.myDefaultRestaurant$
			.pipe(
				untilDestroyed(this),
				filter((id) => !!id)
			)
			.subscribe(() => this._analyzeHistoryFacade.fetchDefaultRestaurantOrdersData());
	}

	setDashboardTimeRange(selectedDate: { from: Date; to: Date }): void {
		this.dateSelected = true;
		this._setTimeRange(selectedDate);
	}

	private _setTimeRange(selectedDate: { from: Date; to: Date }): void {
		const currentDayFrom = DateTime.fromJSDate(selectedDate.from).startOf('day').toISO({ suppressMilliseconds: true }).replace('Z', '');

		const currentDayTo = selectedDate.to
			? DateTime.fromJSDate(selectedDate.to).endOf('day').toISO({ suppressMilliseconds: true }).replace('Z', '')
			: currentDayFrom;

		this._analyzeHistoryFacade.fetchDefaultRestaurantOrdersData(currentDayFrom, currentDayTo);
	}

	handleDate(selectedDate: DateTime) {
		const from = selectedDate.startOf('day').toJSDate();
		const to = selectedDate.endOf('day').toJSDate();
		this.setDashboardTimeRange({ from, to });
	}

	normalizeGrid(element: HTMLElement) {
		const grids = Array.from(element.children);
		grids.forEach((element) => {
			if (element !== null) {
				const count = element.childElementCount;
				(element as HTMLElement).style.gridTemplateColumns = `repeat(${count}, 1fr)`;
			}
		});
	}
}
