import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	effect,
	inject,
	Injector,
	Input,
	OnInit,
	signal,
	Signal,
	WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { setPreferredLanguage } from '@libs/dash/core';
import { ApplicationModuleSections, LanguageISOCode } from '@libs/dash/core/entity';
import { FeatureMapService } from '@libs/dash/guards';
import { AuthStoreSelectors, AuthStoreState, RootStoreState, RouterStoreSelectors } from '@libs/dash/store';
import { MenuModel } from '@libs/shared/models';
import { ConfigurationService } from '@merim/utils';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { NEW_UI_ROUTE } from 'libs/shared/services';
import { combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ManifestReaderService } from '../../shared/manifest-app-version/services';

@Component({
	selector: 'dash-app-content',
	templateUrl: './dash-app-content.component.html',
	styleUrl: './dash-app-content.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashAppContentComponent implements OnInit {
	private readonly _manifestReaderService: ManifestReaderService = inject(ManifestReaderService);
	private readonly _router: Router = inject(Router);
	private readonly _translocoService: TranslocoService = inject(TranslocoService);
	private readonly _configurationService: ConfigurationService = inject(ConfigurationService);
	private readonly _fm: FeatureMapService = inject(FeatureMapService);
	private readonly _dr: DestroyRef = inject(DestroyRef);
	private readonly _injector: Injector = inject(Injector);
	private readonly _store: Store<RootStoreState> = inject(Store<RootStoreState>);

	private readonly _defaultManifestLocation = '/manifest.json';
	private _reportsFeatureEnabled = false;
	private _featureMap = this._fm.featureMap;

	availableLanguages: AvailableLangs;

	i18nSig = toSignal<{ language: string; texts: { [key: string]: string } }>(
		combineLatest([
			this._store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam)),
			this._translocoService.selectTranslateObject('navigation-bar'),
		]).pipe(
			map(([language, texts]) => {
				return {
					language,
					texts,
				};
			})
		)
	);

	hasAccountingPermissionSig = toSignal<boolean>(
		this._store.pipe(
			select(AuthStoreSelectors.getCurrentUser),
			takeUntilDestroyed(this._dr),
			map((user: AuthStoreState.CurrentUser) => {
				const accountingLevel = user?.permissions.filter(
					(permission) => !!permission.name && permission.name.toLowerCase() === 'accounting'
				)[0]?.level;
				const isAvailable = !!accountingLevel && accountingLevel.toLowerCase() !== 'none';
				return isAvailable || user?.is_admin;
			})
		)
	);

	@Input() manifestLocation: string = this._defaultManifestLocation;

	currentAppVersionSig: Signal<string> = toSignal(
		this._manifestReaderService.getAppVersion(this.manifestLocation).pipe(catchError(() => of('N/A')))
	);
	menuItemsSig: WritableSignal<MenuModel<ApplicationModuleSections>[]> = signal([]);
	userSig: Signal<AuthStoreState.CurrentUser> = toSignal(this._store.pipe(select(AuthStoreSelectors.getCurrentUser)));

	ngOnInit() {
		this.loadInitData();
	}

	onLanguageChange(): void {
		const desiredLanguage = this._translocoService.getActiveLang() === 'en' ? LanguageISOCode.FR : LanguageISOCode.EN;
		setPreferredLanguage(desiredLanguage);
		this._translocoService.setActiveLang(desiredLanguage === LanguageISOCode.EN ? 'en' : 'fr');
	}

	onLogout(): void {
		this._router.navigate(['/' + ApplicationModuleSections.ACCOUNT + '/logout']);
	}

	private loadInitData(): void {
		this._reportsFeatureEnabled = this._configurationService.hasFeature('reports');
		this.availableLanguages = this._translocoService.getAvailableLangs();
		effect(
			() => {
				if (!!this.i18nSig() && !!this.hasAccountingPermissionSig()) {
					this.createPageNavigation(this.i18nSig().texts, this.hasAccountingPermissionSig());
				}
			},
			{ injector: this._injector, allowSignalWrites: true }
		);
	}

	private createPageNavigation(translates: { [key: string]: string }, accountingPermissions: boolean): void {
		const navigationBarItems: MenuModel<ApplicationModuleSections>[] = [];

		const generateSettings = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			navigationBarItems.push({
				text: 'navigation-bar.settings',
				url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.SETTINGS}`,
				icon: 'settings',
				submenu: [],
			});
		};

		const generateGlobalOverview = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			if (this._featureMap.sidebar.globalOverview) {
				navigationBarItems.push({
					text: 'navigation-bar.global-overview',
					url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.GLOBAL_OVERVIEW}`,
					icon: 'pie_chart',
					submenu: [],
				});
			}
		};

		const generateReports = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			if (this._featureMap.sidebar.sales && this._reportsFeatureEnabled) {
				navigationBarItems.push({
					text: 'navigation-bar.reports',
					url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.SALES_REPORTS}`,
					icon: 'chart_data',
					submenu: [],
				});
			}
		};

		const generateHistory = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			if (this._featureMap.sidebar.history) {
				navigationBarItems.push({
					text: 'navigation-bar.history-logs',
					url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.HISTORY_LOGS}`,
					icon: 'history',
					submenu: [],
				});
			}
		};

		const generateDtt = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			if (this._featureMap.sidebar.dtt && this._reportsFeatureEnabled) {
				navigationBarItems.push({
					text: 'navigation-bar.reports',
					url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.DTT_EXPORT}`,
					icon: 'garage',
					submenu: [],
				});
			}
		};

		const generateSos = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			if (this._featureMap.sidebar.sos && this._reportsFeatureEnabled) {
				navigationBarItems.push({
					text: 'navigation-bar.speed-of-service',
					url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.TOS}`,
					icon: 'sos',
					submenu: [],
				});
			}
		};

		const generateAccounting = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			if (this._featureMap.sidebar.accounting && accountingPermissions) {
				navigationBarItems.push({
					text: 'navigation-bar.accounting',
					url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.ACCOUNTING}`,
					icon: 'receipt',
					submenu: [],
				});
			}
		};

		const generatePerformance = (navigationBarItems: MenuModel<ApplicationModuleSections>[]): void => {
			if (this._featureMap.sidebar.performance) {
				navigationBarItems.push({
					text: 'navigation-bar.performance',
					url: `/${NEW_UI_ROUTE}/${ApplicationModuleSections.PERFORMANCE}`,
					icon: 'readiness_score',
					submenu: [],
				});
			}
		};

		//generateGlobalOverview(navigationBarItems);
		generateReports(navigationBarItems);
		generateHistory(navigationBarItems);
		//generateDtt(navigationBarItems);
		generateSos(navigationBarItems);
		//generateAccounting(navigationBarItems);
		//generatePerformance(navigationBarItems);
		//generateSettings(navigationBarItems);

		this.menuItemsSig.update(() => navigationBarItems);
	}
}