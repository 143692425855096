import { Injectable } from '@angular/core';

export const NEW_UI_ROUTE = 'v2' as const;

@Injectable({
	providedIn: 'root',
})
export class UiVersionDetectorService {
	isNewUi(): boolean {
		return window.location.pathname.includes(NEW_UI_ROUTE);
	}
}