export function composeDefaultURL(port: string, url?: string): string {
	if (url) {
		return url;
	}

	const protocol = window.location.protocol;
	const hostname = window.location.hostname;

	const beUrl = `${protocol}//${hostname}:${port}`;
	return beUrl;
}

export function urlParam(property: string): string | null {
	const param = new URLSearchParams(window.location.search);

	return param.get(property);
}
