import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
	selector: 'dk-sessions-history',
	templateUrl: './sessions-history.component.html',
	styleUrls: ['./sessions-history.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionsHistoryComponent {
	public localisedTexts$ = this._translocoService.selectTranslateObject('sessions-history');

	constructor(private _translocoService: TranslocoService) {}
}
