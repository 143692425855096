import { MENU_MODEL_ACTION_CONDITION, MenuModel, SubMenuModel } from '@libs/shared/models';
import { NavigationTileActionType } from '@libs/shared/modules/navigation-tile';
import { PriceManagementPaths } from '../features/price-management/price-management-paths';
import { RAP_COLORS } from './colors.model';
import { MODULES } from './config.type';
import { paths } from './paths';

export const SUBMENU_PRICE_MANAGEMENT_PRODUCTS: SubMenuModel<MODULES> = {
	text: 'Product_Price_Management',
	icon: 'inventory',
	url: paths.priceManagement + '/' + PriceManagementPaths.product,
	module: MODULES.PRICE_PRODUCTS_MANAGEMENT,
	color: RAP_COLORS.PRICE_MANAGEMENT_PRODUCTS,
	description: 'Product_Price_Management_description',
	actions: [
		{
			text: 'open-products',
			url: paths.priceManagement + '/' + PriceManagementPaths.product,
			condition: MENU_MODEL_ACTION_CONDITION.NONE,
			action: NavigationTileActionType.Open,
		},
	],
};

export const SUBMENU_PRICE_MANAGEMENT_MENUS: SubMenuModel<MODULES> = {
	text: 'Menu_Price_Management',
	icon: 'dataset',
	url: paths.priceManagement + '/' + PriceManagementPaths.menu,
	module: MODULES.PRICE_MENUS_MANAGEMENT,
	color: RAP_COLORS.PRICE_MANAGEMENT_MENUS,
	description: 'Menu_Price_Management_description',
	actions: [
		{
			text: 'open-menu',
			url: paths.priceManagement + '/' + PriceManagementPaths.menu,
			condition: MENU_MODEL_ACTION_CONDITION.NONE,
			action: NavigationTileActionType.Open,
		},
	],
};

export const SUBMENU_PRICE_MANAGEMENT_INGREDIENTS: SubMenuModel<MODULES> = {
	text: 'Ingredient_Price_Management',
	icon: 'shopping_cart',
	url: paths.priceManagement + '/' + PriceManagementPaths.ingredient,
	module: MODULES.PRICE_INGREDIENTS_MANAGEMENT,
	color: RAP_COLORS.PRICE_MANAGEMENT_INGREDIENTS,
	description: 'Ingredient_Price_Management_description',
	actions: [
		{
			text: 'open-ingredients',
			url: paths.priceManagement + '/' + PriceManagementPaths.ingredient,
			condition: MENU_MODEL_ACTION_CONDITION.NONE,
			action: NavigationTileActionType.Open,
		},
	],
};

export const MENU_PRICE_MANAGEMENT: MenuModel<MODULES> = {
	text: 'Price-management',
	icon: 'euro',
	url: paths.priceManagement,
	module: MODULES.PRICE_MANAGEMENT,
	submenu: [SUBMENU_PRICE_MANAGEMENT_PRODUCTS, SUBMENU_PRICE_MANAGEMENT_MENUS, SUBMENU_PRICE_MANAGEMENT_INGREDIENTS],
	color: RAP_COLORS.PRICE_MANAGEMENT,
	description: 'price-management-description',
	actions: [
		{
			text: 'open-products',
			url: paths.priceManagement + '/' + PriceManagementPaths.product,
			condition: MENU_MODEL_ACTION_CONDITION.NONE,
			action: NavigationTileActionType.Open,
		},
		{
			text: 'open-menu',
			url: paths.priceManagement + '/' + PriceManagementPaths.menu,
			condition: MENU_MODEL_ACTION_CONDITION.NONE,
			action: NavigationTileActionType.Open,
		},
		{
			text: 'open-ingredients',
			url: paths.priceManagement + '/' + PriceManagementPaths.ingredient,
			condition: MENU_MODEL_ACTION_CONDITION.NONE,
			action: NavigationTileActionType.Open,
		},
	],
};

export const MENU_DSS: MenuModel<MODULES> = {
	text: 'dss',
	icon: 'star',
	url: paths.dss,
	module: MODULES.DSS,
	submenu: [],
	color: RAP_COLORS.DSS,
	description: 'dss-description',
	actions: [
		{
			text: 'open',
			url: paths.dss,
			condition: MENU_MODEL_ACTION_CONDITION.NONE,
			action: NavigationTileActionType.Open,
		},
	],
};

export const MENU_PMS: MenuModel<MODULES> = {
	text: 'pms',
	icon: 'event',
	url: paths.pms,
	condition: MENU_MODEL_ACTION_CONDITION.ENABLED,
	module: MODULES.PMS,
	submenu: [],
	color: RAP_COLORS.PMS,
	description: 'pms-description',
	actions: [
		{
			text: 'open',
			url: paths.pms,
			condition: MENU_MODEL_ACTION_CONDITION.ENABLED,
			action: NavigationTileActionType.Open,
		},
	],
};

export const MENU: MenuModel<MODULES>[] = [MENU_PRICE_MANAGEMENT, MENU_DSS, MENU_PMS];
