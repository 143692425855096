import { Inject, Injectable } from '@angular/core';

import {
	MyRestaurantsActions,
	MyRestaurantsSelectors,
	ReportsActions,
	ReportsSelectors,
	RootStoreState,
	RouterStoreSelectors,
} from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { EMPLOYEE_MEAL_FACADE } from '../../employee-meals/facade/employee-meals-facade.injection.token';
import { EmployeeMealsServiceProvider } from '../../employee-meals/facade/employee-meals-facade.provider.interface';
import { SYNTHESIS_REPORT_FACADE } from '../../synthesis-report/facade/synthesis-report-facade.injection.token';
import { SynthesisReportServiceProvider } from '../../synthesis-report/facade/synthesis-report-facade.provider.interface';
import { ReportsServiceProvider } from './reports-facade.provider.interface';

@Injectable()
export class ReportsFacade implements ReportsServiceProvider {
	currentLanguage$ = this._store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam));
	myDefaultRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant));
	restaurantIsOpened$ = this._store.pipe(select(MyRestaurantsSelectors.restaurantIsOpened));
	selectedRestaurants$ = this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurants));

	isLoadingHourlySales$ = this._store.pipe(select(ReportsSelectors.isLoadingHourlySales));
	isLoadingOperatingReport$ = this._store.pipe(select(ReportsSelectors.isLoadingOperatingReport));
	isLoadingWeeklyReport$ = this._store.pipe(select(ReportsSelectors.isLoadingWeeklyReport));
	isLoadingProductsReport$ = this._store.pipe(select(ReportsSelectors.isLoadingProductsReport));
	isLoadingDiscountsReport$ = this._store.pipe(select(ReportsSelectors.isLoadingDiscountsReport));

	hourlySales$ = this._store.pipe(select(ReportsSelectors.hourlySales));
	operatingReport$ = this._store.pipe(select(ReportsSelectors.operatingReport));
	weeklyReport$ = this._store.pipe(select(ReportsSelectors.weeklyReport));
	productsReport$ = this._store.pipe(select(ReportsSelectors.productsReport));
	discountReport$ = this._store.pipe(select(ReportsSelectors.discountsReport));

	isLoadingSavedRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getIsLoading));

	weeklyReportParams$ = this._store.pipe(select(ReportsSelectors.weeklyReportParams));

	ranges = {
		hourlyReport: {
			from: DateTime.now().startOf('day'),
			to: DateTime.now().endOf('day'),
		},
		operatingReport: {
			from: DateTime.now().startOf('day'),
			to: DateTime.now().endOf('day'),
		},
		weeklyReport: DateTime.now().startOf('week'),
		productsReport: {
			from: DateTime.now().startOf('day'),
			to: DateTime.now().endOf('day'),
		},
		discountsReport: {
			from: DateTime.now().startOf('day'),
			to: DateTime.now().endOf('day'),
		},
	};

	constructor(
		private _store: Store<RootStoreState>,
		@Inject(EMPLOYEE_MEAL_FACADE) private readonly employeeMealsService: EmployeeMealsServiceProvider,
		@Inject(SYNTHESIS_REPORT_FACADE) private readonly synthesisReportService: SynthesisReportServiceProvider
	) {}

	fetch(indexs) {
		switch (indexs) {
			case 0:
				this.getHourlySales();
				break;
			case 1:
				this.getWeeklyReport();
				break;
			case 2:
				this.getSynthesisReport();
				break;
			case 3:
				this.getOperatingReport();
				break;
			case 4:
				this.getProductsReport();
				break;
			case 5:
				this.getEmployeeMeals();
				break;
			case 6:
				this.getDiscountsReport();
				break;
		}
	}

	getHourlySales(from = this.ranges.hourlyReport.from, to = this.ranges.hourlyReport.to) {
		this.ranges.hourlyReport.from = from;
		this.ranges.hourlyReport.to = to;
		this._store.dispatch(ReportsActions.getHourlySales({ from, to }));
	}

	getOperatingReport(from = this.ranges.operatingReport.from, to = this.ranges.operatingReport.to) {
		this.ranges.operatingReport.from = from;
		this.ranges.operatingReport.to = to;
		this._store.dispatch(ReportsActions.getOperatingReport({ from, to }));
	}

	getWeeklyReport(weekNumber = this.ranges.weeklyReport) {
		this.ranges.weeklyReport = weekNumber;
		this._store.dispatch(ReportsActions.getWeeklyReport({ weekNumber }));
	}

	getProductsReport(from = this.ranges.productsReport.from, to = this.ranges.productsReport.to) {
		this.ranges.productsReport.from = from;
		this.ranges.productsReport.to = to;
		this._store.dispatch(ReportsActions.getProductsReport({ from, to }));
	}

	getDiscountsReport(from = this.ranges.productsReport.from, to = this.ranges.productsReport.to) {
		this.ranges.productsReport.from = from;
		this.ranges.productsReport.to = to;
		this._store.dispatch(ReportsActions.getDiscountsReport({ from, to }));
	}

	switchMultiSeleciton(enabler: boolean) {
		this._store.dispatch(MyRestaurantsActions.enableMultiSelection({ enabler }));
	}

	getEmployeeMeals(from?: DateTime, to?: DateTime) {
		this.employeeMealsService.getEmployeeMealsReport();
	}

	getSynthesisReport(from?: DateTime, to?: DateTime) {
		this.synthesisReportService.fetch(from, to);
	}
}
