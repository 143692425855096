import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';

import { DkDataTableModule, SharedMaterialModule } from '@libs/dash/shared';
import { SYNTHESIS_REPORT_FACADE } from './facade/synthesis-report-facade.injection.token';
import { SynthesisReportFacade } from './facade/synthesis-report-facade.service';
import { GeneralSynthesisReportComponent } from './partial-components/general-synthesis/general-synthesis-report.component';
import { VatSynthesisReportComponent } from './partial-components/vat-synthesis/vat-synthesis-report.component';
import { SynthesisReportRoutingModule } from './synthesis-report-routing.module';
import { SynthesisReportComponent } from './view-component/synthesis-report.component';

@NgModule({
	declarations: [SynthesisReportComponent, GeneralSynthesisReportComponent, VatSynthesisReportComponent],
	imports: [
		CommonModule,
		SynthesisReportRoutingModule,
		ReactiveFormsModule,
		SharedMaterialModule,
		MatSortModule,
		MatMenuModule,
		DkDataTableModule,
	],
	providers: [
		{
			provide: SYNTHESIS_REPORT_FACADE,
			useClass: SynthesisReportFacade,
		},
	],
	bootstrap: [],
	exports: [SynthesisReportComponent],
})
export class SynthesisReportModule {}
