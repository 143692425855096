import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardItemDetailComponent } from './view-component/dashboard-item-detail.component';

const routes: Routes = [
	{
		path: '',
		component: DashboardItemDetailComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class DashboardItemDetailRoutingModule {}
