import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
	selector: 'dk-data-table-edit',
	templateUrl: './data-table-edit.component.html',
	styleUrls: ['./data-table-edit.component.scss'],
})
export class AccountingDataTableEditComponent implements OnInit, OnChanges {
	@Input() dataSource = [];
	@Input() i18n: Record<string, any>;
	@Input() toggleEdit = false;
	@Input() columnsToEdit = []; // index of columns to edit;
	@Input() total = false;
	@Output() saveData = new EventEmitter<any>();

	columns: Array<any> = [];
	editing: boolean[][];
	toSave = [];
	saved = {};

	ngOnInit(): void {
		this.columns = Object.keys(!!this.dataSource[0] && this.dataSource[0]);
		this.editing = Array.from({ length: this.dataSource.length }, (_) => new Array(this.columns.length).fill(false));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes.toggleEdit) {
			if (changes.toggleEdit.currentValue) {
				this.columnsToEdit.forEach((index) => {
					this.editing.forEach((row) => (row[index] = true));
				});
			} else if (changes.toggleEdit.previousValue && !changes.toggleEdit.currentValue) {
				this.columnsToEdit.forEach((index) => {
					this.editing.forEach((row) => (row[index] = false));
				});
				this.save();
			}
		}
	}

	edit(row, col) {
		if (this.toggleEdit) {
			this.editing[row][col] = true;
		}
	}

	change(row: number, col, event) {
		this.toSave[row] = parseFloat(event.target['value']);
		// this.save();
	}

	save() {
		this.dataSource.forEach((e, i) => {
			e['validated_value'] = this.toSave[i] == undefined || isNaN(this.toSave[i]) ? e['validated_value'] : this.toSave[i];
		});
		this.saveData.emit(this.dataSource);
	}
}
