export const paths = {
	pageNotFound: 'page-not-found',

	/** Root of Dynamic State Services */
	dss: 'dss',

	/** Root of Price Management */
	priceManagement: 'price-management',

	/**
	 * Production/Prediction Management
	 */
	pms: 'production-management',

	/** Basic navigation */
	navigation: 'navigation',

	/** Authentication **/
	login: 'login',
	logout: 'logout',
	unauthorized: 'unauthorized',
};
