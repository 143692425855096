@if (viewData$ | async; as viewData) {
	<h4>{{ viewData.i18n.title }}</h4>
	@if (viewData?.customerWaitingTime?.averageWaitingTime) {
		<dk-gauge [value]="viewData?.customerWaitingTime?.averageWaitingTime"></dk-gauge>
	}
	@if (viewData?.customerWaitingTime?.tableData) {
		<table>
			<thead>
			<tr>
				<th>{{ viewData.i18n.tableHeaderCells.channel }}</th>
				<th>{{ viewData.i18n.tableHeaderCells.count }}</th>
				<th>{{ viewData.i18n.tableHeaderCells.meanservicetime }}</th>
			</tr>
			</thead>
			<tbody>
				@for (row of viewData.customerWaitingTime.tableData; track row) {
					<tr>
						<td>{{ viewData.i18n.channels[row.label] }}</td>
						@for (collumn of row.collumns; track collumn) {
							<td>
								{{ collumn }}
							</td>
						}
					</tr>
				}
			</tbody>
		</table>
	}
} @else {
	<div class="dk-in-progress-spinner-container">
		<mat-spinner></mat-spinner>
	</div>
}

