@if (localisedTexts$ | async; as i18n) {
	<div class="dk-picker" [class.single-display]="singleDaySelection()">
		<mat-form-field appearance="fill" subscriptSizing="dynamic">
			<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
				<input [placeholder]="i18n.startDate" formControlName="from" matStartDate [hidden]="singleDaySelection()"/>
				<input [placeholder]="i18n.endDate" formControlName="to" matEndDate [class.single-display]="singleDaySelection()"/>
			</mat-date-range-input>
			<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
			<mat-date-range-picker #picker displayMode="single">
				<mat-date-range-picker-actions>
					<button mat-button matDateRangePickerCancel>{{ i18n.cancel }}</button>
					<button (click)="setPeriod('period')" color="primary" mat-raised-button matDateRangePickerApply>
						{{ i18n.apply }}
					</button>
				</mat-date-range-picker-actions>
			</mat-date-range-picker>
		</mat-form-field>
		<button class="today-btn" (click)="setPeriod('today')" mat-button>{{ i18n.today }}</button>
		<button (click)="setPeriod('week')" mat-button>{{ i18n.week }}</button>
		<button (click)="setPeriod('month')" mat-button>{{ i18n.month }}</button>
		@if ((isLoading | async) && isProcessing) {
			<mat-spinner class="d-inline" diameter="20"></mat-spinner>
		}
	</div>
	<div class="synth-reports">
		@if (synthesisEnabled) {
			<dk-general-synthesis [i18n]="i18n"></dk-general-synthesis>
		}
		<dk-vat-synthesis [i18n]="i18n"></dk-vat-synthesis>
	</div>
}
