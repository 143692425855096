export enum SortingOrdersAlgorithm {
	/**
	 * You should not ever need this
	 */
	UNKNOWN = 'UNKNOWN',

	/**
	 * This is the Default value.
	 * Also this value is the only valid one for Dependent ORBp.
	 *
	 * It keeps the chronological order in which the Orders have been accepted by ORBp based on Ring Messages.
	 * In other words: There is no sorting :)
	 */
	BY_VALIDATION_TIME = 'BY_VALIDATION_TIME',

	/**
	 * Orders which were paid first, are shown first in ORBp queue.
	 * The rest of the queue (the unpaid Orders) are kept in chronological sorting as in BY_VALIDATION_TIME.
	 */
	BY_PAID_TIME = 'BY_PAID_TIME',

	/**
	 * 'Creation time' is a bit misleading name, because we are using property Order.firstProductDate.
	 * The reason is that POS creates instance of new Order immediately after previous Order has been paid or dismissed.
	 */
	BY_CREATION_TIME = 'BY_CREATION_TIME',
}

export const DefaultSortingOrdersAlgorithm = SortingOrdersAlgorithm.BY_VALIDATION_TIME;
