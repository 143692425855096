import { SalesChannel } from "../../enums";

/**
 * SalesChannels which can be split accross multiple ORBps based on specific devices.
 *
 * For example:
 * Orders from Kiosk 1 will be displayed on ORBp 2,
 * Orders from Kiosk 2 will be displayed on ORBp 4.
 * etc ...
 */
export const SalesChannelsWithDeviceSpecificConfiguration = [SalesChannel.KIOSK, SalesChannel.CASH_MACHINE];

export const canHaveDeviceSpecificConfiguration = (salesChannel: SalesChannel): boolean => {
	return SalesChannelsWithDeviceSpecificConfiguration.includes(salesChannel);
}
