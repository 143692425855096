@if (!!texts && !!openingTimes) {
	<h4>
		{{ texts.title }}
	</h4>
	<dk-time-range-select
		[label]="texts.sections['week-days']"
		[openingTime]="getOpeningTime('monday')"
		[dayOverflowHours]="getNextDayOpeingHour('monday')"
		[texts]="texts.labels"
		(timeRangeChange)="emitNewTimeRange($event, 'monday')"
	>
	</dk-time-range-select>
	<dk-time-range-select
		[label]="texts.sections.saturday"
		[openingTime]="getOpeningTime('saturday')"
		[dayOverflowHours]="getNextDayOpeingHour('saturday')"
		[texts]="texts.labels"
		(timeRangeChange)="emitNewTimeRange($event, 'saturday')"
	>
	</dk-time-range-select>
	<dk-time-range-select
		[label]="texts.sections.sunday"
		[openingTime]="getOpeningTime('sunday')"
		[dayOverflowHours]="getNextDayOpeingHour('sunday')"
		[texts]="texts.labels"
		(timeRangeChange)="emitNewTimeRange($event, 'sunday')"
	>
	</dk-time-range-select>
}
