<dk-page-container [datePicker]="false" [editButton]="false" [isLoading]="false" [title]="(viewData$ | async)?.texts.title">
	@if (viewData$ | async; as viewData) {
		<div class="dki-dashboard-item-card">
			<h5>{{ translateParentName(viewData.sectionTitle, viewData.parentNames) }}</h5>
			<h4>{{ translateRowLabel(viewData.itemName, viewData.rowLabels) }}</h4>
			<table>
				<tbody>
					@for (row of viewData.tableRows; track row) {
						<tr>
							@for (cell of row; track cell) {
								<td>
									{{ translateRowLabel(cell, viewData.rowLabels) | stringToNumber }}
								</td>
							}
						</tr>
					}
				</tbody>
			</table>
			<div class="dk-form-action-buttons" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center stretch">
				<button mat-raised-button [routerLink]="['/' + viewData.currentLanguage + '/todays-overview']">
					{{ viewData.texts.buttons.close }}
				</button>
			</div>
		</div>
	}
</dk-page-container>
