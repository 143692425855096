import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationModuleSections } from '@libs/dash/core/entity';
import { AuthGuard, FeatureGuard, PermissionAccountingGuard, PermissionGuard } from '@libs/dash/guards';
import { DashAppContentComponent } from './dash-app-content.component';

const routes: Routes = [
	{
		path: '',
		component: DashAppContentComponent,
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: ApplicationModuleSections.TOS,
			},
			//{
			//	path: `${ApplicationModuleSections.GLOBAL_OVERVIEW}`,
			//	loadChildren: () => import('@libs/dash/features/v1').then((m) => m.GlobalOverviewModule),
			//	canActivate: [AuthGuard, PermissionGuard],
			//},
			// {
			// 	path: `${ApplicationModuleSections.SALES_REPORTS}`,
			// 	loadChildren: () => import('@libs/dash/features/v1').then((m) => m.ReportsModule),
			// 	canActivate: [AuthGuard, FeatureGuard, PermissionGuard],
			// 	data: { canActivateFeatureFlag: 'reports' },
			// },
			{
				path: `${ApplicationModuleSections.DETAIL}`,
				loadChildren: () => import('@libs/dash/features/v1').then((m) => m.DashboardItemDetailModule),
				canActivate: [AuthGuard, PermissionGuard],
			},
			{
				path: `${ApplicationModuleSections.SETTINGS}`,
				loadChildren: () => import('@libs/dash/features/v1').then((m) => m.SettingsModule),
				canActivate: [AuthGuard, PermissionGuard],
			},
			{
				path: `${ApplicationModuleSections.HISTORY_LOGS}`,
				loadComponent: () => import('@libs/dash/features/v2').then((c) => c.FinanceReportsComponent),
				canActivate: [AuthGuard, FeatureGuard, PermissionGuard],
				data: { canActivateFeatureFlag: 'history' },
			},
			{
				path: `${ApplicationModuleSections.DTT_EXPORT}`,
				loadChildren: () => import('@libs/dash/features/v1').then((m) => m.DttExportModule),
				canActivate: [AuthGuard, PermissionGuard],
				data: { canActivateFeatureFlag: 'dtt' },
			},
			{
				path: `${ApplicationModuleSections.TOS}`,
				loadComponent: () => import('@libs/dash/features/v2').then((c) => c.TimeOfServiceComponent),
				canActivate: [AuthGuard, PermissionGuard],
				data: { canActivateFeatureFlag: 'tos' },
		},
			{
				path: `${ApplicationModuleSections.SALES_REPORTS}`,
				loadComponent: () => import('@libs/dash/features/v2').then((c) => c.SalesReportsComponent),
				canActivate: [AuthGuard, PermissionGuard],
				data: { canActivateFeatureFlag: 'reports' },
			},
			{
				path: `${ApplicationModuleSections.ACCOUNTING}`,
				loadChildren: () => import('@libs/dash/features/v1').then((m) => m.AccountingModule),
				canActivate: [AuthGuard, PermissionAccountingGuard],
				data: { canActivateFeatureFlag: 'accounting' },
			},
			{
				path: `${ApplicationModuleSections.PERFORMANCE}`,
				loadChildren: () => import('@libs/dash/features/v1').then((m) => m.PerformanceModule),
				canActivate: [AuthGuard, PermissionGuard],
				data: { canActivateFeatureFlag: 'performance' },
			},
			{
				path: `${ApplicationModuleSections.DTT_EXPORT_SOURCED}`,
				loadChildren: () => import('@libs/dash/features/v1').then((m) => m.DttExportModule),
				canActivate: [],
				data: { canActivateFeatureFlag: 'dtt' },
			},
			{
				path: `${ApplicationModuleSections.PERFORMANCE_SOURCED}`,
				loadChildren: () => import('@libs/dash/features/v1').then((m) => m.PerformanceModule),
				canActivate: [],
				data: { canActivateFeatureFlag: 'performance' },
			},
			{
				path: `${ApplicationModuleSections.ERROR}`,
				loadChildren: () => import('@libs/dash/shared').then((m) => m.ErrorModule),
				canActivate: [AuthGuard],
			},
			{
				path: `${ApplicationModuleSections.SETTINGS}`,
				loadChildren: () => import('@libs/dash/features/v1').then((m) => m.SettingsModule),
				canActivate: [AuthGuard, PermissionGuard],
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class DashAppContentRoutingModule {}
