/**
 * Hard-coded based on data from productFamilies in the Quick bigdatas.json (type BKBigDatas)
 */
export enum QUICK_PRODUCT_FAMILIES {
	/**
	 * Hot drinks
	 */
	Boissons_chaudes = '27',
	/**
	 * Cold drinks
	 */
	Boissons_froides = '28',
	Burgers = '29',
	Desserts = '30',
	PastryKitchen = '33',
	IceCreams = '31',
	Toys = '34',
	Salads = '32',
	ExtraSauces = '36',
	FriedProducts = '35',
	Fries = '37',
}
