@if (viewData$ | async; as viewData) {
	<h4>{{ viewData.i18n.title }}</h4>
	@if (viewData.revenueProgress) {
		<table>
			<tr>
				<td>{{ viewData.i18n.table.todaysTotal }}</td>
				<td>{{ isNaN(viewData?.revenueProgress?.todaysTotalRevenue) ? 'N/A' : viewData?.revenueProgress?.todaysTotalRevenue | number: '1.2-2' }}
					&euro;
				</td>
			</tr>
			<tr>
				<td>{{ viewData.i18n.table.longTermAverage }}</td>
				<td>{{ isNaN(viewData?.revenueProgress?.averageTotalRevenue) ? 'N/A' : viewData?.revenueProgress?.averageTotalRevenue | number: '1.2-2' }}
					&euro;
				</td>
			</tr>
		</table>
	}
	<dk-graph [dataSource]="viewData.revenueProgress"></dk-graph>
	<div class="dk-line-chart-legend">
		<div class="dk-line-chart-legend-item">
			<div class="dk-line-chart-legend-stripe" [style.backgroundColor]="'#7aa3e5'"></div>
			<div class="dk-line-chart-legend-label">{{ viewData.i18n.legend.todaysTotal }}</div>
		</div>
		<div class="dk-line-chart-legend-item">
			<div class="dk-line-chart-legend-stripe" [style.backgroundColor]="'#CFC0BB'"></div>
			<div class="dk-line-chart-legend-label">{{ viewData.i18n.legend.longTermAverage }}</div>
		</div>
	</div>
} @else {
	<div class="dk-in-progress-spinner-container">
		<mat-spinner></mat-spinner>
	</div>
}

