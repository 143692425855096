import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'dk-safe-deposit',
	templateUrl: './safe-deposit.component.html',
	styleUrls: ['./safe-deposit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SafeDepositComponent {
	public localisedTexts$ = this._translocoService.selectTranslateObject('safe-deposit');

	constructor(private readonly _translocoService: TranslocoService) {}
}
