import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { CommonLayoutModule } from "@libs/shared/modules/common-layout";
import { DashAppContentComponent } from "./dash-app-content.component";
import { DashAppContentRoutingModule } from "./dash-app-content.routing.module";

@NgModule({
  declarations: [DashAppContentComponent],
  imports: [
    CommonModule,
		RouterModule,
		DashAppContentRoutingModule,
		CommonLayoutModule
  ]
})
export class DashAppContentModule { }
