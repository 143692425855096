import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import type { ITranslateLoader } from '../models';

interface ITranslationResource {
	suffix: string;
	prefix: string;
}

const Params = new InjectionToken<ITranslateLoader>('Translation loader files');

@Injectable({
	providedIn: 'root',
})
export class TranslateLoaderService implements TranslocoLoader {
	private resources: ITranslationResource[];

	constructor(
		private http: HttpClient,
		@Inject(Params) translationParams: ITranslateLoader
	) {
		this.resources = translationParams.files.map((suffix) => ({ prefix: 'assets/l10n', suffix }));
	}

	getTranslation(lang: string): Observable<{} | Translation> {
		const requests = this.createRequests(lang);

		return forkJoin(requests).pipe(
			map((data) => {
				const result = data.reduce((res, acc) => ({ ...acc, ...res }));
				return result;
			})
		);
	}

	private createRequests(lang: string): Observable<Translation | {}>[] {
		return this.resources.map((resource) => {
			const path = `${resource.prefix}/${lang}/${resource.suffix}.json`;
			return this.http.get<Translation>(path).pipe(
				catchError((err) => {
					console.error('Could not find translation file:', err, path); // tslint:disable-line

					return of({});
				})
			);
		});
	}
}
