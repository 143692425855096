import { MAX_ERROR_STATE_VALUES } from '@libs/shared/models';
import { createReducer, on } from '@ngrx/store';
import { append, assoc, compose, curry, dropLast, ifElse, lensPath, map, propEq, set, when } from 'ramda';
import { IErrorRootState } from '../interfaces';
import { MarkAllAsSeen, MarkAsSeen, MarkErrorAsSeenType, NewErrorAppeared, StoreErrorType } from './actions';

export const initialState = {
	data: [],
};

const insertNewError = (state: IErrorRootState, action: StoreErrorType) => {
	return <IErrorRootState>compose(
		set(lensPath(['data']), append(action.error, state.data)),
		set(
			lensPath(['data']),
			ifElse(
				() => state.data.length === MAX_ERROR_STATE_VALUES,
				() => dropLast(1, state.data),
				() => state.data
			)
		)
	)(state);
};

const markAsSeen = (state: IErrorRootState, action: MarkErrorAsSeenType) => {
	const alter = curry((seen, id, items) => map(when(propEq('id', id), assoc('seen', seen)), items));
	return <IErrorRootState>compose(set(lensPath(['data']), alter(true, action.id, state.data)))(state);
};

const markAllAsSeen = (state: IErrorRootState) => {
	return <IErrorRootState>compose(set(lensPath(['data']), []))(state);
};

export const reducers = createReducer(
	initialState,
	on(NewErrorAppeared, insertNewError),
	on(MarkAsSeen, markAsSeen),
	on(MarkAllAsSeen, markAllAsSeen)
);
