import { SalesChannelOption, SalesChannelOptionDependency } from '@bk/price-management-common';

// We know that this dependency will not change, so it can be hard wired here.
// These options can not be enabled, unless EatIn is enabled, because they all handled inside the Restaurant.
export const ConfigurationOfSalesChannelOptionsDependency: SalesChannelOptionDependency[] = [
	{
		optionName: SalesChannelOption.WaitingAtTable,
		dependsOn: SalesChannelOption.EatIn
	},
	{
		optionName: SalesChannelOption.TableServiceInside,
		dependsOn: SalesChannelOption.EatIn
	},
	{
		optionName: SalesChannelOption.TableServiceTerrasse,
		dependsOn: SalesChannelOption.EatIn
	}
];
