import { Component } from '@angular/core';
import { ActionButtons } from '@libs/dash/shared';

import { RootStoreState, RouterStoreSelectors } from '@libs/dash/store';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'dk-setup-completed',
	templateUrl: './setup-completed.component.html',
	styleUrls: ['./setup-completed.component.scss'],
})
export class SetupCompletedComponent {
	actionButtons$: Observable<ActionButtons> = combineLatest([
		this._store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam)),
		this._translocoService.selectTranslateObject('setup-completed'),
	]).pipe(
		map(([currentLanguage, localisedSetupCompletedTexts]) => {
			return {
				positive: {
					label: localisedSetupCompletedTexts.buttons.continue,
					disabled: false,
					routerLink: `/account/login`,
				},
			};
		})
	);

	localisedTexts$ = this._translocoService.selectTranslateObject('setup-completed');

	constructor(
		private _store: Store<RootStoreState>,
		private _translocoService: TranslocoService
	) {}
}
