import { Injectable } from '@angular/core';
import * as api from '@dki/api-client';

import {
	AuthStoreActions,
	MyRestaurantsActions,
	MyRestaurantsSelectors,
	ReportsActions,
	ReportsSelectors,
	RootStoreState,
	RouterStoreSelectors,
} from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';

import { DttExportServiceProvider } from './dtt-export.facade.provider.interface';

@Injectable()
export class DttExportFacade implements DttExportServiceProvider {
	currentLanguage$ = this._store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam));
	myDefaultRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant));
	restaurantIsOpened$ = this._store.pipe(select(MyRestaurantsSelectors.restaurantIsOpened));
	selectedRestaurants$ = this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurants));

	dttReport$ = this._store.pipe(select(ReportsSelectors.dttReport));

	isLoading$ = this._store.pipe(select(MyRestaurantsSelectors.getIsLoading));
	isLoadingDTT$ = this._store.pipe(select(ReportsSelectors.isLoadingDttReport));

	range = { from: DateTime.now(), to: DateTime.now() };

	constructor(private _store: Store<RootStoreState>) {}

	fetch(
		from: DateTime = this.range.from,
		to: DateTime = this.range.to,
		aggregation: api.DkiBeClickhouseAnalyticsSosDttReportAggregation[] = [api.DkiBeClickhouseAnalyticsSosDttReportAggregation.Restaurant],
		weekday: api.WeekDay[] = [api.WeekDay.Monday],
		source: api.Source
	) {
		this.range.from = from;
		this.range.to = to;
		this._store.dispatch(ReportsActions.getDTTReport({ from, to, aggregation, weekday, source }));
	}

	fetchRestaurants() {
		this._store.dispatch(AuthStoreActions.getCurrentUserProfileSourced());
	}

	switchMultiSeleciton(enabler: boolean) {
		this._store.dispatch(MyRestaurantsActions.enableMultiSelection({ enabler }));
	}
}
