import { animate, state, style, transition, trigger } from '@angular/animations';
import { ANIMATION_HALF_SECOND_TIME, CUBIC_BEZIER_ANIMATION, MENU_TOGGLE_STATE } from './menu-animations.const';

export const MENU_TOGGLE_TRIGGER = 'expandMenuTrigger' as const;
export const MENU_TEXT_TRIGGER = 'textMenuTrigger' as const;

export const MENU_TOGGLE_ANIMATION = trigger(MENU_TOGGLE_TRIGGER, [
	state(
		MENU_TOGGLE_STATE.CLOSED,
		style({
			gridTemplateColumns: '80px 1fr',
		})
	),
	state(
		MENU_TOGGLE_STATE.OPEN,
		style({
			gridTemplateColumns: '260px 1fr',
		})
	),
	transition(`${MENU_TOGGLE_STATE.OPEN} <=> ${MENU_TOGGLE_STATE.CLOSED}`, [
		animate(`${ANIMATION_HALF_SECOND_TIME} ${CUBIC_BEZIER_ANIMATION}`),
	]),
]);

export const MENU_TEXT_ANIMATION = trigger(MENU_TEXT_TRIGGER, [
	transition(`:enter`, [
		style({ opacity: '0' }),
		animate(
			`${ANIMATION_HALF_SECOND_TIME} ${CUBIC_BEZIER_ANIMATION}`,
			style({
				opacity: '1',
			})
		),
	]),
	transition(`:leave`, [
		style({ opacity: '1' }),
		animate(
			`${ANIMATION_HALF_SECOND_TIME} ${CUBIC_BEZIER_ANIMATION}`,
			style({
				opacity: '0',
			})
		),
	]),
]);
