import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';

import { SharedMaterialModule } from '@libs/dash/shared';
import { SAFE_DEPOSIT_FACADE } from './facade/safe-deposit-facade.injection.token';
import { SafeDepositFacade } from './facade/safe-deposit-facade.service';
import { SafeDepositReportsComponent } from './partial-components/safe-deposit-reports/safe-deposit-reports.component';
import { SafeDepositRoutingModule } from './safe-deposit-routing.module';
import { SafeDepositComponent } from './view-component/safe-deposit.component';

@NgModule({
	imports: [CommonModule, SafeDepositRoutingModule, SharedMaterialModule, ReactiveFormsModule, MatMenuModule],
	declarations: [SafeDepositComponent, SafeDepositReportsComponent],
	providers: [
		{
			provide: SAFE_DEPOSIT_FACADE,
			useClass: SafeDepositFacade,
		},
	],
	exports: [SafeDepositComponent],
	bootstrap: [],
})
export class SafeDepositModule {}
