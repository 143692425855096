import { Pipe, PipeTransform } from '@angular/core';

const BUSINESS_DAY_START_HOUR = 5;

@Pipe({ standalone: true, name: 'toMins' })
export class SecondsToMins implements PipeTransform {
	transform(value: number) {
		if (!value || value < 0) return '-';
		let mins: string | number = Math.floor(value / 60);
		let seconds: string | number = Math.floor(value % 60);
		if (mins < 10) mins = `0${mins}`;
		if (seconds < 10) seconds = `0${seconds}`;
		return `${mins}:${seconds}`;
	}
}
