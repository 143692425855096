<h5>{{ label }}</h5>

<form [formGroup]="rangeFormControl">
	<fieldset [formGroupName]="'from'">
		<legend>{{ texts.from }}:</legend>

		<mat-form-field>
			<mat-label>{{ texts.hours }}:</mat-label>

			<mat-select [formControlName]="'hours'">
				@for (hour of hoursOptions; track trackByIndex(index); let index = $index) {
					<mat-option [value]="hour.value">
						{{ hour.label }}
					</mat-option>
				}
			</mat-select>
		</mat-form-field>

		<mat-form-field>
			<mat-label>{{ texts.minutes }}:</mat-label>

			<mat-select [formControlName]="'minutes'">
				@for (minute of minutes; track minute) {
					<mat-option [value]="minute">
						{{ minute }}
					</mat-option>
				}
			</mat-select>
		</mat-form-field>
	</fieldset>

	<fieldset [formGroupName]="'to'">
		<legend>{{ texts.to }}:</legend>

		<mat-form-field>
			<mat-label>{{ texts.hours }}:</mat-label>

			<mat-select [formControlName]="'hours'">
				@for (hour of hoursOptions; track hour) {
					<mat-option [value]="hour.value" [disabled]="isLessThanFromHours(hour.value)">
						{{ hour.label }}
					</mat-option>
				}

				@for (overflowHour of overflowHours; track overflowHour) {
					<mat-option [value]="overflowHour.value">
						{{ overflowHour.label }}
					</mat-option>
				}
			</mat-select>
		</mat-form-field>

		<mat-form-field>
			<mat-label>{{ texts.minutes }}:</mat-label>

			<mat-select [formControlName]="'minutes'">
				@for (minute of minutes; track minute) {
					<mat-option [value]="minute">
						{{ minute }}
					</mat-option>
				}
			</mat-select>
		</mat-form-field>
	</fieldset>
</form>
