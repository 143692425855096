@if (viewData$ | async; as viewData) {
	@if (isLoading$ | async) {
		<div class="dk-on-progress-overlay">
			<div class="dk-on-progress-spinner-container">
				<mat-spinner></mat-spinner>
			</div>
		</div>
	} @else {
		<div class="dk-picker" [class.single-display]="singleDaySelection()">
			<ng-content></ng-content>
			<mat-form-field appearance="fill" subscriptSizing="dynamic">
				<mat-label>{{ viewData.i18n.chooseDates }}</mat-label>
				<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
					<input [placeholder]="viewData.i18n.startDate" formControlName="from" matStartDate [hidden]="singleDaySelection()"/>
					<input [placeholder]="viewData.i18n.endDate" formControlName="to" matEndDate [class.single-display]="singleDaySelection()"/>
				</mat-date-range-input>
				<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
				<mat-date-range-picker #picker>
					<mat-date-range-picker-actions>
						<button mat-button matDateRangePickerCancel>{{ viewData.i18n.cancel }}</button>
						<button (click)="setPeriod('period')" color="primary" mat-raised-button matDateRangePickerApply>
							{{ viewData.i18n.apply }}
						</button>
					</mat-date-range-picker-actions>
				</mat-date-range-picker>
			</mat-form-field>
			<button (click)="setPeriod('today')" mat-button>{{ viewData.i18n.today }}</button>
			<button (click)="setPeriod('week')" mat-button>{{ viewData.i18n.week }}</button>
			<button (click)="setPeriod('month')" mat-button>{{ viewData.i18n.month }}</button>
			@if (isFetchingDeposits$ | async) {
				<mat-spinner class="d-inline" diameter="20"></mat-spinner>
			}
			<button mat-button [matMenuTriggerFor]="synthMenu" class="export-sessions-synth">{{ viewData.i18n.download }}</button>
			<mat-menu #synthMenu="matMenu" yPosition="above" xPosition="before">
				<button id="csv-synth-report" mat-menu-item (click)="download('csv')">{{ viewData.i18n.downloadCSV }}</button>
				<button id="pdf-synth-report" mat-menu-item (click)="download('pdf')">{{ viewData.i18n.downloadPDF }}</button>
			</mat-menu>
		</div>
		@if (data?.length) {
			<table class="mat-table mat-elevation-z8" id="deposit-summary">
				<thead>
				<tr>
					<th class="left-gray">{{ viewData.i18n[selectedEmployees] }}</th>
					<th class="left-gray text-center">{{ viewData.i18n.real_revenue }}</th>
					@for (method of viewData.data.labels; track method) {
						<th colspan="3" class="left-gray">{{ viewData.i18n.payment_methods[method] || method }}</th>
					}
				</tr>
				<tr class="second-header">
					<td class="left-gray empl">
						<select [(ngModel)]="selectedEmployees" #employees (change)="onFilterChange(employees.value)">
							<optgroup label=""></optgroup>
							<option selected="selected" value="manager">{{ viewData.i18n.manager }}</option>
							<optgroup label=""></optgroup>
							<option value="employee">
								{{ viewData.i18n.employee }}
							</option>
						</select>
					</td>
					<td class="left-gray whiten"></td>
					<!-- sales channels ahead -->
					@for (method of viewData.data.labels; track method) {
						<td class="text-center left-gray whiten">
							<div class="has-filter">
								{{ viewData.i18n.declared_amount }}
								<div>
									<mat-icon (click)="setSorting(method, 'real', false)">arrow_drop_up</mat-icon>
									<mat-icon (click)="setSorting(method, 'real', true)">arrow_drop_down</mat-icon>
								</div>
							</div>
						</td>
						<td class="text-center">
							<div class="has-filter">
								{{ viewData.i18n.gap }}
								<div class="has-filter">
									<mat-icon (click)="setSorting(method, 'gap', false)">arrow_drop_up</mat-icon>
									<mat-icon (click)="setSorting(method, 'gap', true)">arrow_drop_down</mat-icon>
								</div>
							</div>
						</td>
						<td class="text-center">%</td>
					}
					<!-- end of sales channel loop -->
				</tr>
				<!--<tr class="filter-header"></tr>-->
				</thead>
				<tbody>
					@for (row of data; track row) {
						<tr>
							<td>{{ row.staff }}</td>
							<td class="left-gray">{{ row.total | currency: 'EUR':'symbol':'1.2-2' }}</td>
							@for (method of viewData.data.labels; track method) {
								<td class="left-gray bg-light-blue">{{ row.by_label[method]?.amount.real || 0 | currency: 'EUR':'symbol':'1.2-2' }}</td>
								<td class="left-gray">{{ row.by_label[method]?.amount.gap || 0 | currency: 'EUR':'symbol':'1.2-2' }}</td>
								<td class="left-gray">{{ (row.by_label[method]?.amount.real || 0) / row.total | percent: '1.2-2' }}</td>
							}
						</tr>
					}
				<tr class="total-line">
					<td>{{ viewData.i18n.total }}</td>
					<td class="left-gray">{{ totalRevenue() | currency: 'EUR':'symbol':'1.2-2' }}</td>
					@for (method of viewData.data.labels; track method) {
						<td class="left-gray">{{ total(method, 'real', data) | currency: 'EUR':'symbol':'1.2-2' }}</td>
						<td class="left-gray">{{ total(method, 'gap', data) | currency: 'EUR':'symbol':'1.2-2' }}</td>
						<td>{{ (total(method, 'real', data) || 0) / totalRevenue() | percent: '1.2-2' }}</td>
					}
				</tr>
				</tbody>
			</table>
		}
	}
}
