import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PageContainerModule } from '@libs/dash/shared';
import { AnalyzeHistoryModule } from '../analyze-history/analyze-history.module';
import { TodaysOverviewModule } from '../todays-overview/todays-overview.module';
import { GlobalOverviewRoutingModule } from './global-overview-routing.module';
import { GlobalOverviewComponent } from './view-component/global-overview.component';

@NgModule({
	declarations: [GlobalOverviewComponent],
	imports: [CommonModule, GlobalOverviewRoutingModule, PageContainerModule, TodaysOverviewModule, AnalyzeHistoryModule],
	providers: [],
	bootstrap: [],
})
export class GlobalOverviewModule {}
