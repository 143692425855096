import { createReducer, on } from '@ngrx/store';
import { compose, lensPath, set } from 'ramda';
import { IAllergensState } from '../interfaces';
import { AllergensType, CleanAllergensState, UpdateAvailableAllergensState, UpdateUsedAllergensState } from './actions';

export const initialState: IAllergensState = {
	available: {},
	used: {},
};

const cleanAllergens = (state: IAllergensState) => {
	return <IAllergensState>compose(set(lensPath(['used']), initialState))(state);
};

const updateAvailableAllergens = (state: IAllergensState, action: AllergensType) => {
	return <IAllergensState>compose(set(lensPath(['available']), action.allergens))(state);
};

const updateUsedAllergens = (state: IAllergensState, action: AllergensType) => {
	return <IAllergensState>compose(set(lensPath(['used']), action.allergens))(state);
};

export const reducers = createReducer(
	initialState,
	on(UpdateUsedAllergensState, updateUsedAllergens),
	on(UpdateAvailableAllergensState, updateAvailableAllergens),
	on(CleanAllergensState, cleanAllergens)
);
