import { CurrencyPipe, KeyValue } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { getLocale } from '@libs/dash/core';
import { jsPDF } from 'jspdf';
import { DateTime } from 'luxon';
import { SecondsToMins } from '../../pipes/seconds-to-mins.pipe';
import { exportBKFCSV, getDeliveryTime, objectiveAchieved, total, totalAverage, totalTime } from '../../utils';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'bkf-report',
	templateUrl: './bkf-report-component.html',
	styleUrls: ['./bkf-report-component.scss'],
})
export class BkfReportComponent {
	@Input() viewdata: { dttReport; i18n };
	@Input() dateRange: any;
	@Input() selectedRestaurants;
	@Input() sourceType;
	@Input() goals;
	@Input() dayParts;
	@Input() restaurantName;

	@ViewChild('bkfReport', { static: false }) reportElementRef: ElementRef;

	pipe = new SecondsToMins();
	currencyPipe = new CurrencyPipe(getLocale());

	total = total;
	totalAverage = totalAverage;
	getDeliveryTime = getDeliveryTime;
	totalTime = totalTime;
	objectiveAchieved = objectiveAchieved;

	export(extension: 'CSV' | 'PDF', shouldReturnCsv = false) {
		const header = this.restaurantName ? this.restaurantName : 'Multi_Restaurants';
		const footer = this.restaurantName ? '' : this.selectedRestaurants.map((rest) => rest.name).join(', ');
		let title = String(`SOS_DRIVE_${header}_${DateTime.fromJSDate(this.dateRange.value.from).toFormat('yyyy-MM-dd')}`);
		if (DateTime.fromJSDate(this.dateRange.value.from).toMillis() !== DateTime.fromJSDate(this.dateRange.value.to).toMillis()) {
			title += '_' + DateTime.fromJSDate(this.dateRange.value.to).toFormat('yyyy-MM-dd');
		}
		const type = 'BKF';
		const source_type = this.viewdata.i18n['source_types'][this.sourceType];
		if (extension === 'CSV') {
			exportBKFCSV(title, this.viewdata, type, source_type, footer, shouldReturnCsv, this.currencyPipe, this.pipe);
		} else {
			const source = this.reportElementRef.nativeElement;
			const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true });
			doc.setFontSize(16);
			doc.setFont(undefined, 'bold');
			doc.text(header, 20, 15);
			doc.text('BKF', 285, 15, { align: 'right' });
			const from = DateTime.fromJSDate(this.dateRange.controls.from.value).setLocale(getLocale()).toFormat('yyyy LLL dd'),
				to = DateTime.fromJSDate(this.dateRange.controls.to.value).setLocale(getLocale()).toFormat('yyyy LLL dd');
			const date = from === to ? from : `${from} - ${to}`;
			doc.setFontSize(12);
			doc.setFont(undefined, 'light');
			doc.text(`${date}`, 20, 20);
			doc.text(`${source_type}`, 285, 20, { align: 'right' });
			doc.text(`${footer}`, 20, 200, { align: 'left' });
			doc.html(source, {
				html2canvas: {
					scale: 0.28,
					letterRendering: false,
					ignoreElements: (e) => e.classList.contains('export-buttons'),
				},
				autoPaging: 'text',
				margin: [30, 5, 5, 5],
				windowWidth: 1000,
				width: 900,
				fontFaces: [
					{
						family: 'Roboto',
						src: [
							{
								url: '/assets/fonts/roboto.ttf',
								format: 'truetype',
							},
						],
					},
				],
				callback: (doc) => {
					doc.save(title);
				},
			});
		}
	}

	originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
		return 0;
	};
}
