import { Injectable } from '@angular/core';

import { FeatureMapService } from '@libs/dash/guards';
import {
	HistoricStatisticsActions,
	HistoricStatisticsSelectors,
	MyRestaurantsActions,
	MyRestaurantsSelectors,
	RootStoreState,
	RouterStoreSelectors,
} from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';

import { AnalyzeHistoryServiceProvider } from './analyze-history-facade.provider.interface';

@Injectable()
export class AnalyzeHistoryFacade implements AnalyzeHistoryServiceProvider {
	currentLanguage$ = this._store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam));
	myDefaultRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant));
	ordersByChannel$ = this._store.pipe(select(HistoricStatisticsSelectors.ordersByChannel));
	revenueProgress$ = this._store.pipe(select(HistoricStatisticsSelectors.revenueProgress));
	ordersByProduct$ = this._store.pipe(select(HistoricStatisticsSelectors.ordersByProduct));
	topTenProducts$ = this._store.pipe(select(HistoricStatisticsSelectors.topTenProducts));
	ordersByChannelWithAverages$ = this._store.pipe(select(HistoricStatisticsSelectors.ordersByChannelWithAverages));
	sos$ = this._store.pipe(select(HistoricStatisticsSelectors.sos));
	kioskSos$ = this._store.pipe(select(HistoricStatisticsSelectors.kioskSos));
	channelPermoranceInPercentage$ = this._store.pipe(select(HistoricStatisticsSelectors.channelPermoranceInPercentage));
	channelLoadPerformance$ = this._store.pipe(select(HistoricStatisticsSelectors.channelLoadPerformance));
	customerWaitingTime$ = this._store.pipe(select(HistoricStatisticsSelectors.customerWaitingTimeTableData));
	channelPerformanceTableData$ = this._store.pipe(select(HistoricStatisticsSelectors.channelPerformanceTableData));
	topTenProductsTableData$ = this._store.pipe(select(HistoricStatisticsSelectors.topTenProductsTableData));
	dateRange$ = new BehaviorSubject<{ from: string; to: string }>(undefined);
	range = { from: DateTime.now().startOf('day'), to: DateTime.now().endOf('day') };

	constructor(
		private _store: Store<RootStoreState>,
		private fm: FeatureMapService
	) {}

	fetchDefaultRestaurantOrdersData(from = this.range.from.toISO(), to = this.range.to.toISO()): void {
		this.range.from = DateTime.fromISO(from) as DateTime<boolean>;
		this.range.to = DateTime.fromISO(to) as DateTime<boolean>;

		this.dateRange$.next({ from, to });
		// revenue + customer wait time
		if (this.fm.featureMap.globalOverview.analyseHistory.revenue) {
			this._store.dispatch(HistoricStatisticsActions.getOrdersByTime({ from, to }));
			this._store.dispatch(MyRestaurantsActions.getLongTermOrders());
		}

		// old report?
		// this._store.dispatch(HistoricStatisticsActions.getAverageOrdersAtCurrentTime({ from, to }));

		// top 10 products
		if (this.fm.featureMap?.globalOverview?.analyseHistory?.topTenProducts) {
			this._store.dispatch(HistoricStatisticsActions.getOrdersByProduct({ from, to }));
		}

		// horiz bars
		if (this.fm.featureMap?.globalOverview?.analyseHistory?.ratioChannel) {
			this._store.dispatch(HistoricStatisticsActions.getOrdersByChannel({ from, to }));
		}
		if (this.fm.featureMap?.globalOverview?.analyseHistory?.channelPerformance) {
			this._store.dispatch(
				HistoricStatisticsActions.getOrdersByChannelWithAverages({
					from,
					to,
				})
			);
		}
		// sos
		if (this.fm.featureMap?.globalOverview?.analyseHistory?.drive) {
			this._store.dispatch(HistoricStatisticsActions.getSos({ from, to }));
		}
		// kiosk
		if (this.fm.featureMap?.globalOverview?.analyseHistory?.kiosk) {
			this._store.dispatch(HistoricStatisticsActions.getKioskSos({ from, to }));
		}
	}
}
