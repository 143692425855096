import { inject, Injectable, Injector, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { LANGUAGES } from '../models';

@Injectable()
export class TranslateService {
	private readonly _injector: Injector = inject(Injector);
	constructor(private transloco: TranslocoService) {}

	stream(...args: any): Observable<string | unknown | Record<string, string>> {
		return this.transloco.selectTranslate.apply(this.transloco, args);
	}

	instant(...args: any): string {
		return (this.transloco.translate.apply(this.transloco, args) as string) || '';
	}

	get currentLang(): string {
		return this.transloco.getActiveLang();
	}

	getAvailableLanguages(): string[] {
		return this.transloco.getAvailableLangs() as string[];
	}

	set availableLanguages(languages: LANGUAGES[]) {
		this.transloco.setAvailableLangs(languages);
	}

	get langChange(): Signal<string> {
		return toSignal(this.transloco.langChanges$, { injector: this._injector});
	}
}
