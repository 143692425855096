<div class="card" [ngClass]="classColor">
	<div class="wrapper">
		<div class="status">
			<span class="arrow material-icons">{{ icon }}</span>
			<span class="percent">{{ percent ? (percent | percent) : '-' }}</span>
		</div>
		<div class="text">
			<p class="label">
				{{ label }}
			</p>
			<p class="objective">Objectif {{ objectiveMs | date: 'mm:ss'}} min</p>
		</div>
	</div>
</div>