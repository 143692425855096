@if (i18n$ | async; as i18n) {
	<div>
		@if (isLoading$ | async) {
			<div class="dk-on-progress-overlay">
				<div class="dk-on-progress-spinner-container">
					<mat-spinner></mat-spinner>
				</div>
			</div>
		} @else {
			@if (restaurantIsCurrentlyOpened$ | async) {
				<h4>{{ i18n.texts.title }}</h4>
				<div class="dk-grid" #grid [style]="normalizeGrid(grid)">
					<div class="row">
						@if (featureMap.revenue) {
							<dk-revenue-tile></dk-revenue-tile>
						}
						@if (featureMap.waitingTime) {
							<dk-waiting-time-tile></dk-waiting-time-tile>
						}
						<!-- <div class="dk-grid-tile" *ngIf="featureMap.totalCommands" [style.width]="'calc(' + normalizeTileColspan(1) + ' - 96px)'">
						<h4>{{ i18n.texts.tiles['total-orders'].title }}</h4>
						<dk-vertical-treshold-bar [params]="channelLoadPerformance$ | async"></dk-vertical-treshold-bar>
					</div> -->
						@if (featureMap.ratioChannel) {
							<div class="dk-grid-tile">
								<h4>{{ i18n.texts.tiles['channel-load-ratio'].title }}</h4>
								<dk-horizontal-bars [bars]="channelPermoranceInPercentage$ | async"></dk-horizontal-bars>
							</div>
						}
					</div>
					<div class="row">
						@if (featureMap.channelPerformance) {
							<dk-channel-performance-tile></dk-channel-performance-tile>
						}
						@if (featureMap.topTenProducts) {
							<dk-top-ten-products-tile></dk-top-ten-products-tile>
						}
					</div>
					<div class="row">
						@if (featureMap.drive) {
							<dk-sos-tile></dk-sos-tile>
						}
						@if (featureMap.kiosk) {
							<dk-kiosk-sos-tile></dk-kiosk-sos-tile>
						}
					</div>
				</div>
			} @else {
				<div class="dk-closed-restaurant-warning">
					<div class="dk-closed-restaurant-warning-title">
						<mat-icon> warning_amber</mat-icon>
						<span> {{ i18n.texts.warnings['restaurant-closed'].title }} </span>
					</div>
					<div class="dk-closed-restaurant-warning-content">
						<p>{{ i18n.texts.warnings['restaurant-closed'].message }}</p>
						<p>{{ i18n.texts.warnings['restaurant-closed'].hint }} {{ todaysOpeningHour }} .</p>
						<!-- <a [routerLink]="['/' + i18n.language + '/analyze-history']">
						<mat-icon [svgIcon]="'chart'"></mat-icon>
						<span class="dl-navlink-label">
							{{ i18n.texts.warnings['restaurant-closed'].button }}
						</span>
					</a> -->
					</div>
				</div>
			}
		}
	</div>
}
