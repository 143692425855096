import { ChangeDetectionStrategy, Component, inject, Signal } from '@angular/core';
import { MenuModel, SubMenuModel } from '@libs/shared/models';
import { CommonLayoutService } from '@libs/shared/modules/common-layout';
import { MENU_TEXT_ANIMATION } from '../../animations';

@Component({
	selector: 'merim-common-layout-menu',
	templateUrl: './common-layout-menu.component.html',
	styleUrl: './common-layout-menu.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [MENU_TEXT_ANIMATION],
})
export class CommonLayoutMenuComponent<T> {
	private readonly _commonLayoutService: CommonLayoutService<T> = inject(CommonLayoutService<T>);

	isExpanded: Signal<boolean> = this._commonLayoutService.menuExpanded;
	version: Signal<string> = this._commonLayoutService.version;
	title: Signal<string> = this._commonLayoutService.title;
	menuItems: Signal<MenuModel<T>[]> = this._commonLayoutService.menuItems;

	toggleMenu(): void {
		this._commonLayoutService.toggleMenuExpanded();
	}

	onLogoutClick(): void {
		this._commonLayoutService.logoutSubject.next();
	}

	openSubmenu(submenu: SubMenuModel<T>[]): void {
		console.log('submenu: ', submenu);
	}
}
