@if (viewData$ | async; as viewData) {
	<h4>
		{{ viewData.i18n.title }}
		<mat-icon (click)="download()" class="clickable">file_download</mat-icon>
		<mat-icon (click)="downloadIngredients()" class="clickable">shopping_cart</mat-icon>
	</h4>
	@if (viewData?.topTenProductsTableData?.tableData) {
		<table>
			<thead>
			<tr>
				<th>{{ viewData.i18n.tableHeaderCells.product }}</th>
				<th>{{ viewData.i18n.tableHeaderCells.count }}</th>
				<th>{{ viewData.i18n.tableHeaderCells.value }}</th>
			</tr>
			</thead>
			<tbody>
				@for (row of viewData.topTenProductsTableData.tableData; track row) {
					<tr>
						<td>{{ row.label }}</td>
						@for (collumn of row.collumns; track collumn) {
							<td>
								{{ collumn }}
							</td>
						}
					</tr>
				}
			</tbody>
		</table>
	}
} @else {
	<div class="dk-in-progress-spinner-container">
		<mat-spinner></mat-spinner>
	</div>
}

