export enum LANGUAGES {
	EN = 'en-GB',
	FR = 'fr-FR',
	IT = 'it-IT',
	ES = 'es-ES',
	DE = 'de-DE',
}

export type LanguageCode = LANGUAGES;

export const LANGUAGES_LONG = {
	[LANGUAGES.EN]: 'English',
	[LANGUAGES.FR]: 'Français',
};
