@if (localisedTexts$ | async; as texts) {
	<form [formGroup]="logiForm" data-cy="loginForm">
		<legend>
			{{ texts.title }}
		</legend>
		<mat-form-field>
			<mat-label>
				{{ texts.inputs.email.label }}
			</mat-label>
			<input id="dk-login-email" matInput type="email" formControlName="email"/>
			@if (logiForm.controls.email.invalid) {
				<mat-error> * {{ texts.inputs.email['error-message'] }}</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>
				{{ texts.inputs.password.label }}
			</mat-label>
			<input id="dk-login-password" matInput type="password" formControlName="password" (keyup)="watchReturnKeypress($event.key)"/>
			@if (logiForm.controls.password.invalid) {
				<mat-error> * {{ texts.inputs.password['error-message'] }}</mat-error>
			}
		</mat-form-field>
	</form>
	<dk-action-buttons [buttons]="actionButtons$ | async" (positiveAction)="promptLogin()"></dk-action-buttons>
	<a id="dk-reset-password-link" class="dk-hint-link" [routerLink]="passwordRenewalPage$ | async">
		{{ texts.links['forgot-password'] }}
	</a>
}
