@if (viewData$ | async; as viewData) {
	<!-- <dk-filter></dk-filter> -->
	<div class="dk-picker" [class.single-display]="singleDaySelection()">
		<mat-form-field appearance="fill" subscriptSizing="dynamic">
			<mat-label>{{ viewData.i18n.chooseDates }}</mat-label>
			<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
				<input [placeholder]="viewData.i18n.startDate" formControlName="from" matStartDate [hidden]="singleDaySelection()"/>
				<input [placeholder]="viewData.i18n.endDate" formControlName="to" matEndDate [class.single-display]="singleDaySelection()"/>
			</mat-date-range-input>
			<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
			<mat-date-range-picker #picker displayMode="single">
				<mat-date-range-picker-actions>
					<button mat-button matDateRangePickerCancel>{{ viewData.i18n.cancel }}</button>
					<button (click)="setPeriod('period')" color="primary" mat-raised-button matDateRangePickerApply>
						{{ viewData.i18n.apply }}
					</button>
				</mat-date-range-picker-actions>
			</mat-date-range-picker>
		</mat-form-field>
		<mat-icon (click)="setPeriod('prev')">navigate_before</mat-icon>
		<mat-icon (click)="setPeriod('next')">navigate_next</mat-icon>
		@if (isLoading | async) {
			<mat-spinner class="d-inline" diameter="20"></mat-spinner>
		}
		<button mat-button [matMenuTriggerFor]="ProductReport" class="export-label">{{ viewData.i18n.export }}</button>
		<mat-menu #ProductReport="matMenu" yPosition="above" xPosition="before">
			<button mat-menu-item (click)="downloadCSV()">{{ viewData.i18n.exportCsv }}</button>
			<button mat-menu-item (click)="downloadPDF()">{{ viewData.i18n.exportPDF }}</button>
		</mat-menu>
	</div>
	<div class="dk-mat-container scroll-overflow fixTableHead">
		@if (viewData.productsReport) {
			<table class="dki-data-table" id="product-report">
				<thead>
				<tr class="nb">
					<th></th>
					<th class="bl bt h1 border-right" colspan="4">{{ viewData.i18n.total }}</th>
					@for (item of viewData.productsReport[0].channels | keyvalue: sortChannels; track item) {
						<th colspan="4" class="bl br bt h1 border-right">{{ viewData.i18n[item.key] }}</th>
					}
				</tr>
				<tr class="nb">
					<th></th>
					<th class="bl bt h2" colspan="2">{{ viewData.i18n.inMenu }}</th>
					<th class="br bt h2 border-right" colspan="2">{{ viewData.i18n.solo }}</th>
					@for (channel of viewData.productsReport[0].channels | keyvalue: sortChannels; track channel) {
						<th class="bl bt h2" colspan="2">{{ viewData.i18n.inMenu }}</th>
						<th class="br bt h2 border-right" colspan="2">{{ viewData.i18n.solo }}</th>
					}
				</tr>
				<tr class="cm nb">
					<th class="text-left">{{ viewData.i18n.product }}</th>
					<th class="bl bg-light-blue">{{ viewData.i18n.count }}</th>
					<th class="bg-light-blue">{{ viewData.i18n.value }}</th>
					<th>{{ viewData.i18n.count }}</th>
					<th class="border-right">{{ viewData.i18n.value }}</th>
					@for (channel of viewData.productsReport[0].channels | keyvalue: sortChannels; track channel) {
						<th class="bl bg-light-blue">{{ viewData.i18n.count }}</th>
						<th class="bg-light-blue">{{ viewData.i18n.value }}</th>
						<th>{{ viewData.i18n.count }}</th>
						<th class="border-right">{{ viewData.i18n.value }}</th>
					}
				</tr>
				</thead>
				<tbody>
					@for (row of viewData.productsReport; track row) {
						<tr>
							<td class="text-left">{{ row.productName }}</td>
							<td class="bl bg-light-blue">{{ row.total.solo.count | number }}</td>
							<td class="bg-light-blue">{{ row.total.solo.value == 0 ? '-' : (row.total.solo.value | currency: 'EUR':'symbol':'1.2-2') }}
							</td>
							<td>{{ row.total.inMenu.count | number }}</td>
							<td
								class="border-right">{{ row.total.inMenu.value === 0 ? '-' : (row.total.inMenu.value | currency: 'EUR':'symbol':'1.2-2') }}
							</td>
							@for (channel of row.channels | keyvalue: sortChannels; track channel) {
								<td class="bl bg-light-blue">{{ channel.value.solo.count | number }}</td>
								<td
									class="bg-light-blue">{{ channel.value.solo.value == 0 ? '-' : (channel.value.solo.value | currency: 'EUR':'symbol':'1.2-2') }}
								</td>
								<td>{{ channel.value.inMenu.count | number }}</td>
								<td
									class="br border-right">{{ channel.value.inMenu.value == 0 ? '-' : (channel.value.inMenu.value | currency: 'EUR':'symbol':'1.2-2') }}
								</td>
							}
						</tr>
					}
				</tbody>
			</table>
		} @else {
			<div>{{ viewData.i18n.noData }}</div>
		}
		<ng-template #noData>
			<div>{{ viewData.i18n.noData }}</div>
		</ng-template>
	</div>
} @else {
	<div class="dk-in-progress-spinner-container">
		<mat-spinner></mat-spinner>
	</div>
}

