/**
 * Hard-coded based on data from productFamilies of BChef
 */
export enum BC_FR_PRODUCT_FAMILIES {
	BonPlan = '17',
	Burger = '19',
	Bagel = '20',
	Salades = '21',
	HotDog = '22',
	PetiteFaim = '23',
	Patisserie = '24',
	Glace = '25',
	BoissonFroide = '26',
	BoissonChaude = '27',
	Divers = '28',
	Sauce = '29',
	Dessert = '30',
	Extra = '31',
}
