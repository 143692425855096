@if (i18n$ | async; as locale) {
	<!-- <mat-form-field appearance="fill" class="dk-select">
	<mat-label>{{ locale.restaurant }}</mat-label>
	<mat-select multiple>
		<mat-option *ngFor="let topping of toppingList" [value]="topping">{{ topping }}</mat-option>
	</mat-select>
</mat-form-field>
<mat-form-field appearance="fill" class="dk-select">
	<mat-label>{{ locale.restaurantGroup }}</mat-label>
	<mat-select multiple>
		<mat-option *ngFor="let topping of toppingList" [value]="topping">{{ topping }}</mat-option>
	</mat-select>
</mat-form-field>
<mat-form-field appearance="fill" class="dk-select">
	<mat-label>{{ locale.country }}</mat-label>
	<mat-select multiple>
		<mat-option *ngFor="let topping of toppingList" [value]="topping">{{ topping }}</mat-option>
	</mat-select>
</mat-form-field>
<mat-form-field appearance="fill" class="dk-select">
	<mat-label>{{ locale.region }}</mat-label>
	<mat-select multiple>
		<mat-option *ngFor="let topping of toppingList" [value]="topping">{{ topping }}</mat-option>
	</mat-select>
</mat-form-field> -->
	<mat-form-field appearance="fill" class="dk-select">
		<mat-label>{{ locale.salesChannels }}</mat-label>
		<mat-select multiple (selectionChange)="setFilter($event.value)">
			@for (channel of salesChannels; track channel) {
				<mat-option [value]="channel">{{ locale[channel] }}</mat-option>
			}
		</mat-select>
	</mat-form-field>
	<!-- <mat-form-field appearance="fill" class="dk-select">
	<mat-label>{{ locale.consumptionMode }}</mat-label>
	<mat-select multiple>
		<mat-option *ngFor="let topping of toppingList" [value]="topping">{{ topping }}</mat-option>
	</mat-select>
	</mat-form-field>
	<mat-form-field appearance="fill" class="dk-select">
		<mat-label>{{ locale.paymentMethods }}</mat-label>
		<mat-select multiple>
			<mat-option *ngFor="let topping of toppingList" [value]="topping">{{ topping }}</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field appearance="fill" class="dk-select">
		<mat-label>{{ locale.productCategory }}</mat-label>
		<mat-select multiple>
			<mat-option *ngFor="let topping of toppingList" [value]="topping">{{ topping }}</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field appearance="fill" class="dk-select">
		<mat-label>{{ locale.productFamily }}</mat-label>
		<mat-select multiple>
			<mat-option *ngFor="let topping of toppingList" [value]="topping">{{ topping }}</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field appearance="fill" class="dk-select">
		<mat-label>{{ locale.specificProduct }}</mat-label>
		<mat-select multiple>
			<mat-option *ngFor="let topping of toppingList" [value]="topping">{{ topping }}</mat-option>
		</mat-select>
	</mat-form-field> -->
		<!-- <div class="actions">
		<button mat-flat-button class="dk-reset-filter-btn">
			<mat-icon>history</mat-icon>
		</button>
		<button mat-flat-button class="dk-apply-filter-btn">{{locale.applyFilters}}</button>
		</div> -->
}
