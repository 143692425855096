import { CurrencyPipe, KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as api from '@dki/api-client';

import { Range } from '@libs/dash/core/entity';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import jsPDF from 'jspdf';
import { isEqual, xorWith } from 'lodash-es';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { HISTORY_LOGS_FACADE } from '../../facade/history-logs-facade.injection.token';
import { HistoryLogsServiceProvider } from '../../facade/history-logs-facade.provider.interface';

@UntilDestroy()
@Component({
	selector: 'dk-financial-movments',
	templateUrl: './financial-movements.component.html',
	styleUrls: ['./financial-movements.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialMovementComponent implements OnInit {
	dateRange = new FormGroup({
		from: new FormControl(this._historylogService.financialMovementRange.from.toJSDate()),
		to: new FormControl(this._historylogService.financialMovementRange.to.toJSDate()),
	});

	isLoading$ = this._historylogService.isLoading$;

	sub: Subscription;

	entries = new FormControl();
	sorties = new FormControl();
	depenses = new FormControl();

	entriesList = new Set();
	sortiesList = new Set();
	depensesList = new Set();

	entriesFilter = [];
	sortiesFilter = [];
	depensesFilter = [];

	isFetching$ = this._historylogService.isFetchingMovements$;
	filterChanged = new BehaviorSubject<boolean>(false);
	financialMovements$ = this._historylogService.financialMovements$;
	localisedTexts$ = this._translocoService.selectTranslateObject('financial-movements');

	viewData$ = combineLatest([this.localisedTexts$, this.financialMovements$, this.filterChanged]).pipe(
		untilDestroyed(this),
		map(([i18n, data]) => {
			if (data) {
				const filteredData = { entries: [], sorties: [], depenses: [] };
				data.entries.forEach((tx) => this.entriesList.add(tx.operation.name));
				data.sorties.forEach((tx) => this.sortiesList.add(tx.operation.name));
				data.depenses.forEach((tx) => this.depensesList.add(tx.operation.name));
				filteredData.entries =
					this.entries.value && this.entries.value.length
						? data.entries.filter((tx) => this.entries.value?.includes(tx.operation.name))
						: data.entries;
				filteredData.sorties =
					this.sorties.value && this.sorties.value.length
						? data.sorties.filter((tx) => this.sorties.value?.includes(tx.operation.name))
						: data.sorties;
				filteredData.depenses =
					this.depenses.value && this.depenses.value.length
						? data.depenses.filter((tx) => this.depenses.value?.includes(tx.operation.name))
						: data.depenses;
				return { i18n, data: filteredData };
			}
			return { i18n, data };
		})
	);

	constructor(
		private _translocoService: TranslocoService,
		@Inject(HISTORY_LOGS_FACADE) private readonly _historylogService: HistoryLogsServiceProvider,
		private currencyPipe: CurrencyPipe
	) {}

	ngOnInit(): void {
		combineLatest([this._historylogService.myDefaultRestaurant$, this._historylogService.isLoading$])
			.pipe(
				filter(([selectedRestaurants, loading]) => {
					return !loading && !!selectedRestaurants;
				})
			)
			.subscribe(() => {
				this.setPeriod();
			});
	}

	customComparator(prev, curr) {
		return (
			xorWith(
				prev.map((rest) => rest.id),
				curr.map((rest) => rest.id),
				isEqual
			).length === 0
		);
	}

	setPeriod(period?: string) {
		const today = DateTime.now();
		let from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		switch (period) {
			case Range.Today:
				from = today;
				to = today;
				break;
			case Range.Week:
				from = today.startOf(Range.Week);
				to = today.endOf(Range.Week);
				break;
			case Range.Month:
				from = today.startOf(Range.Month);
				to = today.endOf(Range.Month);
				break;
			case Range.Period:
				to = !this.dateRange.controls.to.value ? from : to;
				break;
		}
		this.dateRange.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this._historylogService.getFinancialMovements(from, to);
	}

	singleDaySelection() {
		const from = this.dateRange.controls.from.value as Date;
		const to = this.dateRange.controls.to.value as Date;
		return from.getDate() === to.getDate() && from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
	}

	originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
		return 0;
	};

	setFilter() {
		this.filterChanged.next(true);
	}

	download(tx: api.SafeTransaction) {
		const doc = new jsPDF();

		const quickText = this.getCurrentBrandName();
		const fontSize = 24;
		const boldText = true;
		const textX = doc.internal.pageSize.getWidth() / 2;
		const textY = 50;
		const currentDate = DateTime.fromISO(tx.validation_time).toFormat('yyyy-MM-dd HH:mm:ss');
		const separatorY = textY + 10;

		doc.setFontSize(fontSize);
		doc.setFont(undefined, boldText ? 'bold' : 'normal');
		doc.text(quickText, textX, textY, { align: 'center' });

		doc.setFontSize(14);
		doc.setFont(undefined, 'normal');
		doc.text(currentDate, textX, separatorY + 20, { align: 'center' });

		const lineX1 = 20;
		const lineX2 = doc.internal.pageSize.getWidth() - 20;
		const lineY = separatorY + 40;

		doc.setLineWidth(1);
		doc.line(lineX1, lineY, lineX2, lineY);

		const data = {
			[tx.operation.type]: tx.operation.name,
			RESPONSABLE: tx.manager.login,
			SOURCE: tx.source.label,
			DEST: tx.destination.label,
			TH: tx.amounts.without_taxes,
			TVA: tx.amounts.taxes,
			REMARQUE: tx.comment,
		};
		const lineStartY = lineY + 20;
		const lineSpacing = 15;

		Object.entries(data).forEach((item, index) => {
			const lineStartX = 20;
			const keyX = lineStartX;
			const valueX = lineStartX + 100;
			const lineY = lineStartY + index * lineSpacing;

			const keyLines = doc.splitTextToSize(item[0], 80);
			const valueLines = doc.splitTextToSize(item[1].toString(), 80);

			doc.setFontSize(12);
			doc.setFont(undefined, 'normal');
			doc.text(keyLines, keyX, lineY);
			doc.setFont(undefined, 'bold');
			doc.text(valueLines, valueX, lineY);
		});

		doc.save(`ticket-${currentDate}.pdf`);
	}

	getCurrentBrandName() {
		switch (window['__CONFIG'].brand) {
			case 'qrfr':
				return 'QUICK';
			case 'bkfr':
				return 'BK';
			case 'bkre':
				return 'BK';
			default:
				return 'TICKET';
		}
	}
}
