import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

import { PageContainerModule, PipesModule } from '@libs/dash/shared';
import { DashboardItemDetailRoutingModule } from './dashboard-item-detail-routing.module';
import { DashboardItemDetailComponent } from './view-component/dashboard-item-detail.component';

@NgModule({
	declarations: [DashboardItemDetailComponent],
	imports: [
		CommonModule,
		DashboardItemDetailRoutingModule,
		RouterModule,
		PageContainerModule,
		MatButtonModule,
		FlexLayoutModule,
		PipesModule,
	],
})
export class DashboardItemDetailModule {}
