import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
	FunctionalTranspiler,
	provideTranslocoTranspiler,
	TRANSLOCO_CONFIG,
	TRANSLOCO_FALLBACK_STRATEGY,
	TRANSLOCO_INTERCEPTOR,
	TRANSLOCO_LOADER,
	TRANSLOCO_MISSING_HANDLER,
	translocoConfig,
	TranslocoModule,
	TranslocoService,
} from '@ngneat/transloco';
import { TranslateDirective } from './directives/translate.directive';
import { ITranslateLoader } from './models';

import { TranslatePipe } from './pipes/translate.pipe';
import { TranslocoCustomFallbackStrategy } from './providers/fallback-strategy';
import { TranslocoCustomInterceptor } from './providers/transloco.interceptor';
import { LanguageService } from './services/language.service';
import { MissingTranslation } from './services/missing-translation.service';
import { TranslateInitializer } from './services/translate-initializer.service';
import { TranslateLoaderService } from './services/translate-loader.service';
import { TranslateService } from './services/translate.service';

export function translocoConfigFactory(data: ITranslateLoader) {
	return translocoConfig({
		availableLangs: data.languages,
		defaultLang: data.languages?.[0],
		reRenderOnLangChange: true,
		prodMode: false,
	});
}

@NgModule({
	imports: [TranslocoModule, RouterModule],
	declarations: [TranslatePipe, TranslateDirective],
	exports: [TranslocoModule, TranslatePipe, TranslateDirective],
})
export class TranslateModule {
	static forRoot(translateConfig: ITranslateLoader): ModuleWithProviders<TranslateModule> {
		return {
			ngModule: TranslateModule,
			providers: [
				HttpClientModule,
				{
					provide: TRANSLOCO_CONFIG,
					useFactory: () => translocoConfigFactory(translateConfig),
				},
				provideTranslocoTranspiler(FunctionalTranspiler),
				{
					provide: TRANSLOCO_INTERCEPTOR,
					useClass: TranslocoCustomInterceptor,
				},
				{
					provide: TRANSLOCO_FALLBACK_STRATEGY,
					useClass: TranslocoCustomFallbackStrategy,
				},
				{
					provide: TRANSLOCO_MISSING_HANDLER,
					useClass: MissingTranslation,
				},
				{
					provide: LanguageService,
					useFactory: (
						translocoService: TranslocoService,
						translateInitializer: TranslateInitializer,
						route: ActivatedRoute,
						router: Router
					) => {
						return new LanguageService(translocoService, translateInitializer, route, router, translateConfig.languages);
					},
					deps: [TranslocoService, TranslateInitializer, ActivatedRoute, Router],
				},
				{
					provide: TRANSLOCO_LOADER,
					useFactory: (http: HttpClient) => {
						return new TranslateLoaderService(http, translateConfig);
					},
					deps: [HttpClient],
				},
				TranslateService,
			],
		};
	}
}
