<ng-container *ngIf="translations$ | async as translation">
	<div class="labels">
		<p>{{ translation.dataTable.cumul }}</p>
		<p #secondLabel class="ignore">{{ translation.dataTable.operationalTimes }}</p>
	</div>

	<table class="dk-table">
		<thead>
			<tr>
				<th>{{ translation.dataTable.slots }}</th>
				<th>{{ translation.dataTable.schedule }}</th>
				<th>{{ translation.dataTable.revenue }}</th>
				<th>{{ translation.dataTable.averageTicket }}</th>
				<th>{{ translation.dataTable.orderCount }}</th>
				<th #cell>{{ translation.dataTable.preparation }}</th>
				<th>{{ translation.dataTable.departure }}</th>
				<th class="has-info">
					{{ translation.dataTable.totalService }}
					<button
						mat-icon-button
						[matTooltip]="translation.dataTable.tooltipTotalService"
						[attr.aria-label]="translation.dataTable.tooltipTotalService"
					>
						<mat-icon>info_outline</mat-icon>
					</button>
				</th>
			</tr>
		</thead>
		<tbody>
			@for (period of data; track period.period) {
				<tr>
					<td>{{ translation.daypartLabels[period.period] }}</td>
					<td>{{ formatTimeRange({ start: period.start, end: period.end }) }}</td>
					<td>{{ period.total_revenue | currency: 'EUR' : 'symbol' : '1.2-2' }}</td>
					<td>{{ period.mean_revenue | currency: 'EUR' : 'symbol' : '1.2-2' }}</td>
					<td>{{ period.count }}</td>
					<td [ngClass]="getClassForValue(period[TimeStep.PreparationTime], ValueType.Preparation)">
						{{ period[TimeStep.PreparationTime] | toMins }}
					</td>
					<td>
						{{ period[TimeStep.ServeTime] | toMins }}
					</td>
					<td>
						{{ period[TimeStep.PreparationTime] + period[TimeStep.ServeTime] | toMins }}
					</td>
				</tr>
			}
		</tbody>
		<tfoot>
			<tr>
				<td colspan="2">{{ translation.dataTable.totalAverage }}</td>
				<td>{{ getTotalForField('total_revenue') | currency: 'EUR' : 'symbol' : '1.2-2' }}</td>
				<td>{{ calculateAverageForField('total_revenue') | currency: 'EUR' : 'symbol' : '1.2-2' }}</td>
				<td>{{ getTotalForField('count') }}</td>
				<td>{{ calculateAverageForField(TimeStep.PreparationTime) | toMins }}</td>
				<td>{{ calculateAverageForField(TimeStep.ServeTime) | toMins }}</td>
				<td>{{ calculateAverageForField(TimeStep.PreparationTime) + calculateAverageForField(TimeStep.ServeTime) | toMins }}</td>
			</tr>
		</tfoot>
	</table>
</ng-container>
