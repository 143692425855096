import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationFailedComponent } from './dumb-components/authentication-failed/authentication-failed.component';
import { LoginComponent } from './dumb-components/login/login.component';
import { LogoutComponent } from './dumb-components/logout/logout.component';
import { NewPasswordComponent } from './dumb-components/new-password/new-password.component';
import { PasswordRenewalSentComponent } from './dumb-components/password-renewal-sent/password-renewal-sent.component';
import { RefreshComponent } from './dumb-components/refresh/refresh.component';
import { RequestPasswordRenewalComponent } from './dumb-components/request-password-renewal/request-password-renewal.component';
import { SetupCompletedComponent } from './dumb-components/setup-completed/setup-completed.component';
import { WelcomeComponent } from './dumb-components/welcome/welcome.component';
import { AccountComponent } from './view-component/account.component';

const routes: Routes = [
	{
		path: '',
		component: AccountComponent,
		children: [
			{
				path: 'login',
				component: LoginComponent,
			},
			{
				path: 'logout',
				component: LogoutComponent,
			},
			{
				path: 'refresh',
				component: RefreshComponent,
			},
			{
				path: 'request-password-renewal',
				component: RequestPasswordRenewalComponent,
			},
			{
				path: 'password-renewal-sent',
				component: PasswordRenewalSentComponent,
			},
			{
				path: 'welcome',
				component: WelcomeComponent,
			},
			{
				path: 'new-password',
				component: NewPasswordComponent,
			},
			{
				path: 'setup-completed',
				component: SetupCompletedComponent,
			},
			{
				path: 'authentication-failed',
				component: AuthenticationFailedComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AccountRoutingModule {}
