import { CsiLines, ICsiTicket } from '@bk/jscommondatas';

export function getPaymentLineUuidFromTicket(ticket: ICsiTicket): string {
	if (!Array.isArray(ticket?.lines) || !ticket?.lines?.length) {
		return undefined;
	}
	const paymentLines = ticket.lines.filter((line) => line.typeLigne === CsiLines.REGLEMENT);
	if (!paymentLines.length) {
		return undefined;
	}
	const paymentLine = paymentLines.pop();
	return paymentLine?.lineUuid;
}
