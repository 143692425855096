import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';

import { sortLabels } from '@libs/dash/core/entity';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import { DateTime } from 'luxon';
import { filter, map } from 'rxjs/operators';
import { deepCopyFunction } from '../../../../shared/utils/methods';
import { SYNTHESIS_REPORT_FACADE } from '../../facade/synthesis-report-facade.injection.token';
import { SynthesisReportServiceProvider } from '../../facade/synthesis-report-facade.provider.interface';

@UntilDestroy()
@Component({
	selector: 'dk-general-synthesis',
	templateUrl: './general-synthesis-report.component.html',
	styleUrls: ['./general-synthesis-report.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralSynthesisReportComponent implements OnInit {
	@Input() i18n: any;

	@Input() dateRange: any;

	isProcessing = true;

	synthesisReport$ = this.synthesisReportService.synthesisReport$.pipe(
		filter((data) => !!data),
		untilDestroyed(this),
		map((report) => this.mapSynthesisReport(report))
	);

	vatSynthesisReportEmpty$ = this.synthesisReportService.vatSynthesisReport$.pipe(
		filter((data) => !!data),
		untilDestroyed(this),
		map((a) => {
			return a?.by_channel && Object.keys(a.by_channel).length === 0;
		})
	);

	synthesisReportMapped: Record<string, any>;

	selectedRestaurants: Array<string>;

	// data: any = {};

	displayedColumnsEntries: string[] = ['reglements', 'amountTh', 'declaredAmount', 'gap'];
	displayedColumnsRecap: string[] = ['annulations', 'refunds', 'reductions', 'debit'];

	constructor(@Inject(SYNTHESIS_REPORT_FACADE) private readonly synthesisReportService: SynthesisReportServiceProvider) {}

	ngOnInit(): void {
		this.synthesisReportService.selectedRestaurants$
			.pipe(
				untilDestroyed(this),
				filter((restaurants) => restaurants.length > 0 && !!restaurants[0])
			)
			.subscribe((restaurants) => {
				this.selectedRestaurants = restaurants.map((restaurant) => restaurant.name);
			});
	}

	mapSynthesisReport(data) {
		this.isProcessing = true;
		const synthesisReport = deepCopyFunction(data);
		synthesisReport.recap = [{ cancelled: data.cancelled, refund: data.refunded, discount: data.discounted, withdrawn: data.withdrawn }];
		if (synthesisReport.by_label && synthesisReport.by_label.length > 0) {
			synthesisReport.by_label.sort((x, y) => sortLabels(x.label, y.label));
			const nRendu = synthesisReport.by_label.find((el) => el.category === 'NON RENDU');
			const rEmployee = synthesisReport.by_label.find((el) => el.category === 'REPAS EMPLOYES');
			if (nRendu && nRendu.amount.theoretical != 0) {
				synthesisReport.by_label.push({
					label: 'total_ca_e',
					amount: {
						...data.total,
					},
				});
				synthesisReport.by_label.push(nRendu);
				synthesisReport.by_label.push({
					label: 'total_ca',
					amount: {
						...data.total_collected,
					},
				});
			}
			if (rEmployee && rEmployee.amount.theoretical != 0) synthesisReport.by_label.push(rEmployee);
		}
		this.synthesisReportMapped = synthesisReport;
		return synthesisReport;
	}

	highlightedRow(row) {
		return !!row && row.label && row.label.toLowerCase().includes('total');
	}

	exportCsv() {
		let csv = this.synthesisReportMapped.by_label;
		csv.push({
			label: 'total_ca_employee',
			amount: { ...this.synthesisReportMapped.total_with_employee_meals },
		});
		csv = csv.map((el: { label: string | number; amount: { theoretical: string; real: string; gap: string } }) => {
			return {
				[this.i18n.reglements]: this.i18n.paymentMethods[el.label],
				[this.i18n.amountTh]: parseFloat(el.amount.theoretical).toFixed(2),
				[this.i18n.declaredAmount]: parseFloat(el.amount.real).toFixed(2),
				[this.i18n.gap]: parseFloat(el.amount.gap).toFixed(2),
			};
		});
		const options = {
			filename: this.i18n.general_synth_report,
			decimalSeparator: '.',
			fieldSeparator: ';',
			showLabels: true,
			showTitle: true,
			title: this.i18n.general_synth_report,
			useKeysAsHeaders: true,
		};
		const csvExporter = new ExportToCsv(options);
		csvExporter.generateCsv(csv);
	}

	exportPDF() {
		const title = String(`${this.i18n.title}_${DateTime.fromJSDate(this.dateRange.value.from).toFormat('yyyy-MM-dd')}`);
		const source = document.getElementById('general-vat-report');
		const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true });
		doc.html(source, {
			html2canvas: {
				scale: 0.17,
				letterRendering: false,
				ignoreElements: (e) => e.classList.contains('export-buttons'),
			},
			margin: [30, 5, 5, 5],
			windowWidth: 1000,
			width: 900,
			fontFaces: [
				{
					family: 'Roboto',
					src: [
						{
							url: '/assets/fonts/roboto.ttf',
							format: 'truetype',
						},
					],
				},
			],
			callback: (doc) => {
				doc.setFontSize(16);
				doc.setFont(undefined, 'bold');
				doc.text(this.selectedRestaurants.join(','), 20, 15);
				const date = `${DateTime.fromJSDate(this.dateRange.value.from).toFormat('yyyy-MM-dd')} - ${DateTime.fromJSDate(this.dateRange.value.to).toFormat('yyyy-MM-dd')}`;
				doc.setFontSize(12);
				doc.setFont(undefined, 'light');
				doc.text(`${date}`, 20, 20);

				doc.save(title);
			},
		});
	}
}
