import { Injectable } from '@angular/core';

import { FeatureMapService } from '@libs/dash/guards';
import {
	AuthStoreSelectors,
	MyRestaurantsActions,
	MyRestaurantsSelectors,
	RootStoreState,
	RouterStoreSelectors,
	TodaysStatisticsActions,
	TodaysStatisticsSelectors,
} from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { TodaysOverviewServiceProvider } from './todays-overview-facade.provider.interface';

@Injectable()
export class TodaysOverviewFacade implements TodaysOverviewServiceProvider {
	currentLanguage$ = this._store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam));
	currentUserId$ = this._store.pipe(select(AuthStoreSelectors.getCurrentUserId));
	myDefaultRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant));
	restaurantIsOpened$ = this._store.pipe(select(MyRestaurantsSelectors.restaurantIsOpened));
	ordersByChannel$ = this._store.pipe(select(TodaysStatisticsSelectors.ordersByChannel));
	ordersByProduct$ = this._store.pipe(select(TodaysStatisticsSelectors.ordersByProduct));
	topTenProducts$ = this._store.pipe(select(TodaysStatisticsSelectors.topTenProducts));
	ordersByChannelWithAverages$ = this._store.pipe(select(TodaysStatisticsSelectors.ordersByChannelWithAverages));
	sos$ = this._store.pipe(select(TodaysStatisticsSelectors.sos));
	kioskSos$ = this._store.pipe(select(TodaysStatisticsSelectors.kioskSos));
	channelPermoranceInPercentage$ = this._store.pipe(select(TodaysStatisticsSelectors.channelPermoranceInPercentage));
	revenueProgress$ = this._store.pipe(select(TodaysStatisticsSelectors.revenueProgress));
	channelLoadPerformance$ = this._store.pipe(select(TodaysStatisticsSelectors.channelLoadPerformance));
	customerWaitingTime$ = this._store.pipe(select(TodaysStatisticsSelectors.customerWaitingTimeTableData));
	channelPerformanceTableData$ = this._store.pipe(select(TodaysStatisticsSelectors.channelPerformanceTableData));
	topTenProductsTableData$ = this._store.pipe(select(TodaysStatisticsSelectors.topTenProductsTableData));

	constructor(
		private _store: Store<RootStoreState>,
		private fm: FeatureMapService
	) {}

	fetchDefaultRestaurantData(): void {
		this._store.dispatch(MyRestaurantsActions.getList());
	}

	fetchDefaultRestaurantOrdersData(): void {
		// old report ?
		this._store.dispatch(MyRestaurantsActions.getLongTermOrders());
		if (this.fm.featureMap?.globalOverview?.todays?.revenue) {
			this._store.dispatch(TodaysStatisticsActions.getOrdersByTime());
			this._store.dispatch(TodaysStatisticsActions.getLast15minutesOrdersTotalCount());
		}

		// this._store.dispatch(TodaysStatisticsActions.getAverageOrdersAtCurrentTime());

		if (this.fm.featureMap?.globalOverview?.todays?.topTenProducts) {
			this._store.dispatch(TodaysStatisticsActions.getOrdersByProduct());
		}
		if (this.fm.featureMap?.globalOverview?.todays?.ratioChannel) {
			this._store.dispatch(TodaysStatisticsActions.getOrdersByChannel());
		}
		if (this.fm.featureMap?.globalOverview?.todays?.channelPerformance) {
			this._store.dispatch(TodaysStatisticsActions.getOrdersByChannelWithAverages());
		}
		if (this.fm.featureMap?.globalOverview?.todays?.drive) {
			this._store.dispatch(TodaysStatisticsActions.getSos());
		}
		if (this.fm.featureMap?.globalOverview?.todays?.kiosk) {
			this._store.dispatch(TodaysStatisticsActions.getKioskSos());
		}
	}
}
