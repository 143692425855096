<div id="dtt-report" #bkfReport>
	@if (viewdata.dttReport && viewdata.dttReport.length > 0) {
		<h2>
			{{ restaurantName || viewdata.i18n.dtt_report.title }}
		</h2>
	}
	@if (viewdata.dttReport && viewdata.dttReport.length > 0) {
		<table class="dtt-report" id="dtt-table">
			<thead>
			<tr>
				@for (header of viewdata.i18n.dtt_report['header'] | keyvalue: originalOrder; track header) {
					<th>{{ header.value }}</th>
				}
			</tr>
			</thead>
			<tbody>
				@for (part of viewdata.i18n.dtt_report['day_part'] | keyvalue: originalOrder; track part; let index = $index; let last = $last) {
					<tr [class.highlight]="last">
						<td>
							<!-- dayPart-->
							<p>{{ part.value }}</p>
							@if (!last) {
								<p class="day-part">{{ dayParts[index][0] }}h {{ viewdata.i18n.dtt_report.to }} {{ dayParts[index][1] }}h</p>
							}
						</td>
						<!-- CA -->
						@if (!last) {
							<td>
								{{ viewdata.dttReport[index]?.total_revenue | currency: 'EUR':'symbol':'1.2-2' || '-' }}
							</td>
						}
						@if (last) {
							<td>
								{{ total(viewdata.dttReport, 'total_revenue') | currency: 'EUR':'symbol':'1.2-2' || '-' }}
							</td>
						}
						<!-- avg tx-->
						@if (!last) {
							<td>
								{{
									viewdata.dttReport[index]?.total_revenue && viewdata.dttReport[index]?.total_revenue / viewdata.dttReport[index]?.count
										| currency: 'EUR':'symbol':'1.2-2' || '-'
								}}
							</td>
						}
						@if (last) {
							<td>
								{{ total(viewdata.dttReport, 'total_revenue') / total(viewdata.dttReport, 'count') | currency: 'EUR':'symbol':'1.2-2' || '-' }}
							</td>
						}
						<!-- number of tx -->
						@if (!last) {
							<td>{{ viewdata.dttReport[index]?.count || '-' }}</td>
						}
						@if (last) {
							<td>{{ total(viewdata.dttReport, 'count') || '-' }}</td>
						}
						<!-- cars count -->
						@if (!last) {
							<td>{{ (viewdata.dttReport[index]?.count && viewdata.dttReport[index]?.count - viewdata.dttReport[index]?.sos_samecar) || '-' }}</td>
						}
						@if (last) {
							<td>{{ total(viewdata.dttReport, 'count') - total(viewdata.dttReport, 'sos_samecar') || '-' || '-' }}</td>
						}
						<!-- wait time / attente-->
						<td>
							<p class="raw">
								{{ last ? (totalAverage(viewdata.dttReport, 'mean_sos_waittime', false) | toMins) : (viewdata.dttReport[index]?.mean_sos_waittime | toMins) }}
							</p>
						</td>
						<!-- Order time -->
						<td
							[class.has-goal]="!last"
							[class.achieved]="viewdata.dttReport[index]?.mean_sos_ordertime && !objectiveAchieved('order', viewdata.dttReport[index]?.mean_sos_ordertime)"
						>
							<p [class.raw]="last">
								{{ last ? (totalAverage(viewdata.dttReport, 'mean_sos_ordertime', false) | toMins) : (viewdata.dttReport[index]?.mean_sos_ordertime | toMins) }}
							</p>
							@if (!last && viewdata.dttReport[index]?.mean_sos_ordertime) {
								<p class="goal">{{ goals.mean_sos_ordertime }}</p>
							}
						</td>
						<!-- Commande -->
						<td>
							<p class="raw">
								{{
									last
										? (totalAverage(viewdata.dttReport, 'mean_sos_waittime', false) + totalAverage(viewdata.dttReport, 'mean_sos_ordertime', false) | toMins)
										: (viewdata.dttReport[index]?.mean_sos_waittime + viewdata.dttReport[index]?.mean_sos_ordertime | toMins)
								}}
							</p>
						</td>
						<!-- transit paiement -->
						<td>
							<p class="raw">
								{{ last ? (totalAverage(viewdata.dttReport, 'mean_sos_linetime', false) | toMins) : (viewdata.dttReport[index]?.mean_sos_linetime | toMins) }}
							</p>
						</td>
						<!-- paiement -->
						<td>
							<p class="raw">
								{{ last ? (totalAverage(viewdata.dttReport, 'mean_sos_paytime', false) | toMins) : (viewdata.dttReport[index]?.mean_sos_paytime | toMins) }}
							</p>
						</td>
						<!-- transit livraison -->
						<td>
							<p class="raw">
								{{ last ? (totalAverage(viewdata.dttReport, 'mean_sos_hardservetime', false) | toMins) : (viewdata.dttReport[index]?.mean_sos_hardservetime | toMins) }}
							</p>
						</td>
						<!-- livraison -->
						<td
							[class.has-goal]="!last"
							[class.achieved]="
												viewdata.dttReport[index] &&
												viewdata.dttReport[index]['mean_sos_deliverytime'] &&
												!objectiveAchieved('delivery', viewdata.dttReport[index] && getDeliveryTime(viewdata.dttReport[index]))
											"
						>
							@if (last) {
								<p class="raw">
									{{ totalAverage(viewdata.dttReport, 'mean_sos_deliverytime', false) | toMins }}
								</p>
							}
							@if (!last) {
								<p>
									{{ viewdata.dttReport[index] && getDeliveryTime(viewdata.dttReport[index]) | toMins }}
								</p>
							}
							@if (!last && viewdata.dttReport[index] && viewdata.dttReport[index]['mean_sos_deliverytime']) {
								<p class="goal">{{ goals.mean_sos_servetime }}</p>
							}
						</td>
						<!-- total -->
						<td [class.has-goal]="!last"
						    [class.achieved]="totalTime(viewdata.dttReport[index]) && !objectiveAchieved('total', totalTime(viewdata.dttReport[index]))">
							@if (!last) {
								<p>{{ totalTime(viewdata.dttReport[index]) | toMins }}</p>
							}
							@if (last) {
								<p class="raw">{{ totalAverage(viewdata.dttReport, 'total', true) | toMins }}</p>
							}
							@if (!last && totalTime(viewdata.dttReport[index])) {
								<p class="goal">{{ goals.total }}</p>
							}
						</td>
						<!-- park -->
						<td class="has-goal">
							@if (!last) {
								<p>{{ viewdata.dttReport[index]?.mean_sos_parktime | toMins }}</p>
							}
							@if (!last && viewdata.dttReport[index] && viewdata.dttReport[index]['parked_cars']) {
								<p class="goal">
									{{ viewdata.dttReport[index] && viewdata.dttReport[index]['parked_cars'] }} park
								</p>
							}
							@if (last) {
								<p>{{ totalAverage(viewdata.dttReport, 'mean_sos_parktime', false) | toMins }}</p>
							}
							@if (last && totalAverage(viewdata.dttReport, 'parked_cars', true)) {
								<p class="goal">{{ totalAverage(viewdata.dttReport, 'parked_cars', true) }} park</p>
							}
						</td>
						<!-- depart -->
						<td>
							<p class="raw">
								{{ last ? (totalAverage(viewdata.dttReport, 'mean_sos_departtime', false) | toMins) : (viewdata.dttReport[index]?.mean_sos_departtime | toMins) }}
							</p>
						</td>
					</tr>
				}
			</tbody>
			<tfoot>
			<tr>
				<td colspan="15">
					<div class="hint">{{ viewdata.i18n.objective_done }}</div>
					<div class="export-buttons">
						<button (click)="export('CSV')">{{ viewdata.i18n.exportCsv }}</button>
						<button (click)="export('PDF')">{{ viewdata.i18n.exportPdf }}</button>
					</div>
				</td>
			</tr>
			</tfoot>
		</table>
	}
</div>
