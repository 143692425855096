import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';

import { AccountFormContainerModule, ActionButtonsModule, PageContainerModule } from '@libs/dash/shared';
import { AccountRoutingModule } from './account-routing.module';
import { AuthenticationFailedComponent } from './dumb-components/authentication-failed/authentication-failed.component';
import { LoginComponent } from './dumb-components/login/login.component';
import { LogoutComponent } from './dumb-components/logout/logout.component';
import { NewPasswordComponent } from './dumb-components/new-password/new-password.component';
import { PasswordRenewalSentComponent } from './dumb-components/password-renewal-sent/password-renewal-sent.component';
import { RedirectComponent } from './dumb-components/redirect/redirect.component';
import { RefreshComponent } from './dumb-components/refresh/refresh.component';
import { RequestPasswordRenewalComponent } from './dumb-components/request-password-renewal/request-password-renewal.component';
import { SetupCompletedComponent } from './dumb-components/setup-completed/setup-completed.component';
import { WelcomeComponent } from './dumb-components/welcome/welcome.component';
import { ACCOUNT_FACADE } from './facade/account-facade.injection.token';
import { AccountFacade } from './facade/account-facade.service';
import { AccountComponent } from './view-component/account.component';

@NgModule({
	declarations: [
		AccountComponent,
		LoginComponent,
		WelcomeComponent,
		NewPasswordComponent,
		SetupCompletedComponent,
		LogoutComponent,
		AuthenticationFailedComponent,
		RefreshComponent,
		RequestPasswordRenewalComponent,
		PasswordRenewalSentComponent,
		RedirectComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,
		FormsModule,
		AccountRoutingModule,
		FlexLayoutModule,
		MatButtonModule,
		MatInputModule,
		MatIconModule,
		AccountFormContainerModule,
		ActionButtonsModule,
		PageContainerModule,
	],
	providers: [
		{
			provide: ACCOUNT_FACADE,
			useClass: AccountFacade,
		},
	],
})
export class AccountModule {}
