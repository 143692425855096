import { Injectable } from '@angular/core';

import { MyRestaurantsSelectors, RootStoreState, RouterStoreSelectors, SafeDepositActions, SafeDepositSelectors } from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { map } from 'rxjs/operators';
import { mapApiResponse } from '../helpers/Mapper';
import { SafeDepositServiceProvider } from './safe-deposit-facade.provider.interface';

@Injectable()
export class SafeDepositFacade implements SafeDepositServiceProvider {
	currentLanguage$ = this._store.pipe(select(RouterStoreSelectors.getCurrentLanguageParam));
	myDefaultRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant));
	restaurantIsOpened$ = this._store.pipe(select(MyRestaurantsSelectors.restaurantIsOpened));
	safeDepositRecords = this._store.pipe(select(SafeDepositSelectors.safeRecords), map(mapApiResponse));
	isLoading$ = this._store.pipe(select(SafeDepositSelectors.isLoading));
	safeDateRange$ = this._store.pipe(select(SafeDepositSelectors.range));

	range = {
		from: DateTime.now().startOf('week'),
		to: DateTime.now().endOf('week'),
	};

	constructor(private _store: Store<RootStoreState>) {}

	getRecords(from: DateTime, to: DateTime) {
		this.range = { from, to };
		this._store.dispatch(SafeDepositActions.getSafeDepositReports({ from, to }));
	}
}
