import { Injectable } from '@angular/core';
import * as api from '@dki/api-client';

import { MyRestaurantsActions, MyRestaurantsSelectors, RootStoreState } from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SettingsServiceProvider } from './settings-facade.provider.interface';

@Injectable()
export class SettingsFacade implements SettingsServiceProvider {
	isLoading$: Observable<boolean> = this._store.pipe(select(MyRestaurantsSelectors.getIsLoading));
	myDefaultRestaurant$: Observable<api.Restaurant> = this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant));

	constructor(private _store: Store<RootStoreState>) {}

	sendUpdatedDataToBackend(restaurantSettings: api.UpdateRestaurantSettings): void {
		this._store.dispatch(MyRestaurantsActions.updateMyDefaultRestaurant({ restaurantSettings }));
	}

	fetchDefaultRestaurantData(): void {
		this._store.dispatch(MyRestaurantsActions.getList());
	}
}
