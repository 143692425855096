import {
	AppConfigurationGlobal,
	AVAILABLE_JSON,
	CONFIGURATION_DOWNLOAD_TYPES,
	GlobalAppsConfiguration,
	IBKBigData,
	IRestoSettings,
} from '@libs/shared/models';
import { createAction, props } from '@ngrx/store';
import { IRestoConfig } from '../../../../../models';

export enum ACTION_TYPES {
	InitData = '[Configuration] Init data from local storage',
	LoadBigDatas = '[Configuration] Load big datas',
	LoadBigDatasSuccess = '[Configuration] Load big datas success',
	LoadAvailability = '[Configuration] Load availability',
	LoadAvailabilitySuccess = '[Configuration] Load availability success',
	LoadRestoSettings = '[Configuration] Load resto settings',
	LoadRestoSettingsSuccess = '[Configuration] Load resto settings success',
	LoadRestoConfig = '[Configuration] Load resto config',
	LoadRestoConfigSuccess = '[Configuration] Load resto config success',
	LoadGlobalAppsCredentials = '[Configuration] Load glovbal apps credentials',
	LoadGlobalAppsCredentialsSuccess = '[Configuration] Load global apps credentials success',
	LoadGlobalConfiguration = '[Configuration] Load global configuration',
	LoadGlobalConfigurationSuccess = '[Configuration] Load global configuration success',
	SetLoadingSuccess = '[Configuration] Set loading success',
}

export type LoadConfigurationType = { downloadType: CONFIGURATION_DOWNLOAD_TYPES };
export type LoadConfigurationSuccessType = LoadConfigurationType & { data: IBKBigData | AVAILABLE_JSON | IRestoSettings | IRestoConfig };
export type LoadGlobalAppsCredentialsSuccessType = { data: { dkiBe: Partial<GlobalAppsConfiguration> } };
export type LoadGlobalConfigurationSuccessType = { data: AppConfigurationGlobal };

export const InitData = createAction(ACTION_TYPES.InitData);

export const LoadBigDatas = createAction(ACTION_TYPES.LoadBigDatas, props<LoadConfigurationType>());

export const LoadBigDatasSuccess = createAction(ACTION_TYPES.LoadBigDatasSuccess, props<LoadConfigurationSuccessType>());

export const LoadAvailability = createAction(ACTION_TYPES.LoadAvailability, props<LoadConfigurationType>());

export const LoadAvailabilitySuccess = createAction(ACTION_TYPES.LoadAvailabilitySuccess, props<LoadConfigurationSuccessType>());

export const LoadRestoSettings = createAction(ACTION_TYPES.LoadRestoSettings, props<LoadConfigurationType>());

export const LoadRestoSettingsSuccess = createAction(ACTION_TYPES.LoadRestoSettingsSuccess, props<LoadConfigurationSuccessType>());

export const LoadRestoConfig = createAction(ACTION_TYPES.LoadRestoConfig, props<LoadConfigurationType>());

export const LoadRestoConfigSuccess = createAction(ACTION_TYPES.LoadRestoConfigSuccess, props<LoadConfigurationSuccessType>());

export const SetLoadingSuccess = createAction(ACTION_TYPES.SetLoadingSuccess, props<LoadConfigurationType>());

export const LoadGlobalAppsCredentials = createAction(ACTION_TYPES.LoadGlobalAppsCredentials);

export const LoadGlobalAppsCredentialsSuccess = createAction(
	ACTION_TYPES.LoadGlobalAppsCredentialsSuccess,
	props<LoadGlobalAppsCredentialsSuccessType>()
);

export const LoadGlobalConfiguration = createAction(ACTION_TYPES.LoadGlobalConfiguration);

export const LoadGlobalConfigurationSuccess = createAction(
	ACTION_TYPES.LoadGlobalConfigurationSuccess,
	props<LoadGlobalConfigurationSuccessType>()
);
