import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
	AccountingReportByPaymentMethodRecord,
	AccountingReportByProductGroupRecord,
	AccountingReportResponse,
	PaymentMethodEnum,
	ReportChannel,
} from '@dki/api-client';
import { cloneDeep } from 'lodash-es';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AccountingFacade } from '../../facade/accounting-facade.service';

@Component({
	selector: 'dk-report-detail',
	templateUrl: './report-detail.component.html',
	styleUrls: ['./report-detail.component.scss'],
})
export class ReportDetailComponent implements OnInit, OnDestroy {
	@Input() i18n: Record<string, any>;

	date = new FormControl(new Date());

	isLoading$ = this._accountingFacade.isFetching$;

	hasError$ = this._accountingFacade.hasError$;

	editing = false;

	reportDetail: AccountingReportResponse | Record<string, unknown>;

	toSave: any;

	subscription: Subscription;

	permission$ = this._accountingFacade.accountingPermission$.pipe(
		map((level) => ({
			read: (level && level.toLowerCase().includes('read')) || false,
			write: (level && level.toLowerCase().includes('write')) || false,
		}))
	);

	isAdmin$ = this._accountingFacade.isAdmin$;

	productGroupOrder = [ReportChannel.EatIn, ReportChannel.TakeOut, ReportChannel.Delivery, ReportChannel.DriveThrough];
	paymentMethodOrder = [
		PaymentMethodEnum.PaperCash,
		PaymentMethodEnum.NonRendu,
		PaymentMethodEnum.BankChecks,
		'CB PROXY',
		'CB SC',
		PaymentMethodEnum.RestaurantVoucher,
		PaymentMethodEnum.Ancv,
		PaymentMethodEnum.ConnecsApetiz,
		PaymentMethodEnum.ConnecsCheqDej,
		PaymentMethodEnum.ConnecsEdenred,
		PaymentMethodEnum.DeliveryPartnerPlease,
		PaymentMethodEnum.DeliveryPartnerUberEats,
		PaymentMethodEnum.ConnecsSodexo,
		PaymentMethodEnum.DeliveryPartnerLyveats,
		PaymentMethodEnum.DeliveryPartnerZotLivraison,
	];

	report$ = this._accountingFacade.accountingReport$.pipe(
		filter((report) => !!report.id),
		map((report) => {
			const sortedReport = cloneDeep(report);
			// removing rendu
			sortedReport.records_by_payment_method = (sortedReport.records_by_payment_method as AccountingReportByPaymentMethodRecord[]).filter(
				(e) => e.payment_method != PaymentMethodEnum.Rendu
			);
			(sortedReport.records_by_product_group as AccountingReportByProductGroupRecord[]).sort(
				(a, b) => this.productGroupOrder.indexOf(a.channel) - this.productGroupOrder.indexOf(b.channel)
			);
			(sortedReport.records_by_payment_method as AccountingReportByPaymentMethodRecord[]).sort(
				(a, b) => this.paymentMethodOrder.indexOf(a.payment_method) - this.paymentMethodOrder.indexOf(b.payment_method)
			);
			return sortedReport;
		}),
		map((report) => {
			const formattedReport = {
				day: report.business_day,
				confirmed_at: report.confirmed_at,
				confirmed_by: report.confirmed_by,
				id: report.id,
				records_by_product_group: (report.records_by_product_group as []).map((record: AccountingReportByProductGroupRecord) => ({
					articleType: this.i18n.products[record.product_group] || record.product_group,
					consoType: this.i18n.conso_types[record.channel] || record.channel,
					total_value_without_vat: record.total_value_without_vat,
					total_vat: record.total_vat,
					total: (record.total_value_without_vat + record.total_vat).toFixed(2),
				})),
				records_by_payment_method: (report.records_by_payment_method as []).map((record: AccountingReportByPaymentMethodRecord) => ({
					paymentMode: this.i18n.payment_methods[record.payment_method] || record.payment_method,
					declared_value: record.declared_value,
					validated_value: record.validated_value,
				})),
			};
			const totalRecordProductGroup = formattedReport.records_by_product_group.reduce(
				(acc, record) => {
					acc.total_value_without_vat = Math.round((record.total_value_without_vat + acc.total_value_without_vat) * 1e2) / 1e2;
					acc.total_vat = Math.round((record.total_vat + acc.total_vat) * 1e2) / 1e2;
					acc.total = (parseFloat(record.total) + parseFloat(acc.total)).toFixed(2);
					return acc;
				},
				{
					articleType: 'TOTAL',
					consoType: '',
					total_value_without_vat: 0,
					total_vat: 0,
					total: '0',
				}
			);
			// total lines ahead
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			formattedReport.records_by_product_group.push(totalRecordProductGroup);
			const totalPaymentMethods = formattedReport.records_by_payment_method.reduce(
				(acc, record) => {
					acc.declared_value = record.declared_value + acc.declared_value;
					acc.validated_value = record.validated_value + acc.validated_value;
					return acc;
				},
				{
					paymentMode: 'TOTAL',
					declared_value: 0,
					validated_value: 0,
				}
			);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			formattedReport.records_by_payment_method.push(totalPaymentMethods);
			this.reportDetail = cloneDeep(report);
			return formattedReport;
		})
	);

	constructor(
		private readonly _accountingFacade: AccountingFacade,
		private router: Router,
		private route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.route.params.subscribe((params: Params) => {
			const date = DateTime.fromFormat(params.date, 'yyyy-MM-dd');
			this.date.setValue(date.toJSDate());
			this.subscription = this._accountingFacade.myDefaultRestaurant$.subscribe(() => {
				this._accountingFacade.fetchAccountingReport(date);
			});
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	edit() {
		this.editing = true;
	}

	save() {
		this.editing = false;
	}

	goBack() {
		this.router.navigate(['accounting']);
	}

	handleEvent(e) {
		(this.reportDetail.records_by_payment_method as Array<AccountingReportByPaymentMethodRecord>).forEach((record, index) => {
			this.reportDetail.records_by_payment_method[index].validated_value = e[index].validated_value;
		});
		const records_by_payment_method = {};
		(this.reportDetail.records_by_payment_method as []).forEach((record: AccountingReportByPaymentMethodRecord) => {
			records_by_payment_method[record.payment_method] = record.validated_value;
		});
		this._accountingFacade.updateReport(DateTime.fromJSDate(this.date.value), records_by_payment_method);
		// this._accountingFacade.fetchAccountingReport(DateTime.fromJSDate(this.date.value));
	}

	validate() {
		this._accountingFacade.saveReport(DateTime.fromJSDate(this.date.value));
	}

	setPeriod(e) {
		const dateParam = DateTime.fromJSDate(e.value).toFormat('yyyy-MM-dd');
		this.router.navigate(['/accounting/review', dateParam]);
	}

	changePeriod(label) {
		let dateParam = DateTime.fromJSDate(this.date.value);
		if (label === 'next') {
			dateParam = dateParam.plus({ days: 1 });
			this.date.setValue(dateParam.toJSDate());
		} else {
			dateParam = dateParam.minus({ days: 1 });
			this.date.setValue(dateParam.toJSDate());
		}
		this.router.navigate(['/accounting/review', dateParam.toFormat('yyyy-MM-dd')]);
	}
}
