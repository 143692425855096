import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { SharedMaterialModule } from '../../shared/shared-material/shared-material.module';
import { SESSIONS_HISTORY_FACADE } from './facade/sessions-history-facade.injection.token';
import { SessionsHistoryFacade } from './facade/sessions-history-facade.service';
import { SessionsReportsComponent } from './partial-components/sessions-reports/sessions-reports.component';
import { SessionsHistoryRoutingModule } from './sessions-history-routing.module';
import { SessionsHistoryComponent } from './view-component/sessions-history.component';

@NgModule({
	declarations: [SessionsHistoryComponent, SessionsReportsComponent],
	imports: [
		CommonModule,
		SessionsHistoryRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		SharedMaterialModule,
		MatSlideToggleModule,
		MatMenuModule,
	],
	providers: [
		{
			provide: SESSIONS_HISTORY_FACADE,
			useClass: SessionsHistoryFacade,
		},
	],
	bootstrap: [],
	exports: [SessionsHistoryComponent],
})
export class SessionsHistoryModule {}
