@if (viewData$ | async; as viewData) {
	<!--	<dk-filter (filter)="handleFilterChange($event)" [salesChannels]="channels"></dk-filter>-->
	<div class="dk-picker" [class.single-display]="singleDaySelection()">
		<mat-form-field appearance="fill" subscriptSizing="dynamic">
			<mat-label>{{ viewData.i18n.chooseDates }}</mat-label>
			<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
				<input [placeholder]="viewData.i18n.startDate" formControlName="from" matStartDate [hidden]="singleDaySelection()"/>
				<input [placeholder]="viewData.i18n.endDate" formControlName="to" matEndDate [class.single-display]="singleDaySelection()"/>
			</mat-date-range-input>
			<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
			<mat-date-range-picker #picker displayMode="single">
				<mat-date-range-picker-actions>
					<button mat-button matDateRangePickerCancel>{{ viewData.i18n.cancel }}</button>
					<button (click)="setPeriod('period')" color="primary" mat-raised-button matDateRangePickerApply>
						{{ viewData.i18n.apply }}
					</button>
				</mat-date-range-picker-actions>
			</mat-date-range-picker>
		</mat-form-field>
		<mat-icon (click)="setPeriod('prev')">navigate_before</mat-icon>
		<mat-icon (click)="setPeriod('next')">navigate_next</mat-icon>
		@if (isLoading | async) {
			<mat-spinner class="d-inline" diameter="20"></mat-spinner>
		}
		<button mat-button [matMenuTriggerFor]="OperatingReport" class="export-label">{{ viewData.i18n.export }}</button>
		<mat-menu #OperatingReport="matMenu" yPosition="above" xPosition="before">
			<button mat-menu-item (click)="downloadCsv()">{{ viewData.i18n.exportCsv }}</button>
			<button mat-menu-item (click)="downloadPDF()">{{ viewData.i18n.exportPDF }}</button>
			<button mat-menu-item (click)="downloadXlsx()">{{ viewData.i18n.exportXLSX }}</button>
		</mat-menu>
	</div>
	<div class="dk-mat-container">
		@if (viewData.operatingReport) {
			<table class="dki-data-table" id="operating-report">
				<thead>
				<tr>
					<th></th>
					<th>{{ viewData.i18n.total }}</th>
					<th>{{ viewData.i18n.channels['POS'] }}</th>
					<th>{{ viewData.i18n.channels['KIOSK'] }}</th>
					@for (item of viewData.operatingReport.total.by_channel | keyvalue: sortChannels; track item) {
						@if (viewData.operatingReport.total.by_channel[item.key].value && isVisible(item.key) && !hiddenChannel(item.key)) {
							<th>{{ viewData.i18n.channels[item.key] }}</th>
						}
					}
				</tr>
				</thead>
				<tbody>
					@for (row of viewData.operatingReport.by_time | keyvalue; track row) {
						<tr>
							@if (row.value.total.value) {
								<td>{{ row.key | date }}</td>
								<td>{{ totalPerRow(row) | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
								<td>{{ totalPos(row, true) | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
								<td>{{ totalKiosk(row, true) | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
								@for (item of row.value.by_channel | keyvalue: sortChannels; track item) {
									@if (viewData.operatingReport.total.by_channel[item.key].value && isVisible(item.key) && !hiddenChannel(item.key)) {
										<td>
											{{ item.value.value | currency: 'EUR':'symbol':'1.2-2' || '-' }}
										</td>
									}
								}
							}
						</tr>
					}
				</tbody>
				<tfoot>
				<tr>
					<td>{{ viewData.i18n.total }}</td>
					<td>{{ totalPerData() | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
					<td>{{ totalPos(viewData.operatingReport, false) | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
					<td>{{ totalKiosk(viewData.operatingReport, false) | currency: 'EUR':'symbol':'1.2-2' || '-' }}</td>
					@for (item of viewData.operatingReport.total.by_channel | keyvalue: sortChannels; track item) {
						@if (viewData.operatingReport.total.by_channel[item.key].value && isVisible(item.key) && !hiddenChannel(item.key)) {
							<td>
								{{ item.value.value | currency: 'EUR':'symbol':'1.2-2' || '-' }}
							</td>
						}
					}
				</tr>
				</tfoot>
			</table>
		}
	</div>
} @else {
	<div class="dk-in-progress-spinner-container">
		<mat-spinner></mat-spinner>
	</div>
}

