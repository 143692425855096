import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

import { OpeningTime } from '@libs/dash/core/entity';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'dk-time-range-select',
	templateUrl: './time-range-select.component.html',
	styleUrls: ['./time-range-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeRangeSelectComponent implements OnChanges, OnDestroy {
	private _unsubscribeAll = new Subject();

	public hoursOptions = [];
	public minutes = [];
	public overflowHours = [];
	public rangeFormControl = new FormGroup({
		from: new FormGroup({
			hours: new FormControl(null),
			minutes: new FormControl(null),
		}),
		to: new FormGroup({
			hours: new FormControl(null),
			minutes: new FormControl(null),
		}),
	});

	public viewIsReady = false;

	@Input() dayOverflowHours = 0;
	@Input() label: string;

	// TO DO: PROVIDE INTERFACE
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() openingTime: { [key: string]: any };

	// TO DO: PROVIDE INTERFACE
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	@Input() texts: { [key: string]: any };

	@Output() timeRangeChange = new EventEmitter<OpeningTime>();

	constructor() {
		this.rangeFormControl.valueChanges
			.pipe(
				map(() => {
					if (!!this.fromHoursValue && !!this.toHoursValue) {
						let endTime = '';

						// API does not handle midnight
						if ((this.toHoursValue === '24' || this.toHoursValue === '+00') && this.toMinutesValue === '00') {
							endTime = '23:55';
						} else {
							endTime = `${this.normalizeValue(this.toHoursValue)}:${this.toMinutesValue}:00`;
						}

						const outputValue = {
							from: `${this.fromHoursValue}:${this.fromMinutesValue}:00`,
							to: endTime,
						};

						this.timeRangeChange.emit(outputValue);
					}
				}),
				takeUntil(this._unsubscribeAll)
			)
			.subscribe();
		this.composeHoursOptions();
		this.composeMinutesOptions();
	}

	ngOnChanges(simpleChanges: SimpleChanges): void {
		for (const propName in simpleChanges) {
			const hasOwnProperty = Object.prototype.hasOwnProperty.call(simpleChanges, propName);

			if (hasOwnProperty) {
				switch (propName) {
					case 'openingTime':
						this.fromHoursControl.setValue(this.openingTime.from.hours);
						this.fromMinutesControl.setValue(this.openingTime.from.minutes);

						if (+this.openingTime.to.hours <= +this.openingTime.from.hours) {
							this.toHoursControl.setValue('+' + this.openingTime.to.hours);
						} else {
							this.toHoursControl.setValue(this.openingTime.to.hours);
						}

						this.toMinutesControl.setValue(this.openingTime.to.minutes);

						break;

					case 'dayOverflowHours':
						this.composeOverflowHoursOptions();
						break;
				}
			}
		}
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(true);
		this._unsubscribeAll.complete();
	}

	public get fromControl(): AbstractControl {
		return this.rangeFormControl.get('from');
	}

	public get toControl(): AbstractControl {
		return this.rangeFormControl.get('to');
	}

	public get fromHoursControl(): AbstractControl {
		return this.fromControl.get('hours');
	}

	public get fromMinutesControl(): AbstractControl {
		return this.fromControl.get('minutes');
	}

	public get toHoursControl(): AbstractControl {
		return this.toControl.get('hours');
	}

	public get toMinutesControl(): AbstractControl {
		return this.toControl.get('minutes');
	}

	public get fromHoursValue(): string | null {
		return this.fromHoursControl.value;
	}

	public get fromMinutesValue(): string | null {
		return this.fromMinutesControl.value;
	}

	public get toHoursValue(): string | null {
		return this.toHoursControl.value;
	}

	public get toMinutesValue(): string | null {
		return this.toMinutesControl.value;
	}

	public composeHoursOptions(): void {
		let leadingSign = '';
		const hoursInDay = 24;

		for (let i = 0; i <= hoursInDay; i++) {
			leadingSign = i < 10 ? '0' : '';

			const label = leadingSign + i;
			const value = label === '24' ? '+00' : label;
			this.hoursOptions.push({ label, value });
		}
	}

	public composeOverflowHoursOptions(): void {
		let leadingSign = '';

		for (let i = 1; i <= this.dayOverflowHours; i++) {
			leadingSign = i < 10 ? '0' : '';

			const value = '+' + leadingSign + i;
			const label = '+' + i + 'h (' + this.texts['next-day'] + ')';
			this.overflowHours.push({ label, value });
		}
	}

	public composeMinutesOptions(): void {
		let leadingSign = '';

		for (let i = 0; i <= 55; i++) {
			if (i === 0 || i % 5 === 0) {
				leadingSign = i < 10 ? '0' : '';

				this.minutes.push(leadingSign + i);
			}
		}
	}

	// TO DO: PROVIDE INTERFACE
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public trackByIndex(index: number) {
		return index;
	}

	public normalizeValue(value: string): string {
		return value.replace('+', '');
	}

	public isLessThanFromHours(hour: string): boolean {
		return !hour.includes('+') && +hour <= +this.fromHoursValue;
	}
}
