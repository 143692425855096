@if (!isNewRoute) {
	<dk-page-container [datePicker]="true" [editButton]="false" [title]="(localisedTexts$ | async)?.title"
	                   (dateSelectionChange)="toggleAnalyzeHistory($event)"
	                   (todaySelection)="toggleAnalyzeHistory($event, false)">
		@if (!dateSet) {
			<dk-todays-overview class="zoomed-out"></dk-todays-overview>
		} @else {
			<dk-analyze-history class="zoomed-out" [pickedDate]="date"></dk-analyze-history>
		}
	</dk-page-container>
} @else {
	@if (!dateSet) {
		<dk-todays-overview class="zoomed-out"></dk-todays-overview>
	} @else {
		<dk-analyze-history class="zoomed-out" [pickedDate]="date"></dk-analyze-history>
	}
}