export enum OrbpRole {
	/**
	 * SHOULD BE NEVER USED. FALLBACK FOR WHEN SERVER AND FRONTEND DATA DOES NOT MATCH
	 */
	Unknown = 'Unknown',

	/**
	 * Does not have any Master (obviously), but can have multiple Dependents and Mirrors.
	 */
	Master = 'Master',
	/**
	 * Dependent on Master. Inherits values from Master, but can override them.
	 * Can have multiple Masters. The Master's configuration is then merged into single object.
	 */
	Dependent = 'Dependent',
	/**
	 * Mirrors settings from Master. Can not override them. Mirrored values are readonly.
	 * Can have only single Master.
	 * It's Master can be either in Master role or Dependent Role
	 */
	Mirror = 'Mirror',
}
