import { Injectable } from '@angular/core';

import { AccountingActions, AccountingSelectors, AuthStoreSelectors, MyRestaurantsSelectors, RootStoreState } from '@libs/dash/store';
import { TranslocoService } from '@ngneat/transloco';
import { select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { CurrentUser } from "../../../store/auth/state";
import { AccountingServiceProvider } from './accounting-facade.provider.interface';

@Injectable()
export class AccountingFacade implements AccountingServiceProvider {
	currentLanguage$: Observable<string>;
	accountingReport$ = this._store.pipe(select(AccountingSelectors.accountingReport));
	pendingReports$ = this._store.pipe(select(AccountingSelectors.pendingReports));
	myDefaultRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant));
	myRestaurants$ = this._store.pipe(select(MyRestaurantsSelectors.getListOfMyRestaurants));
	i18n$ = this._translocoService.selectTranslateObject('accounting').pipe(
		withLatestFrom(this._translocoService.selectTranslateObject('channels')),
		map(([i18n, channels]) => {
			i18n['channels'] = channels;
			return i18n;
		})
	);

	accountingPermission$ = this._store.pipe(
		select(AuthStoreSelectors.getCurrentUser),
		map((user) => user.permissions.filter((permission) => permission.name && permission.name.toLowerCase() === 'accounting')[0]?.level)
	);


	isAdmin$ = this._store.pipe(
		select(AuthStoreSelectors.getCurrentUser),
		map((user: CurrentUser) => user.is_admin)
	);

	isLoading$ = this._store.pipe(select(MyRestaurantsSelectors.getIsLoading));
	isFetching$ = this._store.pipe(select(AccountingSelectors.isLoading));
	hasError$ = this._store.pipe(select(AccountingSelectors.hasError));

	constructor(
		private readonly _translocoService: TranslocoService,
		private _store: Store<RootStoreState>
	) {}

	fetchAccountingReport(businessDate: DateTime = DateTime.now()) {
		this._store.dispatch(AccountingActions.getAccountingReport({ businessDate }));
	}

	fetchPendingReport() {
		this._store.dispatch(AccountingActions.getPendingReports());
	}

	saveReport(date) {
		this._store.dispatch(AccountingActions.confirmReport({ businessDate: date }));
	}

	updateReport(date: DateTime, report) {
		this._store.dispatch(AccountingActions.updateReport({ businessDate: date, values: report }));
	}
}
