export function conditionalConsoleLog(msg: string = '', condition: boolean = false): void {
	if (condition) {
		console.log(msg);
	}
}

export function conditionalConsoleWarn(msg: string = '', condition: boolean = false): void {
	if (condition) {
		console.warn(msg);
	}
}

export function conditionalConsoleError(msg: string = '', condition: boolean = false): void {
	if (condition) {
		console.error(msg);
	}
}
