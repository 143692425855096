import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseOrders, DkiBeClickhouseAnalyticsProductReportOrders, ProductReport } from '@dki/api-client';

import { sortChannels } from '@libs/dash/core/entity';
import { TranslocoService } from '@ngneat/transloco';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import { DateTime } from 'luxon';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { REPORTS_FACADE } from '../../facade/reports-facade.injection.token';
import { ReportsServiceProvider } from '../../facade/reports-facade.provider.interface';

type ProductCountAndValue = {
	count: number;
	value: number;
};

type ProductSoloAndInMenu = {
	inMenu: ProductCountAndValue;
	solo: ProductCountAndValue;
};

type ProductsData = {
	productName: string;
	total: ProductSoloAndInMenu;
	channels: { [key: string]: ProductSoloAndInMenu };
}[];

@Component({
	selector: 'dk-products-report-tile',
	templateUrl: './products-report-tile.component.html',
	styleUrls: ['./products-report-tile.component.scss'],
})
export class ProductsReportTileComponent implements OnInit, OnDestroy {
	destroy$: Subject<boolean> = new Subject<boolean>();
	isLoading = this.reportsServiceProvider.isLoadingProductsReport$;
	loadedReport: ProductsData;
	i18n: Record<string, any>;
	exportLoading = false;

	dateRange = new FormGroup({
		from: new FormControl(this.reportsServiceProvider.ranges.productsReport.from.toJSDate()),
		to: new FormControl(this.reportsServiceProvider.ranges.productsReport.to.toJSDate()),
	});

	selectedRestaurants: Array<string>;

	public viewData$ = combineLatest([
		this.reportsServiceProvider.productsReport$.pipe(
			filter((x) => !!x),
			map((x: ProductReport) => this.prepareTableDataSource(x))
		),
		this.translocoService.selectTranslateObject('productsReportTile'),
	]).pipe(
		map(([productsReport, i18n]) => {
			if (productsReport) {
				productsReport.sort((a, b) => {
					return Math.max(b.total.inMenu.value, b.total.solo.value) - Math.max(a.total.inMenu.value, a.total.solo.value);
				});
			}
			return { productsReport, i18n };
		}),
		takeUntil(this.destroy$),
		tap((mappedViewData) => {
			this.i18n = mappedViewData.i18n;
		})
	);

	constructor(
		@Inject(REPORTS_FACADE) private readonly reportsServiceProvider: ReportsServiceProvider,
		private readonly translocoService: TranslocoService
	) {}

	ngOnInit(): void {
		this.reportsServiceProvider.selectedRestaurants$
			.pipe(
				filter((restaurants) => restaurants.length > 0 && !!restaurants[0]),
				takeUntil(this.destroy$)
			)
			.subscribe((restaurants) => {
				this.selectedRestaurants = restaurants.map((restaurant) => restaurant.name);
			});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	prepareTableDataSource(data: ProductReport): ProductsData {
		let products = data.by_family['<no data>']?.by_product;

		if (Object.values(data.by_family).length === 0) {
			return undefined;
		}
		products = Object.values(data.by_family)[0].by_product;
		const toCountAndValue = (data: BaseOrders): ProductCountAndValue => ({ value: data.value, count: data.count });
		const toSoloAndInMenu = (data: DkiBeClickhouseAnalyticsProductReportOrders): ProductSoloAndInMenu => ({
			solo: toCountAndValue(data.in_menu),
			inMenu: toCountAndValue(data.solo),
		});
		const arr = [];
		Object.values(data.by_family).forEach((category) => {
			const product = category.by_product;
			const result = Object.entries(product).map(([key, value]) => {
				const channels: { [key: string]: ProductSoloAndInMenu } = {};

				for (const [k, v] of Object.entries(value.by_channel)) {
					channels[k] = toSoloAndInMenu(v);
				}

				return {
					productName: key,
					total: toSoloAndInMenu(value.total),
					channels,
				};
			});
			arr.push(...result);
		});
		this.loadedReport = arr;
		return arr;
	}

	setPeriod(period?: string) {
		const today = DateTime.now();
		let from = DateTime.fromJSDate(this.dateRange.controls.from.value),
			to = DateTime.fromJSDate(this.dateRange.controls.to.value);
		switch (period) {
			case 'today':
				from = today;
				to = today;
				break;
			case 'week':
				from = today.startOf('week');
				to = today.endOf('week');
				break;
			case 'month':
				from = today.startOf('month');
				to = today.endOf('month');
				break;
			case 'period':
				to = !this.dateRange.controls.to.value ? from : to;
				break;
			case 'next':
				from = from.plus({ days: 7 });
				to = to.plus({ days: 7 });
				break;
			case 'prev':
				from = from.plus({ days: -7 });
				to = to.plus({ days: -7 });
				break;
		}
		this.dateRange.setValue({ from: from.toJSDate(), to: to.toJSDate() });
		this.reportsServiceProvider.getProductsReport(from, to);
	}

	downloadCSV() {
		const csv = this.loadedReport.map((entry) => {
			const csvEntry = {
				[this.i18n.product]: entry.productName,
				[`${this.i18n.total} ${this.i18n.count}`]: entry.total.solo.count + entry.total.inMenu.count,
				[`${this.i18n.total} ${this.i18n.value}`]: Number(entry.total.solo.value + entry.total.inMenu.value).toFixed(2),
			};
			Object.keys(entry.channels).forEach((channel) => {
				csvEntry[`${this.i18n[channel]} ${this.i18n.solo} ${this.i18n.count} `] = entry.channels[channel].solo.count;
				csvEntry[`${this.i18n[channel]} ${this.i18n.solo} ${this.i18n.value} `] = Number(entry.channels[channel].solo.value).toFixed(2);
				csvEntry[`${this.i18n[channel]} ${this.i18n.inMenu} ${this.i18n.count} `] = entry.channels[channel].inMenu.count;
				csvEntry[`${this.i18n[channel]} ${this.i18n.inMenu} ${this.i18n.value} `] = Number(entry.channels[channel].inMenu.value).toFixed(2);
			});
			return csvEntry;
		});
		const options = {
			filename: this.i18n.title,
			decimalSeparator: '.',
			fieldSeparator: ';',
			showLabels: true,
			showTitle: true,
			title: this.i18n.title,
			useKeysAsHeaders: true,
		};
		const csvExporter = new ExportToCsv(options);
		csvExporter.generateCsv(csv);
	}

	downloadPDF() {
		this.pdfExport('product-report', 0.09);
	}

	pdfExport(id, scale = 0.28) {
		this.exportLoading = true;
		const dateSelected = `${DateTime.fromJSDate(this.dateRange.controls.from.value).toFormat('yyyy-MM-dd')} - ${DateTime.fromJSDate(
			this.dateRange.controls.from.value
		).toFormat('yyyy-MM-dd')}`;
		const title = String(`${this.i18n.title}_${dateSelected}`);
		const source = document.getElementById(id);
		const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true });
		doc.setFontSize(16);
		doc.setFont(undefined, 'bold');
		doc.text(this.selectedRestaurants.join(','), 20, 15);
		doc.setFontSize(12);
		doc.setFont(undefined, 'light');
		doc.text(`${dateSelected}`, 20, 20);
		doc.html(source, {
			html2canvas: {
				scale: scale,
				letterRendering: false,
				ignoreElements: (e) => e.classList.contains('export-buttons'),
			},
			margin: [30, 5, 5, 5],
			windowWidth: 1000,
			width: 900,
			fontFaces: [
				{
					family: 'Roboto',
					src: [
						{
							url: '/assets/fonts/roboto.ttf',
							format: 'truetype',
						},
					],
				},
			],
			callback: (doc) => {
				doc.save(title);
				this.exportLoading = false;
			},
		});
	}

	sortChannels(a, b) {
		return sortChannels(a.key, b.key);
	}

	singleDaySelection() {
		const from = this.dateRange.controls.from.value as Date;
		const to = this.dateRange.controls.to.value as Date;
		return from.getDate() === to.getDate() && from.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
	}
}
