import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ReviewComponent } from './partial-component/review/review.component';
import { AccountingComponent } from './view-component/accounting.component';

const routes: Routes = [
	{
		path: '',
		component: AccountingComponent,
	},
	{
		path: 'review/:date',
		component: ReviewComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AccountingRoutingModule {}
