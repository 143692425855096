import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';

import {
	ChartSuplementTableModule,
	GaugeModule,
	HorizontalBarsModule,
	KioskSosTileModule,
	PageContainerModule,
	PipesModule,
	SosTileModule,
	VerticalTresholdBarModule,
} from '@libs/dash/shared';
import { TODAYS_OVERVIEW_FACADE } from './facade/todays-overview-facade.injection.token';
import { TodaysOverviewFacade } from './facade/todays-overview-facade.service';
import { ChannelPerformanceTileComponent } from './partial-components/channel-performance-tile/channel-performance-tile.component';
import { DkGraphComponent } from './partial-components/revenue-graph/revenue-graph.component';
import { RevenueTileComponent } from './partial-components/revenue-tile/revenue-tile.component';
import { TopTenProductsTileComponent } from './partial-components/top-ten-products-tile/top-ten-products-tile.component';
import { WaitingTimeTileComponent } from './partial-components/waiting-time-tile/waiting-time-tile.component';
import { TodaysOverviewRoutingModule } from './todays-overview-routing.module';
import { TodaysOverviewComponent } from './view-component/todays-overview.component';

const ANGULAR_MATERIAL_ELEMENTS = [MatProgressSpinnerModule, MatButtonModule, MatIconModule];

@NgModule({
	declarations: [
		TodaysOverviewComponent,
		RevenueTileComponent,
		WaitingTimeTileComponent,
		ChannelPerformanceTileComponent,
		TopTenProductsTileComponent,
		DkGraphComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		...ANGULAR_MATERIAL_ELEMENTS,
		TodaysOverviewRoutingModule,
		PageContainerModule,
		MatGridListModule,
		PipesModule,
		GaugeModule,
		HorizontalBarsModule,
		VerticalTresholdBarModule,
		SosTileModule,
		KioskSosTileModule,
		ChartSuplementTableModule,
	],
	providers: [
		{
			provide: TODAYS_OVERVIEW_FACADE,
			useClass: TodaysOverviewFacade,
		},
	],
	exports: [TodaysOverviewComponent, DkGraphComponent],
})
export class TodaysOverviewModule {}
