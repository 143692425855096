import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { firstValueFrom, take } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TranslateInitializer {
	constructor(private transloco: TranslocoService) {
		this.transloco.getAvailableLangs().forEach((lang) => this.transloco.load(lang).pipe(take(1)).subscribe());
	}

	get(): Promise<any> {
		const activeLang = this.transloco.getActiveLang();
		const obs$ = this.transloco.load(activeLang);
		return firstValueFrom(obs$);
	}
}
