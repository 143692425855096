export const STRONG_PW_LENGTH = 16;
export const PASSWORD_ERRORS = {
	REQUIRED: 'required',
	NOT_SAME: 'notSame',
	DUPLICATE: 'duplicate',
	NOT_STRONG: 'notStrong',
};
export const PASSWORD_TYPES = {
	NEW: 'new',
	CONFIRM: 'confirm',
	CURRENT: 'current',
} as const;
export const FORM_STATUS_CHANGE = {
	INVALID: 'INVALID',
	VALID: 'VALID',
	PENDING: 'PENDING',
	DISABLED: 'DISABLED',
};
export const MIN_PASSWORD_SCORE = 3;
