import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getRestoUuidFromMap } from '@libs/dash/core';

import { FeatureMapService } from '@libs/dash/guards';
import { RootStoreState } from '@libs/dash/store';
import { Store } from '@ngrx/store';
import { QUERY_PARAM_RESTO_UUID } from '../../../../shared/entity';

@Component({
	selector: 'dk-redirect',
	templateUrl: './redirect.component.html',
	styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private fm: FeatureMapService,
		private _store: Store<RootStoreState>
	) {}

	ngOnInit(): void {
		this.route.queryParams.subscribe((params: Params) => {
			const restoUuid = getRestoUuidFromMap(params);
			if (restoUuid) {
				sessionStorage.setItem(QUERY_PARAM_RESTO_UUID, restoUuid);
			}
			if (params.auth_token) {
				sessionStorage.setItem('dki_session_id', params.auth_token);
				const queryParams = params;
				this.router.navigate([`/${this.homepage()}`], { queryParams });
			}
		});
	}

	// get home page according to featuremap
	private homepage() {
		const homepageLabel = this.fm.featureMap.homepage;
		return homepageLabel;
	}
}
