import { isFeatureEnabled, modifyProductsAvailabilityInModel, readFromLocalStorage, saveToLocalStorage } from '@libs/shared/utils';
import { updateAvailabilityItemPrices } from '@merim/utils';
import { createReducer, on } from '@ngrx/store';
import { AVAILABLE_JSON, CONFIGURATION_DOWNLOAD_TYPES, IBKBigData } from 'libs/shared/models';
import { clone, compose, lensPath, set } from 'ramda';
import { IFeatureConfigurationState } from '../interfaces';
import {
	InitData,
	LoadAvailability,
	LoadAvailabilitySuccess,
	LoadBigDatas,
	LoadBigDatasSuccess,
	LoadConfigurationSuccessType,
	LoadConfigurationType,
	LoadGlobalAppsCredentials,
	LoadGlobalAppsCredentialsSuccess,
	LoadGlobalAppsCredentialsSuccessType,
	LoadGlobalConfiguration,
	LoadGlobalConfigurationSuccess,
	LoadGlobalConfigurationSuccessType,
	LoadRestoConfigSuccess,
	LoadRestoSettingsSuccess,
	SetLoadingSuccess,
} from './actions';

export const initialState: IFeatureConfigurationState = {
	bigData: {
		data: undefined,
		loading: true,
	},
	restoSettings: {
		data: undefined,
		loading: true,
	},
	restoConfig: {
		data: undefined,
		loading: true,
	},
	availability: {
		data: undefined,
		loading: true,
	},
	api: {
		data: {
			dkiBe: {
				restoUuid: '',
				accessToken: '',
				dashUrl: '',
			},
		},
		loading: true,
	},
	globalConfiguration: {
		data: undefined,
		loading: true,
	},
};

const loadConfigurationData = (state: IFeatureConfigurationState, action: LoadConfigurationType) => {
	return <IFeatureConfigurationState>compose(set(lensPath([action.downloadType, 'loading']), true))(state);
};

const loadDkiApiCredentialsDataSuccess = (state: IFeatureConfigurationState, action: LoadGlobalAppsCredentialsSuccessType) => {
	return <IFeatureConfigurationState>(
		compose(set(lensPath(['api', 'data', 'dkiBe']), action.data.dkiBe), set(lensPath(['api', 'loading']), false))(state)
	);
};

const LoadDkiApiCredentialsData = (state: IFeatureConfigurationState) => {
	return <IFeatureConfigurationState>compose(set(lensPath(['api', 'loading']), true))(state);
};

const setLoadingSuccess = (state: IFeatureConfigurationState, action: LoadConfigurationType) => {
	return <IFeatureConfigurationState>compose(set(lensPath([action.downloadType, 'loading']), false))(state);
};

const loadAvailabilityConfigurationDataSuccess = (state: IFeatureConfigurationState, action: LoadConfigurationSuccessType) => {
	const localPriceUsage = {
		inRestaurant: false,
		delivery: false,
	}

	if (state.globalConfiguration.data) {
		localPriceUsage.inRestaurant = isFeatureEnabled(state.globalConfiguration.data.features, 'LOCAL_PRICE_CHANGE');
		localPriceUsage.delivery = isFeatureEnabled(state.globalConfiguration.data.features, 'LOCAL_PRICE_CHANGE_DELIVERY');
	}

	let updatedData: AVAILABLE_JSON = clone({ ...(action.data || state[action.downloadType].data) });

	const products =
		modifyProductsAvailabilityInModel({ ...state.bigData.data?.products }, state.bigData, { ...state.availability, data: updatedData }) ||
		{};

	if(!localPriceUsage.inRestaurant || !localPriceUsage.delivery) {
		updateAvailabilityItemPrices(updatedData?.priceDefinitions?.ingredient, state?.bigData?.data?.ingredients, localPriceUsage);
		updateAvailabilityItemPrices(updatedData?.priceDefinitions?.product, state?.bigData?.data?.products, localPriceUsage);
		updateAvailabilityItemPrices(updatedData?.priceDefinitions?.menu, state?.bigData?.data?.menus, localPriceUsage);
	}

	saveToLocalStorage(CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY, updatedData);
	return <IFeatureConfigurationState>(
		compose(
			set(lensPath([action.downloadType, 'loading']), false),
			set(lensPath([[CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA], 'data', 'products']), products),
			set(lensPath([action.downloadType, 'data']), updatedData)
		)(state)
	);
};

const loadRestoConfigSuccess = (state: IFeatureConfigurationState, action: LoadConfigurationSuccessType) => {
	saveToLocalStorage(CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG, action.data);
	return <IFeatureConfigurationState>(
		compose(set(lensPath([action.downloadType, 'loading']), false), set(lensPath([action.downloadType, 'data']), action.data))(state)
	);
};

const loadRestoSettingsSuccess = (state: IFeatureConfigurationState, action: LoadConfigurationSuccessType) => {
	saveToLocalStorage(CONFIGURATION_DOWNLOAD_TYPES.RESTO_SETTINGS, action.data);
	return <IFeatureConfigurationState>(
		compose(set(lensPath([action.downloadType, 'loading']), false), set(lensPath([action.downloadType, 'data']), action.data))(state)
	);
};

const loadBigDataDataSuccess = (state: IFeatureConfigurationState, action: LoadConfigurationSuccessType) => {
	let updatedData = clone({ ...action.data });
	const products = modifyProductsAvailabilityInModel(
		{ ...(action.data as IBKBigData).products },
		{
			loading: false,
			data: updatedData,
		},
		state.availability
	);
	const newData = { ...updatedData, products };
	saveToLocalStorage(CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA, newData);
	return <IFeatureConfigurationState>(
		compose(set(lensPath([action.downloadType, 'loading']), false), set(lensPath([action.downloadType, 'data']), newData))(state)
	);
};

const loadGlobalConfigurationData = (state: IFeatureConfigurationState) => {
	return <IFeatureConfigurationState>compose(set(lensPath(['globalConfiguration', 'loading']), true))(state);
};

const loadGlobalConfigurationDataSuccess = (state: IFeatureConfigurationState, action: LoadGlobalConfigurationSuccessType) => {
	return <IFeatureConfigurationState>(
		compose(set(lensPath(['globalConfiguration', 'data']), action.data), set(lensPath(['globalConfiguration', 'loading']), false))(state)
	);
};

const initDataFromLocalStorage = (state: IFeatureConfigurationState) => {
	const bigData = readFromLocalStorage(CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA);
	const availability = readFromLocalStorage(CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY);
	const restoSettings = readFromLocalStorage(CONFIGURATION_DOWNLOAD_TYPES.RESTO_SETTINGS);
	const restoConfig = readFromLocalStorage(CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG);
	return <IFeatureConfigurationState>(
		compose(
			set(lensPath([CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA, 'loading']), !!bigData),
			set(lensPath([CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY, 'loading']), !!availability),
			set(lensPath([CONFIGURATION_DOWNLOAD_TYPES.RESTO_SETTINGS, 'loading']), !!restoSettings),
			set(lensPath([CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG, 'loading']), !!restoConfig),
			set(lensPath([CONFIGURATION_DOWNLOAD_TYPES.BIG_DATA, 'data']), bigData || {}),
			set(lensPath([CONFIGURATION_DOWNLOAD_TYPES.AVAILABILITY, 'data']), availability || {}),
			set(lensPath([CONFIGURATION_DOWNLOAD_TYPES.RESTO_SETTINGS, 'data']), restoSettings || {}),
			set(lensPath([CONFIGURATION_DOWNLOAD_TYPES.RESTO_CONFIG, 'data']), restoConfig || {})
		)(state)
	);
};

export const reducer = createReducer(
	initialState,
	on(InitData, initDataFromLocalStorage),
	on(LoadAvailability, loadConfigurationData),
	on(LoadBigDatas, loadConfigurationData),
	on(LoadAvailabilitySuccess, loadAvailabilityConfigurationDataSuccess),
	on(LoadGlobalAppsCredentialsSuccess, loadDkiApiCredentialsDataSuccess),
	on(LoadGlobalAppsCredentials, LoadDkiApiCredentialsData),
	on(LoadBigDatasSuccess, loadBigDataDataSuccess),
	on(SetLoadingSuccess, setLoadingSuccess),
	on(LoadRestoSettingsSuccess, loadRestoSettingsSuccess),
	on(LoadRestoConfigSuccess, loadRestoConfigSuccess),
	on(LoadGlobalConfiguration, loadGlobalConfigurationData),
	on(LoadGlobalConfigurationSuccess, loadGlobalConfigurationDataSuccess)
);
