import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { FeatureMapService } from '@libs/dash/guards';
import { UiVersionDetectorService } from '@libs/shared/services';
import { TranslocoService } from '@ngneat/transloco';

@Component({
	selector: 'dk-history-logs',
	templateUrl: './history-logs.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryLogsComponent {
	public localisedTexts$ = this._translocoService.selectTranslateObject('history-logs');
	featuremap = this.fm.featureMap;

	private readonly _newUiDetector: UiVersionDetectorService = inject(UiVersionDetectorService);
	isNewRoute = this._newUiDetector.isNewUi();

	constructor(
		private _translocoService: TranslocoService,
		private fm: FeatureMapService
	) {}
}
