import { Component } from '@angular/core';
import * as api from '@dki/api-client';

import { ChannelOrders } from '@libs/dash/core/entity';
import { HistoricStatisticsSelectors, RouterStoreSelectors } from '@libs/dash/store';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'dk-dashboard-item-detail',
	templateUrl: './dashboard-item-detail.component.html',
	styleUrls: ['./dashboard-item-detail.component.scss'],
})
export class DashboardItemDetailComponent {
	viewData$ = combineLatest([
		this._store$.select(RouterStoreSelectors.getSelectedDataSourceNameQueryParam),
		this._store$.select(RouterStoreSelectors.getSelectedItemNameQueryParam),
		this._store$.select(HistoricStatisticsSelectors.selectItemFromDatasource),
		this._store$.select(RouterStoreSelectors.getCurrentLanguageParam),
		this._translocoService.selectTranslateObject('dashboard-item-detail'),
		this._translocoService.selectTranslateObject('table-row-labels'),
		this._translocoService.selectTranslateObject('dashboard-item-parent'),
	]).pipe(
		map(
			([
				selectedDataSourceNameQueryParam,
				selectedItemNameQueryParam,
				itemFromDatasource,
				currentLanguage,
				texts,
				rowLabels,
				parentNames,
			]) => {
				// console.log('%c ::: DashboardItemDetailComponent :::', 'color: yellow;', selectedDataSourceNameQueryParam, selectedItemNameQueryParam, itemFromDatasource);

				if (selectedDataSourceNameQueryParam && selectedItemNameQueryParam && itemFromDatasource) {
					const sectionTitlesPerDataSource = {
						ordersByChannelWithAverages: 'Channel performance',
						topTenProducts: 'Product sales status',
					};

					return {
						sectionTitle: sectionTitlesPerDataSource[selectedDataSourceNameQueryParam],
						itemName: selectedItemNameQueryParam.replace(/_/g, ' '),
						tableRows: this.composeTableRows(itemFromDatasource),
						currentLanguage,
						texts,
						rowLabels,
						parentNames,
					};
				} else {
					// TO DO:
					// IF NOT LOADING, FETCH DATA
					return undefined;
				}
			}
		)
	);

	constructor(
		private _translocoService: TranslocoService,
		private _store$: Store
	) {}

	translateRowLabel(cellValue: string | number, translations: { [key: string]: string }): string | number {
		if (typeof cellValue === 'string') {
			const normalizedCellValue = cellValue.replace(/\s/g, '_');

			return translations[normalizedCellValue] ? translations[normalizedCellValue] : cellValue;
		} else {
			return cellValue;
		}
	}

	translateParentName(parentName: string, translations: { [key: string]: string }): string {
		const normalizedParentName = parentName.replace(/\s/g, '-').toLowerCase();

		return translations[normalizedParentName] ? translations[normalizedParentName] : normalizedParentName;
	}

	private composeTableRows(rawData: ChannelOrders | api.OrdersByTime | api.ProductOrders | api.ProductOrders): string[][] {
		const rows = [];

		Object.keys(rawData).forEach((dataSourceEntry) => {
			const row = [];

			if (dataSourceEntry !== 'channel') {
				let displayValue = rawData[dataSourceEntry];

				if (typeof displayValue === 'number') {
					displayValue = displayValue.toFixed(2);
				}

				row.push(dataSourceEntry);
				row.push(displayValue);
			}

			rows.push(row);
		});

		return rows;
	}
}
