<div id="dtt-report" class="bkc" #bkcReport>
	@if (viewdata.dttReport && viewdata.dttReport.length > 0) {
		<h2>
			{{ restaurantName || viewdata.i18n.dtt_report.title }}
		</h2>
	}
	@if (viewdata.dttReport && viewdata.dttReport.length > 0) {
		<table class="dtt-report" id="dtt-table">
			<thead>
			<tr>
				@for (header of viewdata.i18n.dtt_report['bkc'] | keyvalue: originalOrder; track header) {
					<th>{{ header.value }}</th>
				}
			</tr>
			</thead>
			<tbody>
				@for (part of viewdata.i18n.dtt_report['day_part'] | keyvalue: originalOrder; track part; let index = $index; let last = $last) {
					<tr [class.highlight]="last">
						<td>
							<!-- dayPart-->
							<p>{{ part.value }}</p>
							@if (!last) {
								<p class="day-part">{{ dayParts[index][0] }}h {{ viewdata.i18n.dtt_report.to }} {{ dayParts[index][1] }}h</p>
							}
						</td>
						<!-- CA -->
						@if (!last) {
							<td>
								{{ viewdata.dttReport[index]?.total_revenue | currency: 'EUR':'symbol':'1.2-2' || '-' }}
							</td>
						}
						@if (last) {
							<td>
								{{ total(viewdata.dttReport, 'total_revenue') | currency: 'EUR':'symbol':'1.2-2' || '-' }}
							</td>
						}
						<!-- avg tx-->
						@if (!last) {
							<td>
								{{
									viewdata.dttReport[index]?.total_revenue && viewdata.dttReport[index]?.total_revenue / viewdata.dttReport[index]?.count
										| currency: 'EUR':'symbol':'1.2-2' || '-'
								}}
							</td>
						}
						@if (last) {
							<td>
								{{ total(viewdata.dttReport, 'total_revenue') / total(viewdata.dttReport, 'count') | currency: 'EUR':'symbol':'1.2-2' || '-' }}
							</td>
						}
						<!-- number of tx -->
						@if (!last) {
							<td>{{ viewdata.dttReport[index]?.count || '-' }}</td>
						}
						@if (last) {
							<td>{{ total(viewdata.dttReport, 'count') || '-' }}</td>
						}
						<!-- cars count -->
						@if (!last) {
							<td>{{ (viewdata.dttReport[index]?.count && viewdata.dttReport[index]?.count - viewdata.dttReport[index]?.sos_samecar) || '-' }}</td>
						}
						@if (last) {
							<td>{{ total(viewdata.dttReport, 'count') - total(viewdata.dttReport, 'sos_samecar') || '-' }}</td>
						}
						<td>
							<p class="raw">
								{{
									last
										? (totalAverage(viewdata.dttReport, 'mean_sos_waittime', false) + totalAverage(viewdata.dttReport, 'mean_sos_ordertime', false) | toMins)
										: (viewdata.dttReport[index]?.mean_sos_waittime + viewdata.dttReport[index]?.mean_sos_ordertime | toMins)
								}}
							</p>
						</td>
						<td>
							@if (last) {
								<p class="raw">
									{{
										totalAverage(viewdata.dttReport, 'mean_sos_paytime', false) +
										totalAverage(viewdata.dttReport, 'mean_sos_linetime', false) +
										totalAverage(viewdata.dttReport, 'mean_sos_hardservetime', false) | toMins
									}}
								</p>
							}
							@if (!last) {
								<p class="raw">
									{{
										(viewdata.dttReport[index]?.mean_sos_paytime || 0) +
										(viewdata.dttReport[index]?.mean_sos_hardservetime || 0) +
										(viewdata.dttReport[index]?.mean_sos_linetime || 0) | toMins
									}}
								</p>
							}
						</td>
						<td>
							@if (last) {
								<p class="raw">
									{{ totalAverage(viewdata.dttReport, 'mean_sos_deliverytime', false) | toMins }}
								</p>
							}
							@if (!last) {
								<p>
									{{ viewdata.dttReport[index] && getDeliveryTime(viewdata.dttReport[index]) | toMins }}
								</p>
							}
							<!-- <p class="goal" *ngIf="!last && viewdata.dttReport[index]?.mean_sos_servetime">{{ goals.mean_sos_servetime }}</p> -->
						</td>
						<td>
							@if (!last) {
								<p class="raw">{{ totalTime(viewdata.dttReport[index]) | toMins }}</p>
							}
							@if (last) {
								<p class="raw">{{ totalAverage(viewdata.dttReport, 'total', true) | toMins }}</p>
							}
						</td>
					</tr>
				}
			</tbody>
			<tfoot>
			<tr>
				<td colspan="15">
					<div></div>
					<div class="export-buttons">
						<button (click)="export('CSV')">{{ viewdata.i18n.exportCsv }}</button>
						<button (click)="export('PDF')">{{ viewdata.i18n.exportPdf }}</button>
					</div>
				</td>
			</tr>
			</tfoot>
		</table>
	}
</div>
