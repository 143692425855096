import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountingReport, ReportStatus } from '@dki/api-client';
import { DateTime, Duration } from 'luxon';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountingFacade } from '../../facade/accounting-facade.service';

@Component({
	selector: 'dk-accounting-wait-list',
	templateUrl: './wait-list.component.html',
	styleUrls: ['./wait-list.component.scss'],
})
export class AccountingWaitListComponent implements OnInit, OnDestroy {
	@Input() i18n: Record<string, any> = {};
	today = DateTime.now();
	selected = { start: this.today.startOf('year').toJSDate(), end: this.today.endOf('year').toJSDate() };
	ranges: any = {};

	filters = new BehaviorSubject({
		set: false,
		from: this.today.startOf('year').toMillis() / 1000,
		to: this.today.endOf('year').toMillis() / 1000,
	});

	subscription: Subscription;

	pendingReports$ = combineLatest([
		this.accountingService.pendingReports$,
		this.accountingService.myRestaurants$,
		this.filters,
		this.accountingService.myDefaultRestaurant$,
	]).pipe(
		map(([reports, restaurants, filters, restaurant]) => {
			const reportsWithRestaurantNames = reports
				.filter((report) => report.status === ReportStatus.New)
				.filter((report) => report.restaurant_id === restaurant.id)
				.map((report: AccountingReport) => {
					const restaurantName = restaurants.filter((res) => res.id === report.restaurant_id)[0]?.name || report.restaurant_id || '';
					return {
						date: report.business_day,
						bknumber: report.id,
						restaurant: restaurantName,
						action: 'review',
					};
				});
			if (filters.set) {
				return reportsWithRestaurantNames.filter(
					(report) =>
						DateTime.fromFormat(report.date, 'yyyy-MM-dd').toMillis() / 1000 >= filters.from &&
						DateTime.fromFormat(report.date, 'yyyy-MM-dd').toMillis() / 1000 <= filters.to
				);
			}

			return reportsWithRestaurantNames;
		})
	);

	constructor(private readonly accountingService: AccountingFacade) {}

	ngOnInit(): void {
		this.ranges = {
			[this.i18n.today]: [DateTime.now().startOf('day'), DateTime.now().endOf('day')],
			[this.i18n.yesterday]: [this.today.minus(Duration.fromObject({ days: 1 })), this.today.minus(Duration.fromObject({ days: 1 }))],
			[this.i18n.last_week]: [this.today.minus(Duration.fromObject({ days: 7 })), this.today],
			[this.i18n.last_thirties]: [this.today.minus(Duration.fromObject({ days: 29 })), this.today],
			[this.i18n.this_month]: [this.today.startOf('month'), this.today.endOf('month')],
			[this.i18n.last_month]: [
				this.today.minus(Duration.fromObject({ month: 1 })).startOf('month'),
				this.today.minus(Duration.fromObject({ month: 1 })).endOf('month'),
			],
		};
		this.filters.next({
			set: true,
			from: this.today.startOf('year').toMillis() / 1000,
			to: this.today.endOf('year').toMillis() / 1000,
		});
		this.subscription = this.accountingService.myDefaultRestaurant$.subscribe((_) => {
			this.accountingService.fetchPendingReport();
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	switchDate(period) {
		if (period === 'next') {
			this.selected.end = DateTime.fromJSDate(this.selected.end).plus({ days: 7 }).toJSDate();
			this.selected.start = DateTime.fromJSDate(this.selected.start).plus({ days: 7 }).toJSDate();
		} else {
			this.selected.end = DateTime.fromJSDate(this.selected.end).minus({ days: 7 }).toJSDate();
			this.selected.start = DateTime.fromJSDate(this.selected.start).minus({ days: 7 }).toJSDate();
		}
	}

	unsetFilters() {
		this.filters.next({ set: false, from: 0, to: 0 });
	}

	datesUpdated(e) {
		if (e.startDate && e.endDate) {
			this.filters.next({
				set: true,
				from: DateTime.fromJSDate(e.startDate.$d).toMillis() / 1000,
				to: DateTime.fromJSDate(e.endDate.$d).toMillis() / 1000,
			});
		}
	}
}
