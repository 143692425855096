@if (viewData$ | async; as viewdata) {
	@if (!isNewRoute) {
		<dk-page-container [editButton]="false" [title]="viewdata.i18n.title" [sourced]="sourced">
			@if (isLoading$ | async) {
				<div class="dk-on-progress-overlay">
					<div class="dk-on-progress-spinner-container">
						<mat-spinner></mat-spinner>
					</div>
				</div>
			} @else {
				<div class="zoomed-out">
					<div class="dk-picker" [class.single-display]="singleDaySelection()">
						<mat-form-field appearance="fill" subscriptSizing="dynamic">
							<mat-label>{{ viewdata.i18n.chooseDates }}</mat-label>
							<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
								<input [placeholder]="viewdata.i18n.startDate" formControlName="from" matStartDate [hidden]="singleDaySelection()"/>
								<input [placeholder]="viewdata.i18n.endDate" formControlName="to" matEndDate [class.single-display]="singleDaySelection()"/>
							</mat-date-range-input>
							<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
							<mat-date-range-picker #picker>
								<mat-date-range-picker-actions>
									<button mat-button matDateRangePickerCancel>{{ viewdata.i18n.cancel }}</button>
									<button (click)="setPeriod('period')" color="primary" mat-raised-button matDateRangePickerApply>
										{{ viewdata.i18n.apply }}
									</button>
								</mat-date-range-picker-actions>
							</mat-date-range-picker>
						</mat-form-field>
						<button (click)="setPeriod('today')" mat-button>{{ viewdata.i18n.today }}</button>
						<button (click)="setPeriod('week')" mat-button>{{ viewdata.i18n.week }}</button>
						<button (click)="setPeriod('month')" mat-button>{{ viewdata.i18n.month }}</button>
						@if (isLoadingDTT$ | async) {
							<mat-spinner class="d-inline" diameter="20"></mat-spinner>
						}
						@if (isExporting) {
							<mat-spinner class="d-inline" diameter="20"></mat-spinner>
						}
					</div>
					<div class="dtt-container">
						<div class="dtt-settings">
							@if (featureMap?.dtt?.bkc) {
								<mat-tab-group (selectedTabChange)="setReportType($event)">
									<mat-tab label="BKF"></mat-tab>
									<mat-tab label="BKC"></mat-tab>
								</mat-tab-group>
							}
							@if (dirtyForm) {
								<button (click)="fetchDTT()" mat-raised-button class="query-btn">{{ viewdata.i18n.apply }}</button>
							}
							<div class="row">
								<span class="days-label">{{ viewdata.i18n.days }}</span>
								<div class="days">
									@for (day of viewdata.i18n['weekdays'] | keyvalue: originalOrder; track day) {
										<div (click)="selectDay(day.key, $event)">{{ day.value }}</div>
									}
								</div>
							</div>
							<div class="row">
								<span>{{ viewdata.i18n.type_of_group }}</span>
								<div class="group-types">
									<mat-radio-group aria-label="Select an option" [(ngModel)]="groupType" (change)="sourceChanged($event)">
										@for (group of viewdata.i18n['group_type'] | keyvalue; track group) {
											<mat-radio-button [value]="group.key">{{ group.value }}</mat-radio-button>
										}
									</mat-radio-group>
									@if (multiSelection() && viewdata.dttReport) {
										<div class="export-buttons">
											<button mat-raised-button (click)="exportAllCSV(viewdata)">{{ viewdata.i18n.exportAllCsv }}</button>
											<button mat-raised-button (click)="exportAllPDF(viewdata)">{{ viewdata.i18n.exportAllPdf }}</button>
										</div>
									}
								</div>
							</div>
							<div class="row">
								<span>{{ viewdata.i18n.source_type }}</span>
								<div class="source-types">
									<mat-radio-group aria-label="Select an option" [(ngModel)]="sourceType" (change)="sourceChanged($event)">
										@for (source of viewdata.i18n['source_types'] | keyvalue; track source) {
											<mat-radio-button [value]="source.key">{{ source.value }}</mat-radio-button>
										}
									</mat-radio-group>
								</div>
							</div>
						</div>
						@if (!multiSelection()) {
							<div class="dtt-report-table">
								@if (!isBKC) {
									<bkf-report
										[viewdata]="viewdata"
										[sourceType]="sourceType"
										[selectedRestaurants]="selectedRestaurants"
										[dateRange]="dateRange"
										[goals]="goals"
										[dayParts]="dayParts"
									></bkf-report>
								}
								@if (isBKC) {
									<bkc-report
										[viewdata]="viewdata"
										[sourceType]="sourceType"
										[selectedRestaurants]="selectedRestaurants"
										[dateRange]="dateRange"
										[goals]="goals"
										[dayParts]="dayParts"
									></bkc-report>
								}
							</div>
						}
						@if (multiSelection()) {
							<div class="dtt-report-table" id="multiple-dtt-report">
								@for (data of $any(viewdata.dttReport) | keyvalue: originalOrder; track data) {
									<bkf-report
										[viewdata]="$any({ dttReport: data.value, i18n: viewdata.i18n })"
										[sourceType]="sourceType"
										[selectedRestaurants]="selectedRestaurants"
										[dateRange]="dateRange"
										[goals]="goals"
										[dayParts]="dayParts"
										[restaurantName]="restaurantName(data.key)"
										[hidden]="isBKC"
									></bkf-report>
								}
								@for (data of $any(viewdata.dttReport) | keyvalue: originalOrder; track data) {
									<bkc-report
										[viewdata]="$any({ dttReport: data.value, i18n: viewdata.i18n })"
										[sourceType]="sourceType"
										[selectedRestaurants]="selectedRestaurants"
										[dateRange]="dateRange"
										[goals]="goals"
										[dayParts]="dayParts"
										[restaurantName]="restaurantName(data.key)"
										[hidden]="!isBKC"
									></bkc-report>
								}
							</div>
						}
					</div>
				</div>
			}
		</dk-page-container>
	} @else {
		@if (isLoading$ | async) {
			<div class="dk-on-progress-overlay">
				<div class="dk-on-progress-spinner-container">
					<mat-spinner></mat-spinner>
				</div>
			</div>
		} @else {
			<div class="zoomed-out">
				<div class="dk-picker" [class.single-display]="singleDaySelection()">
					<mat-form-field appearance="fill" subscriptSizing="dynamic">
						<mat-label>{{ viewdata.i18n.chooseDates }}</mat-label>
						<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
							<input [placeholder]="viewdata.i18n.startDate" formControlName="from" matStartDate [hidden]="singleDaySelection()"/>
							<input [placeholder]="viewdata.i18n.endDate" formControlName="to" matEndDate [class.single-display]="singleDaySelection()"/>
						</mat-date-range-input>
						<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
						<mat-date-range-picker #picker>
							<mat-date-range-picker-actions>
								<button mat-button matDateRangePickerCancel>{{ viewdata.i18n.cancel }}</button>
								<button (click)="setPeriod('period')" color="primary" mat-raised-button matDateRangePickerApply>
									{{ viewdata.i18n.apply }}
								</button>
							</mat-date-range-picker-actions>
						</mat-date-range-picker>
					</mat-form-field>
					<button (click)="setPeriod('today')" mat-button>{{ viewdata.i18n.today }}</button>
					<button (click)="setPeriod('week')" mat-button>{{ viewdata.i18n.week }}</button>
					<button (click)="setPeriod('month')" mat-button>{{ viewdata.i18n.month }}</button>
					@if (isLoadingDTT$ | async) {
						<mat-spinner class="d-inline" diameter="20"></mat-spinner>
					}
					@if (isExporting) {
						<mat-spinner class="d-inline" diameter="20"></mat-spinner>
					}
				</div>
				<div class="dtt-container">
					<div class="dtt-settings">
						@if (featureMap?.dtt?.bkc) {
							<mat-tab-group (selectedTabChange)="setReportType($event)">
								<mat-tab label="BKF"></mat-tab>
								<mat-tab label="BKC"></mat-tab>
							</mat-tab-group>
						}
						@if (dirtyForm) {
							<button (click)="fetchDTT()" mat-raised-button class="query-btn">{{ viewdata.i18n.apply }}</button>
						}
						<div class="row">
							<span class="days-label">{{ viewdata.i18n.days }}</span>
							<div class="days">
								@for (day of viewdata.i18n['weekdays'] | keyvalue: originalOrder; track day) {
									<div (click)="selectDay(day.key, $event)">{{ day.value }}</div>
								}
							</div>
						</div>
						<div class="row">
							<span>{{ viewdata.i18n.type_of_group }}</span>
							<div class="group-types">
								<mat-radio-group aria-label="Select an option" [(ngModel)]="groupType" (change)="sourceChanged($event)">
									@for (group of viewdata.i18n['group_type'] | keyvalue; track group) {
										<mat-radio-button [value]="group.key">{{ group.value }}</mat-radio-button>
									}
								</mat-radio-group>
								@if (multiSelection() && viewdata.dttReport) {
									<div class="export-buttons">
										<button mat-raised-button (click)="exportAllCSV(viewdata)">{{ viewdata.i18n.exportAllCsv }}</button>
										<button mat-raised-button (click)="exportAllPDF(viewdata)">{{ viewdata.i18n.exportAllPdf }}</button>
									</div>
								}
							</div>
						</div>
						<div class="row">
							<span>{{ viewdata.i18n.source_type }}</span>
							<div class="source-types">
								<mat-radio-group aria-label="Select an option" [(ngModel)]="sourceType" (change)="sourceChanged($event)">
									@for (source of viewdata.i18n['source_types'] | keyvalue; track source) {
										<mat-radio-button [value]="source.key">{{ source.value }}</mat-radio-button>
									}
								</mat-radio-group>
							</div>
						</div>
					</div>
					@if (!multiSelection()) {
						<div class="dtt-report-table">
							@if (!isBKC) {
								<bkf-report
									[viewdata]="viewdata"
									[sourceType]="sourceType"
									[selectedRestaurants]="selectedRestaurants"
									[dateRange]="dateRange"
									[goals]="goals"
									[dayParts]="dayParts"
								></bkf-report>
							}
							@if (isBKC) {
								<bkc-report
									[viewdata]="viewdata"
									[sourceType]="sourceType"
									[selectedRestaurants]="selectedRestaurants"
									[dateRange]="dateRange"
									[goals]="goals"
									[dayParts]="dayParts"
								></bkc-report>
							}
						</div>
					}
					@if (multiSelection()) {
						<div class="dtt-report-table" id="multiple-dtt-report">
							@for (data of $any(viewdata.dttReport) | keyvalue: originalOrder; track data) {
								<bkf-report
									[viewdata]="$any({ dttReport: data.value, i18n: viewdata.i18n })"
									[sourceType]="sourceType"
									[selectedRestaurants]="selectedRestaurants"
									[dateRange]="dateRange"
									[goals]="goals"
									[dayParts]="dayParts"
									[restaurantName]="restaurantName(data.key)"
									[hidden]="isBKC"
								></bkf-report>
							}
							@for (data of $any(viewdata.dttReport) | keyvalue: originalOrder; track data) {
								<bkc-report
									[viewdata]="$any({ dttReport: data.value, i18n: viewdata.i18n })"
									[sourceType]="sourceType"
									[selectedRestaurants]="selectedRestaurants"
									[dateRange]="dateRange"
									[goals]="goals"
									[dayParts]="dayParts"
									[restaurantName]="restaurantName(data.key)"
									[hidden]="!isBKC"
								></bkc-report>
							}
						</div>
					}
				</div>
			</div>
		}
	}
}
