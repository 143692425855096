import { ChangeDetectionStrategy, Component, inject, Signal } from '@angular/core';
import { CommonLayoutService } from '@libs/shared/modules/common-layout';

@Component({
	selector: 'merim-common-layout-header',
	templateUrl: './common-layout-header.component.html',
	styleUrl: './common-layout-header.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonLayoutHeaderComponent<T> {
	private readonly _commonLayoutService: CommonLayoutService<T> = inject(CommonLayoutService<T>);

	userName: Signal<string> = this._commonLayoutService.userName;

	languageChange(): void {
		this._commonLayoutService.languageChangeSubject.next();
	}
}
