import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@libs/shared/modules/i18n';
import { CommonLayoutContentComponent } from './components/common-layout-content/common-layout-content.component';
import { CommonLayoutHeaderComponent } from './components/common-layout-header/common-layout-header.component';
import { CommonLayoutMenuComponent } from './components/common-layout-menu/common-layout-menu.component';
import { CommonLayoutComponent } from './components/common-layout/common-layout.component';
import { CommonLayoutService } from './services';

const components = [CommonLayoutComponent, CommonLayoutContentComponent, CommonLayoutHeaderComponent, CommonLayoutMenuComponent];
const services = [CommonLayoutService];

@NgModule({
	declarations: [...components],
	imports: [CommonModule, RouterModule, MatIcon, MatDialogModule, MatMenuModule, TranslateModule],
	exports: [CommonLayoutComponent],
	providers: [...services],
})
export class CommonLayoutModule {}
