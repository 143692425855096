import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'dk-performance-pie-chart',
	templateUrl: './pie-chart.component.html',
	styleUrls: ['./pie-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PieChartComponent implements AfterViewInit, OnChanges {
	@ViewChild('chart') private chartContainer;
	@Input() goal = '1';
	@Input() label = 'label';
	@Input() rawValue = 0;
	@Input() goalValue = 1;
	@Input() dark = false;
	@Input() tooltip = '';

	blue = '#2196E2';
	red = '#FD3A4B';
	yellow = '#FFC107';

	// should be the same sm breakpoint in performance.component.scss
	smallBreakPoint = 1635;
	xsmallBreakPoint = 764;

	componentIsInit = false;

	ngAfterViewInit() {
		this.drawChart(this.isSmallDevice());
		const resize$ = fromEvent(window, 'resize');
		resize$.pipe(debounceTime(300)).subscribe((e) => {
			this.drawChart(this.isSmallDevice());
		});
		this.componentIsInit = true;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.componentIsInit) this.drawChart(this.isSmallDevice());
	}

	computeColors(value) {
		return parseFloat(value) >= 70 ? this.blue : value > 20 ? this.yellow : this.red;
	}

	valuePercent() {
		return Math.floor((this.rawValue * 100) / this.goalValue) || 0;
	}

	drawChart(windowSmallBreakpoint?: boolean) {
		echarts.dispose(this.chartContainer.nativeElement);
		const chart = echarts.init(this.chartContainer.nativeElement, { renderer: 'svg', height: 240 });
		const option: EChartsOption = {
			series: [
				{
					type: 'gauge',
					startAngle: 90,
					endAngle: -270,
					pointer: {
						show: false,
					},
					progress: {
						show: true,
						overlap: false,
						roundCap: true,
						clip: false,
						itemStyle: {
							color: {
								type: 'linear',
								x: 0.1,
								y: 0.9,
								x2: 0.9,
								y2: 0.2,
								colorStops: [
									{
										offset: 1,
										color: this.computeColors(this.valuePercent()),
									},
									{
										offset: 0,
										color: `${this.computeColors(this.valuePercent())}40`,
									},
								],
							},
						},
					},
					axisLine: {
						lineStyle: {},
					},
					splitLine: {
						show: false,
						distance: 0,
						length: 10,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: false,
						distance: 30,
					},
					detail: {
						formatter: (v) => `${Number.isFinite(v) ? v + '%' : 'N/A'}`,
						color: this.computeColors(this.valuePercent()),
						fontSize: '36px',
					},
					data: [
						{
							value: this.valuePercent(),
							title: {
								offsetCenter: ['0%', '0%'],
							},
							detail: {
								valueAnimation: true,
								offsetCenter: ['0%', '0%'],
							},
						},
					],
				},
			],
		};
		windowSmallBreakpoint ? (option.series[0].center = ['20%', '40%']) : void 0;
		chart.setOption(option);
	}

	isSmallDevice() {
		return window.innerWidth <= this.smallBreakPoint && window.innerWidth >= this.xsmallBreakPoint;
	}
}
