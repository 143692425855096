import { Injectable } from '@angular/core';

import {
	AuthStoreActions,
	MyRestaurantsSelectors,
	PerformanceStoreActions,
	PerformanceStoreSelectors,
	RootStoreState,
	TodaysStatisticsSelectors,
} from '@libs/dash/store';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PerformanceServiceProvider } from './performance-facade.provider.interface';

@Injectable()
export class PerformanceFacade implements PerformanceServiceProvider {
	revenueProgress$ = this._store.pipe(select(TodaysStatisticsSelectors.revenueProgress));

	loading$ = this._store.pipe(select(PerformanceStoreSelectors.isLoading));

	isFetchingRestaurants$ = this._store.pipe(select(MyRestaurantsSelectors.getIsLoading));

	isLoadingPersistentRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.isLoadingPersistedRestaurant));

	// prediciton of the current 15min
	predictionPerTimeInterval$ = this._store.pipe(select(PerformanceStoreSelectors.predictionPerTimeInterval));
	// current revenue of current 15min
	currentRevenuePerTimeInterval$ = this._store.pipe(select(PerformanceStoreSelectors.currentRevenuePerTimeInterval));

	pastHourlySales$ = this._store.pipe(select(PerformanceStoreSelectors.pastHourlySales));

	currentTotalRevenue$ = this._store.pipe(select(PerformanceStoreSelectors.currentTotalRevenue));

	pasttRevenuePerTimeInterval$ = this._store.pipe(select(PerformanceStoreSelectors.pastRevenuePerTimeInterval));

	defaultRestaurant$ = this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant));

	totalPredictedRevenue$ = this._store.pipe(select(PerformanceStoreSelectors.totalPredictedRevenue));

	pastTotalRevenue$ = this._store.pipe(select(PerformanceStoreSelectors.pastTotalRevenue));

	channelsSales$ = this._store.pipe(select(PerformanceStoreSelectors.salesPerChannel));

	revenueByPrediction$ = this._store.pipe(select(PerformanceStoreSelectors.revenueByPrediction));

	predictedAccumulated$ = this._store.pipe(select(PerformanceStoreSelectors.predictedAccumulated));

	constructor(private _store: Store<RootStoreState>) {}

	isLoadingPersistedRestaurant$: Observable<boolean>;

	fetchForecast(): void {
		this._store.dispatch(PerformanceStoreActions.getForecasting());
	}

	fetchRestaurants(): void {
		this._store.dispatch(AuthStoreActions.getCurrentUserProfileSourced());
	}

	fetchHourlySales(current?: boolean): void {
		this._store.dispatch(PerformanceStoreActions.getOrdersByTimeAndChannel({ current }));
	}
}
