<ng-container>
	<div class="flex space-between header-btns">
		<button class="no-fill-btn back-btn" (click)="goBack()">
			<mat-icon>arrow_back</mat-icon>
			{{ i18n.detail['backBtn'] }}
		</button>
		@if ((permission$ | async)?.write || (isAdmin$ | async)) {
			<button class="validate-btn" (click)="validate()">{{ i18n.detail['validate'] }}</button>
		}
	</div>
	<div class="mat-elevation-z2">
		<div class="flex space-between picker-container">
			<div class="flex">
				<span (click)="picker.open()"><mat-icon>calendar_month</mat-icon></span>
				<input [formControl]="date" [matDatepicker]="picker" matInput (click)="picker.open()" (dateChange)="setPeriod($event)"/>
				<!-- <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle> -->
				<mat-datepicker #picker></mat-datepicker>
				<span (click)="changePeriod('back')"><mat-icon>navigate_before</mat-icon></span>
				<span (click)="changePeriod('next')"><mat-icon>navigate_next</mat-icon></span>
			</div>
			@if (isLoading$ | async) {
				<mat-spinner class="d-inline" diameter="20"></mat-spinner>
			}
			<!-- <button class="no-fill-btn">
			{{ i18n['export'] }}
		</button> -->
		</div>
		@if ((isLoading$ | async) === false && (hasError$ | async) === false) {
			<div class="body-container">
				<h2>{{ i18n.detail.title }}</h2>
				<dk-data-table-edit [dataSource]="(report$ | async).records_by_product_group" [i18n]="i18n" [total]="true"></dk-data-table-edit>
			</div>
		}
	</div>
	@if ((isLoading$ | async) === false && (hasError$ | async) === false) {
		<div class="mat-elevation-z2 body-container">
			<div class="flex">
				<h2>{{ i18n.detail.paymentReport }}</h2>
				@if (!editing && (permission$ | async)?.write) {
					<button id="edit-btn" class="no-fill-btn back-btn outline" (click)="edit()">
						<mat-icon>edit</mat-icon>
						{{ i18n.detail['edit'] }}
					</button>
				}
				@if (editing) {
					<button id="save-btn" class="no-fill-btn back-btn outline" (click)="save()">
						<mat-icon>done</mat-icon>
						{{ i18n.detail['save'] }}
					</button>
				}
			</div>
			<dk-data-table-edit
				[toggleEdit]="editing"
				[columnsToEdit]="[2]"
				[dataSource]="(report$ | async).records_by_payment_method"
				(saveData)="handleEvent($event)"
				[i18n]="i18n"
				[total]="true"
			></dk-data-table-edit>
		</div>
	}
	@if (isLoading$ | async) {
		<div class="dk-on-progress-overlay">
			<div class="dk-on-progress-spinner-container">
				<mat-spinner></mat-spinner>
			</div>
		</div>
	}
	@if (hasError$ | async) {
		<h2>{{ i18n.not_found }}</h2>
	}
</ng-container>
