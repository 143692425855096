import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'dk-performance-mode-btn',
	templateUrl: './mode-btn.component.html',
	styleUrls: ['./mode-btn.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModeBtnComponent {
	@Input() btnLabel = 'N/A';
	@Input() modLabel = 'N/A';
	@Input() dark = false;
}
