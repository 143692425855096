import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'dk-performance-graph-chart',
	templateUrl: './graph-chart.component.html',
	styleUrls: ['./graph-chart.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphChartComponent implements AfterViewInit, OnChanges {
	@ViewChild('chart') private chartContainer;
	@Input() barLabel = 'label 1';
	@Input() barSeries = [0];
	@Input() unit = '€';
	@Input() barName = 'name bar';
	@Input() yGraphAxisLabel = 'name y graph';
	@Input() yBarAxisLabel = '';
	@Input() graphLabel = 'label 2';
	@Input() graphSeries = [0];
	@Input() graphName = 'name graph';
	@Input() xAxisLabels = [''];
	@Input() dark = false;

	componentIsInit = false;

	ngAfterViewInit(): void {
		this.drawChart();
		const resize$ = fromEvent(window, 'resize');
		resize$.pipe(debounceTime(300)).subscribe(() => {
			this.drawChart();
		});
		this.componentIsInit = true;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.componentIsInit) this.drawChart(true);
	}

	drawChart(update = false) {
		if (!update) {
			echarts.dispose(this.chartContainer.nativeElement);
		}
		const chart = echarts.init(this.chartContainer.nativeElement, null, { renderer: 'svg', height: this.dark ? 650 : 400 });
		const colors = ['#a5aec9', this.dark ? '#2196e2' : '#5470c6'];
		const option: EChartsOption = {
			color: colors,
			textStyle: {
				color: this.dark ? '#fbfbfb' : '#363636',
			},
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
				},
				textStyle: {
					color: this.dark ? '#fbfbfb' : '#363636',
				},
			},
			grid: {
				left: this.dark ? '50' : '0',
			},
			toolbox: {
				feature: {
					dataView: { show: !this.dark, readOnly: false },
					restore: { show: !this.dark },
					saveAsImage: { show: !this.dark },
				},
			},
			legend: {
				data: [this.barName, this.graphName],
				bottom: 40,
				left: 0,
				textStyle: {
					color: this.dark ? '#fbfbfb' : null,
				},
			},
			xAxis: [
				{
					type: 'category',
					axisTick: {
						alignWithLabel: true,
					},
					data: this.xAxisLabels,
					position: 'top',
					offset: 20,
				},
			],
			yAxis: [
				{
					type: 'value',
					name: this.yBarAxisLabel,
					position: 'right',
					alignTicks: true,
					axisLine: {
						show: true,
						lineStyle: {
							color: colors[0],
						},
					},
					axisLabel: {
						formatter: `{value} ${this.unit}`,
					},
					offset: 20,
				},
			],
			series: [
				{
					name: this.barName,
					type: 'bar',
					data: this.barSeries,
					itemStyle: {
						opacity: 0.4,
					},
				},
				{
					name: this.graphName,
					type: 'line',
					data: this.graphSeries,
					lineStyle: {
						color: colors[1],
						width: this.dark ? 5 : 3,
					},
				},
			],
		};
		chart.setOption(option);
	}
}
