import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
	selector: 'dk-filter',
	templateUrl: './filter-report.component.html',
	styleUrls: ['./filter-report.component.scss'],
})
export class FilterReportComponent {
	public i18n$ = combineLatest([
		this.translocoService.selectTranslateObject('channels'),
		this.translocoService.selectTranslateObject('reports'),
	]).pipe(
		map(([channels, reports]) => {
			return { ...channels, salesChannels: reports.filters.salesChannels };
		})
	);

	// salesChannels = Object.values(Channel).sort(sortChannels);
	@Output() filter = new EventEmitter();
	@Input() salesChannels = [];

	constructor(private readonly translocoService: TranslocoService) {}

	setFilter(value) {
		this.filter.emit(value);
	}
}
