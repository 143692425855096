import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'dk-data-table',
	templateUrl: './data-table.component.html',
	styleUrls: ['./data-table.component.scss'],
})
export class AccountingDataTableComponent implements OnInit, OnChanges {
	@Input() dataSource: Array<any> = [];

	@Input() i18n = {};

	filters = {};
	columns: Array<any> = [];

	ngOnInit(): void {
		this.columns = Object.keys(!!this.dataSource[0] && this.dataSource[0]);
	}

	ngOnChanges(): void {
		this.columns = Object.keys(!!this.dataSource[0] && this.dataSource[0]);
	}

	onFilterChange(el, index) {
		this.filters[index] = el.value.toLowerCase();
	}

	isFiltered(row) {
		const entries = Object.entries(row);
		const filters = Object.entries(this.filters);
		for (let i = 0; i < filters.length; i++) {
			if (!entries[filters[i][0]][1].toLowerCase().includes(filters[i][1])) return false;
		}
		return true;
	}

	constructor(private router: Router) {}

	action(row) {
		this.router.navigate(['accounting/review', row.date]);
	}
}
