import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';

import { PageContainerModule, SharedMaterialModule } from '@libs/dash/shared';
import { DttExportRoutingModule } from './dtt-export.routing.module';
import { BkcReportComponent } from './dumb-components/bkc-report/bkc-report-component';
import { BkfReportComponent } from './dumb-components/bkf-report/bkf-report-component';
import { DTT_EXPORT_FACADE } from './facade/dtt-export.facade.injection.token';
import { DttExportFacade } from './facade/dtt-export.facade.services';
import { SecondsToMins } from './pipes/seconds-to-mins.pipe';
import { DttExportComponent } from './view-component/dtt-export.component';

@NgModule({
	declarations: [DttExportComponent, BkfReportComponent, BkcReportComponent],
	imports: [
		CommonModule,
		DttExportRoutingModule,
		PageContainerModule,
		ReactiveFormsModule,
		SharedMaterialModule,
		MatMenuModule,
		MatTabsModule,
		MatRadioModule,
		FormsModule,
		SecondsToMins,
	],
	providers: [
		{
			provide: DTT_EXPORT_FACADE,
			useClass: DttExportFacade,
		},
	],
	exports: [DttExportComponent],
	bootstrap: [],
})
export class DttExportModule {}
