enum TypeOfPaymentIndex {
	CASH = 0,
	CB = 1,
	TPE_MOBILE = 2,
	CRT = 3,
	ANCV = 4,
	BANK_CHECK = 5,
	UBER_EATS = 6,
	PLEASE = 7,
	APETIZ = 8,
	CHEQDEJ = 9,
	EDENRED = 10,
	SODEXO = 11,
	EMPLOYEE_MEAL = 12,
	NOT_RETURNED = 13,
	RENDU = 14,
}

const TypeOfPaymentCategIndexMap = {
	ESPECES: TypeOfPaymentIndex.CASH,
	'TPE MOBILE': TypeOfPaymentIndex.TPE_MOBILE,
	CONECSAPETIZ: TypeOfPaymentIndex.APETIZ,
	CONECSCHEQDEJ: TypeOfPaymentIndex.CHEQDEJ,
	CONECSEDENRED: TypeOfPaymentIndex.EDENRED,
	CONECSSODEXO: TypeOfPaymentIndex.SODEXO,
	PLEASE: TypeOfPaymentIndex.PLEASE,
	'REPAS EMPLOYES': TypeOfPaymentIndex.EMPLOYEE_MEAL,
	CB: TypeOfPaymentIndex.CB,
	CRT: TypeOfPaymentIndex.CRT,
	ANCV: TypeOfPaymentIndex.ANCV,
	CHEQUES: TypeOfPaymentIndex.BANK_CHECK,
	CHEQUE: TypeOfPaymentIndex.BANK_CHECK,
	'UBER EATS': TypeOfPaymentIndex.UBER_EATS,
	UBEREATS: TypeOfPaymentIndex.UBER_EATS,

	EMPLOYEE: TypeOfPaymentIndex.EMPLOYEE_MEAL,
	'NON RENDU': TypeOfPaymentIndex.NOT_RETURNED,
	RENDU: TypeOfPaymentIndex.RENDU,
	// next should be removed after tests
	TRD: TypeOfPaymentIndex.CRT,
	VIRTUALCB: TypeOfPaymentIndex.CB,
	ICOUPON: TypeOfPaymentIndex.CB,
	'ANCV CONNECT': TypeOfPaymentIndex.ANCV,
	'CLICK COLLECT': TypeOfPaymentIndex.CB,
};

const initialEntriesSource = [
	{ category: 'CASH', comment: '', amount: { real: 0, theoretical: 0 } },
	{ category: 'CB', comment: '', amount: { real: 0, theoretical: 0 } },
	{
		category: 'TPE_MOBILE',
		comment: '',
		amount: { real: 0, theoretical: 0 },
	},
	{ category: 'CRT', comment: '', amount: { real: 0, theoretical: 0 } },
	{ category: 'ANCV', comment: '', amount: { real: 0, theoretical: 0 } },
	{
		category: 'BANK_CHECK',
		comment: '',
		amount: { real: 0, theoretical: 0 },
	},
	{
		category: 'UBER_EATS',
		comment: '',
		amount: { real: 0, theoretical: 0 },
	},
	{ category: 'PLEASE', comment: '', amount: { real: 0, theoretical: 0 } },
	{ category: 'APETIZ', comment: '', amount: { real: 0, theoretical: 0 } },
	{
		category: 'CHEQDEJ',
		comment: '',
		amount: { real: 0, theoretical: 0 },
	},
	{
		category: 'EDENRED',
		comment: '',
		amount: { real: 0, theoretical: 0 },
	},
	{
		category: 'SODEXO',
		comment: '',
		amount: { real: 0, theoretical: 0 },
	},
	{
		category: 'EMPLOYEE_MEAL',
		comment: '',
		amount: { real: 0, theoretical: 0 },
	},
	{
		category: 'NOT_RETURNED',
		comment: '',
		amount: { real: 0, theoretical: 0 },
	},
	{
		category: 'RENDU',
		comment: '',
		amount: { real: 0, theoretical: 0 },
	},
];

export { initialEntriesSource, TypeOfPaymentCategIndexMap, TypeOfPaymentIndex };
