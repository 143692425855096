import { Component, Inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { ANALYZE_HISTORY_FACADE } from '../../facade/analyze-history-facade.injection.token';
import { AnalyzeHistoryServiceProvider } from '../../facade/analyze-history-facade.provider.interface';

@Component({
	selector: 'dk-passed-channel-performance-tile',
	templateUrl: './passed-channel-performance-tile.component.html',
	styleUrls: ['./passed-channel-performance-tile.component.scss'],
})
export class ChannelPerformanceTileComponent {
	public viewData$ = combineLatest([
		this._analyzeHistoryFacade.channelPerformanceTableData$,
		this._translocoService.selectTranslateObject('channelPerformanceTile'),
	]).pipe(
		map(([channelPerformanceTableData, i18n]) => {
			return { channelPerformanceTableData, i18n };
		})
	);

	constructor(
		private _translocoService: TranslocoService,
		@Inject(ANALYZE_HISTORY_FACADE) private _analyzeHistoryFacade: AnalyzeHistoryServiceProvider
	) {}
}
