<div class="dk-picker" [class.single-display]="singleDaySelection()">
	<ng-content></ng-content>
	<mat-form-field appearance="fill" subscriptSizing="dynamic">
		<mat-label>{{ localeText.chooseDates }}</mat-label>
		<mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
			<input [placeholder]="localeText.startDate" formControlName="from" matStartDate [hidden]="singleDaySelection()"/>
			<input [placeholder]="localeText.endDate" formControlName="to" matEndDate [class.single-display]="singleDaySelection()"/>
		</mat-date-range-input>
		<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
		<mat-date-range-picker #picker>
			<mat-date-range-picker-actions>
				<button mat-button matDateRangePickerCancel>{{ localeText.cancel }}</button>
				<button (click)="setPeriod('period')" color="primary" mat-raised-button matDateRangePickerApply>{{ localeText.apply }}</button>
			</mat-date-range-picker-actions>
		</mat-date-range-picker>
	</mat-form-field>
	<button (click)="setPeriod('today')" mat-button>{{ localeText.today }}</button>
	<button (click)="setPeriod('week')" mat-button>{{ localeText.week }}</button>
	<button (click)="setPeriod('month')" mat-button>{{ localeText.month }}</button>
	@if (isLoading | async) {
		<mat-spinner class="d-inline" diameter="20"></mat-spinner>
	}
	<button mat-button [matMenuTriggerFor]="recordsMenu" class="export-records">{{ localeText.downloadAll }}</button>
	<mat-menu #recordsMenu="matMenu" yPosition="above" xPosition="before">
		<button id="csv-discount-report" mat-menu-item (click)="downloadAll()">{{ localeText.exportCsv }}</button>
	</mat-menu>
</div>
@for (record of data; track record) {
	<mat-accordion>
		<mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
			<mat-expansion-panel-header class="pl-0">
				<span>{{ localeText.name }}&nbsp;</span> : <span class="font-weight-bold">&nbsp;{{ record.managerSource[0].login }}</span><span
				class="manager-id">{{ record.managerSource[0].id }}</span>
				<span class="limiter">|</span>
				<span>{{ localeText.validationTime }}&nbsp;</span> : <span class="font-weight-bold">&nbsp;{{ record.date }}</span>
			</mat-expansion-panel-header>
			<table mat-table [dataSource]="record.entries" class="mat-elevation-z8 w-100 mt-5">
				<ng-container matColumnDef="accounts">
					<th mat-header-cell *matHeaderCellDef>{{ localeText.accounts }}</th>
					<td mat-cell *matCellDef="let element">{{ localeText.paymentMethods[element.label] || element.label }}</td>
					<td mat-footer-cell *matFooterCellDef class="uppercase">{{ localeText.total }}</td>
				</ng-container>
				<ng-container matColumnDef="amountTh">
					<th mat-header-cell *matHeaderCellDef>{{ localeText.amountTh }}</th>
					<td mat-cell *matCellDef="let element">{{ element.amount.theoretical | currency: 'EUR' }}</td>
					<td mat-footer-cell *matFooterCellDef>{{ totalCost(record, 1) | currency: 'EUR' }}</td>
				</ng-container>
				<ng-container matColumnDef="declaredAmount">
					<th mat-header-cell *matHeaderCellDef>{{ localeText.declaredAmount }}</th>
					<td mat-cell *matCellDef="let element">{{ element.amount.real | currency: 'EUR' }}</td>
					<td mat-footer-cell *matFooterCellDef>{{ totalCost(record, 2) | currency: 'EUR' }}</td>
				</ng-container>
				<ng-container matColumnDef="gap">
					<th mat-header-cell *matHeaderCellDef>{{ localeText.gap }}</th>
					<td mat-cell *matCellDef="let element">{{ element.amount.theoretical - element.amount.real | currency: 'EUR' }}</td>
					<td mat-footer-cell *matFooterCellDef>{{ totalCost(record, 3) | currency: 'EUR' }}</td>
				</ng-container>
				<ng-container matColumnDef="comment">
					<th mat-header-cell *matHeaderCellDef>{{ localeText.comment }}</th>
					<td mat-cell *matCellDef="let element">{{ element.comment }}</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="displayedColumnsEntries"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumnsEntries"></tr>
				<tr mat-footer-row *matFooterRowDef="displayedColumnsEntries" class="bg-light-blue font-weight-bold"></tr>
			</table>
			<p class="export-one-record" (click)="downloadOne(record)">{{ localeText.downloadOne }}</p>
		</mat-expansion-panel>
	</mat-accordion>
}
