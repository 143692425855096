import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
	selector: 'dk-password-renewal-sent',
	templateUrl: './password-renewal-sent.component.html',
	styleUrls: ['./password-renewal-sent.component.scss'],
})
export class PasswordRenewalSentComponent {
	localisedTexts$ = this._translocoService.selectTranslateObject('password-renewal-sent');

	constructor(private _translocoService: TranslocoService) {}
}
