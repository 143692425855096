@if (localisedTexts$ | async; as i18n) {
	<div>
		<h4>{{ i18n.title }}</h4>
		@if (dateSelected) {
			<div class="dk-grid" #grid [style]="normalizeGrid(grid)">
				<div class="row">
					@if (featureMap.revenue) {
						<dk-passed-revenue-tile></dk-passed-revenue-tile>
					}
					@if (featureMap.waitingTime) {
						<dk-passed-waiting-time-tile></dk-passed-waiting-time-tile>
					}
					@if (featureMap.ratioChannel) {
						<div class="dk-grid-tile">
							<h4>{{ i18n.tiles['channel-load-ratio'].title }}</h4>
							<dk-horizontal-bars [bars]="channelPermoranceInPercentage$ | async"></dk-horizontal-bars>
						</div>
					}
				</div>
				<div class="row">
					@if (featureMap.channelPerformance) {
						<dk-passed-channel-performance-tile></dk-passed-channel-performance-tile>
					}
					@if (featureMap.topTenProducts) {
						<dk-passed-top-ten-products-tile></dk-passed-top-ten-products-tile>
					}
				</div>
				<div class="row">
					@if (featureMap.drive) {
						<dk-passed-sos-tile></dk-passed-sos-tile>
					}
					@if (featureMap.kiosk) {
						<dk-passed-kiosk-sos-tile></dk-passed-kiosk-sos-tile>
					}
				</div>
			</div>
		} @else {
			@if (localisedTexts$ | async; as localisedTexts) {
				<div class="dk-grid">
					<div class="dk-grid-tile">{{ localisedTexts.warnings.noDate }}</div>
				</div>
			}
		}
	</div>
}
