import { Component, Input } from '@angular/core';

@Component({
	selector: 'indicator-card',
	templateUrl: './indicator.component.html',
	styleUrls: ['./indicator.component.scss'],
})
export class IndicatorComponent {
	objectiveMs: number = 0;

	@Input() label: string;
	@Input() percent: number;
	@Input() set objective(objective: number) {
		this.objectiveMs = objective * 1000;
	}

	colors = {
		red: {
			foreground: '#EF9FAC',
			background: '#F4C3CB',
		},
		green: {
			foreground: '#93E1B5',
			background: '#BBDBC9',
		},
		blue: {
			foreground: '#A9D3FF',
			background: '#CCE5FF',
		},
	};

	get classColor(): 'green' | 'red' | 'gray' {
		if (this.percent === 0) {
			return 'gray';
		} else if (this.percent < 0) {
			return 'green';
		} else {
			return 'red';
		}
	}

	get icon(): 'arrow_downward' | 'drag_handle' | 'arrow_upward' | 'visibility_off' {
		return this.classColor == 'red'
			? 'arrow_upward'
			: this.classColor == 'green'
				? 'arrow_downward'
				: this.classColor == 'gray'
					? 'visibility_off'
					: 'drag_handle';
	}
}
