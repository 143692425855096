import { Component, inject } from '@angular/core';
import { UiVersionDetectorService } from '@libs/shared/services';

@Component({
	selector: 'time-of-service',
	templateUrl: './time-of-service.component.html',
	styleUrls: ['./time-of-service.component.scss'],
})
export class TimeOfServiceComponent {
	private readonly _newUiDetector: UiVersionDetectorService = inject(UiVersionDetectorService);
	isNewRoute = this._newUiDetector.isNewUi();
}
