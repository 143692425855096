import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EmployeeMealsComponent } from './view-component/employee-meals.component';

const routes: Routes = [
	{
		path: '',
		component: EmployeeMealsComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class EmployeeMealsRoutingModule {}
