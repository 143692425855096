import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { WeeklyReport } from '@dki/api-client';

import { getOrder, Range } from '@libs/dash/core/entity';
import { generateXlsx, ReportOptions } from '@libs/dash/shared';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import { DateTime } from 'luxon';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { REPORTS_FACADE } from '../../facade/reports-facade.injection.token';
import { ReportsServiceProvider } from '../../facade/reports-facade.provider.interface';

@UntilDestroy()
@Component({
	selector: 'dk-weekly-report-tile',
	templateUrl: './weekly-report-tile.component.html',
	styleUrls: ['./weekly-report-tile.component.scss'],
})
export class WeeklyReportTileComponent implements OnInit {
	isLoading = this.reportsServiceProvider.isLoadingWeeklyReport$;
	weeklyReport: WeeklyReport;
	i18n: Record<string, any>;
	selectedRestaurants: Array<string>;
	@ViewChild('selectedWeek') private weekendInput: ElementRef;

	date = new FormControl(this.reportsServiceProvider.ranges.weeklyReport.toJSDate());

	weekDetails: {
		number: number;
		dateFrom: DateTime;
		dateTo: DateTime;
	};

	weekselected = `${this.reportsServiceProvider.ranges.weeklyReport.startOf(Range.Week).toFormat('dd/MM/yyyy')} - ${this.reportsServiceProvider.ranges.weeklyReport
		.endOf(Range.Week)
		.toFormat('dd/MM/yyyy')}`;

	public viewData$ = combineLatest([
		this.reportsServiceProvider.weeklyReport$,
		this.translocoService.selectTranslateObject('weeklyReportTile'),
	]).pipe(
		filter(([weeklyReport, i18n]) => !!weeklyReport),
		map(([weeklyReport, i18n]) => {
			this.weeklyReport = weeklyReport;
			this.i18n = i18n;
			return { weeklyReport, i18n };
		})
	);

	constructor(
		@Inject(REPORTS_FACADE) private readonly reportsServiceProvider: ReportsServiceProvider,
		private readonly translocoService: TranslocoService
	) {}

	ngOnInit() {
		this.reportsServiceProvider.weeklyReportParams$.pipe(untilDestroyed(this)).subscribe((weekNumber) => {
			const date = weekNumber ? weekNumber : DateTime.now();
			this.weekDetails = {
				number: date.startOf(Range.Week).weekNumber,
				dateFrom: date.startOf(Range.Week),
				dateTo: date.endOf(Range.Week),
			};
		});
		this.reportsServiceProvider.selectedRestaurants$
			.pipe(
				untilDestroyed(this),
				filter((restaurants) => restaurants.length > 0 && !!restaurants[0])
			)
			.subscribe((restaurants) => {
				this.selectedRestaurants = restaurants.map((restaurant) => restaurant.name);
			});
	}

	setWeek(period) {
		let date: DateTime<boolean> = DateTime.now();
		switch (period) {
			case Range.Date:
				date = DateTime.fromJSDate(this.date.value);
				break;
			case Range.Next:
				date = DateTime.fromJSDate(this.date.value);
				date = date.plus({ weeks: 1 });
				this.date.setValue(date.toJSDate(), { emitEvent: false });
				break;
			case Range.Prev:
				date = DateTime.fromJSDate(this.date.value);
				date = date.plus({ weeks: -1 });
				this.date.setValue(date.toJSDate(), { emitEvent: false });
				break;
		}
		this.weekselected = `${date.startOf(Range.Week).toFormat('dd/MM/yyyy')} - ${date.endOf(Range.Week).toFormat('dd/MM/yyyy')}`;
		this.reportsServiceProvider.getWeeklyReport(date.startOf(Range.Week));
	}

	sortPaymentMethods = (a, b) => getOrder(a.key) - getOrder(b.key);

	originalOrder = () => 0;

	vatOrder = (x, y) => x.key - y.key;

	downloadCsv() {
		const weekdays = Object.keys(this.i18n.weekdays);

		// flatting the weird report structure

		const salesBrut = {
			[this.i18n.sales]: this.i18n.salesBrute,
			...this.weeklyReport.gross_value.by_weekday,
			[this.i18n.grandTotal]: this.weeklyReport.gross_value.total,
		};

		const salesNet = {
			[this.i18n.sales]: this.i18n.salesNet,
			...this.weeklyReport.net_value.by_weekday,
			[this.i18n.grandTotal]: this.weeklyReport.net_value.total,
		};

		const vats = [];
		Object.keys(this.weeklyReport.vat)
			.sort((x, y) => parseFloat(x) - parseFloat(y))
			.forEach((vat) => {
				vats.push({
					[this.i18n.sales]: `${this.i18n.value_by_vat.vat} ${this.i18n.value_by_vat[vat] || vat}%`,
					[this.i18n.grandTotal]: this.weeklyReport.vat[vat].total,
					...this.weeklyReport.vat[vat].by_weekday,
				});
			});

		const paymentMethods = [];
		Object.keys(this.weeklyReport.value_by_payment_method).forEach((paymentMethod) => {
			if (!paymentMethod.includes('RENDU')) {
				paymentMethods.push({
					[this.i18n.sales]: `${this.i18n.value_by_payment_method[paymentMethod] || paymentMethod}`,
					[this.i18n.grandTotal]: this.weeklyReport.value_by_payment_method[paymentMethod].total,
					...this.weeklyReport.value_by_payment_method[paymentMethod].by_weekday,
				});
			}
		});

		const offers = {
			[this.i18n.sales]: this.i18n.offers,
			...this.weeklyReport.free_item_value.by_weekday,
			[this.i18n.grandTotal]: this.weeklyReport.free_item_value.total,
		};

		// Exporting csv...
		const options = {
			filename: this.i18n.title,
			decimalSeparator: '.',
			fieldSeparator: ';',
			showLabels: true,
			showTitle: true,
			title: this.i18n.title,
			useKeysAsHeaders: true,
		};
		const csvExporter = new ExportToCsv(options);
		const weeklyReportCsv = [salesBrut, salesNet, ...vats, ...paymentMethods, offers];

		// translating weekdays
		weeklyReportCsv.map((el) => {
			weekdays.forEach((weekday) => {
				delete Object.assign(el, { [this.i18n.weekdays[weekday]]: el[weekday] })[weekday];
			});
		});
		csvExporter.generateCsv(weeklyReportCsv);
	}

	downloadPDF() {
		const title = String(`${this.i18n.title}_${DateTime.fromJSDate(this.date.value).toFormat('yyyy-MM-dd')}`);
		const source = document.getElementById('dki-weekly-report');
		const doc = new jsPDF({ orientation: 'l', putOnlyUsedFonts: true });
		doc.html(source, {
			html2canvas: {
				scale: 0.28,
				letterRendering: false,
				ignoreElements: (e) => e.classList.contains('export-buttons'),
			},
			margin: [30, 5, 5, 5],
			windowWidth: 1000,
			width: 900,
			fontFaces: [
				{
					family: 'Roboto',
					src: [
						{
							url: '/assets/fonts/roboto.ttf',
							format: 'truetype',
						},
					],
				},
			],
			callback: (doc) => {
				doc.setFontSize(16);
				doc.setFont(undefined, 'bold');
				doc.text(this.selectedRestaurants.join(','), 20, 15);
				const date = this.weekselected;
				doc.setFontSize(12);
				doc.setFont(undefined, 'light');
				doc.text(`${date}`, 20, 20);

				doc.save(title);
			},
		});
	}

	downloadXlsx(): void {
		const weekStartDate = this.weekDetails.dateFrom;
		const weekEnd = this.weekDetails.dateTo.toFormat('dd/LL/yyyy');
		const detailText = `Restaurants : ${this.selectedRestaurants.join(', ')}\nSemaine du ${weekStartDate.toFormat('dd/LL/yyyy')} au ${weekEnd}`;

		const weeklyReportOptions: ReportOptions = {
			setupHeaders: (sheet) => {
				const headerRow = ['Jour'];
				const weekStartDate = this.weekDetails.dateFrom;

				Object.keys(this.i18n.weekdays).forEach((dayKey, index) => {
					const dayDate = weekStartDate.plus({ days: index });
					headerRow.push(`${this.i18n.weekdays[dayKey]} ${dayDate.toFormat('dd/LL')}`);
				});

				headerRow.push('Total');
				const headerRowRef = sheet.addRow(headerRow);
				headerRowRef.eachCell((cell) => {
					cell.font = { bold: true };
				});
				return headerRowRef;
			},

			prepareDataRows: (sheet) => {
				const netSalesRow = ['Ventes HT'];
				const grossSalesRow = ['Ventes TTC'];
				const offersRow = ['Offers'];

				Object.keys(this.i18n.weekdays).forEach((dayKey) => {
					netSalesRow.push(this.weeklyReport.net_value.by_weekday[dayKey] || 0);
					grossSalesRow.push(this.weeklyReport.gross_value.by_weekday[dayKey] || 0);
					offersRow.push(this.weeklyReport.free_item_value.by_weekday[dayKey] || 0);
				});

				netSalesRow.push(
					netSalesRow
						.slice(1)
						.reduce((sum, val) => sum + Number(val), 0)
						.toFixed(2)
				);
				grossSalesRow.push(
					grossSalesRow
						.slice(1)
						.reduce((sum, val) => sum + Number(val), 0)
						.toFixed(2)
				);
				offersRow.push(
					offersRow
						.slice(1)
						.reduce((sum, val) => sum + Number(val), 0)
						.toFixed(2)
				);

				sheet.addRow(netSalesRow);
				sheet.addRow(grossSalesRow);
				sheet.addRow(offersRow);
			},

			generateFileName: () => {
				const weekEnd = this.weekDetails.dateTo.toFormat('dd/LL/yyyy');
				const weekStartDate = this.weekDetails.dateFrom;
				return `RapportHebdomadaire-${weekStartDate.toFormat('ddLLyyyy')}-${weekEnd}.xlsx`;
			},
		};

		generateXlsx('Rapport Hebdomadaire', detailText, weeklyReportOptions);
	}
}
